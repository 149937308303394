import React from 'react';
import { AppCard, AppCardHeader, AppSectionHeader, Panel } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { Lead } from '../../../../types/Lead';

export const AppEventLiveChat: React.FC<{ lead: Lead }> = ({ lead }) => {
    const data = lead.chat;
    if (!data) {
        return null;
    }
    return (
        <AppCard>
            <AppCardHeader
                icon={{ name: 'chat' }}
                title="Live Chat"
                description={DateTime.fromISO(lead.created_at as string).toLocaleString(DateTime.DATETIME_SHORT)}
            />
            <AppSectionHeader title="Chat Comments" as="Header" />
            <Panel className="app-event-card-live-chat-comments">
                <code>{data.comments}</code>
            </Panel>
        </AppCard>
    );
};
