import React from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { Button, ButtonPrimary, Form, IconButton, InputText } from '@lambdacurry/component-library';
import { Avatar, Title, SubTitle, InputPhone, AppCard, AppCardHeader, ActionList } from '../../../components-v2/shared';
import { phoneFormatter } from '../../../util/formatters';

import './lead-details.scss';
import {
    CustomerCardStatuses,
    LeadCustomer,
    LeadCustomerUpdate,
    LeadDetailsAction,
    LeadDetailsCustomerValidationSchema,
    LeadDetailsState
} from './LeadDetails.helpers';

export const LeadDetailsCustomerCard: React.FC<{
    updateCustomer: (values: LeadCustomerUpdate, formikHelpers: FormikHelpers<LeadCustomer>) => Promise<void>;
    state: LeadDetailsState;
    dispatch: React.Dispatch<LeadDetailsAction>;
}> = ({ updateCustomer, state, dispatch }) => {
    const { customerCardStatus, customer } = state;

    const toggleEditing = () => dispatch({ name: 'toggleCustomerCardEditingStatus' });
    const handleSubmit = async (
        { first_name, last_name, first_name_secondary, last_name_secondary, email, phone_number }: LeadCustomer,
        formikHelpers: FormikHelpers<LeadCustomer>
    ) => {
        await updateCustomer(
            { first_name, last_name, first_name_secondary, last_name_secondary, email, phone_number },
            formikHelpers
        );
        toggleEditing();
    };

    if (!customer) {
        return <AppCard className="lead-details-card">Loading...</AppCard>;
    }

    if (customerCardStatus === CustomerCardStatuses.VIEWING) {
        return (
            <AppCard className="lead-details-card lead-details-card--customer">
                <IconButton className="lead-details-card-edit" icon="pencil" onClick={toggleEditing} />
                <Avatar>{`${customer.first_name.charAt(0)}${customer.last_name.charAt(0)}`}</Avatar>
                <Title>
                    {customer.first_name} {customer.last_name}
                </Title>
                {customer.first_name_secondary && (
                    <SubTitle>
                        {customer.first_name_secondary + ' '}
                        {customer.last_name_secondary + ' (Representative)'}
                    </SubTitle>
                )}

                {customer.email && (
                    <a className="lead-details-card--customer-email" href={`mailto:${customer.email}`}>
                        {customer.email}
                    </a>
                )}
                {customer.phone_number && (
                    <a className="lead-details-card--customer-phone" href={`tel:${customer.phone_number}`}>
                        {phoneFormatter(customer.phone_number)}
                    </a>
                )}
            </AppCard>
        );
    }

    return (
        <AppCard>
            <AppCardHeader title="Customer Details" />
            <Form
                initialValues={{ ...customer } as LeadCustomer}
                validationSchema={LeadDetailsCustomerValidationSchema}
                onSubmit={handleSubmit}
            >
                {(formikProps: FormikProps<LeadCustomer>) => {
                    return (
                        <>
                            <div className="field-row">
                                <InputText label="First Name" name="first_name" formikProps={formikProps} />
                                <InputText label="Last Name" name="last_name" formikProps={formikProps} />
                                {customer.first_name_secondary && (
                                    <>
                                        <InputText
                                            label="First Name (Rep)"
                                            name="first_name_secondary"
                                            formikProps={formikProps}
                                        />
                                        <InputText
                                            label="Last Name (Rep)"
                                            name="last_name_secondary"
                                            formikProps={formikProps}
                                        />
                                    </>
                                )}
                            </div>
                            <InputText label="Email" name="email" formikProps={formikProps} />
                            <InputPhone label="Phone Number" name="phone_number" formikProps={formikProps} />
                            <ActionList position="end">
                                <Button onClick={toggleEditing}>Cancel</Button>
                                <ButtonPrimary disabled={formikProps.isSubmitting} type="submit">
                                    {formikProps.isSubmitting ? 'Saving' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </>
                    );
                }}
            </Form>
        </AppCard>
    );
};
