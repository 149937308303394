import {
    Button,
    ButtonPrimary,
    Icon,
    InputCheckbox,
    InputColor,
    InputSelect,
    InputText,
    InputTextarea,
    Modal,
    ModalActions,
    ModalHeader
} from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';
import { ActionList, AppCard, AppSectionHeader, Panel } from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import {
    colorPickerDefaults,
    labels,
    SectionProps,
    widgetSizeOptions,
    widgetSkinOptions
} from '../../ApplicationSettings.helpers';
import './styling-section.scss';

export const StylingSection: FC<SectionProps> = observer(({ formikProps, formIsDisabled }) => {
    const { store } = useStore();
    const [showModal, setShowModal] = useState<boolean>(false);

    const revertToLegacy = async () => {
        formikProps.setFieldValue('contrast', 0);
        formikProps.setFieldValue('beta_widget_enabled', false);
        formikProps.setFieldValue('widget_style', 'light')
        await formikProps.validateForm();
        formikProps.submitForm(); // Triggers parent Form `handleSubmit` function
    };

    return (
        <>
            <AppCard className="styling-section">
                {!store.isCompanyLightRole && (
                    <Panel type="default" className="clx-margin-top-0">
                        You're using the updated configuration.{' '}
                        <span className="legacy-link" onClick={() => setShowModal(true)}>
                            Use legacy config instead?
                        </span>
                    </Panel>
                )}

                <AppSectionHeader title="Brand Colors" />

                <div className="field-row">
                    {colorPickerDefaults.map(({ fieldName }) => (
                        <InputColor
                            key={fieldName}
                            name={fieldName}
                            label={labels[fieldName]}
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                        />
                    ))}
                </div>
                {formikProps.errors['contrast'] && (
                    <div className="styling-section-contrast-error">{formikProps.errors['contrast']}</div>
                )}

                <AppSectionHeader title="Style" />
                <InputSelect
                    label="Container Theme"
                    name="skin"
                    options={widgetSkinOptions}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                {formikProps.values.skin !== 'skin1' && (
                    <InputSelect
                        label="App Size"
                        name="widget_size"
                        options={widgetSizeOptions}
                        formikProps={formikProps}
                        disabled={formIsDisabled}
                        optionValueKey="value"
                        value={widgetSizeOptions.filter(size => size.value === 'large')[0]}
                    />
                )}
                <InputSelect
                    label="Desktop: Collapse Threshold"
                    name="collapse_threshhold"
                    optionLabelKey='label'
                    options={[{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }]}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <InputSelect
                    label="Mobile: Collapse Threshold"
                    name="collapse_threshhold_mobile"
                    optionLabelKey='label'
                    options={[{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }]}
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                    optionValueKey="value"
                />
                <em>The Collapse Threshhold is the maximum number of apps to display before stacking them.</em>
                <hr />
                <InputCheckbox
                    name="widgetbar_expanded"
                    className="clx-margin-bottom-16"
                    label="Expanded by default"
                    labelPlacement="end"
                    formikProps={formikProps}
                    disabled={formIsDisabled}
                />

                {formikProps.values.skin === 'skin1' && (
                    <>
                        <AppSectionHeader title="Application Message" />
                        <InputText
                            name="wallet_greeting_heading"
                            label="Greeting"
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                            InputLabelProps={{ shrink: true }}
                            placeholder="Hello!"
                        />
                        <InputTextarea
                            name="wallet_greeting_subheading"
                            label="Message to Customer"
                            formikProps={formikProps}
                            disabled={formIsDisabled}
                            characterLimit={100}
                            InputLabelProps={{ shrink: true }}
                            placeholder="We're committed to making you feel at home before you've even stepped through the door. Use the tools below to get started."
                        />
                    </>
                )}
            </AppCard>

            <Modal isOpen={showModal} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="icon color-danger" />
                            Are you sure you want to use the legacy application?
                        </>
                    }
                />
                <p>
                    Your updated application settings will be saved. You can switch back to the updated application at
                    any time. Please note: Legacy application users will be upgraded to the updated application in the
                    future.
                </p>
                <ModalActions>
                    <ActionList position="end">
                        <Button aria-label="Cancel" onClick={() => setShowModal(false)}>
                            Keep using updated config
                        </Button>
                        <ButtonPrimary aria-label="Confirm" onClick={revertToLegacy}>
                            Take me to legacy
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>
        </>
    );
});
