import * as React from 'react';
import { FormikProps, FormikHelpers } from 'formik';
import { Form, InputText, ButtonPrimary} from '@lambdacurry/component-library';

export interface ITask {
    uniqueName: string;
    friendlyName: string;
}

interface TaskFormProps {
    handleSubmit: (values: any, actions: FormikHelpers<any>) => Promise<void>;
}

export const TaskForm: React.FC<TaskFormProps> = ({handleSubmit}) => {
        return (
            <Form className="form-holder-block" initialValues={{uniqueName: '', friendlyName: ''}} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<ITask>) => (
                    <>
                        <div>
                            <InputText label="Unique Name" name="uniqueName" formikProps={formikProps} />
                            <InputText label="Friendly Name" name="friendlyName" formikProps={formikProps} />
                        </div>
                        <div>
                            <ButtonPrimary type="submit">{formikProps.isSubmitting ? 'Saving' : 'Save Task'}</ButtonPrimary>
                        </div>
                    </>
                )}
            </Form>
        );
};