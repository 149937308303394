import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Header } from '../../../components-v2/shared';
import { FormikHelpers } from 'formik';
import { SampleForm } from '../Task/SampleForm';
import { InjectedProps } from '../../../types';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import Alert from '../../misc/AlertDismissable';
import { ButtonPrimary, ButtonWarning } from '@lambdacurry/component-library';

export const labels = {
    taggedText: 'Sample Text'
}

interface SampleEditState {
    updatedSample?: any;
    deletedSample?: any;
}

@observer
@inject('store')
export class SampleEdit extends React.Component<{}, SampleEditState> {
    public state: SampleEditState = {
    
    }

    public get injected() {
        return this.props as InjectedProps;
    }

    public handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
        const { updateAutoPilotSample } = this.injected.store;
        updateAutoPilotSample(
            this.injected.store.router.params.assistantSid,
            this.injected.store.router.params.taskSid,
            this.injected.store.router.params.sampleSid,
            {
                taggedText: values.taggedText
            }
        ).then(data => {
            this.setState({
                updatedSample: data
            });
            actions.resetForm();
            actions.setSubmitting(false);
        });
    }

    public handleDismissAlert = () => {
        this.setState({ updatedSample: undefined, deletedSample: undefined });
    }

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotEditTask, {assistantSid: this.injected.store.router.params.assistantSid, sid: this.injected.store.router.params.taskSid});
    }

    public deleteSample = async () => {
        const { deleteAutoPilotSample } = this.injected.store;
        deleteAutoPilotSample(
            this.injected.store.router.params.assistantSid,
            this.injected.store.router.params.taskSid,
            this.injected.store.router.params.sampleSid
        ).then(data => {
            this.setState({
                deletedSample: data
            });
        });
    }

    render() {
        const { updatedSample, deletedSample } = this.state;
        return (
            <>
                <Header>Edit Sample</Header>
                <div>
                    <Alert show={updatedSample || deletedSample}>
                        Sample was successfully {updatedSample ? "updated" : "deleted"}.
                    </Alert>
                    <SampleForm handleSubmit={this.handleSubmit}/>
                    <div>
                        <ButtonPrimary onClick={this.handleBack}>
                            Back
                        </ButtonPrimary>
                        <ButtonWarning onClick={this.deleteSample}>
                            Delete Sample
                        </ButtonWarning>
                    </div>
                </div>
            </>
        );
    }
}