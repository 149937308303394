import * as React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faRocket, IconDefinition } from '@fortawesome/free-solid-svg-icons';

export interface GenericButtonProps {
    [index: string]: any;
    showIcon?: boolean;
    icon?: IconDefinition;
    text?: string;
    disabled?: boolean;
}

const ControlButtonSubmit = (props: GenericButtonProps) => {
    const buttonText = props.text || 'Submit';
    const icon = props.icon || faRocket;
    const useIcon = props.showIcon !== false;
    return (
        <Button className="btn btn-green btn-success" disabled={props.disabled} type="submit">
            {props.isSaving && <FontAwesomeIcon className="submit-icon" icon={faSpinner} spin size="lg" />}
            {!props.isSaving && buttonText}
            {!props.isSaving && useIcon && (
                <div className="submit-icon">
                    <FontAwesomeIcon icon={icon} size="lg" />
                </div>
            )}
        </Button>
    );
};

export default ControlButtonSubmit;
