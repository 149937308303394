import React, { useReducer } from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';

import { AppPage, AppHeader, ActionList, useSnackbar, IconButton } from '../../components-v2/shared';
import { DeleteCrmModal, EditCrmForm } from './components';

import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { openModal, crmReducer, crmInitialState } from './Crm.helpers';
import { Crm } from '../../types/Crm';
import { CrmEmailsToArray } from './components/helpers/FormHelper';
import { cloneDeep } from 'lodash';
import { Rule, RuleAction } from '../../types';

export const CrmEdit = observer(() => {
    const [state, dispatch] = useReducer(crmReducer, crmInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, crms, fetchCrms } = store;
    const { companyId, crmId } = router.params;
    const crm = crms.getItem(parseInt(crmId, 10));

    const fetchRequiredData = async () => {
        if (!companyId) {
            return;
        }

        await fetchCrms(parseInt(crmId, 10));
    };
    useAsyncEffect(fetchRequiredData);

    const initialValues = () => {
        if (!crm) {
            return {};
        }

        return {
            ...crm
        };
    };

    const handleValidate = async (values: Crm, crmType: string) => {
        const { validateCrmConfiguration } = store;
        validateCrmConfiguration({ ...values, crm_type: crmType }, addSnackbar);
    };

    const handleSubmit = async (values: Crm, actions: FormikHelpers<Partial<Crm>>, crmType: string) => {
        try {
            const data = cloneDeep(values);
            if (data.configuration.crm_emails) {
                data.configuration.crm_emails = CrmEmailsToArray(data.configuration.crm_emails);
            }
            data.ruleset &&
                data.ruleset.rules.forEach((rule: Rule) => {
                    rule.actions.forEach((action: RuleAction) => {
                        if (action.actionType === 'ReplaceStringArray') {
                            action.options.newValues = CrmEmailsToArray(action.options.newValues);
                        }
                    });
                });
            delete data.default;
            await crms.update({ ...data, crm_type: crmType });
            addSnackbar(`CRM "${crm.name}" has been updated.`, { variant: 'success' });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    return (
        <>
            <AppPage loading={!crm}>
                {crm && (
                    <>
                        <AppHeader
                            title={`CRM: ${crm.name}`}
                            onBack={() => router.goTo(DefaultRoutes.CrmList, { companyId }, store)}
                        >
                            <ActionList position="end">
                                <IconButton icon="trash" onClick={() => openModal(dispatch, 'deleteCrm')} />
                            </ActionList>
                        </AppHeader>
                        <EditCrmForm
                            enableReinitialize={true}
                            initialValues={initialValues()}
                            onSubmit={handleSubmit}
                            onValidate={handleValidate}
                            crmType={crm.crm_type}
                        />

                        <DeleteCrmModal
                            isOpen={state.modal.active === 'deleteCrm'}
                            dispatch={dispatch}
                            crms={crms}
                            crm={crm}
                            onAfterDelete={() => router.goTo(DefaultRoutes.CrmList, { companyId }, store)}
                        />
                    </>
                )}
            </AppPage>
        </>
    );
});
