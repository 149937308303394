import { FormikProps } from 'formik';
import React, { FC } from 'react';
import { AppSection, AppSectionHeader, InputSelect } from '../../../../components-v2/shared';
import { getHouseholds } from '../../../../util/calculator';
import { createNumericalInputOptions } from '../../../../util/form';
import { moneyFormatter } from '../../../../util/formatters';

import './households-table.scss';

export interface HouseholdsTableProps {
    formikProps: FormikProps<any>;
}

export const HouseholdsTableCell: FC<{ as?: string }> = ({ as = 'td', ...props }) => {
    const options = {
        td: 'td',
        th: 'th'
    };

    if (!options[as]) {
        return null;
    }

    const T = options[as];

    return <T className={`households-table-${T === 'td' ? 'data' : 'header'}`} {...props} />;
};

export const HouseholdsTable: FC<HouseholdsTableProps> = ({ formikProps }) => {
    const { ami_one, ami_two, ami_three, household, household_size } = formikProps.values;
    const households = getHouseholds(household, [ami_one, ami_two, ami_three]);
    const numbers = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight'];

    return (
        <AppSection>
            <AppSectionHeader title="AMI vs Household Size">
                <InputSelect
                    name="household_size"
                    label="Number of People"
                    formikProps={formikProps}
                    options={createNumericalInputOptions(8)}
                    optionValueKey="value"
                    autocompleteConfig={{ disableClearable: true }}
                    className="household-size-input"
                />
            </AppSectionHeader>

            <div className="households-table-wrapper">
                <table className="households-table">
                    <thead>
                        <tr>
                            <HouseholdsTableCell as="th">Household Size</HouseholdsTableCell>
                            <HouseholdsTableCell as="th">{ami_one}% AMI</HouseholdsTableCell>
                            <HouseholdsTableCell as="th">{ami_two}% AMI</HouseholdsTableCell>
                            <HouseholdsTableCell as="th">{ami_three}% AMI</HouseholdsTableCell>
                        </tr>
                    </thead>
                    <tbody>
                        {households
                            .sort((a, b) => a.size - b.size)
                            .slice(0, household_size)
                            .map((item, index) => (
                                <tr key={index}>
                                    <HouseholdsTableCell>
                                        {numbers[item.size - 1]} {item.size - 1 === 0 ? 'person' : 'people'}
                                    </HouseholdsTableCell>
                                    <HouseholdsTableCell>{moneyFormatter(item.values[0])}</HouseholdsTableCell>
                                    <HouseholdsTableCell>{moneyFormatter(item.values[1])}</HouseholdsTableCell>
                                    <HouseholdsTableCell>{moneyFormatter(item.values[2])}</HouseholdsTableCell>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </AppSection>
    );
};
