import { CalendarLeadSimple, LeadStatus } from '../../../types';
import { DateTime } from 'luxon';
import { LeadSimple, LeadType, LeadTypeNames } from '../../../types';

export interface LeadViewStats {
    leads: number;
    tours: number;
    chats: number;
    offers: number;
}

export enum LeadViewCalendarView {
    MONTH = 'month',
    WEEK = 'week',
    DAY = 'day'
}

export interface LeadViewCalendarEvent {
    id: number;
    title: string;
    start: Date;
    end: Date;
    lead_type: string;
    lead_customer_id: number;
    resource?: any;
}

export interface LeadsListLead extends LeadSimple {
    calendar_at: string;
    related_session_count: number;
    company_id: number;
    first_touch: string | null;
    last_touch: string | null;
    multi_touch: string | null
}

export interface LeadsList {
    leads: LeadsListLead[];
    leadsCount: number;
    page: number;
    limit: number;
    limitOptions: { label: string; value: number }[];
}

export interface LeadViewState {
    tabValue: number;
    calendarView: LeadViewCalendarView;
    leads?: LeadViewCalendarEvent[];
    leadsList: LeadsList;
    isLoading: boolean;
    leadSearch: string | null;
    leadViewStats?: LeadViewStats;
    calendarData?: {
        company_count: number;
        count: number;
        limit: number;
        offset: number;
        leads: CalendarLeadSimple[];
    };
}

export const LeadViewInitialState: LeadViewState = {
    calendarView: LeadViewCalendarView.MONTH,
    tabValue: 0,
    leadsList: {
        leads: [],
        leadsCount: 0,
        page: 1,
        limit: 25,
        limitOptions: [
            { label: '10', value: 10 },
            { label: '25', value: 25 },
            { label: '50', value: 50 },
            { label: '75', value: 75 },
            { label: '100', value: 100 }
        ]
    },
    isLoading: false,
    leadSearch: ''
};

export const LeadViewReducers = {
    setTabValue: (state: LeadViewState, tabValue: number) => ({ ...state, tabValue }),
    setCalendarData: (state: LeadViewState, calendarData: any) => ({ ...state, calendarData: { ...calendarData } }),
    setLeadsList: (state: LeadViewState, leadsList: LeadsList) => ({ ...state, leadsList }),
    setIsLoading: (state: LeadViewState, isLoading: boolean) => ({ ...state, isLoading }),
    setLeadSearch: (state: LeadViewState, leadSearch: string | null) => ({ ...state, leadSearch }),
    setLeadViewStats: (state: LeadViewState, leadViewStats: LeadViewStats) => ({ ...state, leadViewStats }),
    setPage: (state: LeadViewState, page: number) => ({ ...state, leadsList: { ...state.leadsList, page } })
};

export type LeadViewReducerAction =
    | { name: 'setTabValue'; payload: number }
    | { name: 'setCalendarData'; payload: any }
    | { name: 'setLeadsList'; payload: LeadsList }
    | { name: 'setIsLoading'; payload: boolean }
    | { name: 'setLeadSearch'; payload: string | null }
    | { name: 'setLeadViewStats'; payload: LeadViewStats }
    | { name: 'setPage'; payload: number };

export const LeadViewReducer = (state: LeadViewState, action: LeadViewReducerAction) => {
    if (!LeadViewReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: LeadViewState = (LeadViewReducers[action.name] as any)(state, action.payload);
    return nextState;
};

export const selectCalendarLeads = (state: LeadViewState) => {
    const leads = state.calendarData?.leads;

    if (!leads) {
        return [];
    }

    return leads.map(({ id, calendar_at, lead_duration, lead_type, lead_customer_id, full_name, status }) => {
        const startDate = DateTime.fromISO(calendar_at);
        const endDate = startDate.plus({ minutes: lead_duration });
        const title = status === LeadStatus.APPOINTMENT_CANCELLED ? `Canceled - ${full_name}` : full_name;

        return {
            id,
            title,
            start: startDate.toJSDate(),
            end: endDate.toJSDate(),
            lead_type,
            lead_customer_id
        };
    });
};

export const getLeadTypeName = (leadType: string): string => {
    const leadTypeKey = Object.keys(LeadType).find(key => LeadType[key] === leadType);

    if (!leadTypeKey) {
        return leadType;
    }

    return LeadTypeNames[leadTypeKey];
};

export const LeadStatsMap: Record<keyof LeadViewStats, { label: string; icon: string }> = {
    leads: { label: 'New Leads', icon: 'flame' },
    tours: { label: 'Tours', icon: 'schedule' },
    chats: { label: 'Live Chats', icon: 'chat' },
    offers: { label: 'Offer Claims', icon: 'deal' }
};