import { Route } from 'mobx-router';
import * as React from 'react';

import AuditLogs from '../components/Audit/AuditLog';
import CalendarDayoffEdit from '../components/CalendarDayoff/CalendarDayoffEdit';
import CalendarDayoffList from '../components/CalendarDayoff/CalendarDayoffList';
import CategoryEdit from '../components/Category/CategoryEdit';
import CategoryList from '../components/Category/CategoryList';
import { PropertyManagementCompanyNew } from '../components/PropertyManagementCompany/PropertyManagementCompanyNew';
import PropertyManagementCompanyEdit from '../components/PropertyManagementCompany/PropertyManagementCompanyEdit';
import PropertyManagementCompanyList from '../components/PropertyManagementCompany/PropertyManagementCompanyList';
import FeatureFlags from '../components/FeatureFlags/FeatureFlags';
import CssToolList from '../components/CssTool/CssToolList';
import { ChangePasswordPage } from '../components/Login/ChangePasswordPage';
import { LoginPage } from '../components/Login/LoginPage';
import { NotifyPasswordReset } from '../components/Login/NotifyPasswordReset';
import { ResetPasswordPage } from '../components/Login/ResetPasswordPage';
import Permission from '../components/Permission/Permission';
import RemoteCalendar from '../components/RemoteCalendar/RemoteCalendar';
import Scoreboard from '../components/Scoreboard/Scoreboard';
import { Messages } from '../components/Messages';
import UserEdit from '../components/User/UserEdit';
import UserList from '../components/User/UserList';
import OwnershipGroupEdit from '../components/OwnershipGroup/OwnershipGroupEdit';
import OwnershipGroupList from '../components/OwnershipGroup/OwnershipGroupList';
import RegionsEdit from '../components/Regions/RegionsEdit';
import RegionsList from '../components/Regions/RegionsList';
import { dirBasename } from '../constants';
import Store from '../store';
import CsvImport from '../components/CsvImport/CsvImport';
import MatchbackImport from '../components/Matchback/MatchbackImport';

import { FEATURES } from '../types/Features';
import InsiteMetricDataList from '../components/InsiteMetricData/InsiteMetricDataList';
import InsitePageList from '../components/InsiteMetricData/InsitePageList';
import InsiteDefinedFieldsList from '../components/InsiteMetricData/InsiteDefinedFieldsList';
import InsiteDefinedFieldsEdit from '../components/InsiteMetricData/InsiteDefinedFieldsEdit';
import { AgencyRoutes } from './AgencyRoutes';
import { CompanyRoutes } from './CompanyRoutes';
import { UserRoutes } from './UserRoutes';

import { checkUserPermissions, checkUserHome, checkFeatures, checkPasswordReset, routeCheckAll } from './routeUtils';
import AppointmentTypeList from '../components/AppointmentType/AppointmentTypeList';
import AppointmentTypeEdit from '../components/AppointmentType/AppointmentTypeEdit';
import { ChatBotAdminPage } from '../components/_deprecated_ChatBot/Admin/ChatBotAdminPage';
import { AssistantEdit } from '../components/_deprecated_ChatBot/Assistant/AssistantEdit';
import { TaskEdit } from '../components/_deprecated_ChatBot/Task/TaskEdit';
import { SampleEdit } from '../components/_deprecated_ChatBot/Sample/SampleEdit';
import { AssistantTestPage } from '../components/_deprecated_ChatBot/Assistant/AssistantTestPage';
import { ClarityDashboard } from '../components/Clarity/ClarityDashboard';
import { HomeDashboard } from '../components/Dashboard/HomeDashboard';
import { LeadView } from '../components/Lead/LeadView/LeadView';
import { LeadDetails } from '../components/Lead/LeadDetails/LeadDetails';
import LiveChatManualLeadSubmission from '../components/__deprecated/Chat/LiveChatManualLeadSubmission';
import { ClarityDashboardViewer } from '../components/Clarity/ClarityDashboardViewer';
import { OptOutQueueList } from '../components/OptOutQueue/OptOutList';
import OptOutQueueNew from '../components/OptOutQueue/OptOutNew';
import { QuestionDefinitionPresets } from '../components/QuestionDefinitionPresets/QuestionDefinitionPresets';

const routeBasename = dirBasename ? dirBasename : '';

const DefaultRoutes = {
    ...AgencyRoutes,
    ...CompanyRoutes,
    ...UserRoutes,

    ClarityViewer: new Route({
        path: routeBasename + '/clarity-viewer',
        component: <ClarityDashboardViewer />,
        beforeEnter: (router: RouterStore, params: any, store: Store) => {
            return true;
        }
    }),

    Home: new Route({
        displayName: 'Dashboard',
        path: `${routeBasename}/`,
        component: <HomeDashboard />,
        beforeEnter: routeCheckAll([checkUserHome(), checkPasswordReset()])
    }),

    Login: new Route({
        path: routeBasename + '/login',
        component: <LoginPage />,
        beforeEnter: (router: RouterStore, params: any, store: Store) => {
            if (!store) {
                return true;
            }
            if (!store.loggedIn) {
                return true;
            }
            store.router.goTo(DefaultRoutes.Home, params, store);
            return false;
        }
    }),

    ResetPassword: new Route({
        path: routeBasename + '/reset-password',
        component: <ResetPasswordPage />
    }),

    ChangePassword: new Route({
        path: routeBasename + '/change-password/:hash',
        component: <ChangePasswordPage />
    }),

    NotifyPasswordReset: new Route({
        path: routeBasename + '/reset-your-password',
        component: <NotifyPasswordReset />
    }),

    UserList: new Route({
        path: routeBasename + '/users',
        component: <UserList />,
        displayName: 'Users',
        beforeEnter: routeCheckAll([checkUserPermissions('users', 'view'), checkPasswordReset()])
    }),

    UserEdit: new Route({
        path: routeBasename + '/users/:id',
        component: <UserEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('users', 'view'), checkPasswordReset()])
    }),

    LeadView: new Route({
        displayName: 'Leads',
        path: routeBasename + '/leads',
        component: <LeadView />,
        beforeEnter: routeCheckAll([checkUserPermissions('leads', 'view'), checkPasswordReset()])
    }),

    LeadDetailsByCustomer: new Route({
        displayName: 'Lead Details',
        path: `${routeBasename}/leads/:customerId`,
        component: <LeadDetails />,
        beforeEnter: routeCheckAll([checkUserPermissions('companies', 'update'), checkPasswordReset()])
    }),

    CompanyLeadDetails: new Route({
        displayName: 'Lead Details',
        path: `${routeBasename}/company/:companyId/leads/:customerId`,
        component: <LeadDetails />
    }),

    AuditLog: new Route({
        path: routeBasename + '/audit',
        component: <AuditLogs />,
        displayName: 'Audit Logs',
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'dashboard'), checkPasswordReset()])
    }),

    HolidaysList: new Route({
        displayName: 'Holidays',
        path: routeBasename + '/holidays',
        component: <CalendarDayoffList />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars_daysoff', 'view'), checkPasswordReset()])
    }),

    HolidaysEdit: new Route({
        displayName: 'Holidays',
        path: routeBasename + '/holidays/:id',
        component: <CalendarDayoffEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars_daysoff', 'view'), checkPasswordReset()])
    }),

    RemoteCalendar: new Route({
        path: dirBasename + '/calendars/:calendar_id/remote-calendar-integration',
        component: <RemoteCalendar />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars', 'view'), checkPasswordReset()])
    }),

    CssToolsList: new Route({
        displayName: 'CSS Tools',
        path: routeBasename + '/css-tool',
        component: <CssToolList />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),

    CsvImport: new Route({
        displayName: 'CSV Import',
        path: routeBasename + '/csv-import',
        component: <CsvImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),

    Scoreboard: new Route({
        displayName: 'Scoreboard',
        path: routeBasename + '/scoreboard',
        component: <Scoreboard />,
        beforeEnter: routeCheckAll([checkUserPermissions('roles', 'view'), checkPasswordReset()])
    }),

    ClarityDashboard: new Route({
        displayName: 'CLarity Attribution',
        path: `${routeBasename}/clarity/dashboard/:tab?`,
        component: <ClarityDashboard />,
        beforeEnter: routeCheckAll([checkUserPermissions('stat', 'view'), checkPasswordReset()])
    }),

    Messages: new Route({
        displayName: 'Messages',
        path: `${routeBasename}/messages/:messageId?`,
        component: <Messages />,
        beforeEnter: () =>
            routeCheckAll([
                checkUserPermissions('roles', 'view'),
                checkFeatures(FEATURES.sms_messages),
                checkPasswordReset()
            ])
    }),

    Permission: new Route({
        path: routeBasename + '/permission',
        component: <Permission />
    }),

    CategoryList: new Route({
        displayName: 'Business Categories',
        path: routeBasename + '/category',
        component: <CategoryList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    CategoryEdit: new Route({
        displayName: 'Business Categories',
        path: routeBasename + '/category/:id',
        component: <CategoryEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    OwnershipGroupList: new Route({
        displayName: 'Ownership Group',
        path: routeBasename + '/ownershipGroup',
        component: <OwnershipGroupList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    OwnershipGroupEdit: new Route({
        displayName: 'Ownership Group',
        path: routeBasename + '/ownershipGroup/:id',
        component: <OwnershipGroupEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    RegionsList: new Route({
        displayName: 'Regions',
        path: routeBasename + '/regions',
        component: <RegionsList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    RegionsEdit: new Route({
        displayName: 'Regions',
        path: routeBasename + '/regions/:id',
        component: <RegionsEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    PropertyManagementCompanyNew: new Route({
        displayName: 'Property Management Companies',
        path: routeBasename + '/propertymanagementcompanies/new',
        component: <PropertyManagementCompanyNew />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    PropertyManagementCompanyList: new Route({
        displayName: 'Property Management Companies',
        path: routeBasename + '/propertymanagementcompanies',
        component: <PropertyManagementCompanyList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    PropertyManagementCompanyEdit: new Route({
        displayName: 'Property Management Companies',
        path: routeBasename + '/propertymanagementcompanies/:id',
        component: <PropertyManagementCompanyEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    AppointmentTypeList: new Route({
        displayName: 'Schedule Genie Appointment Types',
        path: routeBasename + '/appointment-type',
        component: <AppointmentTypeList />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    AppointmentTypeEdit: new Route({
        displayName: 'Schedule Genie Appointment Types',
        path: routeBasename + '/appointment-type/:id',
        component: <AppointmentTypeEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('vocabulary', 'view'), checkPasswordReset()])
    }),

    GlobalQuestionDefinitionPresetPage: new Route({
        displayName: 'Question Presets',
        path: routeBasename + '/global/question-definition-presets',
        component: <QuestionDefinitionPresets global={true} />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'update'), checkPasswordReset()])
    }),

    FeatureFlags: new Route({
        displayName: 'Beta Features',
        path: routeBasename + '/FeatureFlags',
        component: <FeatureFlags />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),

    OptOutQueueCreate: new Route({
        displayName: 'Questions',
        path: routeBasename + '/out-out-queue/new',
        component: <OptOutQueueNew />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),

    OptOutQueueList: new Route({
        displayName: 'CCPA Opt Out Queue',
        path: routeBasename + '/opt-out-queue',
        component: <OptOutQueueList />,
        beforeEnter: routeCheckAll([checkUserPermissions('admin', 'view'), checkPasswordReset()])
    }),

    MatchbackImport: new Route({
        displayName: 'Matchback Import',
        path: routeBasename + '/matchback-import',
        component: <MatchbackImport />,
        beforeEnter: routeCheckAll([checkUserPermissions('calendars', 'view'), checkPasswordReset()])
    }),

    InsiteMetricDataList: new Route({
        displayName: 'Insite Metric',
        path: routeBasename + '/insite-metric-data',
        component: <InsiteMetricDataList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    InsitePageList: new Route({
        displayName: 'Insite Metric',
        path: routeBasename + '/insite-page',
        component: <InsitePageList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    InsiteDefinedFieldsList: new Route({
        displayName: 'Insite Metric',
        path: routeBasename + '/insite-defined-fields',
        component: <InsiteDefinedFieldsList />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    InsiteDefinedFieldsEdit: new Route({
        path: routeBasename + '/insite-defined-fields/:id',
        component: <InsiteDefinedFieldsEdit />,
        beforeEnter: routeCheckAll([checkUserPermissions('insite', 'view'), checkPasswordReset()])
    }),

    LiveChatManualLeadSubmission: new Route({
        displayName: 'Live Chat',
        path: routeBasename + '/live-chat-manual-lead-submission',
        component: <LiveChatManualLeadSubmission />,
        beforeEnter: routeCheckAll([checkUserPermissions('live_chat', 'view'), checkPasswordReset()])
    }),

    ChatBotAdmin: new Route({
        displayName: 'Chatbot Admin',
        path: routeBasename + '/chatbot-admin',
        component: <ChatBotAdminPage />
    }),

    ChatBotEditAssistant: new Route({
        displayName: 'Edit Assistant',
        path: routeBasename + '/chatbot-admin/assistant/:sid',
        component: <AssistantEdit />
    }),

    ChatBotEditTask: new Route({
        displayName: 'Edit Task',
        path: routeBasename + '/chatbot-admin/task/:assistantSid/:taskSid',
        component: <TaskEdit />
    }),

    ChatBotEditSample: new Route({
        displayName: 'Edit Sample',
        path: routeBasename + '/chatbot-admin/sample/:assistantSid/:taskSid/:sampleSid',
        component: <SampleEdit />
    }),

    ChatBotTestAssistant: new Route({
        displayName: 'Test Assistant',
        path: routeBasename + '/chatbot-admin/test-assistant/:assistantSid',
        component: <AssistantTestPage />
    })
};

export default DefaultRoutes;
