import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { FieldGroupTextarea } from '../../../misc/FieldGroupTextarea';
import LeadCustomerData from '../LeadCustomerData';
import { LeadFieldLabels, LeadFields } from '../LeadEdit';
import { FEATURES } from '../../../../types/Features';

export interface LeadChatFields extends LeadFields {
    comments: string;
}

const labels = {
    ...LeadFieldLabels,
    comments: 'Chat comments '
};

interface LeadChatFormProps {
    editing: boolean;
    controls: (bag: FormikProps<LeadChatFields>) => React.ReactNode;
    features: { [name: string]: boolean };
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isCompanyLight: boolean;
    preview?: boolean;
}

export default (props: LeadChatFormProps) => {
    return (bag: FormikProps<LeadChatFields>) => {
        const fieldProps = {
            ...bag,
            disabled: !props.editing,
            rows: 6,
            hideMaxLengthBar: true,
            labels,
            isSuperAdmin: props.isSuperAdmin,
            isAdmin: props.isAdmin,
            isCompanyLight: props.isCompanyLight,
            preview: props.preview
        };

        const enableSmsMessages = props.features[FEATURES.sms_messages];
        return (
            <div>
                <Form>
                    <LeadCustomerData {...fieldProps} enableSmsMessages={enableSmsMessages} />
                    <FieldGroupTextarea id="comments" componentClass="textarea" {...fieldProps} />
                    {props.controls(bag)}
                </Form>
            </div>
        );
    };
};
