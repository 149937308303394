import * as React from 'react';
import { Row } from 'react-bootstrap';
import LeadChatItem from './LeadChatItem';
import { CalendarEvent } from '../LeadItem';

interface LeadModalDetailsChatProps {
    event: CalendarEvent;
}

export class LeadModalDetailsChat extends React.Component<LeadModalDetailsChatProps> {
    public render() {
        const { event } = this.props;
        const lead = event.lead as LeadChatItem;
        const companyName = lead.company.name;
        return (
            <React.Fragment>
                <div className="calendar-modal-row">
                    <i className="glyphicon glyphicon-book" />
                    <p>{lead.data.customer.email}</p>
                </div>
                <div className="calendar-modal-row">
                    <i className="glyphicon glyphicon-earphone" />
                    <p>{lead.data.customer.phone_number}</p>
                </div>
                <Row>
                    <div className="col-sm-3 col-xs-6">Company: </div>
                    <div className="col-sm-9 col-xs-6">{companyName}</div>
                </Row>
                <Row>
                    <div className="col-sm-3 col-xs-6">Comments:</div>
                    <div className="col-sm-9 col-xs-6">{lead.getEntity().comments}</div>
                </Row>
            </React.Fragment>
        );
    }
}

export default LeadModalDetailsChat;
