import React, { FunctionComponent } from 'react';

import styles from './email-editor.module.scss';
import './email-preview.scss';
import classNames from 'classnames';
import { ButtonUnstyled, Icon, AppSectionHeader } from '../../components-v2/shared';
import { EmailSection, EmailResponse, EditAppEmailFormValues } from './EmailEditor.types';
import { FormikProps } from 'formik';

// TODO: utilize a type for the email

export const EmailEditorPreview: FunctionComponent<{
    formikProps: FormikProps<EditAppEmailFormValues>;
    email: EmailResponse;
    editing?: EmailSection;
    onSectionClick: (section: EmailSection) => void;
}> = ({ formikProps, email, editing, onSectionClick }) => {
    return (
        <>
            <AppSectionHeader title="Preview">
                <span className={styles['email-editor-preview-hint']}>Select a block to change content:</span>
            </AppSectionHeader>
            <div className={classNames(styles['email-editor-preview'], 'email-preview')}>
                {email.sections
                    .filter(section => {
                        const sectionValues = formikProps.values[section.id];
                        return !sectionValues || !sectionValues.hidden;
                    })
                    .map(section => {
                        if (section.editable) {
                            return (
                                <ButtonUnstyled
                                    type="button"
                                    id={section.id}
                                    className={classNames(
                                        styles['email-editor-preview-section'],
                                        styles['email-editor-preview-button'],
                                        editing?.id === section.id ? styles['active'] : ''
                                    )}
                                    key={section.id}
                                    onClick={() => onSectionClick(section)}
                                >
                                    <Icon
                                        className={styles['email-editor-preview-icon']}
                                        name="pencil"
                                        aria-label={editing?.id !== section.id ? 'Edit section' : 'Cancel editing'}
                                    />
                                    {section.type === 'realync-video' && !section.values?.includeVideo ? (
                                        <div className={styles['email-editor-preview-section-info']}>
                                            <p>To include one or more Realync videos, edit this section.</p>
                                        </div>
                                    ) : (
                                        <span dangerouslySetInnerHTML={{ __html: section.html }} />
                                    )}
                                </ButtonUnstyled>
                            );
                        }

                        return (
                            <div
                                className={styles['email-editor-preview-section']}
                                key={section.id}
                                dangerouslySetInnerHTML={{ __html: section.html }}
                            />
                        );
                    })}
            </div>
        </>
    );
};
