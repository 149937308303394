import { FormikProps } from 'formik';
import * as React from 'react';
import { FormControlProps, FormGroup, HelpBlock } from 'react-bootstrap';
import WysiwygEditor from './WysiwygEditor';

interface FieldGroupWysiwygProps<T> extends FormControlProps, FormikProps<T> {
    id: string;
    defaultV?: string;
    extendedId?: string;
    placeholders?: Record<string, string | undefined>;
}

export function FieldGroupWysiwyg<T>(props: FieldGroupWysiwygProps<T>) {
    const handleChangeValue = (id: string, extendedId?: string) => (v: string) => {
        if (extendedId) {
            props.setFieldValue(id, {
                ...props.values[id],
                [`${extendedId}`]: v
            });
        } else {
            props.setFieldValue(id, v);
        }
    };

    const error = props.errors[`${props.id}`];
    const idWysiwyg = `${props.id}_${props.values && props.values['id'] ? props.values['id'] : 0}`;

    let defaultValue = props.defaultV || '';
    if (!defaultValue && props.id && typeof props.values[props.id] === 'string') {
        defaultValue = props.values[props.id];
    }

    return (
        <FormGroup controlId={props.id} className={props.className} hidden={props.hidden}>
            <WysiwygEditor
                id={idWysiwyg}
                defaultValue={defaultValue}
                onChange={handleChangeValue(props.id, props.extendedId)}
                disabled={props.disabled}
            />
            {error && <HelpBlock className="error">{error}</HelpBlock>}
        </FormGroup>
    );
}
