import React, { useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';

import useStore from '../../store/useStore';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { AppPage, AppHeader, ActionList, InputSwitch, useSnackbar } from '../../components-v2/shared';
import { IncomeCalculatorForm } from './IncomeCalculatorForm';
import { incomeCalculatorInitialState, incomeCalculatorReducer, setLoading } from './IncomeCalculator.helpers';
import { AppsName } from '../../types';

import styles from './IncomeCalculator.module.scss';
import { IncomeCalculator } from '../../types/IncomeCalculator';

export const IncomeCalculatorEdit: React.FC<{ incomeCalculatorId: number }> = observer(({ incomeCalculatorId }) => {
    const [state, dispatch] = useReducer(incomeCalculatorReducer, incomeCalculatorInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchIncomeCalculators, incomeCalculators, Api } = store;
    const { companyId } = router.params;
    const incomeCalculatorItem = incomeCalculators.getItem(incomeCalculatorId);
    const [active, setActive] = useState<boolean>(incomeCalculatorItem?.active);

    const fetchData = async () => {
        await fetchIncomeCalculators();
        setLoading(dispatch, false);
    };
    useAsyncEffect(fetchData);

    const toggleIncomeCalculator = async () => {
        try {
            await incomeCalculators.update({ ...incomeCalculatorItem, active: !incomeCalculatorItem.active });
            setActive(!incomeCalculatorItem.active);
            addSnackbar(`Income calculator has been ${incomeCalculatorItem.active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });

            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: incomeCalculatorItem.active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.IC
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${incomeCalculatorItem.active ? 'deactivate' : 'activate'} income calculator.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: IncomeCalculator, actions: FormikHelpers<IncomeCalculator>) => {
        try {
            await incomeCalculators.update({
                ...values,
                active,
                household: +values.household,
                question1_max: +values.question1_max
            });

            // We need to reset the form after update, because we have disabled reinitialization
            const updatedItem = incomeCalculators.getItem(incomeCalculatorId);
            actions.resetForm({ values: updatedItem });

            addSnackbar(`Income calculator has been updated.`, {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    const initialValues = {
        company_id: companyId,
        ...incomeCalculatorItem
    };

    return (
        <AppPage loading={state.loading} className={styles.incomeCalculator}>
            <AppHeader title="Income Calculator" icon={{ name: 'income', color: 'purple' }}>
                <ActionList position="end">
                    <InputSwitch
                        labelOn="Active"
                        labelOff="Inactive"
                        labelPlacement="start"
                        onClick={toggleIncomeCalculator}
                        checked={active}
                    />
                </ActionList>
            </AppHeader>

            <IncomeCalculatorForm
                incomeCalculatorId={incomeCalculatorId}
                initialValues={initialValues}
                onSubmit={handleSubmit}
            />
        </AppPage>
    );
});
