export const crmConfigTypes = {
    entrata: 'entrata',
    eldermark: 'eldermark',
    yardi: 'yardi',
    yardi_soap: 'yardi_soap',
    yardi_sipp: 'yardi_sipp',
    realpage: 'realpage',
    rent_manager: 'rent_manager',
    hospice_md: 'hospice_md',
    rentcafe: 'rentcafe',
    meet_elise: 'meet_elise',
    adf: 'adf',
    enquire: 'enquire',
    ygl: 'ygl',
    sherpa: 'sherpa',
    welcome_home: 'welcome_home',
    realpage_lead2lease: 'realpage_lead2lease',
    realpage_onesite: 'realpage_onesite',
    zoho: 'zoho',
    knock: 'knock',
    glennis_solutions: 'glennis_solutions',
    rent_dynamics: 'rent_dynamics'
};

export const crmConfigTypeLabels = {
    adf: 'ADF',
    eldermark: 'Eldermark',
    enquire: 'Enquire',
    entrata: 'Entrata',
    hospice_md: 'Hospice MD',
    knock: 'Knock',
    realpage_lead2lease: 'Lead2Lease by RealPage',
    meet_elise: 'Meet Elise',
    realpage_onesite: 'OneSite by RealPage',
    realpage: 'RealPage',
    rentcafe: 'RENTCafé',
    rent_manager: 'Rent Manager',
    sherpa: 'Sherpa',
    welcome_home: 'WelcomeHome',
    yardi: 'Yardi',
    yardi_soap: 'Yardi Senior Housing',
    yardi_sipp: 'Yardi SIPP',
    ygl: `You've Got Leads`,
    zoho: 'Zoho',
    glennis_solutions: 'Glennis Solutions',
    rent_dynamics: 'Rent Dynamics'
};

export const crmConfigTypeLabelArray = (exclude?: Array<string>) => {
    return Object.entries(crmConfigTypeLabels)
        .map(([val, label]) => {
            return { val, label };
        })
        .filter((entry: { val: string; label: string }) => {
            return !exclude || !exclude.includes(entry.val);
        })
        .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
};
