import * as React from 'react';
import { Row } from 'react-bootstrap';
import LeadChatItem from './LeadChatItem';

interface LeadListChatDetailsProps {
    lead: LeadChatItem;
}

export class LeadListChatDetails extends React.Component<LeadListChatDetailsProps> {
    public render() {
        const entity = this.props.lead.getEntity();

        return (
            <div style={{ paddingLeft: '0px' }}>
                <React.Fragment>
                    <Row>
                        <div className="col-xs-4">Comments:</div>
                        <div className="col-xs-8">{entity.comments}</div>
                    </Row>
                </React.Fragment>
            </div>
        );
    }
}

export default LeadListChatDetails;
