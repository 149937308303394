import { Bpn } from './Bpn';
import { Calendar } from './Calendar';
import { Offer } from './Offer';
import { Company } from './Company';
import moment from 'moment';
import { QuestionBundle, QuestionResponse } from './QuestionSettings';

export enum LeadType {
    SG = 'SCHEDULE_GENIE',
    CM = 'CONCESSION_MANAGER',
    IC = 'INCOME_CALCULATOR',
    BPN = 'BPN',
    CH = 'CHAT',
    TU = 'TEXT_US',
    IN = 'INSITE',
    CU = 'CALL_US',
    UCM = 'ULM_CONCESSION_MANAGER',
    USG = 'ULM_SCHEDULE_GENIE'
}

export enum LeadTypeNames {
    SG = 'Schedule Genie',
    CM = 'Spotlight',
    IC = 'Income Calculator',
    BPN = 'Best Price Now',
    CH = 'Live Chat',
    TU = 'Text Us',
    IN = 'Insite',
    CU = 'Call Us'
}

export const LeadTypeNamesOptions = [
    { code: LeadType.SG, name: 'Schedule Genie' },
    { code: LeadType.CM, name: 'Spotlight' },
    { code: LeadType.BPN, name: 'Best Price Now' },
    { code: LeadType.IC, name: 'Income Calculator' },
    { code: LeadType.CH, name: 'Live Chat' },
    { code: LeadType.TU, name: 'Text Us' },
    { code: LeadType.IN, name: 'Insite' },
    { code: LeadType.CU, name: 'Call us' }
];

export interface LeadCustomer {
    id: number;
    company_id: number;
    first_name: string;
    last_name: string;
    first_name_secondary?: string | null;
    last_name_secondary?: string | null;
    email: string;
    phone_number: string;
    chat_transcript?: string | null;
    created_at: string | Date;
    is_agree_sms_notification: string;
    updated_at: string | null;
}
export interface LeadBpn {
    id?: number;
    lead_id: number;
    bpn_id: number;
    need_notify: null;
    email_customer_text: string | null;
    question1: string;
    question2: string;
    question3: string;
    answer1: string;
    answer2: string;
    answer3: string | null;
    bpn: Bpn;
    is_custom: boolean;
    created_at: string;
    updated_at: string | null;
    year: number;
    make: string;
    model: string;
    trim: string;
    trim_name: string;
    state: string;
    state_name: string;
    mileage: string;
    condition: string;
    owner_amount: string;
    estimate_low: string;
    estimate_average: string;
    estimate_high: string;
}
export interface LeadCalendar {
    id?: number;
    lead_id?: number;
    calendar_id: number;
    lead_time: number;
    lead_duration: number;
    calendar_tz: string;
    customer_tz: string;
    questionResponses: QuestionResponse[] | null;
    calendar: Calendar;
    created_at: string;
    updated_at?: string | null;
    chat_transcript: string | null;
    appointment_type: string;
    external_event_guid?: string | null;
    app_source?: string;
    app_source_id?: number;
    need_sms_notify_registration?: boolean;
    need_sms_notify_average?: boolean;
    need_sms_notify_daily?: boolean;
}

export interface LeadCalculator {
    id: number;
    income_calculator_id: number;
    lead_id: number;
    need_notify: boolean;
    household_size: number;
    annual_income: number;
    desired_unit_type: string;
    calculator: {
        id: number;
        company_id: number;
        currency: string;
        ami_one: number;
        ami_two: number;
        ami_three: number;
        income_to_rent_variable: number;
        rent_to_income_percentage: number;
        // households: Array<{ size: number; values: [number, number, number] }>;
        floorplans: Array<{
            floor_plan_name: string;
            bedrooms: number;
            bathrooms: number;
            bed_count: number;
            market_rent: number;
            max_allowed_value_0: number;
            max_allowed_value_1: number;
            max_allowed_value_2: number;
        }>;
        question_bundle: QuestionBundle;
        created_at: string;
        updated_at: string | null;
        deleted_at: string | null;
        active: boolean;
        disclaimer: string;
        qualification_policy: string;
        household: number;
        maximum_allowable_rents: { tag: string; values: [number, number, number] }[];
        sms_message_create: null;
        custom_message_to_prospect: string;
        household_size: number;
        menu_title: string;
        question1_label: string;
        question2_label: string;
        question3_label: string;
        question1_max: number;
        feature_slug: string;
        updated_by: string;
        email_subject: string;
    };
    question1: string;
    question2: string;
    question3: string;
    question4: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    created_at: string;
    updated_at: string | null;
    tenant_qualification: number;
    estimated_rent: number;
}

export interface LeadOffer {
    offer_id: number;
    offer: Offer;
    email_customer_text: string;
    question1: string;
    question2: string;
    question3: string;
    question4: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    created_at: string;
}

export interface LeadChat {
    comments: string;
    created_at: string;
}

export interface LeadTextUs {
    comments: string;
    created_at: string;
}

export interface LeadQuestions {
    id: number;
    lead_id: number;
    question: string;
    created_at: string;
    updated_at: string | null;
}

export interface LeadInsite {
    id: number;
    lead_id: number;
    data?: { [x: string]: string };
    created_at: string;
    updated_at: string | null;
}

export enum LeadStatus {
    APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED',
    OPEN_OPPORTUNITY = 'OPEN_OPPORTUNITY',
    APPOINTMENT_COMPLETED = 'APPOINTMENT_COMPLETED',
    APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED',
    PURCHASED = 'PURCHASED',
    NO_OPPORTUNITY = 'NO_OPPORTUNITY'
}

export const LeadStatusNames = [
    { code: LeadStatus.APPOINTMENT_SCHEDULED, name: 'Appointment Scheduled' },
    { code: LeadStatus.OPEN_OPPORTUNITY, name: 'Open Opportunity' },
    { code: LeadStatus.APPOINTMENT_COMPLETED, name: 'Completed Appointment' },
    { code: LeadStatus.APPOINTMENT_CANCELLED, name: 'Cancelled Appointment' },
    { code: LeadStatus.PURCHASED, name: 'Purchased' },
    { code: LeadStatus.NO_OPPORTUNITY, name: 'No Opportunity' }
];

export interface Lead {
    id: number;
    lead_customer_id: number;
    calendar_id?: number | null;
    year?: null | string;
    customer: LeadCustomer;
    lead_type: LeadType;
    lead_state_name?: string;
    bpn?: LeadBpn | null;
    calendar?: LeadCalendar | null;
    calendar_at?: string;
    calculator?: LeadCalculator | null;
    offer?: LeadOffer | null;
    chat?: LeadChat | null;
    textus?: LeadTextUs | null;
    insite?: LeadInsite | null;
    company_id: number;
    company: Company;
    status: LeadStatus;
    created_at: string | Date;
    updated_at?: string | null;
    external_info?: null;
    utm_data: string;
    referrer: string;
    is_agree_sms_notification?: string;
    opened_from_chatbot_topic?: string;
    chatbot_interactions?: string[];
    opened_from_chatbot?: Boolean;
    questionResponses?: QuestionResponse[] | null;
}

export interface DripEvent {
    timestamp: string | null;
    type: string;
    data: {
        id: number;
        drip_schedule_name?: string;
        email_template_name?: string;
        spotlight_offer?: string;
        mail_guid?: string;
        email_opened: boolean;
        email_clicked: boolean;
        email_bounced: boolean;
        email_unsubscribed: boolean;
        sent_via_email: boolean;
        sent_via_sms: boolean;
    }
}

export interface LeadJourney {
    timestamp: string | null;
    type: string;
    data: {
        id: number;
        status?: string;
        message?: string;
        message_error?: string | null;
        lead_customer_id?: number;
        created_at: string;
        sent_at?: string | null;
        notification_type?: string | null;
        log_type?: string;
        description?: string;
        utm_source?: null | string;
        utm_medium?: null | string;
        utm_campaign?: null | string;
        utm_term?: null | string;
        utm_content?: null | string;
        device_location?: string;
        device_type?: string;
        referrer?: string;
        interactions?: number;
        apps_opened?: string | null;
        lead_via_apps?: null | string;
        leads_data?: string;
    };
}

export interface LeadWithJourney extends Lead {
    journey_events?: LeadJourney[];
}

export interface LeadSimple {
    id: number;
    full_name: string;
    email: string;
    phone_number: string;
    company_name: string;
    lead_type: LeadType;
    lead_customer_id: number;
    status: LeadStatus;
    created_at: string;
}
export interface CalendarLeadSimple extends LeadSimple {
    calendar_at: string;
    lead_duration: number;
}

export interface LeadStat {
    name: string;
    bpn: number;
    cm: number;
    sg: number;
}

export interface WidgetMetricData {
    id: number;
    company_id: number;
    host_uri: string;
    data_event_type: string;
    data?: object;
    created_at: moment.Moment;
}

export interface CompanyCountResponse {
    data: {
        count_companies: number;
    };
}
