import { Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { emptyLeadEmail } from '../../../../store/initial';
import { LeadEmail } from '../../../../types/LeadEmail';
import LeadEmailForm from './LeadEmailForm';

interface LeadEmailNewProps {
    closeForm: () => void;
    createHandler: (values: LeadEmail, actions: FormikHelpers<LeadEmail>) => Promise<void>;
}

@inject('store')
@observer
class LeadEmailNew extends React.Component<LeadEmailNewProps> {
    public initialValues = () => {
        return {
            ...emptyLeadEmail
        };
    };

    public render() {
        const colProps = {
            xs: 12
        };
        return (
            <Row className="block-indent__padding">
                <Col {...colProps}>
                    <Formik enableReinitialize initialValues={this.initialValues()} onSubmit={this.handleSubmit}>
                        {LeadEmailForm({
                            controls: this.controls()
                        })}
                    </Formik>
                </Col>
            </Row>
        );
    }

    public controls = () => {
        return (
            <>
                <Button className="right-indent" bsStyle="primary" type="submit">
                    Send
                </Button>
                <Button className="right-indent" bsStyle="default" type="reset" onClick={this.props.closeForm}>
                    Back
                </Button>
                <Button className="right-indent" bsStyle="danger" type="reset" onClick={this.props.closeForm}>
                    Cancel
                </Button>
            </>
        );
    };

    public handleSubmit = (values: LeadEmail, actions: FormikHelpers<LeadEmail>) => {
        return this.props.createHandler(values, actions);
    };
}

export default LeadEmailNew;
