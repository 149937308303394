import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { ReactComponent as Illustration } from '../Clarity/EmptyReportsIllustration.svg';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppPage,
    ButtonPrimary,
    IconButton,
    AppListEmpty,
    InputSelect,
    InputText,
    Button,
    Icon,
    Modal,
    ModalActions,
    ModalHeader,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import './question-definition-preset.scss';
import { Form, handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import {
    friendlyQuestionDefinitionLevel,
    friendlyResponseFormat,
    QuestionDefinition,
    QuestionDefinitionAdHocTag,
    QuestionDefinitionLevel,
    QuestionPicklistItem,
    QuestionResponseFormat
} from '../../types/QuestionSettings';
import { FormikHelpers, FormikProps } from 'formik';

export const CreateEditQuestionFormValidationSchema = () => {
    return Yup.object().shape({
        text: Yup.string().required(`Please enter a prompt or question.`),
        response_format: Yup.string().required(`Please select a response format.`)
    });
};

interface CreateEditQuestionForm {
    id?: number;
    business_category_id?: number | string;
    tag: string;
    required: boolean;
    text: string;
    response_format: QuestionResponseFormat;
    picklist: QuestionPicklistItem[];
}

interface BusinessCategoryItem {
    id: number;
    title: string;
}

interface QuestionDefinitionManagerState {
    presets: QuestionDefinition[];
    tags: { tag: string }[];
    categories: BusinessCategoryItem[];
}

export const QuestionDefinitionPresets: FC<{ global?: boolean }> = ({ global }) => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api, router } = store;
    const { companyId, agencyId } = router.params;

    const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const [createEditModalOpen, setCreateEditModalOpen] = useState<boolean>(false);
    const [selectedQuestion, setSelectedQuestion] = useState<QuestionDefinition>();
    const [categoryId, setCategoryId] = useState<number | string>();
    const [questionDefinitionManagerState, setQuestionDefinitionManagerState] = useState<
        QuestionDefinitionManagerState
    >({ presets: [], tags: [], categories: [] });

    const fetchQuestionDefinitionPresets = async () => {
        if (!companyId && !agencyId && !global) {
            addSnackbar(`No agency or company selected. Cannot load questions.`, { variant: 'warning' });
            return Promise.resolve();
        }

        const prefix = global ? 'global' : companyId ? `company/${companyId}` : `partner/${agencyId}`;
        const suffix = categoryId ? `/category/${categoryId}` : '';
        const [response, err] = await handlePromise(Api.client.get(`${prefix}/question-definitions${suffix}`));

        if (err || !response.data || !response.data) {
            // TODO: handle error
            return;
        }
        setQuestionDefinitionManagerState(response.data);
    };

    useAsyncEffect(fetchQuestionDefinitionPresets, undefined, [categoryId]);

    const deleteButtonHandler = async (question?: QuestionDefinition) => {
        if (!companyId && !agencyId && !global) {
            addSnackbar(`No partner or company selected. Cannot delete question preset.`, { variant: 'warning' });
            return Promise.resolve();
        }
        if (!question) {
            addSnackbar(`No question selected. Cannot delete question preset.`, { variant: 'warning' });
            return Promise.resolve();
        }
        const prefix = global ? 'global' : companyId ? `company/${companyId}` : `partner/${agencyId}`;
        const [result, err] = await handlePromise(Api.client.delete(`${prefix}/question-definitions/${question.id}`));
        if (err) {
            addSnackbar(`Failed to remove question preset.`, {
                variant: 'error'
            });
            return;
        }
        if (result) {
            addSnackbar(`Question preset has been removed.`, {
                variant: 'success'
            });
        }
        await fetchQuestionDefinitionPresets();
    };

    const newItemButtonHandler = async () => {
        setSelectedQuestion(undefined);
        setCreateEditModalOpen(true);
    };

    const responseFormatSelectOptions = () => {
        return Object.keys(QuestionResponseFormat).map(key => {
            return { label: friendlyResponseFormat[key], value: key };
        });
    };

    const handleCreateEditQuestion: (
        values: CreateEditQuestionForm,
        formikHelpers: FormikHelpers<CreateEditQuestionForm>
    ) => void = async ({ id, tag, text, response_format, picklist }, formikHelpers) => {
        const questionPayload = {
            id,
            business_category_id: categoryId === '' ? undefined : categoryId,
            tag,
            required: false,
            text,
            response_format,
            picklist
        };

        const prefix = global ? 'global' : companyId ? `company/${companyId}` : `partner/${agencyId}`;

        if (!id) {
            const [createQuestionResponse, createQuestionError] = await handlePromise<{
                data: QuestionDefinitionManagerState;
            }>(Api.client.post(`${prefix}/question-definitions`, questionPayload));

            if (createQuestionError || !createQuestionResponse) {
                // TODO: handle error
                return;
            }
        } else {
            const [editQuestionResponse, editQuestionError] = await handlePromise<{
                data: QuestionDefinitionManagerState;
            }>(Api.client.patch(`${prefix}/question-definitions/${id}`, questionPayload));

            if (editQuestionError || !editQuestionResponse) {
                // TODO: handle error
                return;
            }
        }

        await fetchQuestionDefinitionPresets();

        setCreateEditModalOpen(false);
        formikHelpers.resetForm();
    };

    const createNewQuestionButton = (
        <>
            <p>
                <ButtonPrimary onClick={() => newItemButtonHandler()}>New Question Preset</ButtonPrimary>
            </p>
        </>
    );

    const defaultFormValues = (question?: QuestionDefinition) => {
        if (!question) {
            return {
                tag: QuestionDefinitionAdHocTag,
                required: false,
                text: 'Your question text here...',
                response_format: QuestionResponseFormat.text,
                business_category_id: categoryId || '',
                picklist: []
            };
        } else {
            return {
                id: question.id,
                tag: question.tag,
                required: question.required,
                business_category_id: question.business_category_id,
                text: question.text,
                response_format: question.response_format,
                picklist: question.picklist || []
            };
        }
    };

    const canDeleteQuestionForLevel = (level: QuestionDefinitionLevel): boolean => {
        return (
            (!!companyId && level === QuestionDefinitionLevel.company) ||
            (!!agencyId &&
                [QuestionDefinitionLevel.agency, QuestionDefinitionLevel.agency_business_category].includes(level)) ||
            !!global
        );
    };

    const nameLookup = (collection: any[], id: number | string): string => {
        try {
            return collection.find(item => item.id == id).title;
        } catch (e) {
            return '';
        }
    };

    return (
        <AppPage>
            <AppHeader title="Question Preset Manager">
                <ActionList position="end">{createNewQuestionButton}</ActionList>
            </AppHeader>
            {(global || agencyId) && (
                <AppContent>
                    <div>
                        <strong>Filter By Business Category:</strong>{' '}
                        <InputSelect
                            name="business_category_id"
                            optionValueKey="id"
                            optionLabelKey="title"
                            autocompleteConfig={{ disableClearable: true }}
                            value={categoryId || ''}
                            onChange={(evt, val) => {
                                setCategoryId(val);
                            }}
                            options={[{ id: '', title: '-Global-' }, ...questionDefinitionManagerState.categories]}
                        />
                    </div>
                </AppContent>
            )}
            {!global && !agencyId && (
                <AppContent>
                    This page shows all of the available question presets that can be included as Qualifying Questions
                    in each app (Schedule Genie, Spotlight, etc.). Presets that are defined globally (or at the Business
                    Category or Partner level) can be overridden for use by each company. Changes made here do not
                    affect other companies nor does it affect any question already defined in an app.
                </AppContent>
            )}

            <AppContent>
                {questionDefinitionManagerState &&
                    questionDefinitionManagerState.presets &&
                    Array.isArray(questionDefinitionManagerState.presets) &&
                    (!questionDefinitionManagerState.presets.length ? (
                        <AppListEmpty
                            illustration={<Illustration />}
                            title="Create New Question Preset"
                            action={createNewQuestionButton}
                        />
                    ) : (
                        <AppList>
                            {questionDefinitionManagerState.presets.map(
                                (question: QuestionDefinition, index: number) => (
                                    <AppListItem key={index} className="question-definition-list">
                                        <div className="tags">
                                            <span className="darker">
                                                {friendlyQuestionDefinitionLevel[question.level]}
                                            </span>
                                            <span>{question.tag}</span>
                                            <span className="last">
                                                {friendlyResponseFormat[question.response_format]}
                                            </span>
                                        </div>
                                        <div className="text">{question.text}</div>
                                        <ActionList position="end">
                                            <IconButton
                                                icon="pencil"
                                                onClick={() => {
                                                    setSelectedQuestion(question);
                                                    setCreateEditModalOpen(true);
                                                }}
                                            />
                                            <IconButton
                                                icon="trash"
                                                disabled={!canDeleteQuestionForLevel(question.level)}
                                                onClick={() => {
                                                    setSelectedQuestion(question);
                                                    setDeleteModalOpen(true);
                                                }}
                                            />
                                        </ActionList>
                                    </AppListItem>
                                )
                            )}
                        </AppList>
                    ))}
            </AppContent>
            <Modal isOpen={deleteModalOpen} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Are you sure you want to remove this question? This will revert the question back to the
                            previous default value.
                        </>
                    }
                />
                <p>This action cannot be undone.</p>
                <ModalActions>
                    <ActionList position="end">
                        <Button
                            onClick={() => {
                                setDeleteModalOpen(false);
                                setSelectedQuestion(undefined);
                            }}
                        >
                            Cancel
                        </Button>
                        <ButtonPrimary
                            onClick={() => {
                                deleteButtonHandler(selectedQuestion);
                                setDeleteModalOpen(false);
                                setSelectedQuestion(undefined);
                            }}
                        >
                            Confirm
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>

            <Modal isOpen={createEditModalOpen} onAfterClose={() => setCreateEditModalOpen(false)} closeButton={false}>
                <ModalHeader title="Question Definition Preset" />
                <Form
                    initialValues={defaultFormValues(selectedQuestion)}
                    onSubmit={handleCreateEditQuestion}
                    validationSchema={CreateEditQuestionFormValidationSchema}
                >
                    {(formikProps: FormikProps<CreateEditQuestionForm>) => {
                        return (
                            <>
                                {global || agencyId ? (
                                    <>
                                        <InputText
                                            name="business_category_id"
                                            label="Business Category"
                                            labelPlacement="inset"
                                            disabled={true}
                                            value={
                                                nameLookup(
                                                    questionDefinitionManagerState.categories,
                                                    categoryId || ''
                                                ) || 'Global'
                                            }
                                        />
                                        {!selectedQuestion?.id ? (
                                            <InputSelect
                                                label="Tag"
                                                name="tag"
                                                formikProps={formikProps}
                                                optionValueKey="tag"
                                                optionLabelKey="tag"
                                                allowCreateOption={false}
                                                options={questionDefinitionManagerState.tags}
                                            />
                                        ) : (
                                            <InputText
                                                name="tag"
                                                label="Tag"
                                                labelPlacement="inset"
                                                disabled={true}
                                                formikProps={formikProps}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <InputText
                                        name="tag"
                                        label="Tag"
                                        labelPlacement="inset"
                                        disabled={true}
                                        formikProps={formikProps}
                                    />
                                )}
                                <InputText name="text" label="Question Text" formikProps={formikProps} />
                                <InputSelect
                                    label="Response Format"
                                    name="response_format"
                                    formikProps={formikProps}
                                    optionValueKey="value"
                                    options={responseFormatSelectOptions()}
                                />
                                {formikProps.values.response_format === QuestionResponseFormat.select && (
                                    <InputSelect
                                        label="Answer Options"
                                        name="picklist"
                                        options={selectedQuestion?.picklist || []}
                                        formikProps={formikProps}
                                        autocompleteConfig={{ multiple: true, disableClearable: false }}
                                        contentEditable={true}
                                        allowCreateOption={true}
                                        inputProps={{ autoComplete: 'disable' }}
                                    />
                                )}
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => setCreateEditModalOpen(false)}>Cancel</Button>
                                    <ButtonPrimary type="submit">
                                        {!selectedQuestion ? 'Create' : 'Update'} Question
                                    </ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>
        </AppPage>
    );
};
