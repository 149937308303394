import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    InputCheckbox
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, RulesActionFactory, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { crmTimezones } from '../helpers/FormHelper';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';

export interface RealPageOneSiteFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

interface RealPageOneSiteCrm extends Crm {
    configuration: {
        pmcid: string;
        siteid: string;
        crm_tz: string;
        sync_options: string;
        create_via: string;
        crm_emails?: string[] | string;
        lead_email_source_name?: string;
        lead_email_source_type?: string;
        sync_calendar: boolean;
    };
}

const ruleActions: Array<RuleAction> = [RulesActionFactory.ReplaceCrmEmailsAction];

export const RealPageOneSiteForm: FC<RealPageOneSiteFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleSubmit = async (values: RealPageOneSiteCrm, actions: FormikHelpers<RealPageOneSiteCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.realpage_onesite);
    };

    const initialValues = props.initialValues;
    if (!initialValues.configuration) {
        initialValues.configuration = { sync_options: 'pull', create_via: 'email', sync_calendar: true };
    }

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {formikProps => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: OneSite by RealPage" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader
                                    title="Connection Settings"
                                    subtitle="Contact OneSite support for your exact connection settings"
                                />
                                <InputText
                                    name="configuration.pmcid"
                                    label="PMC ID"
                                    helperText="OneSite PMC ID"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <InputText
                                    name="configuration.siteid"
                                    label="Site ID"
                                    helperText="OneSite Site ID"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <AppSectionHeader title="Sync Options" />
                                <InputSelect
                                    label="Push/Pull"
                                    name="configuration.sync_options"
                                    options={[
                                        { val: 'push', label: 'Push new leads to CRM' },
                                        { val: 'pull', label: 'Push new leads to CRM and pull updated leads from CRM' }
                                    ]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="The pull option will poll your OneSite CRM periodically to see if any lead data, such as contact info or appointment times, has changed."
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputCheckbox
                                    name="configuration.sync_calendar"
                                    label="Sync Calendar"
                                    formikProps={formikProps}
                                />
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader title="Time Zone" />
                                <InputSelect
                                    label="CRM Time Zone"
                                    name="configuration.crm_tz"
                                    helperText="The time zone used for submitting leads with appointment time information"
                                    options={crmTimezones}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <AppSectionHeader
                                    title="Delivery Settings"
                                    subtitle="Contact OneSite support for your exact delivery settings"
                                />
                                <InputSelect
                                    label="Deliver Leads Via"
                                    name="configuration.create_via"
                                    options={[
                                        { val: 'direct', label: 'Direct to OneSite API' },
                                        { val: 'email', label: 'CRM Email' }
                                    ]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="If you select delivery via CRM Email, you must also define a CRM Email recipient."
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputText
                                    name="configuration.crm_emails"
                                    label="CRM Email"
                                    helperText="The email address that gets the CRM-formatted content"
                                    formikProps={formikProps}
                                    required={formikProps.values.configuration?.create_via === 'email'}
                                    inputProps={{ maxLength: 255 }}
                                    hidden={formikProps.values.configuration?.create_via !== 'email'}
                                />
                                <InputText
                                    name="configuration.lead_email_source_name"
                                    label="Lead Email Source Name"
                                    helperText="RealPage Lead Email Source Name"
                                    required={formikProps.values.configuration?.create_via === 'email'}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 255 }}
                                    hidden={formikProps.values.configuration?.create_via !== 'email'}
                                />
                                <InputSelect
                                    label="Source Type"
                                    name="configuration.lead_email_source_type"
                                    options={[
                                        { val: 'm', label: 'Multi-Family Housing' },
                                        { val: 's', label: 'Senior Living' }
                                    ]}
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    helperText="Lead Source Type"
                                    required={formikProps.values.configuration?.create_via === 'email'}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                    hidden={formikProps.values.configuration?.create_via !== 'email'}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
