import { ceil } from 'lodash';
import { FloorplanAmiData, FloorplanDetailsFormValues } from '../components/IncomeCalculator/IncomeCalculator.types';
import { IncomeCalculatorFields } from '../components/__deprecated/IncomeCalculator/IncomeCalculatorForm';
import {
    FloorPlan,
    HouseHold,
    MaximumAllowableRent,
    THREE_VALUES
} from '../components/__deprecated/IncomeCalculator/partials/IncomeCalculatorPartial';
import { Floorplan } from '../types/IncomeCalculator';

interface MaxCalculatedRentArgs {
    households: HouseHold[];
    floorplans: FloorPlan[];
    rent_to_income_percentage: number;
}

export function validateCalculator({
    ami_one,
    ami_two,
    ami_three,
    floorplans,
    household,
    rent_to_income_percentage
}: IncomeCalculatorFields) {
    const amiValues = [ami_one, ami_two, ami_three] as THREE_VALUES;
    const definedHouseholds = getHouseholds(household, amiValues);

    const maxAllowableRents = getCalculatedFloorplansMaxAllowableRents({
        households: definedHouseholds,
        floorplans,
        rent_to_income_percentage
    });

    const errors = validateFloorplansMaxAllowableRents(floorplans, maxAllowableRents, amiValues);

    return {
        ...(errors.length && { floorplans: errors })
    };
}

export function getCalculatedFloorplansMaxAllowableRents(args: MaxCalculatedRentArgs) {
    const { floorplans, rent_to_income_percentage, households } = args;

    return floorplans.map((floorplan, i: number) =>
        getCalculatedFloorplanMaxAllowableRents({ floorplan, households, rent_to_income_percentage })
    );
}

export function getCalculatedFloorplanMaxAllowableRents(args: {
    floorplan: Floorplan;
    households: HouseHold[];
    rent_to_income_percentage: number;
}) {
    const { floorplan, households, rent_to_income_percentage } = args;
    const PERCENTS = 100;
    const MONTH_COUNT = 12;

    const householdIndex = floorplan.bedrooms || 1;
    const household = households[householdIndex + 1 || 2];
    const values = household.values.map(
        value => (value * rent_to_income_percentage) / PERCENTS / MONTH_COUNT
    ) as THREE_VALUES;

    const tag = floorplan.floor_plan_name;
    return { tag, values };
}

export function getHouseholds(household: number, ami: THREE_VALUES) {
    const coefs = [0.7, 0.8, 0.9, 1, 1.08, 1.16, 1.24, 1.32];
    const results: HouseHold[] = new Array(coefs.length);
    for (let i = 1; i <= coefs.length; i++) {
        const coef = coefs[i - 1];
        const value = roundUp(household * coef) / 100;
        results[i] = {
            size: i,
            values: [value * ami[0], value * ami[1], value * ami[2]]
        };
    }
    return results;
}

export const roundUp = (value: number, precision: number = -2) => {
    return ceil(value, precision);
};

export function validateFloorplansMaxAllowableRents(
    plans: FloorPlan[],
    maxAllowableRents: MaximumAllowableRent[],
    amiValues: THREE_VALUES
) {
    let errors: any[] = [];

    plans.forEach((floorplan, planIndex: number) => {
        const maxAllowableRent = maxAllowableRents[planIndex];

        const floorplanErrors = validateFloorplanMaxAllowableRents({
            floorplan,
            planIndex,
            maxAllowableRent,
            amiValues
        });
        errors = [...errors, ...floorplanErrors];
    });

    return errors;
}

export function validateFloorplanMaxAllowableRents({
    floorplan,
    maxAllowableRent,
    amiValues,
    planIndex
}: {
    floorplan: Floorplan;
    maxAllowableRent: MaximumAllowableRent;
    amiValues: THREE_VALUES;
    planIndex: number;
}) {
    const errors: any[] = [];
    const { max_allowed_value_0, max_allowed_value_1, max_allowed_value_2, floor_plan_name } = floorplan;

    const maxValues = {
        max_allowed_value_0,
        max_allowed_value_1,
        max_allowed_value_2
    };

    Object.keys(maxValues).forEach((maxValueKey, index: number) => {
        const calculated = roundUp(maxAllowableRent.values[index], 0);

        if (maxValues[maxValueKey] > calculated) {
            const tag = floor_plan_name || `Floorplan ${planIndex + 1}`;
            errors.push({
                tag,
                ami: amiValues[index],
                index,
                planIndex,
                field: maxValueKey,
                message: `Rent for ${tag} and AMI ${amiValues[index]}% is too high (Rent cannot exceed $${calculated}).`,
                field_message: `${amiValues[index]}% AMI cannot exceed $${calculated}.`
            });
        }
    });

    return errors;
}

export function validateFloorplan(
    { index: planIndex, ...floorplan }: FloorplanDetailsFormValues,
    { ami_one, ami_two, ami_three, household, rent_to_income_percentage }: FloorplanAmiData
) {
    let errors = {};

    if (typeof planIndex === 'undefined') {
        return errors;
    }

    const amiValues = [ami_one, ami_two, ami_three] as THREE_VALUES;
    const households = getHouseholds(household, amiValues);
    const maxAllowableRent = getCalculatedFloorplanMaxAllowableRents({
        floorplan,
        households,
        rent_to_income_percentage
    });

    errors = validateFloorplanMaxAllowableRents({
        floorplan,
        maxAllowableRent,
        amiValues,
        planIndex
    }).reduce((acc, curr) => {
        acc[curr.field] = curr.field_message;
        return acc;
    }, errors);

    return errors;
}
