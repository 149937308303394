import React, { useState } from 'react';
import { Form, FormikProps } from 'formik';
import { map, values } from 'lodash';
import * as moment from 'moment';
import { Alert, Clearfix, ControlLabel, Panel, Row } from 'react-bootstrap';
import {
    animationStyleList,
    defaultWidgetColorTheme,
    defaultWidgetColorValues,
    widgetPositionList,
    getWidgetPublicScript,
    widgetSkinList,
    ColorValues
} from '../../constants';
import { CssPath, AppsName } from '../../types';
import { WidgetAdjustments } from '../../types/PublicRole';
import ColorPicker from '../misc/ColorPicker';
import { FieldGroup } from '../misc/FieldGroup';
import { FieldGroupCheckbox } from '../misc/FieldGroupCheckbox';
import { COLUMNS_ONE, COLUMNS_TWO, COLUMNS_THREE } from '../misc/FieldGroupColSizes';
import CompanyRoleWidget from './WidgetPreview/CompanyRoleWidget';
import WidgetsOrder from './WidgetsOrder';
import ImageUpload from './ImageUpload';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';
import { Button, ButtonPrimary, Modal, ModalActions, ModalHeader } from '@lambdacurry/component-library';
import { ActionList } from '../../components-v2/shared';

export interface ICompanyRoleForm {
    id?: number;
    api_key?: string;
    company_id: number;
    css_id: number;
    ga_id: string;
    gm_id: string;
    beta_widget_enabled: boolean;
    brand_primary_color?: string;
    brand_text_color?: string;
    widget_style?: string;
    widget_size?: string;
    widget_hidden: boolean;
    widget_disabled: boolean;
    widget_spacer_color?: string;
    widget_spacer_enabled?: boolean;
    widget_logo?: string;
    widget_logo_url?: string;
    skin: string;
    is_mini: boolean;
    use_custom_colors: number;
    color_default: string;
    color_background_default: string;
    color_background_icon_small: string;
    color_error: string;
    color_error_field: string;
    color_border_show_hide_button: string;
    color_button: string;
    color_month: string;
    color_back_month: string;
    color_calendar_day: string;
    color_calendar_background: string;
    color_calendar_past_day: string;
    color_back_calendar_past_day: string;
    color_back_calendar_day_hover: string;
    color_background_submit_button: string;
    color_available_times_item: string;
    color_background_available_times_item: string;
    color_background_available_times_item_hover: string;
    color_border_focus: string;
    color_background_chevron: string;
    widget_animation_style: string;
    widget_position: string;
    widget_position_mobile: string;
    widget_launcher_button: string;
    widget_code_css: string;
    widget_code_mobile_css: string;
    widgets_order: string[];
    widgets_order_mobile: string[];
    widget_adjustments: WidgetAdjustments;
    widget_zoom_desktop: string | null;
    widget_zoom_mobile: string | null;
    widgetbar_expanded: boolean;
    widget_draggable: boolean;
    updated_at?: Date | string;
    wallet_greeting_heading?: string;
    wallet_greeting_subheading?: string;
}

export const labels = {
    company_id: 'Company',
    api_key: 'Api Key',
    skin: 'Skin',
    is_mini: 'Micro-App',
    css_id: 'Main Color Scheme',
    ga_id: 'Google Universal Analytics ID',
    gm_id: 'Google Analytics Measurement ID',
    beta_widget_enabled: 'Use upgraded application',
    brand_primary_color: 'Brand color',
    brand_text_color: 'Font color',
    widget_style: 'Style',
    widget_size: 'Size',
    widget_hidden: 'Proofing Mode Enabled',
    widget_disabled: 'App Disabled',
    use_custom_colors: 'Use custom app settings',
    color_default: 'Default color',
    color_background_default: 'Default background color',
    color_background_icon_small: 'Background color small icons',
    color_error: 'Color of error messages',
    color_error_field: 'Color for error fields',
    color_border_show_hide_button: 'Border color of show/hide block',
    color_button: 'Buttons color',
    color_back_month: 'Background color of the month',
    color_month: 'Color of the month',
    color_calendar_day: 'Color of active days',
    color_calendar_background: 'Background color of the calendar',
    color_calendar_past_day: 'Color of inactive days',
    color_back_calendar_past_day: 'Background color of inactive days',
    color_back_calendar_day_hover: 'Day background color during mouse hovering',
    color_background_submit_button: 'Background color of submit buttons',
    color_available_times_item: 'Color of available time slots',
    color_background_available_times_item: 'Background color of available time slots',
    color_background_available_times_item_hover: 'Background color of available time slots during hovering',
    color_border_focus: 'Color of focus border',
    color_background_chevron: 'Background color of chevron',
    widget_animation_style: 'Animation style',
    widget_position: 'App position',
    widget_position_mobile: 'App position mobile',
    widget_spacer_color: 'Spacer background color',
    widget_spacer_enabled: 'Enable app spacer',
    widgetbar_expanded: 'Is App Bar expanded by default?',
    widget_draggable: 'Enable draggability',
    widget_code_css: '',
    widget_code_mobile_css: '',
    widget_launcher_button: 'App Launcher Button Design',
    widget_zoom_desktop: 'App Zoom - Desktop',
    widget_zoom_mobile: 'App Zoom - Mobile',
    wallet_greeting_heading: 'Greeting Heading',
    wallet_greeting_subheading: `Greeting Sub-heading (it's best to keep this under 100 characters)`
};

interface CompanyRoleFormProps {
    formikProps: FormikProps<ICompanyRoleForm>;
    controls: (formikProps: FormikProps<ICompanyRoleForm>) => React.ReactNode;
    editing: boolean;
    activeColorFieldName: string;
    handleChangeColorFocus: (field: string) => void;
    textAreaRef: React.RefObject<HTMLTextAreaElement>;
    copyToClipboard: () => void;
    defaultSettings: ColorValues;
}

const SKIN_DISPLAY_NAMES = {
    default: 'Default',
    skin1: 'Wallet'
};
const DEFAULT_SKIN = 'default';

export default observer((props: CompanyRoleFormProps) => {
    const {
        store: {
            activeCompanyId,
            cssPaths,
            activeFeatures,
            activeFeatureList,
            getActiveCompanies,
            isCompanyRole,
            activeUser
        }
    } = useStore();
    const [showModal, setShowModal] = useState<boolean>(false);

    const fieldProps = {
        ...props.formikProps,
        labels,
        disabled: !props.editing
    };

    const colorFieldsBackground = [
        'color_default',
        'color_background_default',
        'color_background_icon_small',
        'color_border_show_hide_button',
        'color_border_focus'
    ];
    const colorFieldsButtons = ['color_button', 'color_background_submit_button'];
    const colorFieldsError = ['color_error', 'color_error_field'];
    const colorFieldsSg = [
        'color_month',
        'color_back_month',
        'color_calendar_background',
        'color_background_chevron',
        'color_calendar_day',
        'color_calendar_past_day',
        'color_back_calendar_past_day',
        'color_back_calendar_day_hover',
        'color_available_times_item',
        'color_background_available_times_item',
        'color_background_available_times_item_hover'
    ];
    const colorContrast = {
        color_background_default: 'color_default',
        color_border_show_hide_button: 'color_default',
        color_background_icon_small: 'color_default',
        color_border_focus: {
            name: 'color_background_default',
            ratio: 3
        },
        color_background_chevron: {
            name: 'color_background_default',
            ratio: 3
        },
        color_button: 'color_background_default',
        color_background_submit_button: 'color_default',
        color_error: 'color_background_default',
        color_error_field: 'color_default',
        color_month: 'color_back_month',
        color_back_month: {
            name: 'color_background_default',
            ratio: 3
        },
        color_calendar_background: 'color_background_default',
        color_calendar_day: 'color_calendar_background',
        color_calendar_past_day: {
            name: 'color_back_calendar_past_day',
            ratio: 3
        },
        color_back_calendar_past_day: {
            name: 'color_calendar_background',
            ratio: 3
        },
        color_available_times_item: 'color_background_available_times_item',
        color_background_available_times_item: {
            name: 'color_background_default',
            ratio: 3
        },
        color_background_available_times_item_hover: {
            name: 'color_background_default',
            ratio: 3
        }
    };

    const zoomLevels = {
        'Full Size': 1,
        '90%': 0.9,
        '80%': 0.8,
        '70%': 0.7,
        '60%': 0.6
    };

    const handleChangeColorFocus = (field: string) => () => {
        props.handleChangeColorFocus(field);
    };

    const handleChangeSkin = (e: React.FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;
        props.formikProps.setFieldValue('skin', value);
        if (value !== DEFAULT_SKIN) {
            props.formikProps.setFieldValue('widget_launcher_button', 'multi');
        }
    };

    const api_key =
        props.formikProps.values && props.formikProps.values.api_key ? props.formikProps.values.api_key : '';

    let widgetColorTheme = defaultWidgetColorTheme;
    if (props.formikProps.values.css_id && cssPaths[props.formikProps.values.css_id]) {
        widgetColorTheme = cssPaths[props.formikProps.values.css_id].name;
    }
    const contrastColor = (field: string | undefined) => {
        if (!field) {
            return '';
        }

        const name = typeof colorContrast[field] === 'object' ? colorContrast[field].name : colorContrast[field];

        if (!name) {
            return '';
        }

        if (props.formikProps.values[name]) {
            return props.formikProps.values[name];
        }

        if (props.defaultSettings[name]) {
            return props.defaultSettings[name];
        }
        return '';
    };

    let logoUrl = props.formikProps.values.widget_logo_url ? props.formikProps.values.widget_logo_url : '';
    if (logoUrl && props.formikProps.values.updated_at) {
        logoUrl +=
            !!props.formikProps.values.updated_at && moment(props.formikProps.values.updated_at).isValid()
                ? `?${moment(props.formikProps.values.updated_at).unix()}`
                : '';
    }

    const desktopActiveFeatureList = activeFeatureList ? activeFeatureList.filter(v => v !== AppsName.TU) : [];

    const hasApplicationSettingsPreview = activeUser?.previewFlags.includes('application-settings');

    const betaWidgetEnabled = props.formikProps.values.beta_widget_enabled;

    const colorPickerDefaults = [
        { propName: 'brand_primary_color', defaultColor: '#0054FE' },
        { propName: 'brand_text_color', defaultColor: '#FFFFFF' }
    ];

    const widgetSizeDefaults = [
        { sizeLabel: 'Small', sizeValue: 'small' },
        { sizeLabel: 'Medium', sizeValue: 'medium' },
        { sizeLabel: 'Large', sizeValue: 'large' }
    ];

    // const widgetStyleDefaults = [
    //     { styleLabel: '...', styleValue: '' },
    //     { styleLabel: 'Dark', styleValue: 'dark' },
    //     { styleLabel: 'Light', styleValue: 'light' }
    // ];

    const handleBetaConfigurationChange = (formikProps: FormikProps<ICompanyRoleForm>) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const enabled = props.formikProps['beta_widget_enabled'];

        // If the beta is enabled, set the brand and text color to the defaults, if they don't have a value. This way, accepting the defaults will actually save to formikProps
        if (enabled) {
            colorPickerDefaults.map(colorProp => {
                formikProps.setFieldValue(
                    colorProp.propName,
                    formikProps[colorProp.propName] || colorProp.defaultColor
                );
            });
        }
    };

    const handleBrandColorChange = (formikProps: FormikProps<ICompanyRoleForm>, colorProp: string, hex: string) => {
        formikProps.setFieldValue(colorProp, hex);
    };

    return (
        <>
            <Form className="clearfix app-details-form fixed-controls">
                <FieldGroupCheckbox id="widget_disabled" {...fieldProps} />
                <FieldGroupCheckbox
                    id="beta_widget_enabled"
                    {...fieldProps}
                    disabled={!props.editing}
                    handleChange={() => {
                        handleBetaConfigurationChange(props.formikProps);
                        fieldProps.setFieldValue('beta_widget_enabled', true);
                        setShowModal(true);
                    }}
                />

                {!isCompanyRole && <FieldGroupCheckbox id="widget_hidden" {...fieldProps} />}
                <Clearfix />

                <Modal isOpen={showModal} closeButton={false}>
                    <ModalHeader title="Upgrade to Beta from Legacy?" />
                    <p>Are you sure you want to use the updated application?</p>
                    <p>
                        Your legacy application settings will be saved. You can switch back to the legacy application at
                        any time.
                    </p>
                    <p>
                        <em>
                            Please note: Legacy application users will be upgraded to the updated application in the
                            future.
                        </em>
                    </p>
                    <ModalActions>
                        <ActionList position="end">
                            <Button
                                aria-label="No"
                                onClick={() => {
                                    fieldProps.setFieldValue('beta_widget_enabled', false);
                                    setShowModal(false);
                                }}
                            >
                                No
                            </Button>
                            {hasApplicationSettingsPreview ? (
                                <ButtonPrimary
                                    aria-label="Yes"
                                    onClick={() => {
                                        fieldProps.setFieldValue('widget_style', 'dark');
                                        fieldProps.submitForm();
                                    }}
                                >
                                    Yes, upgrade
                                </ButtonPrimary>
                            ) : (
                                <ButtonPrimary
                                    aria-label="Yes"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    Yes, upgrade
                                </ButtonPrimary>
                            )}
                        </ActionList>
                    </ModalActions>
                </Modal>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>Beta Widget Configuration</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Row>
                            {colorPickerDefaults.map(color => (
                                <div className="col-md-6 col-xs-12 no-padding">
                                    <ColorPicker
                                        name={color.propName}
                                        key={color.propName}
                                        color={fieldProps.values[color.propName] || color.defaultColor}
                                        disabled={!props.editing}
                                        label={labels[color.propName]}
                                        handleChange={hex => {
                                            handleBrandColorChange({ ...fieldProps }, color.propName, hex);
                                        }}
                                    />
                                </div>
                            ))}
                        </Row>
                        <Row>
                            <FieldGroup id="wallet_greeting_heading" {...fieldProps} colSize={COLUMNS_TWO}></FieldGroup>
                            <FieldGroup
                                id="wallet_greeting_subheading"
                                {...fieldProps}
                                colSize={COLUMNS_TWO}
                            ></FieldGroup>
                        </Row>
                        <Row>
                            {/* <FieldGroup id="widget_style" {...fieldProps} componentClass="select" colSize={COLUMNS_TWO}>
                                 {widgetStyleDefaults.map(style => (
                                     <option key={style.styleLabel} value={style.styleValue}>
                                         {style.styleLabel}
                                     </option>
                                 ))}
                             </FieldGroup> */}
                            <FieldGroup id="widget_size" {...fieldProps} componentClass="select" colSize={COLUMNS_TWO}>
                                {widgetSizeDefaults.map(size => (
                                    <option key={size.sizeLabel} value={size.sizeValue}>
                                        {size.sizeLabel}
                                    </option>
                                ))}
                            </FieldGroup>
                        </Row>
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>Keys & Id Settings</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="negative-indent">
                            <FieldGroup
                                id="company_id"
                                {...fieldProps}
                                componentClass="select"
                                colSize={COLUMNS_ONE}
                                hidden={!!activeCompanyId}
                            >
                                {values(getActiveCompanies()).map((company: any) => (
                                    <option key={company.id} value={company.id}>
                                        {company.name}
                                    </option>
                                ))}
                            </FieldGroup>
                            <FieldGroup
                                id="api_key"
                                {...fieldProps}
                                colSize={COLUMNS_THREE}
                                placeholder="API Key will be generated automatically"
                                disabled
                            />
                            <FieldGroup id="ga_id" {...fieldProps} colSize={COLUMNS_THREE} />
                            <FieldGroup id="gm_id" {...fieldProps} colSize={COLUMNS_THREE} />
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>App order</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="order-block">
                            <h2>Web View</h2>
                            <WidgetsOrder
                                onChange={handleChangeOrder(props.formikProps)}
                                items={props.formikProps.values.widgets_order}
                                modules={activeFeatures}
                                moduleList={desktopActiveFeatureList}
                                enabled={props.editing}
                            />
                            <h2>Mobile View</h2>
                            <WidgetsOrder
                                onChange={handleChangeOrderMobile(props.formikProps)}
                                items={props.formikProps.values.widgets_order_mobile}
                                modules={activeFeatures}
                                moduleList={activeFeatureList}
                                enabled={props.editing}
                            />
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>App Configuration</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <Row className="holder-wrap-widget">
                            <div className="col-xs-12 col-md-6">
                                <Panel bsStyle="primary" defaultExpanded>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <h4>App Design</h4>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <Row>
                                            <FieldGroup
                                                id="skin"
                                                {...fieldProps}
                                                componentClass="select"
                                                handleChange={handleChangeSkin}
                                                disabled={!props.editing}
                                            >
                                                {values(widgetSkinList).map((skin: string, i: number) => (
                                                    <option key={i} value={skin}>
                                                        {SKIN_DISPLAY_NAMES[skin] || skin}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                            <FieldGroup
                                                id="widget_launcher_button"
                                                {...fieldProps}
                                                componentClass="select"
                                                disabled={betaWidgetEnabled || !props.editing}
                                            >
                                                <option value="multi">Multi</option>
                                                {props.formikProps.values.skin !== DEFAULT_SKIN && (
                                                    <option value="carousel">Carousel</option>
                                                )}
                                            </FieldGroup>

                                            <FieldGroupCheckbox
                                                id="widgetbar_expanded"
                                                className="holder-check-block"
                                                {...fieldProps}
                                                disabled={!props.editing}
                                            />
                                            <div className="block-indent__widget-padding">
                                                <FieldGroupCheckbox
                                                    id="is_mini"
                                                    className="holder-check-block"
                                                    {...fieldProps}
                                                    disabled={betaWidgetEnabled || !props.editing}
                                                />

                                                <FieldGroupCheckbox
                                                    id="widget_draggable"
                                                    {...fieldProps}
                                                    disabled={betaWidgetEnabled || !props.editing}
                                                />
                                            </div>
                                            <FieldGroup
                                                id="css_id"
                                                {...fieldProps}
                                                componentClass="select"
                                                handleChange={handleChangeStyle(props.formikProps, cssPaths.data)}
                                                disabled={betaWidgetEnabled || !props.editing}
                                            >
                                                {values(cssPaths.data).map((css: CssPath) => (
                                                    <option key={css.id} value={css.id}>
                                                        {css.name}
                                                    </option>
                                                ))}
                                            </FieldGroup>

                                            <FieldGroup
                                                id="widget_zoom_desktop"
                                                {...fieldProps}
                                                componentClass="select"
                                                handleChange={handleChangeDesktopZoom(props.formikProps)}
                                                disabled={betaWidgetEnabled || !props.editing}
                                            >
                                                {Object.keys(zoomLevels).map(zoom => (
                                                    <option key={`desktop_zoom_${zoom}`} value={zoomLevels[zoom]}>
                                                        {zoom}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                            <FieldGroup
                                                id="widget_zoom_mobile"
                                                {...fieldProps}
                                                componentClass="select"
                                                handleChange={handleChangeMobileZoom(props.formikProps)}
                                                disabled={betaWidgetEnabled || !props.editing}
                                            >
                                                {Object.keys(zoomLevels).map(zoom => (
                                                    <option key={`desktop_zoom_${zoom}`} value={zoomLevels[zoom]}>
                                                        {zoom}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                        </Row>
                                        <h4>App Colors</h4>
                                        <Panel bsStyle="primary">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <h4>Background</h4>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    {colorFieldsBackground.map(field => (
                                                        <ColorPicker
                                                            name={field}
                                                            key={field}
                                                            color={
                                                                props.formikProps.values &&
                                                                    props.formikProps.values[field]
                                                                    ? props.formikProps.values[field]
                                                                    : defaultWidgetColorValues[widgetColorTheme][field]
                                                            }
                                                            label={labels[field]}
                                                            handleChange={handleChangeColor(props.formikProps, field)}
                                                            handleOnFocus={handleChangeColorFocus(field)}
                                                            disabled={betaWidgetEnabled || !props.editing}
                                                            contrastColor={contrastColor(field)}
                                                            contrastRatio={
                                                                colorContrast[field] &&
                                                                    typeof colorContrast[field] === 'object'
                                                                    ? colorContrast[field].ratio
                                                                    : 0
                                                            }
                                                        />
                                                    ))}
                                                </Row>
                                            </Panel.Body>
                                        </Panel>

                                        <Panel bsStyle="primary">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <h4>Buttons</h4>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    {colorFieldsButtons.map(field => (
                                                        <ColorPicker
                                                            name={field}
                                                            key={field}
                                                            color={
                                                                props.formikProps.values &&
                                                                    props.formikProps.values[field]
                                                                    ? props.formikProps.values[field]
                                                                    : defaultWidgetColorValues[widgetColorTheme][field]
                                                            }
                                                            label={labels[field]}
                                                            handleChange={handleChangeColor(props.formikProps, field)}
                                                            handleOnFocus={handleChangeColorFocus(field)}
                                                            disabled={betaWidgetEnabled || !props.editing}
                                                            contrastColor={contrastColor(field)}
                                                            contrastRatio={
                                                                colorContrast[field] &&
                                                                    typeof colorContrast[field] === 'object'
                                                                    ? colorContrast[field].ratio
                                                                    : 0
                                                            }
                                                        />
                                                    ))}
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                        <Panel bsStyle="primary">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <h4>Error Messages</h4>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    {colorFieldsError.map(field => (
                                                        <ColorPicker
                                                            name={field}
                                                            key={field}
                                                            color={
                                                                props.formikProps.values &&
                                                                    props.formikProps.values[field]
                                                                    ? props.formikProps.values[field]
                                                                    : defaultWidgetColorValues[widgetColorTheme][field]
                                                            }
                                                            label={labels[field]}
                                                            handleChange={handleChangeColor(props.formikProps, field)}
                                                            handleOnFocus={handleChangeColorFocus(field)}
                                                            disabled={betaWidgetEnabled || !props.editing}
                                                            contrastColor={contrastColor(field)}
                                                            contrastRatio={
                                                                colorContrast[field] &&
                                                                    typeof colorContrast[field] === 'object'
                                                                    ? colorContrast[field].ratio
                                                                    : 0
                                                            }
                                                        />
                                                    ))}
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                        <Panel bsStyle="primary">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <h4>Schedule Genie Calendar</h4>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    {colorFieldsSg.map(field => (
                                                        <ColorPicker
                                                            name={field}
                                                            key={field}
                                                            color={
                                                                props.formikProps.values &&
                                                                    props.formikProps.values[field]
                                                                    ? props.formikProps.values[field]
                                                                    : defaultWidgetColorValues[widgetColorTheme][field]
                                                            }
                                                            label={labels[field]}
                                                            handleChange={handleChangeColor(props.formikProps, field)}
                                                            handleOnFocus={handleChangeColorFocus(field)}
                                                            disabled={betaWidgetEnabled || !props.editing}
                                                            contrastColor={contrastColor(field)}
                                                            contrastRatio={
                                                                colorContrast[field] &&
                                                                    typeof colorContrast[field] === 'object'
                                                                    ? colorContrast[field].ratio
                                                                    : 0
                                                            }
                                                        />
                                                    ))}
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                        <Panel bsStyle="primary">
                                            <Panel.Heading>
                                                <Panel.Title toggle>
                                                    <h4>App Spacer</h4>
                                                </Panel.Title>
                                            </Panel.Heading>
                                            <Panel.Body collapsible>
                                                <Row>
                                                    <div className="block-indent__widget-padding">
                                                        <FieldGroupCheckbox
                                                            id="widget_spacer_enabled"
                                                            {...fieldProps}
                                                        />
                                                    </div>
                                                    <ColorPicker
                                                        name="widget_spacer_color"
                                                        key="widget_spacer_color"
                                                        color={props.formikProps.values.widget_spacer_color || ''}
                                                        label={labels.widget_spacer_color}
                                                        disabled={!props.editing}
                                                        handleChange={handleChangeColor(
                                                            props.formikProps,
                                                            'widget_spacer_color'
                                                        )}
                                                    />
                                                </Row>
                                            </Panel.Body>
                                        </Panel>
                                    </Panel.Body>
                                </Panel>
                                <Panel bsStyle="primary" defaultExpanded>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <h4>App Behavior</h4>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <Row>
                                            <FieldGroup
                                                id="widget_animation_style"
                                                {...fieldProps}
                                                componentClass="select"
                                                type="select"
                                            >
                                                {map(animationStyleList, (title: string, css: string) => (
                                                    <option value={css} key={`animation_style_option_${css}`}>
                                                        {title}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                            <FieldGroup
                                                id="widget_position"
                                                {...fieldProps}
                                                componentClass="select"
                                                type="select"
                                            >
                                                {map(widgetPositionList, (title: string, css: string) => (
                                                    <option value={css} key={`widget_position_option_${css}`}>
                                                        {title}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                            <FieldGroup
                                                id="widget_position_mobile"
                                                {...fieldProps}
                                                componentClass="select"
                                                type="select"
                                            >
                                                {map(widgetPositionList, (title: string, css: string) => (
                                                    <option value={css} key={`widget_position_mobile_option_${css}`}>
                                                        {title}
                                                    </option>
                                                ))}
                                            </FieldGroup>
                                        </Row>
                                    </Panel.Body>
                                </Panel>
                            </div>
                            <div className="col-xs-12 col-md-6 preview-widget-wrapper">
                                <header className="widget-preview-header">App Preview</header>
                                <div className="preview-widget-container class2">
                                    <CompanyRoleWidget
                                        settings={props.formikProps}
                                        cssPaths={cssPaths.data}
                                        activeColorFieldName={props.activeColorFieldName}
                                    />
                                </div>
                                <Panel bsStyle="primary" defaultExpanded>
                                    <Panel.Heading>
                                        <Panel.Title toggle>
                                            <h4>App Logo</h4>
                                        </Panel.Title>
                                    </Panel.Heading>
                                    <Panel.Body collapsible>
                                        <Row>
                                            <ImageUpload
                                                enabled={props.editing}
                                                imagePreviewUrl={props.formikProps.values.widget_logo || logoUrl}
                                                onChange={props.formikProps.handleChange('widget_logo')}
                                                validate={img => img.clientHeight === img.clientWidth}
                                                background={props.formikProps.values.color_background_default}
                                                onError={message =>
                                                    props.formikProps.setFieldError('widget_logo', message)
                                                }
                                            />
                                            {props.formikProps.errors.widget_logo && (
                                                <Alert bsStyle="danger inline-error">
                                                    {props.formikProps.errors.widget_logo}
                                                </Alert>
                                            )}
                                        </Row>
                                    </Panel.Body>
                                </Panel>
                            </div>
                        </Row>
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>App Code</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="code-textarea">
                            <textarea
                                name="code-textarea"
                                ref={props.textAreaRef}
                                value={getWidgetPublicScript({
                                    api_key
                                })}
                                readOnly
                            />
                            <button className="btn btn-success" onClick={props.copyToClipboard}>
                                Copy to clipboard
                            </button>
                        </div>
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="primary" defaultExpanded>
                    <Panel.Heading>
                        <Panel.Title toggle>
                            <h4>App Adjustments</h4>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body collapsible>
                        <div className="col-md-12">
                            <Row className={'widget-position-adjustments'}>
                                <h4>Position Adjustments (in pixels)</h4>
                                <div className="wrap-position-primery">
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Top</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.top}
                                            onChange={handleChangeAdjustment(props.formikProps, 'top')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Bottom</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.bottom}
                                            onChange={handleChangeAdjustment(props.formikProps, 'bottom')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Left</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.left}
                                            onChange={handleChangeAdjustment(props.formikProps, 'left')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Right</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.right}
                                            onChange={handleChangeAdjustment(props.formikProps, 'right')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <h4>CSS Adjustments</h4>
                                <div className="code-textarea">
                                    <textarea
                                        id="widget_code_css"
                                        name="code-css-textarea"
                                        value={fieldProps.values.widget_code_css || ''}
                                        disabled={!props.editing}
                                        onChange={handleChangeCSSCode(props.formikProps, 'widget_code_css')}
                                    />
                                </div>
                            </Row>
                            <Row className={'widget-position-adjustments'}>
                                <h4>Mobile Device Position Adjustments (in pixels)</h4>
                                <div className="wrap-position-primery">
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Top</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.mobile_top}
                                            onChange={handleChangeAdjustment(props.formikProps, 'mobile_top')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Bottom</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.mobile_bottom}
                                            onChange={handleChangeAdjustment(props.formikProps, 'mobile_bottom')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Left</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.mobile_left}
                                            onChange={handleChangeAdjustment(props.formikProps, 'mobile_left')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-3">
                                        <ControlLabel>Right</ControlLabel>
                                        <input
                                            className="input-number"
                                            type="number"
                                            value={props.formikProps.values.widget_adjustments.mobile_right}
                                            onChange={handleChangeAdjustment(props.formikProps, 'mobile_right')}
                                            disabled={!props.editing}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <h4> Mobile Device CSS Adjustments</h4>
                                <div className="code-textarea">
                                    <textarea
                                        id="widget_code_mobile_css"
                                        name="code-css-textarea"
                                        value={fieldProps.values.widget_code_mobile_css || ''}
                                        disabled={!props.editing}
                                        onChange={handleChangeCSSCode(props.formikProps, 'widget_code_mobile_css')}
                                    />
                                </div>
                            </Row>
                        </div>
                    </Panel.Body>
                </Panel>

                <div className="col-xs-12">{props.controls(props.formikProps)}</div>
            </Form>
        </>
    );
});

const handleChangeAdjustment = (
    formikProps: FormikProps<ICompanyRoleForm>,
    border: 'top' | 'bottom' | 'left' | 'right' | 'mobile_top' | 'mobile_bottom' | 'mobile_left' | 'mobile_right'
) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    formikProps.setFieldValue('widget_adjustments', { ...formikProps.values.widget_adjustments, [border]: value });
};

const handleChangeOrder = (formikProps: FormikProps<ICompanyRoleForm>) => (widgets: string[]) => {
    formikProps.setFieldValue('widgets_order', widgets);
};

const handleChangeOrderMobile = (formikProps: FormikProps<ICompanyRoleForm>) => (widgets: string[]) => {
    formikProps.setFieldValue('widgets_order_mobile', widgets);
};

const handleChangeColor = (formikProps: FormikProps<ICompanyRoleForm>, field: string) => (color: string) => {
    formikProps.setFieldValue(field, color);
};

const handleChangeCSSCode = (formikProps: FormikProps<ICompanyRoleForm>, field: string) => (event: any) => {
    const { value } = event.target;
    formikProps.setFieldValue(field, value);
};

const handleChangeStyle = (formikProps: FormikProps<ICompanyRoleForm>, cssPaths: Record<number, CssPath>) => (
    event: React.ChangeEvent<HTMLInputElement>
) => {
    const cssId = event.target.value;
    formikProps.setFieldValue('css_id', cssId);
    if (!cssId || !cssPaths) {
        return;
    }

    let widgetScheme = defaultWidgetColorTheme;
    if (cssPaths[cssId]) {
        widgetScheme = cssPaths[cssId].name;
    }

    setStyleColors(formikProps, widgetScheme);
};

const handleChangeDesktopZoom = (formikProps: FormikProps<ICompanyRoleForm>) => (
    event: React.ChangeEvent<HTMLInputElement>
) => {
    const zoom = event.target.value;
    formikProps.setFieldValue('widget_zoom_desktop', zoom);
};

const handleChangeMobileZoom = (formikProps: FormikProps<ICompanyRoleForm>) => (
    event: React.ChangeEvent<HTMLInputElement>
) => {
    const zoom = event.target.value;
    formikProps.setFieldValue('widget_zoom_mobile', zoom);
};

const setStyleColors = (formikProps: FormikProps<ICompanyRoleForm>, widgetScheme: string) => {
    const colorValues = defaultWidgetColorValues[widgetScheme];
    Object.keys(colorValues).forEach(k => {
        formikProps.setFieldValue(k, colorValues[k]);
    });
};
