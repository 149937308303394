import React from 'react';
import { DateTime } from 'luxon';
import { BillingCharge } from '../Billing.types';
import './billing-history.scss';
import { moneyFormatter } from '../../../util/formatters';

export const BillingHistory: React.FC<{ charges: Array<BillingCharge> }> = ({ charges }) => {
    return (
        <ul className="billing-history">
            {charges.map(charge => (
                <li className="billing-history-charge">
                    <span className="billing-history-date">
                        {DateTime.fromSeconds(charge.created).toLocaleString()}
                    </span>
                    <span className="billing-history-amount">{moneyFormatter(charge.amount / 100, 2)}</span>
                </li>
            ))}
        </ul>
    );
};
