import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { FC, useState } from 'react';

import { AppCard, AppSectionHeader, useSnackbar } from '../../../../components-v2/shared';
import useStore from '../../../../store/useStore';
import { AppsName } from '../../../../types';
import { getActiveAppsList, SectionProps } from '../../ApplicationSettings.helpers';
import { AppOrderList } from './AppOrderList';
import './order-section.scss';

export interface ActiveApp {
    app_id: number;
    company_id: number;
    display_name: string;
    module_name: AppsName;
    slug: string;
}

export const OrderSection: FC<SectionProps> = observer(({ formikProps }) => {
    const [activeApps, setActiveApps] = useState<ActiveApp[]>([]);
    const { activeCompanyId, Api } = useStore().store;
    const { addSnackbar } = useSnackbar();
    const { beta_widget_enabled, widgets_order, widgets_order_mobile } = formikProps.values;

    const fetchData = async () => {
        const [response, error] = await handlePromise<{ data: ActiveApp[] }>(
            Api.client.get(`/widget/${activeCompanyId}/enabled-apps`)
        );

        if (!response || error) {
            addSnackbar('Unable to fetch active apps', { variant: 'error' });
            return;
        }

        /* Change UI label for chatbot to virtual assistant */
        response.data?.map(module => {
            if (module.slug === 'chatbot') {
                module.display_name = 'Virtual Assistant';
            }
        });

        /* If the beta widget is enabled, do not allow Insite */
        setActiveApps(beta_widget_enabled ? response.data.filter(module => module.slug !== 'insite') : response.data);
    };

    useAsyncEffect(fetchData, undefined, []);

    const appsList = getActiveAppsList(widgets_order, activeApps);
    const mobileAppsList = getActiveAppsList(widgets_order_mobile, activeApps).filter(
        ({ module_name }) => module_name !== AppsName.CH
    );
    const desktopAppsList = appsList.filter(
        ({ module_name }) => module_name !== AppsName.TU && module_name !== AppsName.CH
    );
    return (
        <AppCard>
            <AppSectionHeader
                title="App Orders"
                subtitle={
                    Array.isArray(appsList) && appsList.length
                        ? "Enable and disable Apps from the 'My Apps' Portal."
                        : 'You do not have any apps available.'
                }
            />

            <AppSectionHeader as="Header" title="Desktop App Order" className="clx-margin-bottom-16" />
            <AppOrderList appsList={desktopAppsList} appListField="widgets_order" formikProps={formikProps} />

            <AppSectionHeader as="Header" title="Mobile App Order" className="clx-margin-top-32 clx-margin-bottom-16" />
            <AppOrderList appsList={mobileAppsList} appListField="widgets_order_mobile" formikProps={formikProps} />
        </AppCard>
    );
});
