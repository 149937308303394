import React, { FC } from 'react';
import classNames from 'classnames';

import './labels.scss';

export interface LabelProps {
    type?: 'default' | 'info' | 'success' | 'warning' | 'error';
    className?: string;
}

export const Label: FC<LabelProps> = ({ type = 'default', className, ...props }) => {
    return <span className={classNames('lc-label', `lc-label--${type}`, className)} {...props} />;
};
