import { Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import LeadEmailForm from './LeadEmailForm';
import { LeadEmail } from '../../../../types';

interface LeadEmailEditProps {
    id: number;
    data: LeadEmail;
    closeForm: () => void;
    updateHandler: (values: LeadEmail, actions: FormikHelpers<LeadEmail>) => Promise<void>;
    deleteHandler: (id: number) => Promise<void>;
}

@inject('store')
@observer
class LeadEmailEdit extends React.Component<LeadEmailEditProps> {
    public initialValues = () => {
        return {
            ...this.props.data
        };
    };

    public render() {
        const colProps = {
            xs: 12
        };

        return (
            <Row className="block-indent__padding">
                <Col {...colProps}>
                    <Formik enableReinitialize initialValues={this.initialValues()} onSubmit={this.handleSubmit}>
                        {LeadEmailForm({
                            controls: this.controls()
                        })}
                    </Formik>
                </Col>
            </Row>
        );
    }

    public controls = () => {
        return (
            <>
                {!this.props.data.sent_at ? (
                    <Button className="right-indent" bsStyle="primary" type="submit">
                        Send
                    </Button>
                ) : null}
                <Button className="right-indent" bsStyle="default" type="reset" onClick={this.props.closeForm}>
                    Back
                </Button>
                {!this.props.data.sent_at ? (
                    <Button bsStyle="danger" type="reset" onClick={this.handleDelete}>
                        Cancel
                    </Button>
                ) : null}
            </>
        );
    };

    public handleSubmit = (values: LeadEmail, actions: FormikHelpers<LeadEmail>) => {
        return this.props.updateHandler(values, actions);
    };

    public handleDelete = () => {
        return this.props.deleteHandler(this.props.id);
    };
}

export default LeadEmailEdit;
