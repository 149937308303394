import React, { useMemo, useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import {
    AppPage,
    AppHeader,
    AppContent,
    AppList,
    ActionList,
    ButtonPrimary,
    AppListEmpty,
    AppSection
} from '../../components-v2/shared';
import { ReactComponent as Illustration } from './EmptyOffersIllustration.svg';
import { useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { Offer } from '../../types/Offer';
import {
    openModal,
    closeModal,
    concessionManagerReducer,
    concessionManagerInitialState
} from './ConcessionManager.helpers';
import { DeleteOfferModal, CopyOfferModal, SelectTemplateModal } from './components';
import { OfferList } from './components/OffersList';

export const ConcessionManagerList = observer(() => {
    const [loading, setLoading] = useState(true);
    const [state, dispatch] = useReducer(concessionManagerReducer, concessionManagerInitialState);
    const { store } = useStore();
    const { router, offers, activeUser, fetchOffers } = store;
    const { companyId } = router.params;
    const spotlightPreview = activeUser?.previewFlags.includes('spotlight-preview');
    const sortedOffersbyOrder = useMemo(() => (offers?.values?.sort((a, b) => {
        if (a.id !== b.id) {
            return a.order - b.order;
        }
        if (a.offer_text === b.offer_text) {
            return 0;
        }
        return a.offer_text > b.offer_text ? 1 : -1;
    })), [offers?.values])

    const fetchRequiredData = async () => {
        await fetchOffers();
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    const handleEditClick = (offer: Offer) =>
        router.goTo(DefaultRoutes.ConcessionManagerEdit, { companyId, offerId: offer.id }, store);

    const handleNewClick = () => {
        if (spotlightPreview) {
            openModal(dispatch, 'selectTemplate');
        } else {
            router.goTo(DefaultRoutes.ConcessionManagerNew, { companyId }, store);
        }
    };
    const createNewOfferButton = (
        <ButtonPrimary data-test-id="button_cm_create_new_offer" onClick={handleNewClick}>
            Create New Offer
        </ButtonPrimary>
    );

    const handleCopyClick = (offer: Offer) => {
        offer.copy_offer_id = offer.id;
        openModal(dispatch, 'copyOffer', { offer });
    };
    const handleDeleteOffer = (offer: Offer) => {
        offer.copy_offer_id = offer.id;
        openModal(dispatch, 'deleteOffer', { offer });
    };
    const onAfterDelete = () => {
        setLoading(true)
        closeModal(dispatch)
        setLoading(false)
    }

    return (
        <>
            <AppPage loading={loading}>
                <AppHeader title="Spotlight" icon={{ name: 'concessionManager', color: 'green' }}>
                    <ActionList position="end">{createNewOfferButton}</ActionList>
                </AppHeader>
                <AppContent>
                    <AppSection>
                        <AppList>
                            {offers.length < 1 && (
                                <AppListEmpty
                                    illustration={<Illustration />}
                                    title="Capture more leads with incentivized offers!"
                                    description="Offer potential customers discounts on services to capture and convert more leads on your website."
                                    action={createNewOfferButton}
                                />
                            )}
                            <OfferList
                                store={store}
                                dispatch={dispatch}
                                handleCopyClick={handleCopyClick}
                                handleEditClick={handleEditClick}
                                handleDeleteOffer={handleDeleteOffer}
                                offers={sortedOffersbyOrder}
                                setLoadingTopics={(value: boolean | ((prevState: boolean) => boolean)) =>
                                    setLoading(value)
                                }
                            />
                        </AppList>
                    </AppSection>
                </AppContent>
            </AppPage>
            <DeleteOfferModal
                isOpen={state.modal.active === 'deleteOffer'}
                dispatch={dispatch}
                offers={offers}
                offer={state.modal.data?.offer}
                onAfterDelete={onAfterDelete}
            />

            <CopyOfferModal
                isOpen={state.modal.active === 'copyOffer'}
                dispatch={dispatch}
                offers={offers}
                offer={state.modal.data?.offer}
                onAfterDelete={() => closeModal(dispatch)}
            />

            <SelectTemplateModal isOpen={state.modal.active === 'selectTemplate'} dispatch={dispatch} />
        </>
    );
});
