import React, { FC, Dispatch } from 'react';
import { DateTime } from 'luxon';
import { CardDetails, PaymentMethodStatus } from '../Billing.types';
import { ButtonOutlinePrimary, CreditCardIcon } from '@lambdacurry/component-library';
import { ActionList, AppList, AppListItem, AppListItemContent } from '../../../components-v2/shared';

import './payment-method-summary.scss';

export interface PaymentMethodSummaryProps {
    card: CardDetails;
    dispatch: Dispatch<{}>;
}

const cardBrandIconNamesMap = {
    'American Express': 'AmericanExpress'
};

export const PaymentMethodSummary: FC<PaymentMethodSummaryProps> = ({ card, dispatch }) => {
    const expiration = DateTime.fromObject({ month: card.exp_month, year: card.exp_year }).toFormat('MM/yy');
    const creditCardIcon = cardBrandIconNamesMap[card.brand] ? cardBrandIconNamesMap[card.brand] : card.brand;

    return (
        <AppList>
            <AppListItem>
                <CreditCardIcon brand={creditCardIcon} className="payment-method-summary-icon" />
                <AppListItemContent
                    title={`**** **** **** ${card.last4}`}
                    description={`Exp. ${expiration}`}
                ></AppListItemContent>
                <ActionList position="end">
                    <ButtonOutlinePrimary
                        onClick={() =>
                            dispatch({
                                name: 'setPaymentMethodStatus',
                                payload: PaymentMethodStatus.AddingPaymentMethod
                            })
                        }
                    >
                        Update Payment
                    </ButtonOutlinePrimary>
                </ActionList>
            </AppListItem>
        </AppList>
    );
};
