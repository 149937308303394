import React, { FC } from 'react';
import { Title } from '../../../components-v2/shared';
import { BillSummary } from '../../Billing';

import { moneyFormatter } from '../../../util/formatters';

import './billing-summary.scss';

export interface BillingSummaryProps {
    billSummary: BillSummary;
}

export const BillingSummary: FC<BillingSummaryProps> = ({ billSummary }) => (
    <div className="billing-summary">
        <div className="billing-summary-item">
            Active Companies
            <Title>{billSummary?.companies?.length}</Title>
        </div>
        <div className="billing-summary-item">
            Paid Apps
            <Title>{billSummary.billableAppsCount}</Title>
        </div>
        <div className="billing-summary-item billing-summary-item-monthly-cost">
            Monthly Estimate
            <Title>{moneyFormatter((billSummary.monthlyEstimate || 0) / 100, 2)}</Title>
        </div>
    </div>
);
