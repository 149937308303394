import React, { FC } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputText,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList,
    InputSelect,
    ButtonOutlinePrimary
} from '../../../../components-v2/shared';

import useStore from '../../../../store/useStore';
import DefaultRoutes from '../../../../routes/DefaultRoutes';
import { Crm } from '../../../../types/Crm';
import { crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../../constants';
import { JSONWithRuleset, RuleEditor } from '../../../RuleEditor/RuleEditor';
import { CrmRuleConditionSubjects, CrmRulePredicatePicklists } from '../helpers/FormHelper';
import { RuleAction } from '../../../../types';

interface EldermarkCrm extends Crm {
    configuration: {
        api_key: string;
        form_id: string;
        fields_first_name: string;
        fields_last_name: string;
        fields_email: string;
        fields_phone: string;
        fields_comments: string;
        fields_lead_source: string;
    };
}
export interface EldermarkFormProps {
    initialValues: EldermarkCrm;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => Promise<void>;
    onValidate: (values: Crm, crmType: string) => Promise<void>;
    enableReinitialize?: boolean;
}

const ruleActions: Array<RuleAction> = [];

export const EldermarkForm: FC<EldermarkFormProps> = observer(({ onSubmit, onValidate, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store);

    const handleValidate = async (formikProps: FormikProps<EldermarkCrm>) => {
        await onValidate(formikProps.values, crmConfigTypes.eldermark);
    };

    const handleSubmit = async (values: EldermarkCrm, actions: FormikHelpers<EldermarkCrm>) => {
        await onSubmit(values, actions, crmConfigTypes.eldermark);
    };

    const { fetchEldermarkFormKeys, eldermarkFormKeys } = store;

    const loadEldermarkFormKeys = (formikProps: any) => {
        if (formikProps.values.configuration.form_id === '' || formikProps.values.configuration.api_key === '') {
            alert('Valid API Key and form ID required');
            return;
        }
        fetchEldermarkFormKeys(formikProps.values.configuration.api_key, formikProps.values.configuration.form_id);
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {(formikProps: FormikProps<EldermarkCrm>) => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="CRM Configuration: Eldermark" />
                                <InputText
                                    name="name"
                                    label="Name"
                                    helperText="Unique name for this CRM Configuration"
                                    required={true}
                                    formikProps={formikProps}
                                    inputProps={{ maxLength: 64 }}
                                />
                                <AppSectionHeader title="API Authorization" />
                                <InputText
                                    name="configuration.api_key"
                                    label="API Key"
                                    helperText="Eldermark API Key"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <AppSectionHeader title="Form Settings" />
                                <InputText
                                    name="configuration.form_id"
                                    label="Form ID"
                                    helperText="The form ID provided by Eldermark support"
                                    formikProps={formikProps}
                                    required={true}
                                    inputProps={{ maxLength: 25 }}
                                />
                                <Button onClick={() => loadEldermarkFormKeys(formikProps)}>
                                    Load Form Field Mappings
                                </Button>
                            </div>
                            <div className="field-group-col">
                                <AppSectionHeader
                                    title="Form Field Mapping"
                                    subtitle='Use the "Load Form Field Mappings" button to populate the fields below. Match the field name with the closest option on each list. Requires a valid API Key and Form ID.'
                                />

                                <InputSelect
                                    label="First Name"
                                    name="configuration.fields_first_name"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    label="Last Name"
                                    name="configuration.fields_last_name"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />

                                <InputSelect
                                    label="Email"
                                    name="configuration.fields_email"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />

                                <InputSelect
                                    label="Phone"
                                    name="configuration.fields_phone"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    label="Comments"
                                    name="configuration.fields_comments"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    label="Lead Source"
                                    name="configuration.fields_lead_source"
                                    options={eldermarkFormKeys || []}
                                    optionValueKey="key"
                                    optionLabelKey="label"
                                    disabled={!eldermarkFormKeys}
                                    required={true}
                                    autocompleteConfig={{ disableClearable: true }}
                                    formikProps={formikProps}
                                />
                            </div>
                        </AppSection>
                        <RuleEditor
                            formikProps={formikProps as FormikProps<JSONWithRuleset>}
                            availableRuleActions={ruleActions}
                            availableRuleConditionSubjects={CrmRuleConditionSubjects}
                            predicatePicklists={CrmRulePredicatePicklists}
                        />
                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonOutlinePrimary onClick={() => handleValidate(formikProps)}>
                                    Validate
                                </ButtonOutlinePrimary>
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
