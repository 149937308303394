import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from '../../../types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { SampleNew } from './SampleNew';
import { ISample } from './SampleForm';
import { TaskForm } from '../Assistant/TaskForm';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { Title } from '../../../components-v2/shared';
import Alert from '../../misc/AlertDismissable';
import { ButtonPrimary, ButtonWarning } from '@lambdacurry/component-library';

interface TaskEditState {
    newSample?: ISample;
    deletedTask?: any;
    updatedTask?: any;
    samples: Array<any>;
    newSampleOpen: boolean;
}

@observer
@inject('store')
export class TaskEdit extends React.Component<{}, TaskEditState> {
    public state: TaskEditState = {
        samples: [],
        newSampleOpen: false
    };

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        const { fetchAutoPilotSamples } = this.injected.store;
        fetchAutoPilotSamples(this.injected.store.router.params.assistantSid, this.injected.store.router.params.taskSid).then(data => {
            this.setState({
                samples: data.data
            });
        });
    }

    render() {
        const options = {
            onRowClick: this.handleRowClick
        };
        const { newSample, deletedTask } = this.state;

        return (
            <>
                <Title>Task Details</Title>
                <div className="col-lg-12">
                        <Alert show={!!newSample}>
                            New Sample "{newSample?.taggedText}" was created successfully.
                        </Alert>
                        <Alert show={!!deletedTask}>
                            Task was deleted successfully.
                        </Alert>
                    <div>
                        <SampleNew
                            assistantSid={this.injected.store.router.params.assistantSid}
                            sid={this.injected.store.router.params.taskSid}
                            handleClose={this.itemAdded}
                        />
                    </div>
                    <div>
                        <Title>Task Details</Title>
                        <TaskForm handleSubmit={this.handleSubmit}/>
                    </div>
                    <div>
                        <BootstrapTable data={this.state.samples} options={options} pagination search striped>
                            <TableHeaderColumn dataField="taggedText" isKey dataSort>
                                Sample Text
                            </TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                    <div>
                        <div>
                            <ButtonPrimary onClick={this.handleBack}>
                                Back
                            </ButtonPrimary>
                            <ButtonWarning onClick={this.deleteTask}>
                                Delete Task
                            </ButtonWarning>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    public handleRowClick = (row: any) => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotEditSample, {assistantSid: row.assistantSid, taskSid: row.taskSid, sampleSid: row.sid})
    }


    public itemAdded = (item: any) => {
        this.setState({ newSample: item });
    };

    public handleDismissAlert = () => {
        this.setState({ newSample: undefined, deletedTask: undefined });
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotEditAssistant, {sid: this.injected.store.router.params.assistantSid});
    }

    public deleteTask = async () => {
        const { deleteAutoPilotTask } = this.injected.store;
        deleteAutoPilotTask(this.injected.store.router.params.assistantSid, this.injected.store.router.params.taskSid).then(data => {
            this.setState({
                deletedTask: data
            });
        });
    }

    public handleSubmit = async (values: any) => {
        const { updateAutoPilotTask } = this.injected.store;
        updateAutoPilotTask(this.injected.store.router.params.assistantSid, this.injected.store.router.params.taskSid, {
            uniqueName: values.uniqueName,
            friendlyName: values.friendlyName
        }).then(data => {
            this.setState({
                updatedTask: data
            });
        });
    }
}