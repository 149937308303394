import React, { useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import {
    AppPage,
    AppHeader,
    AppContent,
    AppList,
    AppListItem,
    IconButton,
    ActionList,
    ButtonPrimary,
    ButtonUnstyled,
    AppListEmpty,
    AppListItemContent
} from '../../components-v2/shared';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { ReactComponent as Illustration } from './EmptyCrmsIllustration.svg';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { Crm } from '../../types/Crm';
import { openModal, closeModal, crmReducer, crmInitialState } from './Crm.helpers';
import { DeleteCrmModal } from './components';
import { crmConfigTypeLabels } from '../../constants';

export const CrmList = observer(() => {
    const [loading, setLoading] = useState(true);
    const [state, dispatch] = useReducer(crmReducer, crmInitialState);
    const { store } = useStore();
    const { router, crms, fetchCrms } = store;
    const { companyId } = router.params;

    const fetchRequiredData = async () => {
        await fetchCrms();
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    const handleEditClick = (crm: Crm) => router.goTo(DefaultRoutes.CrmEdit, { companyId, crmId: crm.id }, store);

    const createNewCrmButton = (
        <ButtonPrimary onClick={() => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store)}>
            Create New CRM Configuration
        </ButtonPrimary>
    );

    return (
        <>
            <AppPage loading={loading}>
                <AppHeader title="CRM Configurations">
                    <ActionList position="end">{createNewCrmButton}</ActionList>
                </AppHeader>
                <AppContent>
                    <AppList>
                        {crms && crms.length < 1 && (
                            <AppListEmpty
                                illustration={<Illustration />}
                                title="Integrate your CRM system!"
                                description="Send new leads automatically to your CRM without the need for double-entry."
                                action={createNewCrmButton}
                            />
                        )}

                        {crms &&
                            crms.length > 0 &&
                            crms.values.map((crm: Crm & { in_use_by: string }, index) => {
                                return (
                                    <AppListItem key={index}>
                                        <AppListItemContent
                                            title={
                                                <ButtonUnstyled onClick={() => handleEditClick(crm)}>
                                                    {crm.name}
                                                </ButtonUnstyled>
                                            }
                                            description={`CRM Provider: ${crmConfigTypeLabels[crm.crm_type]} ${
                                                crm.in_use_by ? ` -- Used By ${crm.in_use_by}` : ''
                                            }`}
                                        />

                                        <ActionList position="end">
                                            <IconButton icon="pencil" onClick={() => handleEditClick(crm)} />
                                            <IconButton
                                                icon="trash"
                                                onClick={() => openModal(dispatch, 'deleteCrm', { crm })}
                                            />
                                        </ActionList>
                                    </AppListItem>
                                );
                            })}
                    </AppList>
                </AppContent>
            </AppPage>

            <DeleteCrmModal
                isOpen={state.modal.active === 'deleteCrm'}
                dispatch={dispatch}
                crms={crms}
                crm={state.modal.data?.crm}
                onAfterDelete={() => closeModal(dispatch)}
            />
        </>
    );
});
