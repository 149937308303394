import React from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { AppPage, AppHeader, useSnackbar } from '../../components-v2/shared';
import { EditOfferForm } from './components';

import { companyEmptyNotification } from '../../store/initial';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { Offer } from '../../types/Offer';

export const ConcessionManagerNew = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const {
        router,
        companies,
        offers,
        selectedSpotlightTemplate,
        fetchCompanies,
        fetchCrmConfigOptions,
        fetchProspectQuestions
    } = store;
    const { companyId } = router.params;
    const company = companyId ? companies.getItem(parseInt(companyId, 10)) : undefined;

    const fetchRequiredData = async () => {
        if (!companyId) {
            return;
        }

        await fetchCompanies(parseInt(companyId, 10));
        await fetchCrmConfigOptions();
        await fetchProspectQuestions();
    };
    useAsyncEffect(fetchRequiredData);

    const initialValues = {
        ...selectedSpotlightTemplate?.templateValues,
        pathnames: [''], // Make sure we have a pathname to avoid console errors
        company_id: parseInt(companyId, 10),
        active_time_ongoing: true,
        ...companyEmptyNotification(company)
    };

    const handleSubmit = async ({ active_time_ongoing, ...values }: Offer, actions: FormikHelpers<Offer>) => {
        try {
            const offerId = await offers.create({ ...values, active: true });
            addSnackbar(`Offer "${values.offer_text}" has been added.`, { variant: 'success' });
            router.goTo(DefaultRoutes.ConcessionManagerEdit, { companyId, offerId }, store);
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    return (
        <AppPage>
            <AppHeader
                title="Spotlight: Add New"
                icon={{ name: 'concessionManager', color: 'green' }}
                onBack={() => router.goTo(DefaultRoutes.ConcessionManagerList, { companyId }, store)}
            />
            <EditOfferForm enableReinitialize={true} initialValues={initialValues as Offer} onSubmit={handleSubmit} />
        </AppPage>
    );
});
