import {
    Lead,
    LeadBpn,
    LeadCalculator,
    LeadCalendar,
    LeadChat,
    LeadOffer,
    LeadTextUs,
    LeadType,
    LeadInsite
} from '../../../types/Lead';
import LeadChatItem from './Chat/LeadChatItem';

const createLeadItem = (lead: Lead) => {
    switch (lead.lead_type) {
        case LeadType.CH:
            return new LeadChatItem(lead);
        default:
            throw new Error('Invalid lead type ' + lead.lead_type);
    }
};

export type LeadItemType = LeadBpn | LeadCalculator | LeadCalendar | LeadOffer | LeadChat | LeadTextUs | LeadInsite;

export default createLeadItem;
