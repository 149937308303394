import React, { FC, useEffect, useRef, useState } from 'react';
import { AppCard, AppSectionHeader, InputSwitch } from '../../../../components-v2/shared';
import { HelpIcon } from '../../../../components-v2/shared/';
import { SectionProps } from '../../ApplicationSettings.helpers';
import './preview-section.scss';
import { handlePromise, useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../../../store/useStore';

export interface PreviewSection extends SectionProps {
    hostname?: string;
}

const demoModeHelpMsg = (
    <div>
        Demo Mode enables you to view your website with the application.
        <br />
        <br />
        While in Demo Mode the application is not visible to visitors to your site and only those with the Demo Mode URL
        can view the application.
    </div>
);

export const PreviewSection: FC<PreviewSection> = ({ formikProps, hostname, formIsDisabled }) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const { api_key, widget_hidden } = formikProps.values;
    const { store } = useStore();
    const { Api, activeCompanyId, activeCompany } = store;
    const { agency_id: agencyId } = activeCompany || {};
    const [hasValidPaymentMethod, setHasValidPaymentMethod] = useState<boolean>(false);
    const previewURL =
        hostname === process.env.REACT_APP_WIDGET_ENV_LC
            ? `${process.env.REACT_APP_WIDGET_ENV_LC}?preview=true`
            : `${hostname}/widget/preview/${api_key}?preview=true`;

    useEffect(() => {
        if (iframeRef.current?.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                { name: 'updateWidgetConfig', payload: JSON.parse(JSON.stringify(formikProps.values)) },
                '*'
            );
        }
    }, [formikProps.values]);

    const fetchPaymentMethodStatus = async () => {
        if (!agencyId) {
            setHasValidPaymentMethod(true);
            return;
        }

        const [response, error] = await handlePromise<{ data: { canApprove: boolean } }>(
            Api.client.get(`/company/${activeCompanyId}/proofing/can-approve`)
        );

        if (!response?.data || error) {
            console.error(error);
            return;
        }

        setHasValidPaymentMethod(response.data.canApprove);
    };

    useAsyncEffect(fetchPaymentMethodStatus, undefined, [agencyId]);

    return (
        <AppCard className="preview-section">
            <AppSectionHeader title="Preview" />
            {hostname && <iframe ref={iframeRef} width="100%" height="520px" src={previewURL} />}

            <AppSectionHeader
                title={
                    <div className="help-icon-wrapper">
                        <span>Demo Mode</span>
                        <HelpIcon tooltip={demoModeHelpMsg} />
                    </div>
                }
                className="clx-margin-top-16"
            >
                <InputSwitch
                    name="widget_hidden"
                    checked={widget_hidden}
                    formikProps={formikProps}
                    disabled={formIsDisabled || !hasValidPaymentMethod}
                />
            </AppSectionHeader>
        </AppCard>
    );
};
