import { FormikProps } from 'formik';
import * as React from 'react';
import { ControlLabel, FormControl, FormControlProps, FormGroup, HelpBlock } from 'react-bootstrap';

export interface FieldGroupProps<T> extends FormControlProps, FormikProps<T> {
    key?: string | number | undefined;
    labels: Record<string, string | undefined>;
    placeholders?: Record<string, string | undefined>;
    maxLength?: number;
    hideMaxLengthBar?: boolean;
    rows?: number;
    type?: string;
    preview?: boolean;
}
type ValidationState = 'success' | 'warning' | 'error' | null;

export function FieldGroupTextarea<T>(props: FieldGroupProps<T>) {
    let validationState: ValidationState = null;
    const error = props.errors[`${props.id}`];
    const defaultMaxLength = 500;
    const maxLength = props.maxLength || defaultMaxLength;
    if (props.value) {
        validationState = 'success';
    }
    if (error) {
        validationState = 'error';
    }
    const value = props.values[`${props.id}`];
    const label = props.labels[`${props.id}`];
    const placeholder = props.placeholders ? props.placeholders[`${props.id}`] : '';
    const countItems = value ? value.length : 0;
    const calcCharacters = maxLength - countItems;

    return (
        <FormGroup
            controlId={props.id}
            validationState={validationState}
            className={props.className}
            hidden={props.hidden}
        >
            <ControlLabel>{label}</ControlLabel>
            {props.preview ? (
                <div className="preview">
                    {props.type}
                    <p>{value}</p>
                </div>
            ) : (
                <FormControl
                    name={props.id}
                    componentClass={props.componentClass}
                    rows={props.rows}
                    disabled={props.disabled}
                    type={props.type}
                    autoComplete={props.autoComplete}
                    value={value === null ? '' : value}
                    onChange={props.handleChange}
                    onKeyDown={props.onKeyDown}
                    hidden={props.hidden}
                    placeholder={placeholder}
                >
                    {props.children}
                </FormControl>
            )}
            {!props.hideMaxLengthBar ? (
                <HelpBlock className={'text-right'} style={calcCharacters < 0 ? { color: 'red' } : {}}>
                    {calcCharacters} characters remaining
                </HelpBlock>
            ) : null}
            {error && <HelpBlock>{error}</HelpBlock>}
        </FormGroup>
    );
}
