export interface AgencyCustomerInfo {
    source: any;
    id: string;
    email: string;
    name: string;
}

export interface AgencyCustomerInfoResponse {
    customer: AgencyCustomerInfo | null;
}
export interface BillingDetails {
    address: {
        city: string | null;
        country: string | null;
        line1: string | null;
        line2: string | null;
        postal_code: string | null;
        state: string | null;
    };
    email: string | null;
    name: string | null;
    phone: string | null;
}

export interface CardDetails {
    brand: string; // 'visa'
    checks: {
        address_line1_check: string | null;
        address_postal_code_check: string | null;
        cvc_check: string; // 'pass'
    };
    country: string; // 'US'
    exp_month: number; // 4
    exp_year: number; // 2022
    fingerprint: string;
    funding: string; // 'credit'
    installments: null;
    last4: string; // '4242'
    network: string; // 'visa'
    three_d_secure: string | null;
    wallet: string | null;
}

export interface PaymentMethodDetails {
    card: CardDetails;
    type: string; // 'card'
}

export interface BillingCharge {
    id: string;
    object: string; // 'charge'
    amount: number; // in pennies
    amount_captured: number; // in pennies
    amount_refunded: number; // in pennies
    application: null;
    application_fee: null;
    application_fee_amount: null;
    balance_transaction: string;
    billing_details: BillingDetails;
    calculated_statement_descriptor: string;
    captured: boolean;
    created: number; // date
    currency: string; // 'usd'
    customer: string; // id of customer
    description: string;
    disputed: boolean;
    failure_code: any;
    failure_message: any;
    fraud_details: any;
    invoice: string; // id of invoice
    livemode: boolean;
    metadata: any;
    on_behalf_of: any;
    order: any;
    outcome: {
        network_status: string;
        reason: string | null;
        risk_level: string;
        risk_score: number;
        seller_message: string;
        type: string;
    };
    paid: boolean;
    payment_intent: string;
    payment_method: string; // id of payment method?
    payment_method_details: PaymentMethodDetails;
    receipt_email: string | null;
    receipt_number: string | null;
    receipt_url: string; // 'https://pay.stripe.com/receipts/acct_1I3npqHwEApklW1z/ch_1I61AvHwEApklW1zmT3MBQOw/rcpt_IhQ0H9F6415CEn7xIy7bdsd8JUGDUZQ'
    refunded: boolean;
    refunds: {
        object: string; // 'list'
        data: [];
        has_more: boolean;
        url: string; // '/v1/charges/ch_1I61AvHwEApklW1zmT3MBQOw/refunds'
    };
    review: null;
    shipping: null;
    source_transfer: null;
    statement_descriptor: null;
    statement_descriptor_suffix: null;
    status: string; // 'succeeded'
    transfer_data: null;
    transfer_group: null;
}

export interface BillSummaryCompany {
    id: number;
    name: string;
    logo: string;
    billable: boolean;
    appsCount: number;
    monthlyEstimate: number;
    freeCompany: boolean;
    proofingMode: boolean;
    inTrial: boolean;
    trialEndDate: Date;
}

export interface BillSummary {
    id: number;
    monthlyEstimate: number | null;
    billableCompaniesCount: number | null;
    billableAppsCount: number | null;
    companies: BillSummaryCompany[];
}

// Question: Do we need this here anymore?
export const exampleCharge: BillingCharge = {
    id: 'ch_1I61AvHwEApklW1zmT3MBQOw',
    object: 'charge',
    amount: 24697,
    amount_captured: 24697,
    amount_refunded: 0,
    application: null,
    application_fee: null,
    application_fee_amount: null,
    balance_transaction: 'txn_1I61AvHwEApklW1zgOTHkPM1',
    billing_details: {
        address: {
            city: null,
            country: null,
            line1: null,
            line2: null,
            postal_code: null,
            state: null
        },
        email: null,
        name: null,
        phone: null
    },
    calculated_statement_descriptor: 'Stripe',
    captured: true,
    created: 1609797917,
    currency: 'usd',
    customer: 'cus_IhPvlQmDrh6csp',
    description: 'Subscription update',
    disputed: false,
    failure_code: null,
    failure_message: null,
    fraud_details: {},
    invoice: 'in_1I61AuHwEApklW1zsKi4gHQV',
    livemode: false,
    metadata: {},
    on_behalf_of: null,
    order: null,
    outcome: {
        network_status: 'approved_by_network',
        reason: null,
        risk_level: 'normal',
        risk_score: 64,
        seller_message: 'Payment complete.',
        type: 'authorized'
    },
    paid: true,
    payment_intent: 'pi_1I61AuHwEApklW1zo3Xrlt2R',
    payment_method: 'card_1I61AQHwEApklW1zZKCg8fQT',
    payment_method_details: {
        card: {
            brand: 'visa',
            checks: {
                address_line1_check: null,
                address_postal_code_check: null,
                cvc_check: 'pass'
            },
            country: 'US',
            exp_month: 4,
            exp_year: 2022,
            fingerprint: 'VqpMQfk0PEsSYuOa',
            funding: 'credit',
            installments: null,
            last4: '4242',
            network: 'visa',
            three_d_secure: null,
            wallet: null
        },
        type: 'card'
    },
    receipt_email: null,
    receipt_number: null,
    receipt_url:
        'https://pay.stripe.com/receipts/acct_1I3npqHwEApklW1z/ch_1I61AvHwEApklW1zmT3MBQOw/rcpt_IhQ0H9F6415CEn7xIy7bdsd8JUGDUZQ',
    refunded: false,
    refunds: {
        object: 'list',
        data: [],
        has_more: false,
        url: '/v1/charges/ch_1I61AvHwEApklW1zmT3MBQOw/refunds'
    },
    review: null,
    shipping: null,
    source_transfer: null,
    statement_descriptor: null,
    statement_descriptor_suffix: null,
    status: 'succeeded',
    transfer_data: null,
    transfer_group: null
};

export enum PaymentMethodStatus {
    Loading = 'loading',
    AddingPaymentMethod = 'adding-payment-method',
    ShowingCustomerData = 'showing-customer-data'
}
