import { Formik, FormikHelpers, FormikProps, FormikState } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, PageHeader } from 'react-bootstrap';
import { getRouteWithContext } from '../../../../routes/routeUtils';
import { InjectedProps } from '../../../../types';
import ControlButtonGroup from '../../../misc/ControlButtonGroup';
import LeadForm, { LeadChatFields } from './LeadChatForm';
import LeadChatItem from './LeadChatItem';

interface LeadEditChatProps {
    lead: LeadChatItem;
}

interface LeadEditChatState {
    editing: boolean;
}

@inject('store')
@observer
export class LeadEditChat extends React.Component<LeadEditChatProps & Partial<InjectedProps>, LeadEditChatState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        editing: false
    };

    public initialValues(): any {
        const { lead } = this.props;
        const leadChat = lead.getEntity();
        return {
            ...lead.data,
            first_name: lead.customer.first_name,
            last_name: lead.customer.last_name,
            email: lead.customer.email,
            phone_number: lead.customer.phone_number,
            comments: leadChat.comments,
            is_agree_sms_notification: lead.customer.is_agree_sms_notification || ''
        };
    }

    public render() {
        return (
            <React.Fragment>
                <div className="header-line__holder">
                    <PageHeader>Lead Details Page</PageHeader>
                    <div className="hold__icon-text">
                        <span>
                            <i className="clxicon clxicon-chat" /> Chat
                        </span>
                    </div>
                </div>
                <Col className="fixed-controls">
                    <Formik initialValues={this.initialValues()} enableReinitialize onSubmit={this.handleSubmit}>
                        {this.renderForm()}
                    </Formik>
                </Col>
            </React.Fragment>
        );
    }

    protected renderForm = () => {
        const { editing } = this.state;
        const {
            isAdminRole,
            isSuperAdminRole,
            isCompanyLightRole,
            companyFeatureFlags,
            features
        } = this.injected.store;

        return LeadForm({
            isSuperAdmin: isSuperAdminRole,
            isAdmin: isAdminRole,
            isCompanyLight: isCompanyLightRole,
            editing: this.state.editing,
            companyFeatureFlags,
            features,
            controls: (bag: FormikProps<LeadChatFields>) => (
                <ControlButtonGroup
                    editing={editing}
                    canEdit
                    canDelete={false}
                    handleEdit={this.toggleEdit}
                    handleBack={this.handleBack}
                    handleCancel={this.handleCancel(bag)}
                    handleDelete={this.handleDelete}
                />
            )
        });
    };

    protected toggleEdit = () => {
        this.setState({ editing: !this.state.editing });
    };

    protected handleCancel = (bag: FormikProps<LeadChatFields>) => () => {
        bag.resetForm(bag.initialValues as Partial<FormikState<LeadChatFields>>);
        this.setState({ editing: false });
    };

    protected handleBack = () => {
        const { router } = this.injected.store;
        const backLink = (router.params?.backRoute as unknown) as Route;
        const LeadListRoute = getRouteWithContext('LeadList', router);

        if (backLink) {
            return router.goTo(backLink, router.params, this.injected.store);
        }

        router.goTo(LeadListRoute, router.params, this.injected.store);
    };

    protected handleDelete = () => {
        if (window.confirm('Are you sure you wish to delete this item?')) {
            const { deleteLead } = this.injected.store;
            deleteLead(this.props.lead!.data.id);
            this.handleBack();
        }
    };

    protected handleSubmit = (values: LeadChatFields, actions: FormikHelpers<any>) => {
        actions.setSubmitting(true);
        const { leads } = this.injected.store;

        return leads
            .update({
                id: values.id,
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_number: values.phone_number,
                comments: values.comments,
                status: values.status,
                note: values.note,
                is_agree_sms_notification: values.is_agree_sms_notification || ''
            } as any)
            .then(() => {
                actions.setSubmitting(false);
                this.setState({ editing: false });
            })
            .catch(error => {
                actions.setSubmitting(false);
                actions.setErrors(error.response.data);
            });
    };
}

export default LeadEditChat;
