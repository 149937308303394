import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import {
    Modal,
    ModalHeader,
    ModalActions,
    Form,
    InputText,
    InputSelect,
    Button,
    ButtonPrimary,
    ActionList
} from '../../../../components-v2/shared';
import {
    clearModalData,
    closeModal,
    IncomeCalculatorReducerAction,
    IncomeCalculatorReducerState
} from '../../IncomeCalculator.helpers';
import { FloorplanAmiData, FloorplanDetailsFormValues } from '../../IncomeCalculator.types';
import { CreateFloorplanValidationSchema, UpdateFloorplanValidationSchema } from './FloorplanDetailsModal.validation';
import { validateFloorplan } from '../../../../util/calculator';

import './floorplan-details-modal.scss';
import styles from '../../IncomeCalculator.module.scss';
import { createNumericalInputOptions } from '../../../../util/form';

export interface FloorplanDetailsModalProps {
    isOpen: boolean;
    amiData: FloorplanAmiData;
    floorplan: FloorplanDetailsFormValues;
    state: IncomeCalculatorReducerState;
    dispatch: React.Dispatch<IncomeCalculatorReducerAction>;
    onSubmit: (values: FloorplanDetailsFormValues, actions: FormikHelpers<FloorplanDetailsFormValues>) => void;
}

export const FloorplanDetailsModal: FC<FloorplanDetailsModalProps> = ({
    amiData,
    isOpen,
    floorplan,
    dispatch,
    onSubmit
}) => {
    if (!floorplan) {
        return null;
    }

    const mode = typeof floorplan.index !== 'undefined' ? 'update' : 'create';
    const validationSchema = mode === 'create' ? CreateFloorplanValidationSchema : UpdateFloorplanValidationSchema;

    const validate = (values: FloorplanDetailsFormValues) => validateFloorplan(values, amiData);

    return (
        <Modal
            id="floorplanDetails"
            className={`floorplan-details-modal-${mode}`}
            isOpen={isOpen}
            onAfterClose={() => {
                closeModal(dispatch);
                clearModalData(dispatch);
            }}
        >
            <ModalHeader title="Floorplan Details" />
            <Form
                enableReinitialize={true}
                initialValues={floorplan}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                validate={validate}
            >
                {formikProps => (
                    <>
                        <div className={mode === 'update' ? 'field-group-content' : ''}>
                            <div className="field-group-column">
                                <InputText name="floor_plan_name" label="Title" formikProps={formikProps} />
                                <div className={styles.incomeCalculatorFieldRow}>
                                    <InputSelect
                                        name="bedrooms"
                                        label="Bedrooms"
                                        options={createNumericalInputOptions(8)}
                                        formikProps={formikProps}
                                        optionValueKey="value"
                                        autocompleteConfig={{ disableClearable: true }}
                                    />
                                    <InputSelect
                                        name="bathrooms"
                                        label="Bathrooms"
                                        options={createNumericalInputOptions(8)}
                                        formikProps={formikProps}
                                        optionValueKey="value"
                                        autocompleteConfig={{ disableClearable: true }}
                                    />
                                </div>
                                <InputText
                                    name="market_rent"
                                    label="Current Market Monthly Rent"
                                    type="number"
                                    formikProps={formikProps}
                                />
                            </div>

                            {mode === 'update' && (
                                <div className="field-group-column">
                                    <InputText
                                        name="max_allowed_value_0"
                                        label={`${amiData.ami_one}% AMI`}
                                        type="number"
                                        formikProps={formikProps}
                                    />
                                    <InputText
                                        name="max_allowed_value_1"
                                        label={`${amiData.ami_two}% AMI`}
                                        type="number"
                                        formikProps={formikProps}
                                    />
                                    <InputText
                                        name="max_allowed_value_2"
                                        label={`${amiData.ami_three}% AMI`}
                                        type="number"
                                        formikProps={formikProps}
                                    />
                                </div>
                            )}
                        </div>

                        <ModalActions>
                            <ActionList position="end">
                                <Button onClick={() => closeModal(dispatch)}>Cancel</Button>
                                <ButtonPrimary type="submit">Save</ButtonPrimary>
                            </ActionList>
                        </ModalActions>
                    </>
                )}
            </Form>
        </Modal>
    );
};
