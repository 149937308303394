import * as Yup from 'yup';

export const AgencyValidationSchema = {
    create: () =>
        Yup.object().shape({
            agency: Yup.object().shape({
                name: Yup.string()
                    .nullable()
                    .required(`Please enter a partner name.`),
                company_price_id: Yup.string()
                    .nullable()
                    .required(`Please select a pricing model.`)
            })
        }),
    update: () =>
        Yup.object().shape({
            agency: Yup.object().shape({
                name: Yup.string()
                    .nullable()
                    .required(`Please enter a partner name.`)
            })
        })
};
