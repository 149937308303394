import React, { Fragment } from 'react';
import { DripEvent } from '../../../../types';
import './drip-event-cards.scss';

import { AppCard, AppCardGrid, Panel } from '../../../../components-v2/shared';
import { DripEventCard } from './DripEventCard';

export const DripEventCards: React.FC<{ data?: DripEvent[] }> = ({ data }) => {
    if (!data) {
        return <AppCard>Loading...</AppCard>;
    }

    return data.length === 0 ? (
        <>
            <Panel type="info">
                <div className="clx-text-align-center clx-font-size-16 clx-font-weight-700">
                    No Lead Nurturing Events found.
                </div>
            </Panel>
        </>
    ) : (
        <AppCardGrid>
            {data.map((dripEvent, index: number) => (
                <Fragment key={`${dripEvent.type}-${index}`}>
                    <DripEventCard dripEvent={dripEvent} />
                </Fragment>
            ))}
        </AppCardGrid>
    );
};
