import { action, computed, observable, runInAction } from 'mobx';
import { RouterStore } from 'mobx-router';
import { LoginFormValues } from '../components/Login/LoginPage';
import * as moment from 'moment-timezone';
import {
    DEFAULT_TEXT,
    FILTER_METRIC_CODE,
    apiWSTimeCheckAlerts,
    pushDebug,
    ClarityChartConfig
} from '../constants';
import {
    App,
    Agency,
    Company,
    PublicRole,
    RemoteAuth,
    RemoteCalendarData,
    resourceWidgetName,
    SmsMessage,
    TwilioNumber,
    User,
    UserRole,
    FilterCommon,
    FiltersCommon,
    ListCommon,
    ListsCommon,
    AppointmentTypes
} from '../types';
import { Alert } from '../types/Alert';
import { AuditLogs } from '../types/AuditLogs';
import { Bpn } from '../types/Bpn';
import { Calendar } from '../types/Calendar';
import { CalendarAppointmentTypes } from '../types/CalendarAppointmentTypes';
import { SelectedAppointmentType } from '../types/SelectedAppointmentTypes';
import { CalendarDayoff } from '../types/CalendarDayoff';
import { CallUs } from '../types/CallUs';
import { Category } from '../types/Category';
import { Chat } from '../types/Chat';
import { Chatbot } from '../types/Chatbot';
import { AccessibleCompany } from '../types/Company';
import { CssPath } from '../types/CssPath';
import { AllFeatureFlag, FEATURE_FLAGS } from '../types/FeatureFlag';
import { Insite } from '../types/Insite';
import { Lead, LeadStat } from '../types/Lead';
import { Offer, SpotlightTemplate } from '../types/Offer';
import { Question } from '../types/Question';
import { OwnershipGroup } from '../types/ownershipGroup';
import { PropertyManagementCompanyTypes } from '../types/PropertyManagementCompany';
import { ProspectQuestion } from '../types/ProspectQuestion';
import { Regions } from '../types/Regions';
import { AvailabilitySchedule } from '../types/Schedule';
import { SmsThread } from '../types/SmsThread';
import { TextUs } from '../types/TextUs';
import { ActiveUser } from '../types/User';
import Api from '../util/api';
import { clearSessionItem, getSessionObject, setSessionObject } from '../util/session';
import { buildUrlParamsQuery } from '../util/url';
import Resource from './Resource';
import { detectRoutePath } from './routePath';
import { cloneDeep, get } from 'lodash';
import { LeadNote } from '../types/LeadNote';
import { LeadReminder } from '../types/LeadReminder';
import { LeadEmail, LeadSmsMessage, CompanyCountResponse } from '../types';
import { AgencyStore } from './agencyStore';
import { FormAlertsData } from '../components/FormAlerts/FormAlerts';
import { askPermission, subscribeUserToPush } from '../serviceWorker';
import { ApiKeyManager } from '../types/ApiKeyManager';
import { handlePromise } from '../util/async';
import Axios from 'axios';
import { Crm, CrmConfigOption } from '../types/Crm';
import { Filters } from '../components/Filters/Filters.helpers';
import { OptOutQueue } from '../types/OptOutQueue';
import { DripSchedule } from '../types/DripSchedule';

class Store {
    public agencyStore: AgencyStore;

    @observable
    public auth: {
        token: string | null;
        refreshToken: string | null;
    };
    @observable public activeUser: ActiveUser | null;
    @observable public isSidebarActive: boolean = true;
    @observable public pendingRequests = 0;
    @observable public activeUserFetched = false;
    @observable public errors: object = {};
    @observable public _activeCompanyId: number = 0;
    @observable public alerts: Alert[] = [];
    @observable public alertIdLast: number = 0;
    @observable public accessibleCompanies: AccessibleCompany[];
    @observable public leadsStat: LeadStat[];
    @observable public remoteAuths: RemoteAuth[];
    @observable public remoteAuthKeys: Array<{ name: string; api_key: string; key_type_id: number }>;
    @observable public featureFlags: AllFeatureFlag[];
    @observable public companyApiKeys: ApiKeyManager[];
    @observable public features: { [name: string]: boolean } = {};
    @observable public crmConfigOptionList: Array<CrmConfigOption>;
    @observable public remoteCalendarData: RemoteCalendarData;
    @observable public companyFeatureFlags: object = {};
    @observable public enquireProperties: Array<{ name: string; exactPropertyName: string }>;
    @observable public sherpaLeasingCounselors: Array<{ id: string; description: string }>;
    @observable public eldermarkFormKeys: Array<{ label: string; key: string }>;
    @observable public filter: FiltersCommon;
    @observable public draft: any;
    @observable public list: ListsCommon;
    @observable public activeFeatures: object = {};
    @observable public activeFeatureList: string[];
    @observable.struct public windowDimensions: {
        width: number;
        height: number;
    };
    @observable public brokenInternetConnection: boolean = false;
    @observable public refreshLeadSms: number = 0;
    @observable public formAlerts: FormAlertsData[] = [];
    @observable public amountUnreadSmsMessages: number = 0;
    @observable public smsTabArchiveState: boolean = false;
    @observable public selectedSpotlightTemplate: SpotlightTemplate | null;

    public router: RouterStore;
    public accessibleCompaniesIndex: Record<number, number>;
    public calendars: Resource<Calendar>;
    public calendarAppointmentTypes: Resource<CalendarAppointmentTypes>;
    public selectedAppointmentTypes: Resource<SelectedAppointmentType>;
    public agencies: Resource<Agency>;
    public apps: Resource<App>;
    public companies: Resource<Company>;
    public insites: Resource<Insite>;
    public users: Resource<User>;
    public roles: Resource<UserRole>;
    public publicRoles: Resource<PublicRole>;
    public cssPaths: Resource<CssPath>;
    public leads: Resource<Lead>;
    public offers: Resource<Offer>;
    public questions: Resource<Question>;
    public crms: Resource<Crm>;
    public chats: Resource<Chat>;
    public chat: Resource<Chat>;
    public chatbots: Resource<Chatbot>;
    public bpn: Resource<Bpn>;
    public callUs: Resource<CallUs>;
    public textUs: Resource<TextUs>;
    public calendarsDaysoff: Resource<CalendarDayoff>;
    public incomeCalculators: Resource<any>;
    public schedules: Resource<AvailabilitySchedule>;
    public dripSchedules: Resource<DripSchedule>;
    public sms: Resource<SmsMessage>;
    public smsThreads: Resource<SmsThread>;
    public twilioNumbers: Resource<TwilioNumber>;
    public auditLogs: Resource<AuditLogs>;
    public auditCompanies: Resource<AuditLogs>;
    public auditApps: Resource<AuditLogs>;
    public appointmentTypes: Resource<AppointmentTypes>;
    public businessCategory: Resource<Category>;
    public PropertyManagementCompany: Resource<PropertyManagementCompanyTypes>;
    public prospectQuestions: Resource<ProspectQuestion>;
    public optOutQueues: Resource<OptOutQueue>;
    public ownershipGroup: Resource<OwnershipGroup>;
    public regions: Resource<Regions>;
    public forwardNumbers: Resource<any>;
    public readonly api: Api;
    public crmValidationResponse: string = '';
    protected ws: WebSocket;
    protected wsTasks: string[] = [];
    protected wsReconnectTimeout = 1000;
    private timerSaveDraft: any;
    private pushNotificationSubscribed = false;
    private pushNotificationWaitSubscribe = false;
    private pushNotificationThreadsRefresh: number[] = [];
    public propertyManagementCompanies: any;

    constructor() {
        this.api = new Api({ store: this });
        this.agencyStore = new AgencyStore(this);
        this.auth = {
            token: localStorage.getItem('token'),
            refreshToken: localStorage.getItem('refreshToken')
        };
        this.router = new RouterStore();
        this.agencies = new Resource(this.api, 'agencies');
        this.apps = new Resource(this.api, 'apps');
        this.bpn = new Resource(this.api, 'bpn');
        this.companies = new Resource(this.api, 'companies');
        this.publicRoles = new Resource(this.api, 'public_roles');
        this.calendars = new Resource(this.api, 'calendars');
        this.roles = new Resource(this.api, 'roles');
        this.users = new Resource(this.api, 'users');
        this.cssPaths = new Resource(this.api, 'css_paths');
        this.leads = new Resource(this.api, 'leads');
        this.offers = new Resource(this.api, 'offers');
        this.questions = new Resource(this.api, 'questions');
        this.crms = new Resource(this.api, 'crm_configs');
        this.chats = new Resource(this.api, 'chats');
        this.chat = new Resource(this.api, 'chat');
        this.chatbots = new Resource(this.api, 'chatbot');
        this.callUs = new Resource(this.api, 'call_us');
        this.textUs = new Resource(this.api, 'text-us', 'text_us');
        this.smsThreads = new Resource(this.api, 'sms-threads', 'sms_threads');
        this.calendarAppointmentTypes = new Resource(this.api, 'calendar_appointment_types');
        this.selectedAppointmentTypes = new Resource(this.api, 'calendar_appointment_types');
        this.calendarsDaysoff = new Resource(this.api, 'calendars_daysoff');
        this.incomeCalculators = new Resource(this.api, 'income_calculators');
        this.schedules = new Resource(this.api, 'schedules');
        this.dripSchedules = new Resource(this.api, 'drip_schedules');
        this.sms = new Resource(this.api, 'sms', 'sms_messages');
        this.twilioNumbers = new Resource(this.api, 'twilio-numbers', 'twilio_provisioned_numbers');
        this.businessCategory = new Resource(this.api, 'business_categories');
        this.appointmentTypes = new Resource(this.api, 'appointment_types');
        this.PropertyManagementCompany = new Resource(this.api, 'management_companies');
        this.prospectQuestions = new Resource(this.api, 'prospect_questions');
        this.optOutQueues = new Resource(this.api, 'opt_out_queue');
        this.ownershipGroup = new Resource(this.api, 'ownership_groups');
        this.regions = new Resource(this.api, 'regions');
        this.insites = new Resource(this.api, 'insite');
        this.forwardNumbers = new Resource(this.api, 'forward_sms_numbers');
        this.init();
    }

    public init() {
        if (detectRoutePath()) {
            this.runInQueue(this.fetchActiveUser().then(this.wsInit));
        }

        // Get initial size
        this.getWindowDimensions();

        // Add window resize listener
        window.addEventListener('resize', () => {
            this.getWindowDimensions();
        });

        this.filter = {};
        this.list = {};
        this.draft = {};

        ['list', 'filter', 'draft'].forEach(v => {
            const s = getSessionObject(v);
            if (s) {
                this[v] = s;
            }
        });

        setInterval(this.wsCheckNotifications, apiWSTimeCheckAlerts);
    }

    protected wsInit = () => {
        return;
        // if (localStorage.staticClaritySettings) {
        //     return;
        // }
        // try {
        //     const ws = new WebSocket(`${apiServerWSUrl}/channel/${this.auth.token}`);
        //     if (!ws) {
        //         return;
        //     }

        //     ws.onclose = () => {
        //         if (!this.activeUser) {
        //             return;
        //         }

        //         setTimeout(this.wsInit, this.wsReconnectTimeout);
        //         const maxTimeout = 60000;

        //         if (this.wsReconnectTimeout < maxTimeout) {
        //             this.wsReconnectTimeout += this.wsReconnectTimeout;
        //         }
        //     };

        //     ws.onmessage = async (ev: MessageEvent) => {
        //         runInAction(() => {
        //             this.brokenInternetConnection = false;
        //         });
        //         try {
        //             const response = JSON.parse(ev.data) as Alert[];
        //             let foundSms = false;
        //             let needRefreshThreads = false;

        //             if (!response || !response.length) {
        //                 return;
        //             }

        //             response.forEach((alert: Alert) => {
        //                 switch (alert.reason) {
        //                     case 'text_us':
        //                         foundSms = true;
        //                         if (
        //                             alert.details &&
        //                             alert.details.data &&
        //                             alert.details.company_id === this.activeCompanyId
        //                         ) {
        //                             this.sms.replace(alert.details.data);
        //                             if (
        //                                 alert.details.data.thread_id &&
        //                                 this.pushNotificationThreadsRefresh.indexOf(alert.details.data.thread_id) < 0
        //                             ) {
        //                                 this.pushNotificationThreadsRefresh.push(alert.details.data.thread_id);
        //                                 needRefreshThreads = true;
        //                             }
        //                         }
        //                         this.addAlert(alert);
        //                         break;
        //                     case 'lead_reminder':
        //                         this.addAlert(alert);
        //                         break;
        //                     case 'offer':
        //                         if (alert.action && alert.action === 'delete') {
        //                             this.deleteAlert(alert);
        //                         } else {
        //                             this.addAlert(alert);
        //                         }
        //                         break;
        //                 }
        //             });

        //             if (needRefreshThreads) {
        //                 setTimeout(() => {
        //                     this.refreshThreads();
        //                 }, 3000);
        //             }

        //             if (foundSms) {
        //                 runInAction(() => {
        //                     this.refreshLeadSms++;
        //                     this.fetchSmsThreads();
        //                 });
        //             }
        //         } catch (e) {
        //             global.console.log(e.message);
        //         }
        //     };

        //     ws.onerror = () => {
        //         runInAction(() => {
        //             // this.brokenInternetConnection = true;
        //         });
        //     };

        //     this.ws = ws;
        // } catch (e) {
        //     // global.console.log(e);
        // }
    };

    protected wsCheckNotifications = () => {
        if (this.ws && this.ws.readyState === this.ws.OPEN) {
            try {
                if (!this.activeUser) {
                    return;
                }
                if (!this.activeCompanyId && !this.activeUser.view_all_alerts) {
                    return;
                }

                const lastPart = this.alertIdLast ? `/${this.alertIdLast}` : '';
                this.ws.send(`/alert-notifications/${this.activeCompanyId}${lastPart}`);

                if (this.wsTasks.length) {
                    this.wsTasks.map(task => this.ws.send(task));
                    this.wsTasks = [];
                }
            } catch { }
        }
    };

    protected checkNotifications = () => {
        const lastPart = this.alertIdLast ? `/${this.alertIdLast}` : '';
        this.wsTasks.push(`/alert-notifications/${this.activeCompanyId}${lastPart}`);
    };

    protected checkOfferNotifications = () => {
        if (this.activeCompanyId) {
            this.wsTasks.push(`/offer-notifications/${this.activeCompanyId}`);
        }
    };

    protected needUpdateAlerts() {
        return (
            this.activeCompanyId && this.activeUser && !['company_light', 'chat'].includes(this.activeUser.role.slug)
        );
    }

    protected refreshThreads() {
        return Promise.all(
            this.pushNotificationThreadsRefresh.map(thread_id => this.smsThreads.refresh(thread_id))
        ).finally(() => {
            this.pushNotificationThreadsRefresh = [];
        });
    }

    public get activeCompanyId() {
        return this.router.params?.companyId ? parseInt(this.router.params.companyId, 10) : this._activeCompanyId;
    }

    public get adminRoles() {
        return ['admin', 'super_admin'];
    }

    public get agencyRoles() {
        return ['agency_super_admin', 'agency_admin'];
    }

    public get companyRoles() {
        return ['company_light', 'company_admin'];
    }

    public isSuperAdmin() {
        return get(this, 'activeUser.role.slug') === 'super_admin';
    }

    @computed
    get activeCompany(): AccessibleCompany {
        return this.accessibleCompanies[this.accessibleCompaniesIndex[this.activeCompanyId]];
    }

    @computed
    public get isAdminRole() {
        return this.activeUser ? this.adminRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isSuperAdminRole() {
        return this.activeUser ? this.activeUser.role.slug === 'super_admin' : false;
    }

    @computed
    public get isAgencySuperAdminRole() {
        return this.activeUser ? this.activeUser.role.slug === 'agency_super_admin' : false;
    }

    @computed
    public get isAgencyRole() {
        return this.activeUser ? this.agencyRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isCompanyRole() {
        return this.activeUser ? this.companyRoles.indexOf(this.activeUser.role.slug) > -1 : false;
    }

    @computed
    public get isCompanyLightRole() {
        return this.activeUser ? this.activeUser.role.slug === 'company_light' : false;
    }

    @computed
    public get isChatRole() {
        return this.activeUser ? this.activeUser.role.slug === 'chat' : false;
    }

    @computed
    public get Api() {
        return this.api;
    }

    @computed
    public get loggedIn(): boolean {
        return !!this.auth.token;
    }

    @computed
    public get isLoading(): boolean {
        return this.pendingRequests > 0;
    }

    @action.bound
    public addFormAlert(alert: FormAlertsData) {
        this.formAlerts = [alert];
    }

    @action.bound
    public readStateSmsThread(id: number) {
        this.smsThreads.getItem(id).marked_read = true;
    }

    @action.bound
    public async calcUnreadSmsMessages() {
        this.amountUnreadSmsMessages = this.smsThreads.values.reduce((previousValue: any, thread: any) => {
            if (!thread.marked_read) {
                return ++previousValue;
            }
            return previousValue;
        }, 0);
    }

    @action.bound
    public setSmsTabArchiveState(state: boolean) {
        this.smsTabArchiveState = state;
    }

    @action.bound
    public removeFormAlert() {
        this.formAlerts.shift();
    }

    @action.bound
    public addAlert(alert: Alert) {
        if (!this.alerts.some(a => a.id === alert.id)) {
            if (alert.db_id && alert.db_id > this.alertIdLast) {
                this.alertIdLast = alert.db_id;
            }
            this.alerts.push(alert);
        }
    }

    @action.bound
    public deleteAlert(alert: Alert) {
        this.alerts.forEach((a, i) => {
            if (alert.id === a.id) {
                this.alerts.splice(i, 1);
            }
        });
    }

    @action.bound
    public getWindowDimensions() {
        this.windowDimensions = {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    @action.bound
    public setActiveCompanyId(id: number) {
        this._activeCompanyId = id;
        localStorage.setItem('activeCompanyId', `${id}`);
        this.alertIdLast = 0;
        this.alerts = [];
        this.checkNotifications();
        this.checkOfferNotifications();
        this.fetchPublicRoles();
    }

    @action.bound
    public deleteCompanyFromList(id: any) {
        const index = id ? this.accessibleCompaniesIndex[id] : -1;
        if (index >= 0) {
            this.accessibleCompanies.splice(index, 1);
            this.rebuildCompaniesIndex();
            if (this.activeCompanyId === parseInt(id, 10)) {
                this.setActiveCompanyId(0);
            }
            // if there are no more companies - logout
            if (!this.accessibleCompanies.length) {
                this.logout();
            }
        }
    }

    @action.bound
    public setTokens(token: string, refreshToken: string) {
        this.auth = { token, refreshToken };
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
    }

    @action.bound
    public hasAccess(request: string) {
        const user = this.activeUser;
        return user && this.loggedIn && user.role.permissions[request];
    }

    @action.bound
    public setErrors(e: object) {
        runInAction(() => {
            this.errors = e;
        });
    }

    @action.bound
    public setRemoteCalendarData(d: RemoteCalendarData) {
        runInAction(() => {
            this.remoteCalendarData = d;
        });
    }

    @action.bound
    public clearErrors() {
        this.errors = {};
    }

    @action.bound
    public toggleSitebar() {
        this.isSidebarActive = !this.isSidebarActive;
    }

    @action.bound
    public login(args: LoginFormValues) {
        this.pendingRequests = 0;
        return this.runInQueue(
            this.api
                .login(args)
                .then(this.authenticate)
                .then(() => this.wsInit())
        );
    }

    @action.bound
    public authenticate(response: { data: { token: string; refresh_token: string } }) {
        if (!response) {
            return;
        }

        const { token, refresh_token } = response.data;
        this.setTokens(token, refresh_token);
        this.setActiveUserData(response.data as any);
        this.api.setAuth({ token, refreshToken: refresh_token });
    }

    public pushSubscribe() {
        if (pushDebug) {
            global.console.log('subscribed', this.pushNotificationSubscribed);
        }

        if (!this.activeUser) {
            return;
        }

        if (this.pushNotificationSubscribed) {
            return;
        }

        if (this.pushNotificationWaitSubscribe) {
            return;
        }

        this.pushNotificationWaitSubscribe = true;

        return askPermission()
            .then((result: any) => {
                if (pushDebug) {
                    global.console.log('askPermission() ', result);
                }

                if (!result) {
                    return Promise.resolve();
                }

                return subscribeUserToPush(process.env.REACT_APP_PUBLIC_VAPID_KEY || '');
            })
            .then((subscription: any) => {
                this.pushNotificationSubscribed = true;

                if (pushDebug) {
                    global.console.log('subscribed', this.pushNotificationSubscribed);
                    global.console.log('subscription', subscription);
                }

                return subscription ? this.api.subscribe(subscription, this.activeUser!.id) : Promise.resolve();
            })
            .catch((e: any) => {
                if (pushDebug) {
                    global.console.log('askPermission error: ', e);
                }
            });
    }

    @action.bound
    public changePassword(args: { password: string; confirmPassword: string; hash: string }) {
        this.pendingRequests = 0;
        return this.runInQueue(
            this.api
                .changePassword({ pass: args.password, confirm_pass: args.confirmPassword, hash: args.hash })
                .then(response =>
                    runInAction(() => {
                        this.auth = {
                            token: response.data.token,
                            refreshToken: response.data.refresh_token
                        };
                        this.setActiveUserData(response.data);
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('refreshToken', response.data.refresh_token);
                    })
                )
        );
    }

    @action.bound
    public logout() {
        const userEmail = localStorage.getItem('rememberEmail');
        const staticClaritySettings = localStorage.getItem('staticClaritySettings');
        localStorage.clear();
        if (userEmail) {
            localStorage.setItem('rememberEmail', userEmail);
        }
        if (staticClaritySettings) {
            localStorage.setItem('staticClaritySettings', staticClaritySettings);
        }
        this.clearAllSessionSetting();
        this.setActiveCompanyId(0);
        this.accessibleCompanies = [];
        this.accessibleCompaniesIndex = [];
        this.activeUserFetched = false;

        if (this.activeUser) {
            this.api.unsubscribe(this.activeUser.id);
            this.pushNotificationSubscribed = false;
            this.pushNotificationWaitSubscribe = false;
        }

        if (this.agencyStore.activeAgencyId) {
            this.agencyStore.fetchAgencyCustomizationByHostname();
        }

        this.activeUser = null;
        this.auth = { token: '', refreshToken: '' };
        this.api.logout();
        if (this.ws && this.ws.OPEN) {
            this.ws.close();
        }
        this.pendingRequests = 0;
    }

    @action.bound
    public fetchUsers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.users.fetch(params));
    }

    @action.bound
    public fetchRoles(id?: number) {
        return this.runInQueue(this.roles.fetch({ id }));
    }

    @action.bound
    public fetchCompanies(id?: number, active?: boolean) {
        const params = this.getDefaultParams(id);
        params.filters.active = active;
        return this.runInQueue(this.companies.fetch(params));
    }

    @action.bound
    public refreshCompany(id: number) {
        return this.runInQueue(this.companies.refresh(id));
    }

    @action.bound
    public fetchIncomeCalculators(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.incomeCalculators.fetch(params));
    }

    @action.bound
    public fetchCalendars(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.calendars.fetch(params));
    }

    @action.bound
    public fetchBpn(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.bpn.fetch(params));
    }

    @action.bound
    public fetchApps(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.apps.fetch(params));
    }

    @action.bound
    public fetchInsites(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.insites.fetch(params));
    }

    @action.bound
    public fetchPublicRoles(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.publicRoles.fetch(params));
    }

    @action.bound
    public fetchActiveFeatures() {
        return this.api.client.get(`/company-features/${this.activeCompanyId}/app-configs`).then(result => {
            runInAction(() => {
                this.activeFeatures = result.data;
                this.activeFeatureList = Object.keys(result.data);
            });
        });
    }

    @action.bound
    public fetchCssPaths(id?: number) {
        return this.runInQueue(this.cssPaths.fetch({ id }));
    }

    @action.bound
    public fetchLeads(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.leads.fetch(params));
    }

    @action.bound
    public fetchDefineFields(id: number) {
        return this.Api.fetch(`insite-defined-fields/${id}`);
    }

    @action.bound
    public updateDefineFields(id: number, data: object) {
        return this.Api.client.patch(`insite-defined-fields/${id}`, data);
    }

    @action.bound
    public fetchMetricsCompaniesCount(range: { start: string; end: string }) {
        return this.fetchMetrics('companies-count', range);
    }

    @action.bound
    public fetchMetricsCalendarsCount(range: { start: string; end: string }) {
        return this.fetchMetrics('calendars-count', range);
    }

    @action.bound
    public fetchMetricsCallUsNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('call-us-now-count', range);
    }

    @action.bound
    public fetchMetricsOffersCount(range: { start: string; end: string }) {
        return this.fetchMetrics('offers-count', range);
    }

    @action.bound
    public fetchMetricsBestPriceNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('bpn-count', range);
    }

    @action.bound
    public fetchMetricsIncomeCalculatorCount(range: { start: string; end: string }) {
        return this.fetchMetrics('ic-count', range);
    }

    @action.bound
    public fetchMetricsChatCount(range: { start: string; end: string }) {
        return this.fetchMetrics('chat-count', range);
    }

    @action.bound
    public fetchMetricsTextUsNowCount(range: { start: string; end: string }) {
        return this.fetchMetrics('tun-count', range);
    }

    @action.bound
    public fetchMetricsLeadsCount(range: { start: string; end: string }) {
        return this.fetchMetrics('leads-count', range);
    }

    @action.bound
    public fetchTopNCompanies(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('top-n-companies-by-leads', range, numCompanies);
    }

    @action.bound
    public fetchBottomNCompanies(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('bottom-n-companies-by-leads', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByCompany(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-company', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByPMC(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-pmc', range, numCompanies);
    }

    @action.bound
    public fetchMatchbacksByWeek(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('matchbacks-count-by-week', range, numCompanies);
    }

    @action.bound
    public fetchLeadsCountByDay(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('leads-count-by-day', range, numCompanies);
    }

    @action.bound
    public fetchLeadsCountByMonth(range: { start: string; end: string }, numCompanies: number) {
        return this.fetchMetrics('leads-count-by-month', range, numCompanies);
    }

    @action.bound
    public fetchLeadsPage(listCode: string, filterCode: string, listDefault: ListCommon) {
        const customFilterSettings = this.getLeadsPeriodFilterSettings();

        return this.fetchPoint('leads', listCode, filterCode, listDefault, {}, customFilterSettings);
    }

    @action.bound
    public fetchLeadsPeriod(listCode: string, filterCode: string, view = 'month') {
        const customFilterSettings = this.getLeadsPeriodFilterSettings();

        return this.fetchPoint('leads/calendar', listCode, filterCode, {}, {}, customFilterSettings, { view });
    }

    public getLeadsPeriodFilterSettings() {
        let customFilterSettings: string[] = [];

        if (this.activeCompanyId) {
            customFilterSettings = ['start', 'end', 'lead_type', 'search', 'status'];
        }

        return customFilterSettings;
    }

    @action.bound
    public fetchLeadsCallUs(listCode: string, filterCode: string) {
        return this.fetchPoint('leads/callUsCount', listCode, filterCode);
    }

    @action.bound
    public fetchLeadsChatStartedCount(listCode: string, filterCode: string) {
        return this.fetchPoint('leads/chatStartedCount', listCode, filterCode);
    }

    @action.bound
    public fetchLeadsTextUs(listCode: string, filterCode: string) {
        return this.fetchPoint('leads/textUsMessagesCount', listCode, filterCode);
    }

    @action.bound
    public getSmsThreadsCount(listCode: string, filterCode: string) {
        return this.fetchPoint('leads/smsThreadsCount', listCode, filterCode);
    }

    @action.bound
    public fetchCompanyCount(
        listCode: string,
        filterCode: string,
        page = 'leads' || 'insite_leads'
    ): Promise<CompanyCountResponse> {
        const filter = this.getFilterSetting(filterCode);

        if (this.activeCompanyId || filter.company_id) {
            return Promise.resolve({
                data: {
                    count_companies: 1
                }
            });
        }

        let filterSettings = this.getLeadsPeriodFilterSettings();
        if (page === 'insite_leads') {
            filterSettings = this.getInsiteMetaDataFilterSettings();
        }

        return this.fetchPoint('leads/companyCount', listCode, filterCode, undefined, undefined, filterSettings);
    }

    @action.bound
    public fetchLead(id: number): Promise<any> {
        return this.Api.fetch(`leads/${id}`);
    }

    @action.bound
    public fetchLeadsStat() {
        const defaultParams = this.getDefaultParams();
        const t: number = moment()
            .startOf('day')
            .unix();
        const url = `leads/stat/?time=${t}&${buildUrlParamsQuery(defaultParams.filters)}`;

        return this.runInQueue(
            this.api.client.get(url).then(response =>
                runInAction(() => {
                    this.leadsStat = response.data;
                })
            )
        );
    }

    @action.bound
    public fetchCrms(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.crms.fetch(params));
    }

    @action.bound
    public fetchOffers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.offers.fetch(params));
    }

    @action.bound
    public fetchChatbot(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chatbots.fetch(params));
    }

    @action.bound
    public fetchChatbotQuestions(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.questions.fetch(params));
    }

    @action.bound
    public fetchProspectQuestions(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.prospectQuestions.fetch(params));
    }

    @action.bound
    public fetchChats(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chats.fetch(params));
    }

    @action.bound
    public fetchChat(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.chat.fetch(params));
    }

    @action.bound
    public fetchCompanyChat(id?: number) {
        return this.runInQueue(this.chats.fetch());
    }

    @action.bound
    public fetchCallUs(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.callUs.fetch(params));
    }

    @action.bound
    public fetchTextUs(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.textUs.fetch(params));
    }

    @action.bound
    public fetchCalendarDaysOff(id?: number) {
        const params = this.getDefaultParams(id);
        // Note: LC-452 we want to fetch all holidays even when in the context of an agency, but users will still only
        // be able to edit holidays if they were created by the agency - Jake
        delete params.filters.agency_id;
        return this.runInQueue(this.calendarsDaysoff.fetch(params));
    }

    @action.bound
    public async fetchSchedules() {
        const [getSchedulesResponse, getSchedulesError] = await handlePromise<{
            data: { count: number; data: AvailabilitySchedule[] };
        }>(this.api.client.get(`company/${this.activeCompanyId}/availability-schedules`));

        if (!getSchedulesError && getSchedulesResponse) {
            this.schedules.replaceAll(getSchedulesResponse.data);
        }

        return Promise.resolve([getSchedulesResponse, getSchedulesError]);
    }

    @action.bound
    public async fetchDripSchedules() {
        const [getDripSchedulesResponse, getDripSchedulesError] = await handlePromise<{
            data: { count: number; data: DripSchedule[] };
        }>(this.api.client.get(`company/${this.activeCompanyId}/drip-schedules`));

        if (!getDripSchedulesError && getDripSchedulesResponse) {
            this.dripSchedules.replaceAllDrips(getDripSchedulesResponse.data);
        }

        return Promise.resolve([getDripSchedulesResponse, getDripSchedulesError]);
    }

    @action.bound
    public fetchTwilioNumbers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.twilioNumbers.fetch(params));
    }

    @action.bound
    public fetchRemoteAuthKeys(id?: number) {
        const companyId = id ? id : this.getDefaultParams().filters.company_id;

        const url = `/external/auth/tokens/${companyId}`;

        return this.runInQueue(
            this.api.client.get(url).then(response =>
                runInAction(() => {
                    this.remoteAuthKeys = response.data.map((ra: RemoteAuth) => {
                        return { name: ra.name, api_key: ra.key, key_type_id: ra.type_id };
                    });
                })
            )
        );
    }

    @action.bound
    public generateNewCompanyApiKey() {
        const url = `/api-key-manager/${this.activeCompanyId}`;
        return this.api.client.post(url);
    }

    @action.bound
    public fetchCrmConfigOptions(id?: number) {
        const companyId = id ? id : this.getDefaultParams().filters.company_id;

        const url = `crm_configs?company_id=${companyId}`;

        return this.runInQueue(
            this.api.client.get(url).then(response =>
                runInAction(() => {
                    this.crmConfigOptionList = response.data.crm_configs.map((cfg: Crm) => {
                        return {
                            id: cfg.id,
                            name: `${cfg.name} [${cfg.crm_type}]`,
                            type: cfg.crm_type
                        };
                    });
                })
            )
        );
    }

    @action.bound
    public validateCrmConfiguration(data: Crm, snack: any) {
        interface CRMValidation {
            endpoint: string;
            status: string;
            message: string;
        }
        return this.runInQueue(
            this.Api.client
                .post('crm_configs/validate', data)
                .then(response =>
                    runInAction(() => {
                        const results: CRMValidation = response.data;

                        snack(results.message, {
                            variant: results.status,
                            persist: true
                        });
                    })
                )
                .catch(err =>
                    runInAction(() => {
                        snack(`FAILED: ${JSON.stringify(err.response?.data)}`, { variant: 'error', persist: true });
                    })
                )
        );
    }

    @action.bound
    public updateRemoteAuths(data: RemoteAuth) {
        const url = `external/auth/tokens/${data.id}`;
        return this.runInQueue(
            this.api.client.patch(url, data).then(response =>
                runInAction(() => {
                    this.remoteAuths = response.data;
                })
            )
        );
    }

    @action.bound
    public deleteRemoteAuth(id: number) {
        const url = `external/auth/${id}`;
        return this.runInQueue(
            this.api.client.delete(url).then(response =>
                runInAction(() => {
                    this.remoteAuths = response.data;
                })
            )
        );
    }

    @action.bound
    public fetchCrmConfigs(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.crms.fetch(params));
    }

    @action.bound
    public fetchOwnershipGroup(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.ownershipGroup.fetch(params));
    }

    @action.bound
    public fetchRegions(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.regions.fetch(params));
    }

    @action.bound
    public fetchPropertyManagementCompany(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.PropertyManagementCompany.fetch(params));
    }

    @action.bound
    public deleteOwnershipGroup(id: number) {
        return this.runInQueue(this.ownershipGroup.delete(id));
    }

    @action.bound
    public deleteRegions(id: number) {
        return this.runInQueue(this.regions.delete(id));
    }

    @action.bound
    public fetchSms(companyId?: number, threadId?: number) {
        const params = this.getDefaultParams(companyId);

        if (threadId) {
            params.filters.thread_id = threadId;
        }

        return this.runInQueue(this.sms.fetch(params));
    }

    @action.bound
    public sendSms(companyId: number, fromNumber: string, toNumber: string, message: string) {
        return this.Api.client.post<{ id: number }>('/sms/send', {
            company_id: companyId,
            from_number: fromNumber,
            to_number: toNumber,
            message
        });
    }

    @action.bound
    public fetchSmsThreads(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(
            this.smsThreads.fetch(params).then(() => {
                this.calcUnreadSmsMessages();
            })
        );
    }

    @action.bound
    public fetchSmsPage(args: { page: number; pageSize: number; order: string; search: string }) {
        const { page, pageSize, order, search } = args;
        return this.Api.fetch('sms', {
            page,
            pageSize,
            order,
            filters: {
                search,
                ...(this.activeCompanyId && { company_id: this.activeCompanyId })
            }
        });
    }

    @action.bound
    public fetchCategory(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.businessCategory.fetch(params));
    }

    @action.bound
    public fetchAppointmentType(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.appointmentTypes.fetch(params));
    }

    @action.bound
    public fetchSelectedAppointmentTypesForCompany(companyId: number) {
        const params = this.getDefaultParams(companyId);
        return this.runInQueue(this.selectedAppointmentTypes.fetch(params));
    }

    @action.bound
    public fetchForwardNumbers(id?: number) {
        const params = this.getDefaultParams(id);
        return this.runInQueue(this.forwardNumbers.fetch(params));
    }

    @action.bound
    public fetchCategoryFilter() {
        return this.api.client
            .get(
                `business_categories/filterList?agency_id=${this.agencyStore.activeAgencyId}&company_id=${this.activeCompanyId}`
            )
            .then(response => response.data);
    }

    @action.bound
    public getAllFeatureFlags() {
        return this.api.client.get('/feature_flags/' + this.activeCompanyId);
    }

    @action.bound
    public saveAllFeatureFlags(data: AllFeatureFlag[]) {
        return this.api.client.post('/feature_flags/' + this.activeCompanyId, {
            data
        });
    }

    @action.bound
    public getCompanyFeatures(id?: number) {
        const companyId = id ? id : this.activeCompanyId;
        return this.api.client.get(`/company-features/${companyId}`);
    }

    @action.bound
    public getCompanyFeatureSlugs(id?: number) {
        const companyId = id ? id : this.activeCompanyId;
        return this.api.client.get(`/company-features/${companyId}/slugs`);
    }

    @action.bound
    public getFeatures() {
        return this.api.client.get(`/features/`);
    }

    @action.bound
    public saveCompanyFeatures(featureSlugs: string[]) {
        return this.api.client
            .patch('/company-features/' + this.activeCompanyId, {
                feature_slugs: featureSlugs
            })
            .then(() => {
                this.fetchFeatures();
            });
    }

    @action.bound
    public saveAppsAuditProcessed(data: {}) {
        return this.api.client.post('/auditlogs/apps/', {
            data
        });
    }

    @action.bound
    public saveExceptions(add: string[], del: string[]) {
        return this.api.client.post('/insite-pages/exceptions/', {
            add,
            del
        });
    }

    @action.bound
    public deleteApp(id: number) {
        return this.runInQueue(this.apps.delete(id));
    }

    @action.bound
    public deleteCalendar(id: number) {
        return this.runInQueue(this.calendars.delete(id));
    }

    @action.bound
    public deleteBpn(id: number) {
        return this.runInQueue(this.bpn.delete(id));
    }

    @action.bound
    public deleteCompany(id: number) {
        return this.runInQueue(this.companies.delete(id)).then(() => {
            this.deleteCompanyFromList(id);
        });
    }

    @action.bound
    public deleteIncomeCalculator(id: number) {
        return this.runInQueue(this.incomeCalculators.delete(id));
    }

    @action.bound
    public deleteCalendarDaysoff(id: number) {
        return this.runInQueue(this.calendarsDaysoff.delete(id));
    }

    @action.bound
    public deleteUser(id: number) {
        return this.runInQueue(this.users.delete(id));
    }

    @action.bound
    public deleteCompanyRole(id: number) {
        return this.runInQueue(this.publicRoles.delete(id));
    }

    @action.bound
    public deleteOffer(id: number) {
        return this.runInQueue(this.offers.delete(id));
    }

    @action.bound
    public deleteProspectQuestion(id: number) {
        return this.runInQueue(this.prospectQuestions.delete(id));
    }

    @action.bound
    public deleteOptOutQueue(id: number) {
        return this.runInQueue(this.optOutQueues.delete(id));
    }

    @action.bound
    public processOptOutQueue(id: number) {
        const url = `/opt_out_queue/${id}/process`;
        return this.api.client.post(url);
    }

    @action.bound
    public deleteCallUs(id: number) {
        return this.runInQueue(this.callUs.delete(id));
    }

    @action.bound
    public deleteTextUs(id: number) {
        return this.runInQueue(this.textUs.delete(id));
    }

    @action.bound
    public deleteChat(id: number) {
        return this.runInQueue(this.chats.delete(id));
    }

    @action.bound
    public deleteLead(id: number) {
        return this.runInQueue(this.leads.delete(id));
    }

    @action.bound
    public deleteSchedule(id: number) {
        return this.runInQueue(this.schedules.delete(id));
    }

    @action.bound
    public deleteCrmConfig(id: number) {
        return this.runInQueue(this.crms.delete(id));
    }

    @action.bound
    public deleteSms(id: number) {
        return this.runInQueue(this.sms.delete(id));
    }

    @action.bound
    public deleteCategory(id: number) {
        return this.runInQueue(this.businessCategory.delete(id));
    }

    @action.bound
    public deletePropertyManagementCompany(id: number) {
        return this.runInQueue(this.PropertyManagementCompany.delete(id));
    }

    @action.bound
    public deleteAppointmentType(id: number) {
        return this.runInQueue(this.appointmentTypes.delete(id));
    }

    @action.bound
    public deleteInsite(id: number) {
        return this.runInQueue(this.insites.delete(id));
    }

    @action.bound
    public cloneOffer(id: number) {
        return this.Api.client.post(`/offers/${id}/clone`).then(response => {
            runInAction(() => {
                this.offers.data[response.data.id] = response.data;
            });
        });
    }

    @action.bound
    public runInQueue(operation: Promise<void>) {
        this.pushRequest();
        return operation.then(this.pullRequest).catch(this.pullRequestError);
    }

    @action.bound
    public fetchAuditUsers(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs?agency_id=${queryParams?.agency_id}`).then(response => {
            return (this.auditLogs = response.data);
        });
    }

    @action.bound
    public fetchAuditCompanies(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs/companies?agency_id=${queryParams?.agency_id}`).then(response => {
            return (this.auditCompanies = response.data);
        });
    }

    @action.bound
    public fetchAuditApps(queryParams?: { agency_id: number | undefined }) {
        return this.Api.client.get(`/auditlogs/apps?agency_id=${queryParams?.agency_id}`).then(response => {
            return (this.auditApps = response.data);
        });
    }

    @action.bound
    public getActiveCompanies(): AccessibleCompany[] {
        if (this.activeCompanyId) {
            const accessibleCompany = this.accessibleCompanies[this.accessibleCompaniesIndex[this.activeCompanyId]];
            return [accessibleCompany];
        }
        return this.accessibleCompanies;
    }

    @action.bound
    public getCompanyNameById = (id: number) => {
        const companyName =
            id && this.accessibleCompaniesIndex[id] !== undefined
                ? this.accessibleCompanies[this.accessibleCompaniesIndex[id]].name
                : DEFAULT_TEXT;
        if (companyName === DEFAULT_TEXT) {
            global.console.log('Company Name: ', DEFAULT_TEXT);
            global.console.log('Company Id: ', id);
            global.console.log('AccessibleCompaniesIndex: ', this.accessibleCompaniesIndex);
            global.console.log('AccessibleCompanies: ', this.accessibleCompanies);
        }
        return companyName;
    };

    @action.bound
    public fetchFeatureFlags() {
        this.api.client
            .get(`feature_flags/company/${this.activeCompanyId}`)
            .then(response => {
                runInAction(() => {
                    this.featureFlags = response.data;
                    if (response.data) {
                        Object.values(FEATURE_FLAGS).forEach(flag => {
                            this.companyFeatureFlags[flag] = response.data
                                .map((a: { slug: string }) => a.slug)
                                .includes(flag);
                        });
                    }
                });
            })
            .catch(e => {
                // Do nothing.
            });
    }

    @action.bound
    public fetchFeatures(companyId?: number) {
        this.getCompanyFeatures(companyId || this.activeCompanyId).then(result => {
            runInAction(() => {
                if (result) {
                    this.features = result.data;
                }
            });
        });
    }

    @action.bound
    public fetchEnquireProperties() {
        this.api.client
            .get(`crm/enquire/${this.activeCompanyId}/properties`)
            .then(response => {
                runInAction(() => {
                    this.enquireProperties = response.data;
                });
            })
            .catch(e => {
                // Do nothing.
            });
    }

    @action.bound
    public fetchEldermarkFormKeys(apiKey: string, formId: string) {
        const options = {
            headers: { 'x-api-key': apiKey }
        };
        Axios.get(`https://api.activedemand.com/v1/forms/fields.json?form_id=${formId}`, options)
            .then(response => {
                runInAction(() => {
                    this.eldermarkFormKeys = response.data;
                });
            })
            .catch(e => {
                alert('Error fetching form keys');
            });
    }

    @action.bound
    public fetchSherpaLeasingCounselors(api_key: string, endpoint: string, company_id: string, community_id: string) {
        this.api.client
            .post(`crm/sherpa/leasing-counselors`, { company_id, community_id, api_key, endpoint })
            .then(response => {
                runInAction(() => {
                    this.sherpaLeasingCounselors = response.data;
                });
            })
            .catch(e => {
                // Do nothing.
            });
    }

    @action.bound
    public changeListSetting(listCode: string, list: ListCommon) {
        this.changeSessionObject('list', listCode, list);
    }

    @action.bound
    public changeFilterSetting(filterCode: string, filter: FilterCommon) {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }

        this.changeSessionObject('filter', filterCode, filter);
    }

    @action.bound
    public saveDraftForm(formCode: string, values: any) {
        const saveDraftTimeoutDelay = 800;

        if (this.timerSaveDraft !== undefined) {
            clearTimeout(this.timerSaveDraft);
        }

        this.timerSaveDraft = window.setTimeout(() => {
            runInAction(() => {
                this.changeSessionObject('draft', formCode, values);
            });
        }, saveDraftTimeoutDelay);
    }

    private changeSessionObject(sessionCode: 'list' | 'filter' | 'draft', valueCode: string, values: any) {
        this[sessionCode][valueCode] = {
            ...this[sessionCode][valueCode],
            ...values
        };

        Object.keys(this[sessionCode][valueCode]).forEach(k => {
            if (
                this[sessionCode][valueCode][k] === undefined ||
                this[sessionCode][valueCode][k] === null ||
                this[sessionCode][valueCode][k] === ''
            ) {
                delete this[sessionCode][valueCode][k];
            }
        });

        setSessionObject(sessionCode, this[sessionCode]);
    }

    @action.bound
    public getListSetting(listCode: string, listDefault?: ListCommon): ListCommon {
        return this.getSessionObject('list', listCode, listDefault) as ListCommon;
    }

    @action.bound
    public getFilterSetting(filterCode: string, filterDefault?: FilterCommon): FilterCommon {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }

        return this.getSessionObject('filter', filterCode, filterDefault) as FilterCommon;
    }

    @action.bound
    public getDraftSetting(formCode: string): any {
        return this.getSessionObject('draft', formCode, {}) as FilterCommon;
    }

    private getSessionObject(
        sessionCode: 'draft' | 'list' | 'filter',
        valueCode: string,
        defaultValues?: FilterCommon | ListCommon
    ): FilterCommon | ListCommon {
        if (!this[sessionCode][valueCode]) {
            this[sessionCode][valueCode] = {};

            if (defaultValues) {
                this.changeSessionObject(sessionCode, valueCode, defaultValues);
            }
        }

        return this[sessionCode][valueCode];
    }

    @action.bound
    public clearFilterSetting(filterCode: string) {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }
        this.filter[filterCode] = {};
        setSessionObject('filter', this.filter);
    }

    @action.bound
    public clearCalendarFilterSetting(filterCode: string) {
        if (!filterCode) {
            filterCode = FILTER_METRIC_CODE;
        }

        const start = this.filter[filterCode].start;
        const end = this.filter[filterCode].end;

        this.filter[filterCode] = {
            start,
            end
        };

        setSessionObject('filter', this.filter);
    }

    @action.bound
    public clearDraftForm(formCode: string) {
        this.draft[formCode] = {};
        setSessionObject('draft', this.draft);
    }

    @action.bound
    public clearAllSessionSetting() {
        this.filter = {};
        this.list = {};
        this.draft = {};
        clearSessionItem('filter');
        clearSessionItem('list');
        clearSessionItem('draft');
    }

    @action.bound
    public fetchBatchWidgetMetrics(datasetNames: string[], filter: FilterCommon) {
        // activeCompany has the highest priority
        const companyId = this.activeCompanyId ? this.activeCompanyId : filter.company_id || 0;
        const agencyId = this.agencyStore.activeAgencyId ? this.agencyStore.activeAgencyId : filter.agency_id || 0;

        const companyFilter = `company_id=${companyId}`;
        const agencyFilter = agencyId ? `&agency_id=${agencyId}` : '';
        const dateRangeFilter = filter.start && filter.end ? `&start=${filter.start}&end=${filter.end}` : '';
        const bcFilter = filter.business_category_id ? `&business_category_id=${filter.business_category_id}` : '';
        const dmaFilter = filter.dma ? `&dma=${filter.dma}` : '';
        const ogFilter = filter.ownership_group_id ? `&ownership_group_id=${filter.ownership_group_id}` : '';
        const pmcFilter = filter.property_management_company_id
            ? `&property_management_company_id=${filter.property_management_company_id}`
            : '';
        const regionFilter = filter.region_id ? `&region_id=${filter.region_id}` : '';

        return this.Api.client.post(
            `/metrics/widget/batch-reports?${companyFilter}${agencyFilter}${dateRangeFilter}${bcFilter}${dmaFilter}${ogFilter}${pmcFilter}${regionFilter}`,
            {
                report_list: datasetNames
            }
        );
    }

    @action.bound
    public fetchClarityData(chartConfig: ClarityChartConfig, filter: Filters) {
        return this.Api.client.post(`/clarity/chart`, { chartConfig, filter });
    }

    @action.bound
    public fetchAvailableClarityChartConfigs() {
        return this.Api.client.get(`/clarity/charts/available`);
    }

    @action.bound
    public fetchClarityPreset(presetId: number) {
        return this.Api.client.get(`/clarity/preset/${presetId}`);
    }

    @action.bound
    public patchClarityPreset(presetId: number, config: any, filter: any, settings: any) {
        return this.Api.client.patch(`/clarity/preset/${presetId}`, { config, filter, settings });
    }

    @action.bound
    public fetchClarityReportRequests(limit?: number, offset?: number, search?: string) {
        return this.Api.client.get(`/clarity/report-requests`, {
            params: { limit, offset, search, status: 'ready,requested' }
        });
    }

    @action.bound
    public deleteClarityReportRequest(report_guid: string) {
        return this.Api.client.delete(`/clarity/report/${report_guid}`);
    }

    @action.bound
    public fetchClarityReportURL(chartConfigs: Array<ClarityChartConfig>, filter: Filters) {
        return this.Api.client.post(`/clarity/report`, { chartConfigs, filter });
    }

    @action.bound
    public fetchInsiteMetaData(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        const customFilterSettings = this.getInsiteMetaDataFilterSettings();

        return this.fetchPoint(
            'insite-meta-data',
            listCode,
            filterCode,
            listDefault,
            filterDefault,
            customFilterSettings
        );
    }

    public getInsiteMetaDataFilterSettings() {
        let customFilterSettings: string[] = [
            'start',
            'end',
            'business_category_id',
            'dma',
            'ownership_group_id',
            'property_management_company_id',
            'region_id',
            'search'
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['start', 'end', 'search'];
        }

        return customFilterSettings;
    }

    @action.bound
    public fetchInsitePages(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        let customFilterSettings: string[] = [
            'business_category_id',
            'region_id',
            'ownership_group_id',
            'dma',
            'search'
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['search'];
        }

        return this.fetchPoint('insite-pages', listCode, filterCode, listDefault, filterDefault, customFilterSettings);
    }

    @action.bound
    public fetchInsiteDefinedFields(
        listCode: string,
        filterCode: string,
        listDefault: ListCommon,
        filterDefault?: FilterCommon
    ) {
        let customFilterSettings: string[] = [
            'business_category_id',
            'region_id',
            'ownership_group_id',
            'dma',
            'search'
        ];

        if (this.activeCompanyId) {
            customFilterSettings = ['search'];
        }

        return this.fetchPoint(
            'insite-defined-fields',
            listCode,
            filterCode,
            listDefault,
            filterDefault,
            customFilterSettings
        );
    }

    @action.bound
    public updateInsiteDefinedFields(leadId: number, data: any) {
        return this.Api.client
            .patch(`insite-defined-fields/${leadId}`, {
                lead_id: leadId,
                data
            })
            .then(() => {
                return true;
            });
    }

    @action.bound
    public createInsiteLead(id: number, companyId?: number | undefined) {
        return this.Api.client.post('insite-meta-data', {
            id,
            company_id: companyId || this.activeCompanyId
        });
    }

    @action.bound
    public fetchLeadReminder(leadId: number) {
        return this.Api.fetch(`lead-reminders/${leadId}`, {
            page: 0,
            pageSize: 0,
            order: '',
            filters: {
                lead_id: leadId
            }
        });
    }

    @action.bound
    public fetchLeadNote(leadId: number) {
        return this.Api.fetch(`lead-notes/${leadId}`, {
            page: 0,
            pageSize: 0,
            order: '',
            filters: {
                lead_id: leadId
            }
        });
    }

    @action.bound
    public fetchLeadEmail(leadId: number) {
        return this.Api.fetch(`lead-emails/${leadId}`, {
            page: 0,
            pageSize: 0,
            order: '',
            filters: {
                lead_id: leadId
            }
        });
    }

    @action.bound
    public fetchLeadSms(leadId: number) {
        return this.Api.fetch(`lead-sms/${leadId}`, {
            page: 0,
            pageSize: 0,
            order: 'sms_messages.created_at DESC',
            filters: {
                lead_id: leadId
            }
        });
    }

    @action.bound
    public createLeadReminder(leadId: number, companyId: number, data: LeadReminder) {
        return this.Api.client.post(`lead-reminders/${leadId}`, {
            company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
            ...data,
            lead_id: leadId
        });
    }

    @action.bound
    public createLeadNote(leadId: number, companyId: number, data: LeadNote) {
        return this.Api.client.post(`lead-notes/${leadId}`, {
            lead_id: leadId,
            company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
            ...data
        });
    }

    @action.bound
    public createLeadEmail(leadId: number, companyId: number, data: LeadEmail) {
        const { cc, bcc, subject, content } = data;
        return this.Api.client.post(`lead-emails/${leadId}`, {
            lead_id: leadId,
            company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
            cc,
            bcc,
            subject,
            content
        });
    }

    @action.bound
    public createLeadSms(leadId: number, companyId: number, data: LeadSmsMessage) {
        const { message } = data;
        return this.Api.client.post(`lead-sms/${leadId}`, {
            lead_id: leadId,
            company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
            message
        });
    }

    @action.bound
    public updateLeadReminder(leadId: number, companyId: number, data: LeadReminder) {
        if (!data.id) {
            return Promise.resolve(false);
        }

        return this.Api.client
            .patch(`lead-reminders/${leadId}/${data.id}`, {
                company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
                ...data,
                lead_id: leadId
            })
            .then(() => {
                return true;
            });
    }

    @action.bound
    public updateLeadNote(leadId: number, companyId: number, data: LeadNote) {
        if (!data.id) {
            return Promise.resolve(false);
        }

        return this.Api.client
            .patch(`lead-notes/${leadId}/${data.id}`, {
                lead_id: leadId,
                company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
                ...data
            })
            .then(() => {
                return true;
            });
    }

    @action.bound
    public updateLeadEmail(leadId: number, companyId: number, data: LeadEmail) {
        if (!data.id) {
            return Promise.resolve(false);
        }

        const { id, cc, bcc, subject, content } = data;

        return this.Api.client
            .patch(`lead-emails/${leadId}/${data.id}`, {
                id,
                lead_id: leadId,
                company_id: this.activeCompanyId ? this.activeCompanyId : companyId,
                cc,
                bcc,
                subject,
                content
            })
            .then(() => {
                return true;
            });
    }

    @action.bound
    public deleteLeadReminder(leadId: number, reminderId: number) {
        return this.Api.client.delete(`lead-reminders/${leadId}/${reminderId}`);
    }

    @action.bound
    public deleteLeadNote(leadId: number, noteId: number) {
        return this.Api.client.delete(`lead-notes/${leadId}/${noteId}`);
    }

    @action.bound
    public deleteLeadEmail(leadId: number, emailId: number) {
        return this.Api.client.delete(`lead-emails/${leadId}/${emailId}`);
    }

    @action.bound
    public createAutoPilotAssistant(unique_name: string, friendly_name: string) {
        return this.Api.client.post('/autopilot/assistant', {
            unique_name,
            friendly_name
        });
    }

    @action.bound
    public deleteAutoPilotAssistant(assistantSid: string): Promise<any> {
        return this.Api.client.delete(`/autopilot/assistant/${assistantSid}`);
    }

    @action.bound
    public fetchAutoPilotAssistants(): Promise<any> {
        return this.Api.client.get('/autopilot/assistants');
    }

    @action.bound
    public fetchAutoPilotTasks(assistantSid: string): Promise<any> {
        return this.Api.client.get(`/autopilot/tasks/${assistantSid}`);
    }

    @action.bound
    public createAutoPilotTask(assistantSid: string, uniqueName: string, friendlyName: string): Promise<any> {
        return this.Api.client.post(`/autopilot/task/${assistantSid}`, {
            unique_name: uniqueName,
            friendly_name: friendlyName
        });
    }

    @action.bound
    public updateAutoPilotTask(assistantSid: string, taskSid: string, taskUpdates: Object): Promise<any> {
        return this.Api.client.patch(`/autopilot/task/${assistantSid}/${taskSid}`, {
            updates: JSON.stringify(taskUpdates)
        });
    }

    @action.bound
    public deleteAutoPilotTask(assistantSid: string, taskSid: string): Promise<any> {
        return this.Api.client.delete(`/autopilot/task/${assistantSid}/${taskSid}`);
    }

    @action.bound
    public fetchAutoPilotSamples(assistantSid: string, taskSid: string): Promise<any> {
        return this.Api.client.get(`/autopilot/samples/${assistantSid}/${taskSid}`);
    }

    @action.bound
    public createAutoPilotSample(assistantSid: string, taskSid: string, sampleText: string): Promise<any> {
        return this.Api.client.post(`/autopilot/sample/${assistantSid}/${taskSid}`, {
            sample_text: sampleText
        });
    }

    @action.bound
    public updateAutoPilotSample(
        assistantSid: string,
        taskSid: string,
        sampleSid: string,
        sampleUpdates: Object
    ): Promise<any> {
        return this.Api.client.patch(`/autopilot/sample/${assistantSid}/${taskSid}/${sampleSid}`, {
            sample_updates: JSON.stringify(sampleUpdates)
        });
    }

    @action.bound
    public deleteAutoPilotSample(assistantSid: string, taskSid: string, sampleSid: string): Promise<any> {
        return this.Api.client.delete(`/autopilot/sample/${assistantSid}/${taskSid}/${sampleSid}`);
    }

    @action.bound
    public createAutoPilotModelBuild(assistantSid: string): Promise<any> {
        return this.Api.client.post(`/autopilot/modelbuild/${assistantSid}`);
    }

    @action.bound
    public createAutoPilotTestQuery(assistantSid: string, queryText: string): Promise<any> {
        return this.Api.client.post(`/autopilot/query/${assistantSid}`, {
            query: queryText
        });
    }

    public fetchPoint(
        entryPoint: string,
        listCode: string,
        filterCode: string,
        listDefault?: ListCommon,
        filterDefault?: FilterCommon,
        filterFieldsOnly?: string[],
        extendedData?: { [key: string]: string | number }
    ) {
        const list = this.getListSetting(listCode, listDefault);
        const filter = this.getFilterSetting(filterCode, filterDefault);

        let filters: FilterCommon = {
            company_id: this.activeCompanyId ? this.activeCompanyId : filter.company_id ? filter.company_id : undefined,
            agency_id: this.agencyStore.activeAgencyId
                ? this.agencyStore.activeAgencyId
                : filter.agency_id
                    ? filter.agency_id
                    : undefined,
            ...extendedData
        };

        if (
            !filters.company_id &&
            ['insite-meta-data', 'insite-defined-fields', 'insite-pages'].indexOf(entryPoint) >= 0
        ) {
            filters.is_companies_count = true;
        }

        let filterSettings = [
            'start',
            'end',
            'search',
            'status',
            'lead_type',
            'business_category_id',
            'dma',
            'ownership_group_id',
            'property_management_company_id',
            'region_id'
        ];

        if (filterFieldsOnly && filterFieldsOnly.length) {
            filterSettings = filterFieldsOnly;
        }

        filterSettings.forEach(k => {
            if (filter[k]) {
                filters[k] = filter[k];
            }
        });

        return this.Api.fetch(entryPoint, {
            page: list.page ? list.page : 1,
            pageSize: list.pageSize ? list.pageSize : 0,
            order: list.order ? list.order : '',
            filters
        });
    }

    @action.bound
    public fetchActiveUser() {
        return this.api.client
            .get(`users/profile`)
            .then(response =>
                runInAction(() => {
                    this.setActiveUserData(response.data);
                })
            )
            .catch(e =>
                runInAction(() => {
                    this.logout();
                })
            );
    }

    @action.bound
    public updateAccessibleCompanyName(id: number, name: string) {
        if (this.accessibleCompaniesIndex[id] !== undefined) {
            this.accessibleCompanies[this.accessibleCompaniesIndex[id]].name = name;
        }
    }

    @action.bound
    public updateAccessibleCompany(company: Company) {
        if (this.accessibleCompaniesIndex[company.id] !== undefined) {
            this.accessibleCompanies[this.accessibleCompaniesIndex[company.id]] = company;
            this.accessibleCompanies = [...this.accessibleCompanies];
        }
    }

    @action.bound
    public disableResource(resourceName: resourceWidgetName) {
        if (this.activeCompanyId) {
            return this.runInQueue(this[resourceName].disableAll(this.activeCompanyId, this.getDefaultParams()));
        }
        return false;
    }

    @action.bound
    public enableResource(resourceName: resourceWidgetName) {
        if (this.activeCompanyId) {
            return this.runInQueue(this[resourceName].enableLast(this.activeCompanyId, this.getDefaultParams()));
        }
        return false;
    }

    public updateCompanyLogo = (id: number, origin: string, mini: string) => {
        return this.api.client.put('/companies/logo', { id, origin, mini });
    };

    @action.bound
    public pushRequest() {
        this.pendingRequests++;
    }

    @action.bound
    public pullRequest() {
        if (this.pendingRequests > 0) {
            this.pendingRequests--;
        }
    }

    @action.bound
    protected pullRequestError(err?: Error) {
        this.pullRequest();
        throw err;
    }

    @action.bound
    protected setActiveUserData(data: { companies: Company[]; user: ActiveUser; agencies: Agency[] }) {
        this.activeUser = data.user;
        this.activeUserFetched = true;
        this.accessibleCompanies = [];
        this.accessibleCompaniesIndex = [];

        data.companies.forEach((company: Company, index: number) => {
            if (
                data.companies.filter(dataItem => dataItem.name.toLowerCase() === company.name.toLowerCase()).length > 1
            ) {
                company.displayName = `${company.name} (${company.state})`;
            }

            this.accessibleCompanies.push(cloneDeep(company));
            this.accessibleCompaniesIndex[company.id] = index;
        });

        if (data.user.role.slug === 'company_admin' || data.user.role.slug === 'company_light') {
            this.pushSubscribe();
        }

        if (
            data.companies.length === 1 &&
            (data.user.role.slug === 'company_admin' || data.user.role.slug === 'company_light')
        ) {
            this.activeUser.companies = data.companies;
            this.setActiveCompanyId(data.companies[0].id);
        } else {
            const activeCompany = Number(localStorage.getItem('activeCompanyId'));
            if (activeCompany && this.accessibleCompaniesIndex[activeCompany] !== undefined) {
                this.setActiveCompanyId(activeCompany);
            }
        }

        this.agencyStore.setAccessibleAgencies(data.agencies);
    }

    @action.bound
    public alertClose(alertUuid: string) {
        if (this.isAdminRole) {
            return;
        }

        return this.Api.client.post('/alert-notifications/close', {
            uuid: alertUuid
        });
    }

    @action.bound
    public alertView(alertUuid: string) {
        runInAction(() => {
            this.brokenInternetConnection = false;

            this.alerts.forEach((a: Alert, i: number) => {
                if (a.id === alertUuid) {
                    this.alerts[i].views += 1;
                }
            });
        });

        return this.Api.client.post('/alert-notifications/view', {
            uuid: alertUuid
        });
    }

    protected rebuildCompaniesIndex() {
        this.accessibleCompaniesIndex = [];
        this.accessibleCompanies.forEach((company: Company, index: number) => {
            this.accessibleCompaniesIndex[company.id] = index;
        });
    }

    private getDefaultParams(id?: number) {
        const filters: any = {};

        if (this.activeCompanyId) {
            filters.company_id = this.activeCompanyId;
        }

        if (this.agencyStore.activeAgencyId) {
            filters.agency_id = this.agencyStore.activeAgencyId;
        }

        return { id, filters };
    }

    private fetchMetrics(api: string, range: { start: string; end: string }, numCompanies?: number) {
        let start = '-1';
        let end = '-1';

        if (range) {
            start = range.start;
            end = range.end;
        }

        const filters: any = {
            start,
            end,
            ...(this.activeCompanyId && { company_id: this.activeCompanyId })
        };

        if (numCompanies) {
            filters.numCompanies = numCompanies;
        }

        return this.Api.fetch(`metrics/${api}`, { filters });
    }

    @action.bound
    public deleteHoliday(id: number) {
        return this.api.client.delete(`calendars_daysoff/holiday/${id}`);
    }

    @action.bound
    public setSpotlightTemplate(template: SpotlightTemplate) {
        this.selectedSpotlightTemplate = template;
    }
}

export default Store;
