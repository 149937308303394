import React, { FC } from 'react';
import classNames from 'classnames';
import { Icon } from '@lambdacurry/component-library';
import { ViewSwitcherOption } from './ViewSwitcher.types';
import { MenuItemAvatar as Avatar } from '../../../../../components-v2/shared';

export interface ViewSwitcherMenuItemProps extends ViewSwitcherOption {
    onClick: (event: any, option: ViewSwitcherOption) => void;
    isSelected?: boolean;
}

export const ViewSwitcherMenuItem: FC<ViewSwitcherMenuItemProps> = ({ isSelected, onClick, ...option }) => {
    const { id, icon, label, active } = option;

    return (
        <li
            key={id}
            className={classNames('view-switcher-menu-item', {
                'view-switcher-menu-item-active': active,
                'view-switcher-menu-item-inactive': !active,
                'view-switcher-menu-item-selected': isSelected
            })}
        >
            <button className="view-switcher-menu-item-button" onClick={event => onClick(event, option)}>
                <Avatar img={icon} className="view-switcher-menu-item-avatar">
                    {label.charAt(0).toUpperCase()}
                </Avatar>
                <span className="view-switcher-menu-item-label">{label}</span>
                {isSelected && (
                    <span className="view-switcher-menu-item-selected-indicator">
                        <Icon name="check" className="view-switcher-menu-item-selected-indicator-check" />
                        <Icon name="close" className="view-switcher-menu-item-selected-indicator-close" />
                    </span>
                )}
            </button>
        </li>
    );
};
