import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { FieldGroupTextarea } from '../../../misc/FieldGroupTextarea';
import LeadCustomerData from '../LeadCustomerData';
import { LeadFieldLabels, LeadFields } from '../LeadEdit';
import LeadJourneyData from '../LeadJourneyData';
import LeadNote from '../LeadNote/LeadNote';
import LeadReminder from '../LeadReminder/LeadReminder';
import LeadEmail from '../LeadEmail/LeadEmail';
import LeadSms from '../LeadSms/LeadSms';
import { FEATURE_FLAGS } from '../../../../types/FeatureFlag';
import { FEATURES } from '../../../../types/Features';

export interface LeadChatFields extends LeadFields {
    comments: string;
}

const labels = {
    ...LeadFieldLabels,
    comments: 'Chat comments'
};

interface LeadChatFormProps {
    editing: boolean;
    controls: (bag: FormikProps<LeadChatFields>) => React.ReactNode;
    companyFeatureFlags: {};
    features: { [name: string]: boolean };
    isSuperAdmin: boolean;
    isAdmin: boolean;
    isCompanyLight: boolean;
}

export default (props: LeadChatFormProps) => {
    return (bag: FormikProps<LeadChatFields>) => {
        const fieldProps = {
            ...bag,
            disabled: !props.editing,
            rows: 6,
            hideMaxLengthBar: true,
            labels,
            isSuperAdmin: props.isSuperAdmin,
            isAdmin: props.isAdmin,
            isCompanyLight: props.isCompanyLight
        };
        const leadId = bag.values ? bag.values.id : 0;
        const companyId = bag.values ? bag.values.company_id : 0;
        const customerPhone = bag.values ? bag.values.phone_number : '';
        const enableLeadNotes = props.companyFeatureFlags[FEATURE_FLAGS.ENABLE_LEAD_NOTES];
        const enableLeadReminders = props.companyFeatureFlags[FEATURE_FLAGS.ENABLE_LEAD_REMINDERS];
        const enableLeadEmails = props.companyFeatureFlags[FEATURE_FLAGS.ENABLE_LEAD_EMAILS];
        const enableLeadSms = props.companyFeatureFlags[FEATURE_FLAGS.ENABLE_LEAD_TEXT_US_NOW];
        const enableSmsMessages = props.features[FEATURES.sms_messages];

        return (
            <div>
                <Form>
                    <LeadCustomerData {...fieldProps} enableSmsMessages={enableSmsMessages} />
                    <LeadJourneyData {...fieldProps} />
                    <Panel bsStyle="primary" defaultExpanded>
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <h4>Chat details</h4>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                            <FieldGroupTextarea id="comments" componentClass="textarea" {...fieldProps} />
                        </Panel.Body>
                    </Panel>
                    {props.controls(bag)}
                </Form>
                {leadId ? (
                    <>
                        {enableLeadNotes ? <LeadNote lead_id={leadId} company_id={companyId} /> : null}
                        {enableLeadReminders ? <LeadReminder lead_id={leadId} company_id={companyId} /> : null}
                        {enableLeadEmails ? <LeadEmail lead_id={leadId} company_id={companyId} /> : null}
                        {enableLeadSms ? (
                            <LeadSms lead_id={leadId} company_id={companyId} contact={customerPhone} />
                        ) : null}
                    </>
                ) : null}
            </div>
        );
    };
};
