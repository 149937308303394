import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap';
import { dateTimeFormats } from '../../../../constants';
import { LeadEmail } from '../../../../types';

interface LeadEmailListProps {
    activateItem: (r: LeadEmail) => void;
    activeId?: number;
    emails: any[];
}

@inject('store')
@observer
export default class LeadEmailList extends React.Component<LeadEmailListProps> {
    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Email Activity</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body className="hold-box-list" collapsible>
                    {this.props.emails && this.props.emails.length ? (
                        <ListGroup className="list-group__holder">
                            {this.props.emails.map((row: LeadEmail, i: number) => {
                                const disabled = row.id === this.props.activeId;

                                return (
                                    <ListGroupItem key={i} disabled={disabled}>
                                        <div onClick={this.handleClick(row)}>
                                            <div className="email-list__box">
                                                {row.subject}
                                                <span className="date-text">
                                                    {row.sent_at
                                                        ? moment(row.sent_at).format(dateTimeFormats.dateDefault)
                                                        : ''}
                                                </span>
                                            </div>
                                            <div className="email-first__line date-text">
                                                TO: {row.recipient_name} {row.recipient_email}
                                            </div>
                                        </div>
                                    </ListGroupItem>
                                );
                            })}
                        </ListGroup>
                    ) : null}
                </Panel.Body>
            </Panel>
        );
    }

    public handleClick = (row: LeadEmail) => () => {
        if (this.props.activateItem) {
            this.props.activateItem(row);
        }
    };
}
