import React, { FC } from 'react';
import { Link } from 'mobx-router';
import { FormikHelpers, FormikProps } from 'formik';
import {
    Form,
    AppSection,
    AppSectionHeader,
    InputText,
    InputSelect,
    ButtonOutline,
    Icon,
    InputTextarea,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList
} from '../../components-v2/shared';

import { IncomeCalculator } from '../../types/IncomeCalculator';
import { FloorplansList } from './components/FloorplansList/FloorplansList';
import { HouseholdsTable } from './components/HouseholdsTable/HouseholdsTable';
import DefaultRoutes from '../../routes/DefaultRoutes';
import useStore from '../../store/useStore';
import { validateCalculator } from '../../util/calculator';

import styles from './IncomeCalculator.module.scss';
import { createNumericalInputOptions } from '../../util/form';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { JSONWithQuestionBundle, QuestionEditor } from '../QuestionEditor/QuestionEditor';

export interface IncomeCalculatorFormProps {
    initialValues: IncomeCalculator;
    onSubmit: (values: IncomeCalculator, formikHelpers: FormikHelpers<IncomeCalculator>) => void | Promise<any>;
    incomeCalculatorId?: number;
}

export const IncomeCalculatorForm: FC<IncomeCalculatorFormProps> = ({
    initialValues,
    onSubmit,
    incomeCalculatorId,
    ...props
}) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    return (
        <Form
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validateCalculator}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
            {...props}
        >
            {(formikProps: FormikProps<IncomeCalculator>) => (
                <>
                    <AppSection className="field-group-content">
                        <div className="field-group-column">
                            <AppSectionHeader
                                title={`Average Median Income & Percentages`}
                                subtitle="These fields determine the required annual income and percentages to pre-qualify for residence."
                            />
                            <InputText
                                name="household"
                                type="number"
                                label="AMI for a family of 4"
                                formikProps={formikProps}
                                prefix="$"
                            />
                            <div className="field-row">
                                <InputText
                                    type="number"
                                    name="ami_one"
                                    label="Low"
                                    formikProps={formikProps}
                                    suffix="%"
                                />
                                <InputText
                                    type="number"
                                    name="ami_two"
                                    label="Medium"
                                    formikProps={formikProps}
                                    suffix="%"
                                />
                                <InputText
                                    type="number"
                                    name="ami_three"
                                    label="High"
                                    formikProps={formikProps}
                                    suffix="%"
                                />
                            </div>

                            <AppSectionHeader title="Income Qualifications" />
                            <div className="field-row">
                                <InputText
                                    type="number"
                                    name="income_to_rent_variable"
                                    label="Income to Rent Variable"
                                    formikProps={formikProps}
                                    suffix="X"
                                />
                                <InputText
                                    type="number"
                                    name="rent_to_income_percentage"
                                    label="Rent to Income Percentage"
                                    formikProps={formikProps}
                                    suffix="%"
                                />
                            </div>

                            <AppSectionHeader
                                title="Required Questions"
                                subtitle="These required fields determine pre-qualification for residence."
                            />
                            <div className="field-row">
                                <div className={styles.incomeCalculatorRequiredQuestionOneText}>
                                    <InputText name="question1_label" label="Question 1" formikProps={formikProps} />
                                </div>
                                <InputSelect
                                    name="question1_max"
                                    label="Max Response"
                                    formikProps={formikProps}
                                    options={createNumericalInputOptions(8)}
                                    autocompleteConfig={{ disableClearable: true }}
                                    optionValueKey="value"
                                />
                            </div>
                            <InputText name="question2_label" label="Question 2" formikProps={formikProps} />
                            <InputText name="question3_label" label="Question 3" formikProps={formikProps} />
                        </div>
                        <div className="field-group-column">
                            <AppSectionHeader title="App Title" />
                            <InputText name="menu_title" label="Title" formikProps={formikProps} />

                            <QuestionEditor
                                sectionTitle="Qualification Questions"
                                formikProps={formikProps as FormikProps<JSONWithQuestionBundle>}
                            />
                        </div>
                    </AppSection>

                    <HouseholdsTable formikProps={formikProps} />

                    <FloorplansList formikProps={formikProps} />

                    <AppSection className="field-group-content">
                        <div className="field-group-column">
                            <AppSectionHeader title="Disclaimer" />
                            <InputTextarea name="disclaimer" formikProps={formikProps} rows={6} characterLimit={180} />

                            <AppSectionHeader title="Qualification Policy" />
                            <InputTextarea
                                name="qualification_policy"
                                formikProps={formikProps}
                                rows={6}
                                characterLimit={500}
                            />
                        </div>

                        <div className="field-group-column">
                            <AppSectionHeader title="Confirmation Email" />
                            <ButtonOutline
                                className="field-group-button-full-width"
                                data-lc-trigger-unsaved-changes={true}
                                icon={<Icon name="confirmationEmail" />}
                                as={buttonProps => (
                                    <Link
                                        {...buttonProps}
                                        view={DefaultRoutes.EmailTemplatesList}
                                        params={{ companyId }}
                                        store={store}
                                    />
                                )}
                            >
                                Edit Email
                            </ButtonOutline>
                        </div>
                    </AppSection>

                    <AppFooter sticky={true}>
                        <ActionList position="end">
                            {formikProps.dirty && (
                                <Button
                                    onClick={() => formikProps.resetForm()}
                                    disabled={formikProps.isSubmitting}
                                    data-lc-trigger-unsaved-changes={true}
                                    data-test-id="button_cancel"
                                >
                                    Cancel
                                </Button>
                            )}
                            <ButtonPrimary
                                type="submit"
                                disabled={
                                    (!!incomeCalculatorId && !formikProps.dirty) ||
                                    formikProps.isSubmitting ||
                                    !formikProps.isValid
                                }
                                data-test-id="button_save"
                            >
                                {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                            </ButtonPrimary>
                        </ActionList>
                    </AppFooter>
                </>
            )}
        </Form>
    );
};
