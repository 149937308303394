import React from 'react';
import { observer } from 'mobx-react';
import { FieldArray, FormikHelpers } from 'formik';
import {
    AppPage,
    AppHeader,
    AppContent,
    AppSectionHeader,
    ActionList,
    Button,
    ButtonLink,
    ButtonPrimary,
    Form,
    IconButton,
    InputColor,
    InputSwitch,
    InputTextarea,
    InputText,
    AppFooter,
    useSnackbar
} from '../../components-v2/shared';
import { useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { Company, NotificationBarLink } from '../../types';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { omit } from 'lodash';


export const WebNotifications = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchCompanies, companies } = store;
    const { companyId } = router.params;
    const company = companies.getItem(parseInt(companyId, 10));

    const fetchRequiredData = async () => {
        await fetchCompanies();
    };
    useAsyncEffect(fetchRequiredData);

    const formatDataToSave = (values: Partial<Company>) => {
        const {
            rev_link_google_date,
            rev_link_google_small,
            rev_link_yelp_date,
            rev_link_yelp_small,
            full_name,

            ...data
        } = values;

        return {
            ...data,
            // Since we are using the `companies` API, we are essentially doing a patch here.
            // The API expects the `logo` field to be a base64 encoded data URL or `null`, so we are setting it to `null` to
            // avoid the "Input buffer contains unsupported image format" API error.
            // This is basically what is happening when you save the main company settings without adding/updating the logo image.
            logo: null,
            notification_bar_active: company?.notification_bar_active
        };
    };

    const handleSubmit = async (values: Partial<Company>, actions: FormikHelpers<Partial<Company>>) => {
        const data = formatDataToSave(values);

        try {
            await companies.update(data);
            addSnackbar('Web notifications have been updated.', { variant: 'success' });
            actions.resetForm({ values: data });
        } catch (error) {
            addSnackbar('Failed to update web notifications.', { variant: 'error' });
            actions.setErrors(error.response.data);
        }
    };

    const handleToggleClick = async () => {
        if (!company) {
            return;
        }
        const omittedValues = [
            'active_coupon_id',
            'custom_disclaimer',
            'deleted_at',
            'estimate_multiplier',
            'widget_logo'
        ];
        const data = omit(formatDataToSave(company), omittedValues);

        try {
            await companies.update({
                ...data,
                notification_bar_active: !data.notification_bar_active
            });
            addSnackbar(`Web notifications has been ${data.notification_bar_active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${data.notification_bar_active ? 'deactivate' : 'activate'} web notifications.`, {
                variant: 'error'
            });
        }
    };

    const initialValues = (): Partial<Company> | any =>
        company
            ? {
                  ...company,
                  notification_bar_message: company.notification_bar_message || ''
              }
            : {};

    return (
        <AppPage loading={!company}>
            <AppHeader title="Web Notifications" icon={{ name: 'bellRing', color: 'blue' }}>
                <ActionList position="end">
                    <InputSwitch
                        labelOn="Active"
                        labelOff="Inactive"
                        labelPlacement="start"
                        onClick={handleToggleClick}
                        checked={company?.notification_bar_active}
                    />
                </ActionList>
            </AppHeader>
            <Form
                initialValues={initialValues()}
                onSubmit={handleSubmit}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {formikProps => (
                    <>
                        <AppContent className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Notification Banner Message" />
                                <InputTextarea
                                    name="notification_bar_message"
                                    placeholder="Enter a message"
                                    formikProps={formikProps}
                                />

                                <AppSectionHeader title="Primary Colors" />
                                <div className="field-group-content">
                                    <InputColor
                                        name="notification_bar_color"
                                        label="Text Color"
                                        labelPlacement="above"
                                        formikProps={formikProps}
                                    />
                                    <InputColor
                                        name="notification_bar_background_color"
                                        label="Background Color"
                                        labelPlacement="above"
                                        formikProps={formikProps}
                                    />
                                </div>
                            </div>

                            <div className="field-group-col">
                                <FieldArray name="notification_bar_links">
                                    {arrayHelpers => (
                                        <>
                                            <AppSectionHeader title="Calls to Action">
                                                <ButtonLink onClick={() => arrayHelpers.push({ caption: '', url: '' })}>
                                                    + Add call to action
                                                </ButtonLink>
                                            </AppSectionHeader>

                                            {formikProps.values.notification_bar_links.map(
                                                (notificationBarLink: NotificationBarLink, index: number) => {
                                                    const fieldKey = `notification_bar_links.${index}`;

                                                    return (
                                                        <div key={fieldKey}>
                                                            <AppSectionHeader
                                                                as="Header"
                                                                title={`Call to Action ${index + 1}`}
                                                            >
                                                                {index > 0 && (
                                                                    <IconButton
                                                                        icon="trash"
                                                                        onClick={() => arrayHelpers.remove(index)}
                                                                    />
                                                                )}
                                                            </AppSectionHeader>
                                                            <InputText
                                                                name={`${fieldKey}.caption`}
                                                                label="Call to action button text"
                                                                placeholder="Click here"
                                                                formikProps={formikProps}
                                                            />
                                                            <InputText
                                                                name={`${fieldKey}.url`}
                                                                label="Call to action URL"
                                                                placeholder="Website address"
                                                                formikProps={formikProps}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </>
                                    )}
                                </FieldArray>
                            </div>
                        </AppContent>

                        <AppFooter>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        disabled={formikProps.isSubmitting}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary 
                                    type="submit" 
                                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    Save
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                )}
            </Form>
        </AppPage>
    );
});
