import React, { FC } from 'react';
import { FormikHelpers } from 'formik';
import { observer } from 'mobx-react';

import {
    AppSection,
    AppSectionHeader,
    InputSelect,
    Form,
    AppFooter,
    Button,
    ButtonPrimary,
    ActionList
} from '../../../components-v2/shared';

import useStore from '../../../store/useStore';
import DefaultRoutes from '../../../routes/DefaultRoutes';

import { Crm } from '../../../types/Crm';
import { crmConfigTypeLabelArray, crmConfigTypes, unsavedChangesContainerSelectorDefault } from '../../../constants';

export interface SelectCrmTypeFormProps {
    initialValues: any;
    onSubmit: (values: Crm, actions: FormikHelpers<Crm>) => Promise<void>;
    enableReinitialize?: boolean;
}

export const SelectCrmTypeForm: FC<SelectCrmTypeFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { router } = store;
    const { companyId } = router.params;

    const handleCancel = () => router.goTo(DefaultRoutes.CrmList, { companyId }, store);

    const handleSubmit = (values: Crm, actions: FormikHelpers<Crm>) =>
        router.goTo(DefaultRoutes.CrmNew, { companyId, crmType: values.crm_type }, store);

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            confirmUnsavedChanges
            unsavedChangesConfig={{
                containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
            }}
        >
            {formikProps => {
                return (
                    <>
                        <AppSection className="field-group-content">
                            <div className="field-group-col">
                                <AppSectionHeader title="Select the CRM type to configure" />
                                <InputSelect
                                    name="crm_type"
                                    label="CRM Type"
                                    optionValueKey="val"
                                    optionLabelKey="label"
                                    options={crmConfigTypeLabelArray([crmConfigTypes.zoho])} // Exclude Zoho for now
                                    formikProps={formikProps}
                                    autocompleteConfig={{
                                        disableClearable: true
                                    }}
                                />
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button onClick={handleCancel} data-lc-trigger-unsaved-changes={true}>
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary type="submit" disabled={!formikProps.dirty || formikProps.isSubmitting}>
                                    {formikProps.isSubmitting ? 'Continuing...' : 'Continue'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                );
            }}
        </Form>
    );
});
