import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from '../../../types';
import { Title } from '../../../components-v2/shared';
import { TestQueryForm } from './TestQueryForm';
import { FormikHelpers } from 'formik';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { ButtonGroup, Button } from '@lambdacurry/component-library';

interface AssistantTestPageState {
    taskResult?: string;
}

@inject('store')
@observer
export class AssistantTestPage extends React.Component<{}, AssistantTestPageState> {
    public state: AssistantTestPageState = {

    }

    public get injected() {
        return this.props as InjectedProps;
    }

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotEditAssistant, {sid: this.injected.store.router.params.assistantSid});
    }

    public handleSubmit = async (values: any, actions: FormikHelpers<any>) => {
        const { createAutoPilotTestQuery } = this.injected.store;
        createAutoPilotTestQuery(this.injected.store.router.params.assistantSid, values.queryText).then(data => {
            this.setState({
                taskResult: data.data.task_unique_name
            });
        });
    }

    render() {
        return(
            <>
            <Title>Test Assistant</Title>
            <div>
                <div>{this.state.taskResult ? (<h2>Task result: {this.state.taskResult}</h2>) : null}                    
                </div>
                <div>
                    <TestQueryForm handleSubmit={this.handleSubmit} />
                </div>
                <div>
                    <ButtonGroup>
                        <Button onClick={this.handleBack}>
                            Back
                        </Button>
                    </ButtonGroup>
                </div>
            </div>
            </>
        );
    }
}