import { handlePromise, useAsyncEffect, Tabs } from '@lambdacurry/component-library';
import { observer } from 'mobx-react';
import React, { useReducer, useState, useEffect } from 'react';
import { AppCardGrid, AppHeader, useSnackbar } from '../../../components-v2/shared';
import useStore from '../../../store/useStore';
import { AppEventCards } from './AppEventCards/AppEventCards';
import './lead-details.scss';
import {
    CustomerCardStatuses,
    LeadCustomerDripsResponse,
    LeadCustomerLeadJourneyResponse,
    LeadCustomerLeadListResponse,
    LeadCustomerResponse,
    LeadCustomerUpdate,
    LeadDetailsReducer
} from './LeadDetails.helpers';
import { LeadDetailsCustomerCard } from './LeadDetailsCustomerCard';
import { LeadDetailsMetricsCard } from './LeadDetailsMetricsCard';
import { CustomerLeadJourney } from './CustomerLeadJourney/CustomerLeadJourney';
import { getRouteWithContext } from '../../../routes/routeUtils';
import { DripEventCards } from './DripEventCards/DripEventCards';
import { FEATURES } from '../../../types/Features';

export const LeadDetails = observer(() => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { store } = useStore();
    const { Api, router, features } = store;
    const { addSnackbar } = useSnackbar();

    const [state, dispatch] = useReducer(LeadDetailsReducer, { customerCardStatus: CustomerCardStatuses.VIEWING });
    const hideLeadNurturing = !features[FEATURES.lead_nurturing];

    useEffect(() => {
        const index = router?.params?.tabId ? parseInt(router?.params?.tabId) : 0;
        setSelectedTab(index);
    }, [router?.params?.tabId]);

    const handleBack = () => {
        const backLink = (router.params?.backRoute as unknown) as Route;
        const LeadListRoute = getRouteWithContext('LeadView', router);

        if (backLink) {
            return router.goTo(backLink, router.params, store);
        }

        router.goTo(LeadListRoute, router.params, store);
    };

    const fetchLeadByCustomer = async () => {
        const [response, error] = await handlePromise<LeadCustomerResponse>(
            Api.client.get(`/lead-customer/${router.params.customerId}`)
        );

        if (!response || error) {
            addSnackbar('Failed to fetch the customer data.', { variant: 'error' });
            return console.error('No response from lead customer.');
        }

        dispatch({ name: 'storeCustomerData', payload: response });
    };

    const fetchLeadListByCustomer = async () => {
        const [response, error] = await handlePromise<LeadCustomerLeadListResponse>(
            Api.client.get(`/lead-customer/${router.params.customerId}/leads`)
        );

        if (!response || error) {
            addSnackbar('Failed to fetch the lead data.', { variant: 'error' });
            return console.error('No response from lead customer.');
        }

        dispatch({ name: 'storeCustomerLeads', payload: response });
    };

    const fetchLeadJourneyByCustomer = async () => {
        const [response, error] = await handlePromise<LeadCustomerLeadJourneyResponse>(
            Api.client.get(`/lead-customer/${router.params.customerId}/journey`)
        );

        if (!response || error) {
            addSnackbar('Failed to fetch the lead journey data.', { variant: 'error' });
            return console.error('No response from lead customer.');
        }

        dispatch({ name: 'storeCustomerLeadJourney', payload: response });
    };

    const fetchLeadDripsByCustomer = async () => {
        const [response, error] = await handlePromise<LeadCustomerDripsResponse>(
            Api.client.get(`/lead-customer/${router.params.customerId}/drips`)
        );

        if (!response || error) {
            addSnackbar('Failed to fetch the lead journey data.', { variant: 'error' });
            return console.error('No response from lead customer.');
        }

        dispatch({ name: 'storeCustomerDrips', payload: response });
    };

    const updateCustomer: (values: LeadCustomerUpdate) => Promise<void> = async values => {
        const [response, error] = await handlePromise<LeadCustomerResponse>(
            Api.client.patch(`/lead-customer/${router.params.customerId}`, values)
        );

        if (!response || error) {
            addSnackbar('Failed to update the customer.', { variant: 'error' });
            return console.error('No response from lead customer.');
        }

        dispatch({ name: 'storeCustomerData', payload: response });
    };

    useAsyncEffect(fetchLeadByCustomer);
    useAsyncEffect(fetchLeadListByCustomer);
    useAsyncEffect(fetchLeadJourneyByCustomer);
    useAsyncEffect(fetchLeadDripsByCustomer);

    const tabs = [
        {
            label: 'App Events',
            render: (
                <AppEventCards
                    data={state.leads}
                    updateLead={lead => dispatch({ name: 'updateLead', payload: lead })}
                />
            )
        },
        { label: 'Lead Journey', render: <CustomerLeadJourney data={state.leadJourney} /> }
    ];

    if (!hideLeadNurturing) {
        tabs.push({ label: 'Lead Nurturing', render: <DripEventCards data={state.drips} /> });
    }

    return (
        <div className="lead-details">
            <AppHeader variant="secondary" onBack={handleBack} title="Customer Information" />
            <div className="lead-details-content">
                <section className="lead-details-customer-info">
                    <AppCardGrid className="lead-details-customer-info-grid">
                        <LeadDetailsCustomerCard updateCustomer={updateCustomer} state={state} dispatch={dispatch} />
                        <LeadDetailsMetricsCard state={state} />
                    </AppCardGrid>
                </section>

                <section className="lead-details-events">
                    <Tabs value={selectedTab} tabs={tabs} />
                </section>
            </div>
        </div>
    );
});
