import { InputText } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import React, { FC } from 'react';

import { AppCard, AppSectionHeader, HelpIcon } from '../../../../../components-v2/shared';
import { ApplicationSettingsFormValues } from '../../../ApplicationSettings.helpers';

export interface TrackingTabProps {
    formikProps: FormikProps<ApplicationSettingsFormValues>;
}

const apiKeyHelpMsg = 'Use your API Key to export dashboard data to be imported to a third-party platform or tool.';
const gaIdHelpMsg = 'Add dashboard data to Google Analytics by including your Google tracking ID or UA ID.';

export const TrackingTab: FC<TrackingTabProps> = ({ formikProps }) => (
    <AppCard className="advanced-settings-tracking">
        <AppSectionHeader
            title={
                <div className="help-icon-wrapper">
                    <span>Application API Key</span>
                    <HelpIcon tooltip={apiKeyHelpMsg} />
                </div>
            }
        />
        <div className="embed-code embed-code-readonly">
            <div className="embed-code-content">{formikProps.values.api_key}</div>
        </div>

        <AppSectionHeader
            title={
                <div className="help-icon-wrapper">
                    <span>Analytics</span>
                    <HelpIcon tooltip={gaIdHelpMsg} />
                </div>
            }
        />
        <InputText name="ga_id" label="Google Universal Analytics ID" formikProps={formikProps} />
        <InputText name="gm_id" label="Google Analytics Measurement ID" formikProps={formikProps} />
    </AppCard>
);
