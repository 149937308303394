import React from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { AppHeader, AppPage, useSnackbar } from '../../components-v2/shared';

import { emptyCrm } from '../../store/initial';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { Crm } from '../../types/Crm';
import { EditCrmForm } from './components/EditCrmForm';
import { CrmEmailsToArray } from './components/helpers/FormHelper';
import { cloneDeep } from 'lodash';

export const CrmNew = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, crms } = store;
    const { companyId, crmType } = router.params;

    const initialValues = {
        ...emptyCrm,
        company_id: companyId
    };

    const handleValidate = async (values: Crm, crmType: string) => {
        const { validateCrmConfiguration } = store;
        validateCrmConfiguration({ ...values, crm_type: crmType }, addSnackbar);
    };

    const handleSubmit = async (values: Crm, actions: FormikHelpers<Crm>, crmType: string) => {
        try {
            const data = cloneDeep(values);
            if (data.configuration.crm_emails) {
                data.configuration.crm_emails = CrmEmailsToArray(data.configuration.crm_emails);
            }
            const crmId = await crms.create({ ...data, crm_type: crmType });
            addSnackbar(`CRM "${values.name}" has been added.`, { variant: 'success' });
            router.goTo(DefaultRoutes.CrmEdit, { companyId, crmId }, store);
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    return (
        <AppPage>
            <AppHeader
                title="CRM: Add New"
                onBack={() => router.goTo(DefaultRoutes.CrmSelectType, { companyId }, store)}
            />
            <EditCrmForm
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                onValidate={handleValidate}
                crmType={crmType}
            />
        </AppPage>
    );
});
