import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { FormControl } from 'react-bootstrap';
import { FieldGroupTextarea } from '../../../misc/FieldGroupTextarea';

export interface LeadSmsFields {
    message: string;
}

interface LeadSmsFormProps {
    controls: (bag: FormikProps<LeadSmsFields>) => React.ReactNode;
}

const labels = {
    message: 'Message'
};

export default (props: LeadSmsFormProps) => {
    return (bag: FormikProps<LeadSmsFields>) => {
        const fieldProps = {
            ...bag,
            labels
        };
        const maxLengthProspect = 500;

        const handleKeyDown = (e: React.KeyboardEvent<FormControl>) => {
            const ENTER_CODE = 13;

            if (e.keyCode === ENTER_CODE) {
                e.preventDefault();
                if (bag.values.message.trim().length) {
                    bag.submitForm();
                    return;
                }
            }
        };

        return (
            <div>
                <Form>
                    {fieldProps.errors && typeof fieldProps.errors === 'string' ? (
                        <span className="error">{fieldProps.errors}</span>
                    ) : null}
                    <div className="indent-outside">
                        <FieldGroupTextarea
                            id="message"
                            {...fieldProps}
                            componentClass="textarea"
                            maxLength={maxLengthProspect}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    {props.controls(bag)}
                </Form>
            </div>
        );
    };
};
