import { FormikProps } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { Col, FormControlProps, Panel, Table } from 'react-bootstrap';
import { COLUMNS_ONE } from '../../misc/FieldGroupColSizes';
import { LeadFields, LeadJourneyEventType } from './LeadEdit';
import { friendlyModuleNames } from '../../../components/WidgetMetrics/WidgetMetricCommon';

const labels = {
    lead_customer_log: 'Event Log',
    session_touch: 'Session/Touch',
    sms_message: 'SMS Message',
    matchback: 'Matchback'
};

const tagClasses = {
    device_type: 'device',
    device_location: 'device',
    os_name: 'device'
};

const tagLabels = {
    matchback: 'Matchback',
    referrer: 'Referrer',
    interactions: 'Interactions',
    device_type: 'Device Type',
    device_location: 'Device Location',
    os_name: 'Device OS',
    utm_source: 'UTM Source',
    utm_medium: 'UTM Medium',
    utm_campaign: 'UTM Campaign',
    utm_term: 'UTM Term',
    utm_content: 'UTM Content'
};

interface LeadJourneyDataFieldGroupProps extends FormControlProps, FormikProps<LeadFields> {}
const multiFamily = 5;
const seniorLiving = 7;

const LeadJourneyData: React.FC<LeadJourneyDataFieldGroupProps> = props => {
    const fieldProps = {
        ...props,
        labels
    };

    const journeyEvents = fieldProps.values.journey_events || [];
    const isLeaseCompany = [multiFamily, seniorLiving].includes(
        fieldProps.values.company && fieldProps.values.company.business_category_id
    );

    let isFirstTouch = true;

    const renderUTMTag = (key: string, val: string) => {
        const className = key.substr(0, 'utm'.length) === 'utm' ? 'utm' : tagClasses[key] || key;
        return (
            val && (
                <div key={key} className={className}>
                    <span>{tagLabels[key] || key}:</span>
                    {val}
                </div>
            )
        );
    };

    return (
        <div className="pannel-holder__collapse">
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>{isLeaseCompany ? 'Lease' : 'Purchase'} Journey Visualizer</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <div className="indent-outside">
                        <Col {...COLUMNS_ONE}>
                            <Table className={'lead-journey-details-table'} striped>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Type</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {journeyEvents.map((journeyEvent, i: number) => {
                                        const tags = journeyEvent.data;
                                        return (
                                            <tr key={i}>
                                                <td className={'lead-journey-details-table-date'}>
                                                    {journeyEvent.timestamp &&
                                                        moment(journeyEvent.timestamp).format('MM/DD/YYYY hh:mm A')}
                                                    {!journeyEvent.timestamp &&
                                                        journeyEvent.type === LeadJourneyEventType.smsMessage &&
                                                        'Message not sent'}
                                                </td>
                                                <td>
                                                    <div>{labels[journeyEvent.type]}</div>
                                                </td>
                                                <td className={'lead-journey-details-table-tags'}>
                                                    {journeyEvent.type === LeadJourneyEventType.matchback && (
                                                        <>
                                                            <div className="matchback">
                                                                <span>Matchback:</span>A client list was uploaded
                                                                containing a match for this customer.
                                                            </div>
                                                        </>
                                                    )}
                                                    {journeyEvent.type === LeadJourneyEventType.smsMessage && (
                                                        <>
                                                            {tags.status === 'Sent' && tags.sent_at && (
                                                                <div className="message">
                                                                    <span>Message Sent</span>
                                                                </div>
                                                            )}
                                                            {tags.status !== 'Sent' && tags.message_error && (
                                                                <div className="interactions">
                                                                    <span>Delivery Error Message:</span>{' '}
                                                                    {tags.message_error}
                                                                </div>
                                                            )}
                                                            <div className="message">
                                                                <span>Notification Type:</span>
                                                                {tags.notification_type}
                                                            </div>
                                                            <div className="message">
                                                                <span>Message:</span>
                                                                {tags.message}
                                                            </div>
                                                        </>
                                                    )}
                                                    {journeyEvent.type === LeadJourneyEventType.lead_customer_log && (
                                                        <>
                                                            <div className="apps">
                                                                <span>Event Type:</span>
                                                                {tags.log_type}
                                                            </div>
                                                            <div className="log_message">{tags.description}</div>
                                                        </>
                                                    )}
                                                    {journeyEvent.type === LeadJourneyEventType.sessionTouch && (
                                                        <>
                                                            {isFirstTouch && (
                                                                <div className="firsttouch">FIRST TOUCH</div>
                                                            )}
                                                            {tags.lead_via_apps && (
                                                                <>
                                                                    {tags.lead_via_apps.split(',').map(app => {
                                                                        return (
                                                                            <div key={app} className="conversion">
                                                                                CONVERSION via{' '}
                                                                                {friendlyModuleNames[app]}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </>
                                                            )}
                                                            {tags.apps_opened && (
                                                                <div className="apps">
                                                                    APPS OPENED:{' '}
                                                                    {tags.apps_opened
                                                                        .split(',')
                                                                        .map(app => {
                                                                            return friendlyModuleNames[app];
                                                                        })
                                                                        .join(', ')}
                                                                </div>
                                                            )}
                                                            {tags.interactions > 0 &&
                                                                renderUTMTag('interactions', `${tags.interactions}`)}
                                                            {(isFirstTouch = false)}
                                                            {Object.keys(tags)
                                                                .filter((key: string) => {
                                                                    return ![
                                                                        'id',
                                                                        'created_at',
                                                                        'lead_via_apps',
                                                                        'apps_opened',
                                                                        'interactions'
                                                                    ].includes(key);
                                                                })
                                                                .map((tag: any) => {
                                                                    return renderUTMTag(tag, tags[tag]);
                                                                })}
                                                        </>
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </div>
                </Panel.Body>
            </Panel>
        </div>
    );
};

export default LeadJourneyData;
