export interface Feature {
    id: number;
    feature_id: number;
    name: string;
}

export enum FEATURES {
    best_price_now = 'best_price_now',
    call_us_now = 'call_us_now',
    chat = 'chat',
    clarity = 'clarity',
    income_calculator = 'income_calculator',
    insite = 'insite',
    lead_nurturing = 'lead_nurturing',
    schedule_genie = 'schedule_genie',
    text_us_now = 'text_us_now',
    concession_manager = 'concession_manager',
    sms_messages = 'sms_messaging',
    alert_notifications_bar = 'alert_notifications_bar',
    amount_twilio_numbers = 'amount_twilio_numbers',
    ulm_concession_manager = 'ulm_concession_manager',
    ulm_schedule_genie = 'ulm_schedule_genie',
    realync = 'realync',
    chatbot = 'chatbot',
}

export enum FEATURES_BY_CODE {
    BPN = 'best_price_now',
    CU = 'call_us_now',
    CH = 'chat',
    IC = 'income_calculator',
    IN = 'insite',
    SG = 'schedule_genie',
    TU = 'text_us_now',
    CM = 'concession_manager',
    CHB = 'chatbot',
    UCM = 'ulm_concession_manager',
    USG = 'ulm_schedule_genie'
}
