import { Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import LeadReminderForm, { LeadReminderFields } from './LeadReminderForm';
import { LeadReminder as LeadReminderType } from '../../../../types';

interface LeadReminderNewProps {
    id: number;
    data: LeadReminderType;
    closeForm: () => void;
    updateHandler: (values: LeadReminderFields, actions: FormikHelpers<LeadReminderType>) => Promise<void>;
    deleteHandler: (id: number) => Promise<void>;
    completeHandler: (data: LeadReminderType, active: boolean) => any;
}

@inject('store')
@observer
class LeadReminderEdit extends React.Component<LeadReminderNewProps> {
    public initialValues = () => {
        return {
            ...this.props.data
        };
    };

    public render() {
        const colProps = {
            xs: 12
        };

        return (
            <Row className="block-indent__padding">
                <Col {...colProps}>
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues()}
                        onSubmit={this.handleSubmit}
                    >{LeadReminderForm({
                        controls: this.controls()
                    })}</Formik>
                </Col>
            </Row>
        );
    }

    public controls = () => {
        return (
            <>
                {this.props.data.active ? (
                    <Button bsStyle="success" onClick={this.handleComplete}>
                        Mark as Completed
                    </Button>
                ) : null}
                <br />
                {this.props.data.active ? (
                    <Button className="right-indent" bsStyle="primary" type="submit">
                        Save
                    </Button>
                ) : null}
                <Button className="right-indent" bsStyle="default" type="reset" onClick={this.props.closeForm}>
                    {this.props.data.active ? 'Cancel' : 'Back'}
                </Button>
                <Button bsStyle="danger" type="reset" onClick={this.handleDelete}>
                    Delete
                </Button>
            </>
        );
    };

    public handleSubmit = (values: LeadReminderFields, actions: FormikHelpers<LeadReminderType>) => {
        return this.props.updateHandler(values, actions);
    };

    public handleDelete = () => {
        return this.props.deleteHandler(this.props.id);
    };

    public handleComplete = () => {
        return this.props.completeHandler(this.props.data, false);
    };
}

export default LeadReminderEdit;
