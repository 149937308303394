import { FormikProps } from 'formik';
import * as React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import { ControlLabel, FormControlProps, FormGroup, HelpBlock, Col, Button } from 'react-bootstrap';

interface FieldGroupDatePickerProps<T> extends FormControlProps, FormikProps<T> {
    labels: Record<string, string | undefined>;
    type: 'date' | 'time';
}

type ValidationState = 'success' | 'warning' | 'error' | null;

export class FieldGroupDatePicker<T> extends React.Component<FieldGroupDatePickerProps<T>> {
    public handleOnChange = (date: Date) => {
        this.props.setFieldValue(`${this.props.id}`, date);
    };

    public handleClear = () => {
        if (!this.props.disabled) {
            this.props.setFieldValue(`${this.props.id}`, null);
        }
    };

    public getDateFromString = (d: string) => {
        if (typeof d === 'string') {
            if (d.match(/^\d{4}-\d{2}-\d{2}$/)) {
                return new Date(moment(d).format('YYYY-MM-DD'));
            }

            return new Date(moment(d).format('LLLL'));
        }
        return d;
    };

    public render() {
        const error = this.props.errors[`${this.props.id}`];
        let validationState: ValidationState = null;
        if (error) {
            validationState = 'error';
        }
        const value = this.getDateFromString(this.props.values[`${this.props.id}`]);
        const label = this.props.labels[`${this.props.id}`];
        const timeInterval = 15;

        return (
            <FormGroup
                controlId={this.props.id}
                validationState={validationState}
                className={this.props.className}
                readOnly
                hidden={this.props.hidden}
            >
                <ControlLabel>{label}</ControlLabel>
                <Col className="holder-form-group new-holder-box">
                    {this.props.type === 'time' ? (
                        <DatePicker
                            name={this.props.id}
                            disabled={this.props.disabled}
                            onChange={this.handleOnChange}
                            dateFormat="yyyy-MM-dd HH:mm"
                            required={false}
                            autoComplete="off"
                            selected={value}
                            showTimeSelect
                            timeFormat="HH:mm"
                            timeIntervals={timeInterval}
                            timeCaption="Time"
                        />
                    ) : (
                        <DatePicker
                            name={this.props.id}
                            disabled={this.props.disabled}
                            onChange={this.handleOnChange}
                            dateFormat="yyyy-MM-dd"
                            required={false}
                            autoComplete="off"
                            selected={value}
                        />
                    )}
                    <Button
                        type="button"
                        bsStyle="danger"
                        className="clear-button"
                        onClick={this.handleClear}
                        disabled={this.props.disabled}
                    >
                        Clear Date
                    </Button>
                </Col>
                {error && <HelpBlock>{error}</HelpBlock>}
            </FormGroup>
        );
    }
}
