import React from 'react';
import { AppCard, AppCardHeader, ListGroupItem } from '../../../../components-v2/shared';
import { DateTime } from 'luxon';
import { DripEvent } from '../../../../types/Lead';

const TITLES = {
    SCHEDULE_GENIE: 'Schedule Genie',
    CONCESSION_MANAGER: 'Spotlight'
};

const ICONS = {
    SCHEDULE_GENIE: { name: 'schedule' },
    CONCESSION_MANAGER: { name: 'concessionManager', color: 'green' }
};

export const DripEventCard: React.FC<{ dripEvent: DripEvent }> = ({ dripEvent }) => {
    const title =
        dripEvent.type === 'CONCESSION_MANAGER'
            ? `${TITLES[dripEvent.type]}: ${dripEvent.data.spotlight_offer}`
            : TITLES[dripEvent.type];
    const sentVia: string[] = [];
    if (dripEvent.data.sent_via_email) {
        sentVia.push('Email');
    }
    if (dripEvent.data.sent_via_sms) {
        sentVia.push('SMS');
    }
    return (
        <AppCard>
            <AppCardHeader
                icon={ICONS[dripEvent.type]}
                title={title}
                description={
                    dripEvent.timestamp
                        ? `Notification Sent: ${DateTime.fromISO(dripEvent.timestamp as string).toLocaleString(
                            DateTime.DATETIME_SHORT
                        )} ${sentVia.length > 0 ? 'via' : ''} ${sentVia.join(' and ')}`
                        : 'Notification Queued'
                }
            ></AppCardHeader>
            <div className="drip-event-card-content">
                <>
                    <div className="field-row">
                        <ListGroupItem icon={{ name: 'schedule' }} label="Drip Schedule">
                            {dripEvent.data.drip_schedule_name}
                        </ListGroupItem>
                        <ListGroupItem icon={{ name: 'details' }} label="Drip Notification Name">
                            {dripEvent.data.email_template_name}
                        </ListGroupItem>
                        {dripEvent.data.email_bounced ? (
                            <ListGroupItem icon={{ name: 'analytics' }} label="Bounced">
                                Yes
                            </ListGroupItem>
                        ) : dripEvent.data.email_unsubscribed ? (
                            <ListGroupItem icon={{ name: 'analytics' }} label="Unsubscribed">
                                Yes
                            </ListGroupItem>
                        ) : (
                            <>
                                <ListGroupItem icon={{ name: 'bellRing' }} label="Opened">
                                    {dripEvent.data.email_clicked || dripEvent.data.email_opened ? 'Yes' : 'No'}
                                </ListGroupItem>
                                <ListGroupItem icon={{ name: 'touch' }} label="Clicked">
                                    {dripEvent.data.email_clicked ? 'Yes' : 'No'}
                                </ListGroupItem>
                            </>
                        )}
                    </div>
                </>
            </div>
        </AppCard>
    );
};
