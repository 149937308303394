import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { FormikHelpers, FormikProps } from 'formik';
import {
    ActionList,
    AppFooter,
    AppHeader,
    AppPage,
    AppSection,
    AppSectionHeader,
    Button,
    ButtonOutline,
    ButtonPrimary,
    Form,
    Icon,
    InputSwitch,
    InputText,
    useSnackbar
} from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { Modal, ModalActions, ModalHeader } from '@lambdacurry/component-library';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { Bpn } from '../../types';
import { emptyBpn } from '../../store/initial';
import { EditBestPriceNowFormValidationSchema } from './BestPriceNow.validation';
import { NotificationSettingsFieldGroup } from '../../components-v2/shared/field-groups';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { AppsName } from '../../types';
import { JSONWithQuestionBundle, QuestionEditor } from '../QuestionEditor/QuestionEditor';

export const BestPriceNow = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, bpn, fetchBpn, fetchCrmConfigOptions, fetchProspectQuestions, Api } = store;
    const { companyId } = router.params;
    const bestPriceNowItem = bpn.values.length ? bpn.values[0] : null;
    const mode = bestPriceNowItem?.id ? 'update' : 'create';

    const [loading, setLoading] = useState<boolean>(true);
    const [active, setActive] = useState<boolean>(true);
    const [activateBpnModal, setActivateBpnModal] = useState<boolean>(false);

    const BPNFriendlyName = 'Best Price Now';

    const fetchData = async () => {
        await fetchBpn();
        await fetchCrmConfigOptions();
        await fetchProspectQuestions();
        setLoading(false);
    };
    useAsyncEffect(fetchData);

    // Change the active state when switching between modes
    useEffect(() => {
        setActive(mode === 'update' ? !!bestPriceNowItem?.active : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    const handleToggleClick = async () => {
        if (mode !== 'update') {
            return;
        }

        // If toggling to active, display modal
        if (!active) {
            return setActivateBpnModal(true);
        }

        toggleBpn();
    };

    const toggleBpn = async () => {
        try {
            await bpn.update({ ...bestPriceNowItem, active: !active });
            setActive(!active);
            addSnackbar(`${BPNFriendlyName} has been ${active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.BPN
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${active ? 'deactivate' : 'activate'} ${BPNFriendlyName}.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: Bpn, actions: FormikHelpers<Bpn>) => {
        try {
            await bpn[mode]({ ...values, active, crm_config_id: values.crm_config_id || null });
            addSnackbar(`${BPNFriendlyName} has been saved.`, { variant: 'success' });
            actions.resetForm({ values });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar(`Failed to save ${BPNFriendlyName}.`, { variant: 'error' });
        }
    };

    const initialValues = () => {
        return {
            ...emptyBpn,
            company_id: parseInt(companyId, 10),
            ...bestPriceNowItem
        };
    };

    return (
        <AppPage loading={loading}>
            <AppHeader title={BPNFriendlyName} icon={{ name: 'deal', color: 'success' }}>
                {mode === 'update' && (
                    <ActionList position="end">
                        <InputSwitch
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            onClick={handleToggleClick}
                            checked={active}
                        />
                    </ActionList>
                )}
            </AppHeader>

            <Modal isOpen={activateBpnModal} closeButton={false}>
                <ModalHeader
                    title={
                        <>
                            <Icon name="error" className="color-danger" />
                            Alert: This is a paid app
                        </>
                    }
                />

                <p>
                    Activating this app immediately bills your account.
                    <br />
                    Are you sure you want to activate this app?
                </p>

                <ModalActions>
                    <ActionList position="end">
                        <Button onClick={() => setActivateBpnModal(false)}>No, Cancel</Button>
                        <ButtonPrimary
                            onClick={() => {
                                toggleBpn();
                                setActivateBpnModal(false);
                            }}
                        >
                            Yes, Activate
                        </ButtonPrimary>
                    </ActionList>
                </ModalActions>
            </Modal>

            <Form
                initialValues={initialValues()}
                onSubmit={handleSubmit}
                validationSchema={EditBestPriceNowFormValidationSchema}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
            >
                {(formikProps: FormikProps<Bpn>) => (
                    <>
                        <AppSection>
                            <div className="field-group-content">
                                <div className="field-group-col">
                                    <AppSectionHeader title="Best Price Offer" />
                                    <InputText name="bpn_menu_title" label="Title" formikProps={formikProps} />
                                    <AppSectionHeader title="Trade Multiplier" />
                                    <div className="field-row">
                                        <InputText
                                            name="low_trade_multiplier"
                                            label="Low"
                                            type="number"
                                            formikProps={formikProps}
                                            suffix="x"
                                        />
                                        <InputText
                                            name="ave_trade_multiplier"
                                            label="Medium"
                                            type="number"
                                            formikProps={formikProps}
                                            suffix="x"
                                        />
                                        <InputText
                                            name="high_trade_multiplier"
                                            label="High"
                                            type="number"
                                            formikProps={formikProps}
                                            suffix="x"
                                        />
                                    </div>
                                </div>

                                <div className="field-group-col">
                                    <QuestionEditor
                                        sectionTitle="Qualification Questions"
                                        formikProps={formikProps as FormikProps<JSONWithQuestionBundle>}
                                    />
                                </div>
                            </div>
                        </AppSection>

                        <AppSection>
                            <div className="field-group-content">
                                <div className="field-group-col">
                                    <NotificationSettingsFieldGroup
                                        formikProps={formikProps}
                                        appName={BPNFriendlyName}
                                        crmBlacklist={[]}
                                    />
                                </div>
                                <div className="field-group-col">
                                    <AppSectionHeader title="Confirmation Email" />
                                    <ButtonOutline
                                        className="field-group-button-full-width"
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_bpn_edit_email"
                                        icon={<Icon name="confirmationEmail" />}
                                        as={buttonProps => (
                                            <Link
                                                {...buttonProps}
                                                view={DefaultRoutes.EmailTemplatesList}
                                                params={{ companyId }}
                                                store={store}
                                            />
                                        )}
                                    >
                                        Edit Email
                                    </ButtonOutline>
                                </div>
                            </div>
                        </AppSection>

                        <AppFooter sticky={true}>
                            <ActionList position="end">
                                {formikProps.dirty && (
                                    <Button
                                        onClick={() => formikProps.resetForm()}
                                        disabled={formikProps.isSubmitting}
                                        data-lc-trigger-unsaved-changes={true}
                                        data-test-id="button_cancel"
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <ButtonPrimary
                                    type="submit"
                                    disabled={
                                        (!!bestPriceNowItem?.id && !formikProps.dirty) ||
                                        formikProps.isSubmitting ||
                                        !formikProps.isValid
                                    }
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppFooter>
                    </>
                )}
            </Form>
        </AppPage>
    );
});
