import { Icon } from '@lambdacurry/component-library';
import { ClarityDataGridButtonLink } from './ClarityDataGridButtonLink';
import React from 'react';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { phoneFormatter } from '../../../util/formatters';
import { friendlyUtmSourceNames } from '../../../constants';

enum icons {
    SCHEDULE_GENIE = 'schedule',
    CONCESSION_MANAGER = 'concessionManager',
    INCOME_CALCULATOR = 'income',
    BPN = 'deal',
    CHAT = 'chat',
    TEXT_US = 'sms',
    CALL_US_NOW = 'phoneRing',
    INSITE = 'addUser'
}
enum titles {
    SCHEDULE_GENIE = 'Schedule Genie',
    CONCESSION_MANAGER = 'Spotlight',
    INCOME_CALCULATOR = 'Income Calculator',
    BPN = 'Best Price Now',
    CHAT = 'Chat',
    TEXT_US = 'Text Us Now',
    CALL_US_NOW = 'Call Us Now',
    INSITE = 'Insite',
    TOTAL = 'Total Unique'
}

enum touchSource {
    MODULE_SCHEDULEGENIE = 'Schedule Genie',
    MODULE_CONCESSIONMANAGER = 'Spotlight',
    MODULE_INCOMECALCULATOR = 'Income Calculator',
    MODULE_BPN = 'Best Price Now',
    MODULE_CHAT = 'Live Chat',
    MODULE_CHATBOT = 'Virtual Assistant',
    MODULE_TEXT_US = 'Text Us Now',
    MODULE_CALL_US_NOW = 'Call Us Now',
    DIRECT = 'Direct'
}

const linkToLeadDetails = (cellObj: { data: any }) => {
    return (
        <ClarityDataGridButtonLink
            buttonText={cellObj.data.full_name}
            route={DefaultRoutes.CompanyLeadDetails}
            params={{ companyId: cellObj.data.company_id, customerId: cellObj.data.lead_customer_id, tabId: 0 }}
        />
    );
};

const phoneFormat = (cellObj: { value: string }) => {
    return phoneFormatter(cellObj.value);
};

const leadTypeArrayToIcons = (cellObj: { value: Array<string> }) => {
    return (
        <>
            {cellObj.value.map((type: string) => (
                <span title={titles[type]}>
                    <Icon name={icons[type] || 'concessionManager'} />
                </span>
            ))}
        </>
    );
};
const multiIcon = (cellObj: { value: string; data: any }) => {
    if (['imps.conversionlogix.com', 'imps.theconversioncloud.com'].includes(cellObj.value)) {
        return clxIcon(cellObj);
    }

    let friendlyName;

    if (cellObj.value) {
        friendlyName = friendlyUtmSourceNames[cellObj.value];
    }

    return (
        <ClarityDataGridButtonLink
            buttonText={friendlyName || cellObj.value || 'Direct'}
            route={DefaultRoutes.CompanyLeadDetails}
            params={{ companyId: cellObj.data.company_id, customerId: cellObj.data.lead_customer_id, tabId: 1 }}
        />
    );
};
const clxIcon = (cellObj: { value: string }) => {
    return cellObj.value ? (
        <>
            {
                <span style={{ width: '30px' }}>
                    <Icon name={'clxIcon'} />
                </span>
            }
        </>
    ) : (
        ''
    );
};
const touchTypeToFriendlyName = (cellObj: { value: string; data: any }) => {
    const touch = cellObj?.value?.split(',') ? cellObj.value.split(',') : [];
    const formatted = touch.map(value => {
        return touchSource[value];
    });

    return (
        <ClarityDataGridButtonLink
            buttonText={formatted.join(', ')}
            route={DefaultRoutes.CompanyLeadDetails}
            params={{ companyId: cellObj.data.company_id, customerId: cellObj.data.lead_customer_id, tabId: 1 }}
        />
    );
};

const leadTypeToFriendlyName = (cellObj: { value: string }) => {
    return titles[cellObj.value];
};

const formatPercentage = (cellObj: { value: number }) => {
    return `${(cellObj.value * 100).toFixed(2)}%`;
};

export const ClarityDataGridPlugins: { [x: string]: Function } = {
    leadTypeArrayToIcons: leadTypeArrayToIcons,
    multiIcon: multiIcon,
    linkToLeadDetails: linkToLeadDetails,
    phoneFormat: phoneFormat,
    leadTypeToFriendlyName: leadTypeToFriendlyName,
    formatPercentage: formatPercentage,
    touchTypeToFriendlyName: touchTypeToFriendlyName,
    clxIcon: clxIcon
};
