import * as React from 'react';
import { FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import { Panel, Row, Button, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import LeadReminderList from './LeadReminderList';
import LeadReminderNew from './LeadReminderNew';
import LeadReminderEdit from './LeadReminderEdit';
import Store from '../../../../store';
import { LeadReminder as LeadReminderType } from '../../../../types';

interface LeadReminderProps {
    lead_id: number;
    company_id: number;
    store?: Store;
}

interface LeadReminderState {
    reminderId: number;
    reminderData?: LeadReminderType;
    showForm: boolean;
    activeReminders: any[];
    passiveReminders: any[];
}

@inject('store')
@observer
export default class LeadReminder extends React.Component<LeadReminderProps, LeadReminderState> {
    public state = {
        reminderId: 0,
        reminderData: undefined,
        showForm: false,
        activeReminders: [],
        passiveReminders: []
    };

    public get injected() {
        return this.props.store!;
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Reminders</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <Row className="holder-wrap-widget">
                        <div className="col-xs-12 col-md-6">
                            <Panel bsStyle="primary" defaultExpanded>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <h4>Reminder Details</h4>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    {this.state.showForm ? (
                                        this.state.reminderId && this.state.reminderData ? (
                                            <LeadReminderEdit
                                                id={this.state.reminderId}
                                                data={this.state.reminderData!}
                                                updateHandler={this.updateReminder}
                                                deleteHandler={this.deleteReminder}
                                                completeHandler={this.completeReminder}
                                                closeForm={this.closeForm}
                                            />
                                        ) : (
                                            <LeadReminderNew
                                                closeForm={this.closeForm}
                                                createHandler={this.createReminder}
                                            />
                                        )
                                    ) : (
                                        <div>
                                            <ButtonToolbar>
                                                <Button bsStyle="primary" onClick={this.showAddForm}>
                                                    New Reminders <Glyphicon glyph="plus" />
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    )}
                                </Panel.Body>
                            </Panel>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <LeadReminderList
                                id={this.state.reminderId}
                                activateItem={this.showEditForm}
                                activeReminders={this.state.activeReminders}
                                passiveReminders={this.state.passiveReminders}
                            />
                        </div>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }

    public showEditForm = (row: LeadReminderType) => {
        if (!row || !row.id) {
            return;
        }

        this.setState({
            reminderId: row.id,
            reminderData: row,
            showForm: true
        });
    };

    public showAddForm = () => {
        this.setState({
            reminderId: 0,
            reminderData: undefined,
            showForm: true
        });
    };

    public closeForm = () => {
        this.setState({
            reminderId: 0,
            reminderData: undefined,
            showForm: false
        });
    };

    public createReminder = (values: LeadReminderType, actions: FormikHelpers<LeadReminderType>) => {
        const { createLeadReminder } = this.injected;
        return createLeadReminder(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public updateReminder = (values: LeadReminderType, actions: FormikHelpers<LeadReminderType>) => {
        const { updateLeadReminder } = this.injected;
        return updateLeadReminder(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public completeReminder = (values: LeadReminderType, active: boolean) => {
        const { updateLeadReminder } = this.injected;

        values.active = active;

        return updateLeadReminder(this.props.lead_id, this.props.company_id, values).then(() => {
            this.closeForm();
            return this.fetchData();
        });
    };

    public deleteReminder = (id: number) => {
        const { deleteLeadReminder } = this.injected;
        return deleteLeadReminder(this.props.lead_id, id).then(() => {
            this.closeForm();
            return this.fetchData();
        });
    };

    public fetchData() {
        const { fetchLeadReminder } = this.injected;

        return fetchLeadReminder(this.props.lead_id).then((response: any) => {
            const a: any[] = [];
            const b: any[] = [];
            if (response.data.leads_reminder) {
                response.data.leads_reminder.forEach((v: any) => {
                    if (v.active) {
                        a.push(v);
                    } else {
                        b.push(v);
                    }
                });
            }
            this.setState({
                activeReminders: a,
                passiveReminders: b
            });
        });
    }
}
