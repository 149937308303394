import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import { FormikProps, FormikHelpers } from 'formik';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { Chatbot } from '../../types/Chatbot';
import { ChatbotReducerState, ChatbotReducerAction, chatbotReducer } from './ChatBot.helpers';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { ChatBotValidationSchema } from './Chatbot.validation';
import {
    ActionList,
    AppPage,
    AppSection,
    AppHeader,
    Button,
    ButtonPrimary,
    Form,
    InputText,
    useSnackbar
} from '../../components-v2/shared';
import { ToggleChatbotSwitch } from './components';

export interface ChatBotForm {
    greeting: string;
    call_to_action: string;
}

export const ChatBotEdit = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { chatbots, router, fetchChatbot, fetchChatbotQuestions } = store;
    const { companyId } = router.params;

    const [state, dispatch] = useReducer<Reducer<ChatbotReducerState, ChatbotReducerAction>>(chatbotReducer, {
        loading: true
    });

    const fetchRequiredData = async () => {
        if (!companyId) {
            return;
        }
        await fetchChatbotQuestions();
        await fetchChatbot();

        await fetchChatbot();
        dispatch({ name: 'setLoading', payload: false });
    };
    useAsyncEffect(fetchRequiredData);

    const handleSubmit = async (values: Chatbot, actions: FormikHelpers<Chatbot>) => {
        try {
            await chatbots.update(values);
            addSnackbar('Virtual Assistant has been updated', {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
            addSnackbar('Failed to update Virtual Assistant.', {
                variant: 'error'
            });
        }
    };

    const handleManageTopics = () => {
        router.goTo(DefaultRoutes.ChatBotTopicList, { companyId }, store);
    };

    const getInitialValues = () => {
        if (!chatbots.values[0]?.call_to_action && !chatbots.values[0]?.greeting) {
            return {
                ...chatbots.values[0],
                greeting: '',
                call_to_action: ''
            };
        }
        return chatbots.values[0];
    };

    return (
        <AppPage loading={state.loading}>
            <AppHeader
                title="Virtual Assistant"
                icon={{
                    name: 'chat',
                    color: 'green',
                    img: 'https://storage.googleapis.com/static.clxp.us/tcc/icons/claire-icon.png',
                    height: 48
                }}
            >
                <ActionList position="end">
                    <ToggleChatbotSwitch chatbot={chatbots.values[0]} chatbots={chatbots} />
                </ActionList>
            </AppHeader>
            <Form
                enableReinitialize
                initialValues={getInitialValues()}
                onSubmit={handleSubmit}
                validationSchema={ChatBotValidationSchema}
                confirmUnsavedChanges
                unsavedChangesConfig={{}}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {(formikProps: FormikProps<Chatbot>) => {
                    return (
                        <AppSection>
                            <InputText formikProps={formikProps} name="call_to_action" label="Call to Action" />
                            <InputText formikProps={formikProps} name="greeting" label="Greeting" />
                            <ActionList position="end">
                                <Button onClick={handleManageTopics}>Manage Topics</Button>
                                <ButtonPrimary
                                    type="submit"
                                    disabled={!formikProps.dirty || formikProps.isSubmitting}
                                    data-test-id="button_save"
                                >
                                    {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                </ButtonPrimary>
                            </ActionList>
                        </AppSection>
                    );
                }}
            </Form>
        </AppPage>
    );
});
