import React from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { AppPage, AppHeader, useSnackbar } from '../../components-v2/shared';
import { EditQuestionForm } from './components/EditQuestionForm';
import { Question } from '../../types/Question';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { emptyChatBotQuestion } from '../../store/initial';

export const QuestionNew = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { questions, router, activeCompanyId } = store;
    const { chatbotId, companyId } = router.params;

    const handleSubmit = async (values: Question, actions: FormikHelpers<Question>) => {
        try {
            values.order = questions.values.length + 1;
            const questionId = await questions.create({
                ...values,
                company_id: activeCompanyId,
                chatbot_id: parseInt(chatbotId, 10)
            });
            router.goTo(DefaultRoutes.ChatBotQuestionEdit, { companyId, questionId }, store);
            addSnackbar(`Question "${values.name}" has been added.`, { variant: 'success' });
        } catch (error) {
            actions.setErrors(error.response.data);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <>
            <AppPage>
                <AppHeader
                    title="Virtual Assistant"
                    icon={{
                        name: 'chat',
                        color: 'green',
                        img: 'https://storage.googleapis.com/static.clxp.us/tcc/icons/claire-icon.png',
                        height: 48
                    }}
                    onBack={() => router.goTo(DefaultRoutes.ChatBotTopicList, { companyId }, store)}
                ></AppHeader>
                <EditQuestionForm initialValues={emptyChatBotQuestion as Question} onSubmit={handleSubmit} />
            </AppPage>
        </>
    );
});
