import React from 'react';
import { Menu, MenuItem, Highlighter } from 'react-bootstrap-typeahead';

export const RenderedTypeaheadMenu = (results: any, menuProps: any) => {
    let idx = 0;
    const groupBy = (xs: any, key: any) => {
        return xs.reduce((rv: any, x: any) => {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    const grouped = groupBy(results, 'active');
    const items = Object.keys(grouped)
        .sort()
        .reverse()
        .map(group => [
            !!idx && <Menu.Divider key={`${group}-divider`} />,
            group === 'false' && <Menu.Header key={`clx-${group}-header`}>-- Not Active --</Menu.Header>,
            grouped[group].map((state: any) => {
                const item = (
                    <MenuItem
                        key={idx}
                        option={state}
                        position={idx}
                        className={!state.active ? 'clx-not-active' : 'clx-active'}
                    >
                        <Highlighter search={menuProps.text}>
                            {`${state.name} ${state.state ? ' - ' + state.state : ''}`}
                        </Highlighter>
                    </MenuItem>
                );

                idx++;
                return item;
            })
        ]);

    return <Menu {...menuProps}>{items}</Menu>;
};
