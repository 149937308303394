import * as Yup from 'yup';
import { QuestionBundleValidator } from '../../types/QuestionSettings';

const regexPath = /^(\/|\/[\w+\-][\/[\w+\-\.\!\$\&\'\(\)\,\;\=\:\@\~\*]*]*)(\?.*)?$/i;

export const EditOfferFormValidationSchema = () =>
    Yup.object({
        offer_text: Yup.string()
            .max(25, 'The offer title may be no more than 25 characters.')
            .required('Please provide a title for this offer.'),
        active_time_end: Yup.date()
            .nullable()
            .min(new Date(), 'Start date should not be in past')
            .when('active_time_start', (startDate: any, schema: any) => {
                if (startDate) {
                    return schema.min(startDate, 'End Date must be later than Start Date.');
                }
            }),
        active_time_start: Yup.date()
            .nullable(),
        offer_menu_title: Yup.string().required('Please provide a title for the offer app.'),
        fine_print: Yup.string()
            .nullable()
            .when('disclaimer', (values: any, schema: any) => {
                if (values) {
                    return schema.required('Please add a description to the Disclaimer Text field.')
                }
            }),
        pathnames: Yup.array()
            .nullable()
            .of(
                Yup.string()
                    .nullable()
                    .matches(
                        regexPath,
                        `Must be a valid URL path string. (Paths must start with "/", may not contain spaces, and may only include alphanumeric characters and some specific special characters.)`
                    )
            ),
        question_bundle: QuestionBundleValidator
    });
