import * as React from 'react';
import { FormikProps, FormikHelpers } from 'formik';
import { Form, InputText, ButtonPrimary} from '@lambdacurry/component-library';

export interface ISample {
    taggedText: string;
}

interface SampleFormProps {
    handleSubmit: (values: any, actions: FormikHelpers<any>) => Promise<void>;
}

export const SampleForm: React.FC<SampleFormProps> = ({handleSubmit}) => {
    return (
        <Form className="form-holder-block" initialValues={{uniqueName: '', friendlyName: ''}} onSubmit={handleSubmit}>
            {(formikProps: FormikProps<ISample>) => (
                <>
                    <div>
                        <InputText label="Tagged Text" name="taggedText" formikProps={formikProps} />
                    </div>
                    <div>
                        <ButtonPrimary type="submit">{formikProps.isSubmitting ? 'Saving' : 'Save Sample'}</ButtonPrimary>
                    </div>
                </>
            )}
        </Form>
    );
}
