import * as React from 'react';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { AxiosResponse } from 'axios';
import { Panel, Row, Col, ControlLabel } from 'react-bootstrap';
import LeadSmsList from './LeadSmsList';
import LeadSmsNew from './LeadSmsNew';
import Store from '../../../../store';
import { LeadSmsMessage, InjectedProps } from '../../../../types';
import { COLUMNS_ONE, COLUMNS_TWO } from '../../../misc/FieldGroupColSizes';
import { phoneFormatter } from '../../../../util/formatters';
import { toast } from 'react-toastify';

interface LeadSmsProps {
    lead_id: number;
    company_id: number;
    contact: string;
    store?: Store;
}

interface LeadSmsState {
    sms: any[];
}

@inject('store')
@observer
export default class LeadSms extends React.Component<LeadSmsProps, LeadSmsState> {
    public state = {
        sms: []
    };

    public get injected() {
        return this.props as InjectedProps;
    }

    public get formCode() {
        return 'lead_sms_' + this.props.lead_id;
    }

    public componentDidMount() {
        reaction(() => this.injected.store.refreshLeadSms, this.fetchData);

        return this.fetchData();
    }

    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Text Us Now</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <Row className="holder-wrap-widget">
                        <div className="lead_mobile-hold">
                            <Col {...COLUMNS_ONE}>
                                <div className="holder-message-box">
                                    <ControlLabel>{phoneFormatter(this.props.contact)}</ControlLabel>
                                    <LeadSmsList sms={this.state.sms} />
                                </div>
                                <div className="text-display-block indent-outside">
                                    <Col {...COLUMNS_TWO}>
                                        <LeadSmsNew formCode={this.formCode} createHandler={this.create} />
                                    </Col>
                                </div>
                            </Col>
                        </div>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }

    public create = (values: LeadSmsMessage, actions: FormikHelpers<LeadSmsMessage>): Promise<boolean> => {
        const { sendSms } = this.injected.store;

        return sendSms(this.props.company_id, '', this.props.contact, values.message)
            .then((response: AxiosResponse<{id: number, status: string}>): Promise<any> => {
                if (response && response.data && response.data.id) {
                    return this.fetchData();
                }
                return Promise.resolve(false);
            })
            .then((result) => {
                if (result === false) {
                    toast('System couldn\'t send a message.', { type: toast.TYPE.ERROR });
                    return false;
                }

                return true;
            })
            .catch((e: any) => {
                if (e.response && e.response.status) {
                    toast(e.response.data, { type: toast.TYPE.ERROR });
                }

                return false;
            });
    };

    public fetchData = () => {
        const { fetchLeadSms } = this.injected.store;

        return fetchLeadSms(this.props.lead_id).then((response: any) => {
            this.setState({
                sms: response.data.sms_messages
            });
        });
    };
}
