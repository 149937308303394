import { range } from 'lodash';
export type ServerRequestStatus = 'waiting' | 'sending' | 'sent' | 'error';

export type ScheduleGenieModals = 'none' | 'editRemoteCalendar';

export interface ScheduleGenieModalData {
    title?: string;
    formInitialValues?: any;
};

export interface ScheduleGenieReducerState {
    modal: {
        active: ScheduleGenieModals;
        data: ScheduleGenieModalData;
    };
    usingOverrideAddress: boolean;
    loading: boolean;
    showCustomerAppointmentsPerTimeSlotFields: boolean;
    showAvailableAppointmentTypes: boolean;
}

export interface ScheduleGenieReducerAction {
    name: keyof typeof scheduleGenieReducers;
    payload?: any;
}

const scheduleGenieReducers = {
    closeModal: (state: ScheduleGenieReducerState, _: any) => {
        state.modal.active = 'none' as ScheduleGenieModals;
        return { ...state };
    },
    openModal: (state: ScheduleGenieReducerState, activeModal: ScheduleGenieModals) => {
        state.modal.active = activeModal;
        return { ...state };
    },
    setModalData: (state: ScheduleGenieReducerState, modalData: ScheduleGenieModalData) => {
        state.modal.data = { ...state.modal.data, ...modalData };
        return { ...state };
    },
    setUsingOverrideAddress: (
        state: ScheduleGenieReducerState,
        usingOverrideAddress: ScheduleGenieReducerState['usingOverrideAddress']
    ) => ({
        ...state,
        usingOverrideAddress
    }),
    setLoading: (state: ScheduleGenieReducerState, loading: ScheduleGenieReducerState['loading']) => ({
        ...state,
        loading
    }),
    setShowCustomerAppointmentsPerTimeSlotFields: (
        state: ScheduleGenieReducerState,
        showCustomerAppointmentsPerTimeSlotFields: ScheduleGenieReducerState['showCustomerAppointmentsPerTimeSlotFields']
    ) => ({
        ...state,
        showCustomerAppointmentsPerTimeSlotFields
    }),
    setShowAvailableAppointmentTypes: (
        state: ScheduleGenieReducerState,
        showAvailableAppointmentTypes: ScheduleGenieReducerState['showAvailableAppointmentTypes']
    ) => ({
        ...state,
        showAvailableAppointmentTypes
    })
};

export const scheduleGenieReducer = (state: ScheduleGenieReducerState, action: ScheduleGenieReducerAction) => {
    if (!scheduleGenieReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ScheduleGenieReducerState = scheduleGenieReducers[action.name](state, action.payload);
    return nextState;
};

export const periodStartWorkLengths = [
    30,
    60,
    120,
    180,
    360,
    1440,
    2880,
    4320,
    5760,
    7200,
    8640,
    10080,
    11520,
    12960,
    14400
];

export const maxAppointmentPeriods = [
    { value: '1 week', label: '1 week' },
    { value: '2 week', label: '2 weeks' },
    { value: '3 week', label: '3 weeks' },
    { value: '4 week', label: '4 weeks' },
    { value: '1 month', label: '1 month' },
    { value: '5 week', label: '5 weeks' },
    { value: '6 week', label: '6 weeks' },
    { value: '7 week', label: '7 weeks' },
    { value: '8 week', label: '8 weeks' },
    { value: '2 month', label: '2 months' },
    { value: '3 month', label: '3 months' },
    { value: '4 month', label: '4 months' },
    { value: '5 month', label: '5 months' },
    { value: '6 month', label: '6 months' },
    { value: '7 month', label: '7 months' },
    { value: '8 month', label: '8 months' },
    { value: '9 month', label: '9 months' },
    { value: '10 month', label: '10 months' },
    { value: '11 month', label: '11 months' },
    { value: '1 year', label: '1 year' }
];

// Note: created by victorvarenic a year ago as part of TCCS-100 - Jake 11/04/2020
export function getAppointmentDurationLabels(time: number): string {
    let results = '';
    const amountHoursInDay = 24;
    const minutesInHour = 60;
    const minutesInDay = amountHoursInDay * minutesInHour;

    const days = Math.floor(time / minutesInDay);
    if (days) {
        results += ` ${days} day${days > 1 ? 's' : ''}`;
    }

    const timeWithoutDays = time - minutesInDay * days;
    const hours = Math.floor(timeWithoutDays / minutesInHour);
    const minutes = timeWithoutDays % minutesInHour;

    results += hours ? ` ${hours} hour${hours > 1 ? 's' : ''}` : '';
    if (minutes) {
        results = `${results} ${minutes} minutes`;
    }
    if (!results) {
        results = `0 minutes`;
    }
    return results;
}

export function getAppointmentDurationOptions(slotLengthRange: {
    start: number;
    end: number;
    step: number;
}): Array<{ label: string; value: number }> {
    return range(slotLengthRange.start, slotLengthRange.end, slotLengthRange.step).map(i => ({
        label: getAppointmentDurationLabels(i),
        value: i
    }));
}
