import React, { FC, useReducer } from 'react';
import {
    AppList,
    AppListHeader,
    AppListItem,
    AppListEmpty,
    ActionList,
    IconButton,
    AppSection,
    AppSectionHeader,
    ButtonLink,
    ButtonPrimary
} from '../../../../components-v2/shared';
import { moneyFormatter } from '../../../../util/formatters';
import { FloorplanDetailsFormValues } from '../../IncomeCalculator.types';
import {
    incomeCalculatorInitialState,
    incomeCalculatorReducer,
    openModal,
    closeModal
} from '../../IncomeCalculator.helpers';
import { FloorplanDetailsModal } from '../FloorplanDetailsModal/FloorplanDetailsModal';
import { FormikProps } from 'formik';
import { Floorplan, IncomeCalculator } from '../../../../types/IncomeCalculator';
import { ReactComponent as EmptyFloorplansIllustration } from './EmptyFloorplansIllustration.svg';

import './floorplans-list.scss';
import styles from '../../IncomeCalculator.module.scss';
import classNames from 'classnames';

export interface FloorplansListProps {
    formikProps: FormikProps<IncomeCalculator>;
}

export interface FloorplanError {
    tag: string;
    ami: number;
    index: number;
    field: string;
    planIndex: number;
    message: string;
}

export const FloorplansList: FC<FloorplansListProps> = ({ formikProps }) => {
    const [state, dispatch] = useReducer(incomeCalculatorReducer, incomeCalculatorInitialState);
    const { ami_one, ami_two, ami_three, household, rent_to_income_percentage, floorplans } = formikProps.values;
    const floorplansErrors = formikProps.errors.floorplans as FloorplanError[];

    const openDetailsModal = (data?: FloorplanDetailsFormValues) => {
        const modalData = {
            index: undefined,
            floor_plan_name: '',
            bedrooms: 1,
            bathrooms: 1,
            bed_count: 0,
            market_rent: 0,
            max_allowed_value_0: 0,
            max_allowed_value_1: 0,
            max_allowed_value_2: 0,
            ...data
        };

        openModal(dispatch, 'floorplanDetails', modalData);
    };

    const createFloorplan = (values: Floorplan) => {
        formikProps.setFieldValue('floorplans', [...floorplans, values]);
    };

    const copyFloorplan = ({ index, ...values }: FloorplanDetailsFormValues) => {
        createFloorplan({ ...values, floor_plan_name: `${values.floor_plan_name} - Copy` });
    };

    const updateFloorplan = (index: number, values: Floorplan) => {
        const updatedFloorplans = [...floorplans];
        updatedFloorplans[index] = values;
        formikProps.setFieldValue('floorplans', updatedFloorplans);
    };

    const deleteFloorplan = (index: number) => {
        const updatedFloorplans = [...floorplans];
        updatedFloorplans.splice(index, 1);
        formikProps.setFieldValue('floorplans', updatedFloorplans);
    };

    const handleFloorplanDetailsSubmit = ({ index, ...values }: FloorplanDetailsFormValues) => {
        if (typeof index !== 'undefined') {
            updateFloorplan(index, values);
            return closeModal(dispatch);
        }

        createFloorplan(values);
        closeModal(dispatch);
    };

    const fields: [string, string][] = [
        ['Title', 'floor_plan_name'],
        ['Beds', 'bedrooms'],
        ['Baths', 'bathrooms'],
        ['Current Rent', 'market_rent'],
        [`${ami_one}% AMI`, 'max_allowed_value_0'],
        [`${ami_two}% AMI`, 'max_allowed_value_1'],
        [`${ami_three}% AMI`, 'max_allowed_value_2']
    ];

    if (!floorplans) {
        return <>Loading...</>;
    }

    return (
        <>
            <AppSection>
                <AppSectionHeader title="Floorplans">
                    <ButtonLink onClick={() => openDetailsModal()}>+ Add New Floorplan</ButtonLink>
                </AppSectionHeader>

                {floorplans.length < 1 && (
                    <AppListEmpty
                        illustration={<EmptyFloorplansIllustration />}
                        title="No flooplan has been added yet!"
                        description="Add a floorplan to showcase your inventory and pricepoints,"
                        action={<ButtonPrimary onClick={() => openDetailsModal()}>Add Floorplan</ButtonPrimary>}
                    />
                )}

                {floorplans.length > 0 && (
                    <AppList className="floorplans-list">
                        <AppListHeader className="floorplans-list-header">
                            {fields.map(([fieldLabel, fieldName]) => (
                                <div key={fieldName}>{fieldLabel}</div>
                            ))}
                        </AppListHeader>

                        {floorplans.map((floorplan: Floorplan, index: number) => {
                            const floorplanErrors = floorplansErrors?.filter(error => error.planIndex === index);

                            return (
                                <div key={index} className="floorplans-list-item-wrapper">
                                    <AppListItem
                                        className={classNames('floorplans-list-item', {
                                            'floorplans-list-item-has-error': floorplanErrors?.length
                                        })}
                                    >
                                        {fields.map(([fieldLabel, fieldName]: [string, string]) => {
                                            let displayValue = floorplan[fieldName];
                                            const fieldErrors = floorplanErrors?.filter(
                                                error => error.field === fieldName
                                            );

                                            if (
                                                [
                                                    'market_rent',
                                                    'max_allowed_value_0',
                                                    'max_allowed_value_1',
                                                    'max_allowed_value_2'
                                                ].includes(fieldName)
                                            ) {
                                                displayValue = moneyFormatter(displayValue);
                                            }

                                            return (
                                                <div
                                                    key={fieldName}
                                                    className={classNames('floorplans-list-item-field', {
                                                        'floorplans-list-item-field-error': fieldErrors?.length > 0
                                                    })}
                                                >
                                                    <span className="floorplans-list-item-field-label">
                                                        {fieldLabel}:{' '}
                                                    </span>
                                                    {displayValue}
                                                </div>
                                            );
                                        })}
                                        <div className="floorplans-list-item-actions">
                                            <ActionList
                                                className={styles.incomeCalculatorFloorplansListItemActions}
                                                position="end"
                                            >
                                                <IconButton
                                                    icon="pencil"
                                                    onClick={() =>
                                                        openDetailsModal({
                                                            index,
                                                            ...floorplan
                                                        })
                                                    }
                                                />
                                                <IconButton icon="trash" onClick={() => deleteFloorplan(index)} />
                                                <IconButton icon="copy" onClick={() => copyFloorplan(floorplan)} />
                                            </ActionList>
                                        </div>
                                    </AppListItem>

                                    {floorplanErrors?.length > 0 && (
                                        <div className="floorplans-list-item-error">
                                            {floorplanErrors.map((error, errorIndex) => (
                                                <span key={errorIndex}>
                                                    {error.message}
                                                    {` `}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </AppList>
                )}
            </AppSection>

            <FloorplanDetailsModal
                state={state}
                dispatch={dispatch}
                isOpen={state.modal.active === 'floorplanDetails'}
                amiData={{ ami_one, ami_two, ami_three, household, rent_to_income_percentage }}
                floorplan={state.modal.data}
                onSubmit={handleFloorplanDetailsSubmit}
            />
        </>
    );
};
