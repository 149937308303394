import React, { FC, useContext } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { ButtonUnstyled, Icon } from '@lambdacurry/component-library';
import useStore from '../../../../../store/useStore';
import { goToAgency, goToCompany, goToHome } from '../../SideNav.helpers';
import { LayoutContext } from '../../../LayoutWrapper';

import './breadcrumbs.scss';

export interface BreadcrumbsProps {}

export const Breadcrumbs: FC<BreadcrumbsProps> = observer(() => {
    const { activeAgency, activeCompany } = useContext(LayoutContext);
    const { store } = useStore();
    const { isAdminRole, isAgencyRole } = store;

    const handleBackClick = async () => {
        if (activeCompany && activeCompany.agency_id) {
            return goToAgency(activeCompany.agency_id, store);
        }

        return goToHome(store);
    };

    if (!((isAdminRole && activeAgency) || (isAgencyRole && activeCompany))) {
        return null;
    }

    return (
        <ul className="breadcrumbs">
            <li className="breadcrumbs-item breadcrumbs-item-back">
                <ButtonUnstyled onClick={handleBackClick}>
                    <Icon name="arrowLeft" />
                </ButtonUnstyled>
            </li>

            {isAdminRole && (
                <li className="breadcrumbs-item">
                    <ButtonUnstyled onClick={() => goToHome(store)}>Admin</ButtonUnstyled>
                </li>
            )}

            {activeAgency && (
                <li
                    className={classNames('breadcrumbs-item', {
                        'breadcrumbs-item-secondary': !!activeCompany
                    })}
                >
                    <ButtonUnstyled onClick={() => goToAgency(activeAgency.id, store)}>
                        {activeAgency.name}
                    </ButtonUnstyled>
                </li>
            )}

            {activeCompany && (
                <li className="breadcrumbs-item">
                    <ButtonUnstyled onClick={() => goToCompany(activeCompany.id, store)}>
                        {activeCompany.name}
                    </ButtonUnstyled>
                </li>
            )}
        </ul>
    );
});
