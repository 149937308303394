import * as React from 'react';
import { FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import { Panel, Row, Button, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import LeadEmailList from './LeadEmailList';
import LeadEmailNew from './LeadEmailNew';
import LeadEmailEdit from './LeadEmailEdit';
import Store from '../../../../store';
import { LeadEmail as LeadEmailType } from '../../../../types';

interface LeadEmailProps {
    lead_id: number;
    company_id: number;
    store?: Store;
}

interface LeadEmailState {
    emailId: number;
    emailData?: LeadEmailType;
    showForm: boolean;
    emails: any[];
}

@inject('store')
@observer
export default class LeadEmail extends React.Component<LeadEmailProps, LeadEmailState> {
    public state = {
        emailId: 0,
        emailData: undefined,
        showForm: false,
        emails: []
    };

    public get injected() {
        return this.props.store!;
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Email</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <Row className="holder-wrap-widget">
                        <div className="col-xs-12 col-md-6">
                            <Panel bsStyle="primary" defaultExpanded>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <h4>Email Message</h4>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    {this.state.showForm ? (
                                        this.state.emailId && this.state.emailData ? (
                                            <LeadEmailEdit
                                                id={this.state.emailId}
                                                data={this.state.emailData!}
                                                updateHandler={this.update}
                                                deleteHandler={this.delete}
                                                closeForm={this.closeForm}
                                            />
                                        ) : (
                                            <LeadEmailNew closeForm={this.closeForm} createHandler={this.create} />
                                        )
                                    ) : (
                                        <div>
                                            <ButtonToolbar>
                                                <Button bsStyle="primary" onClick={this.showAddForm}>
                                                    New Email <Glyphicon glyph="plus" />
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    )}
                                </Panel.Body>
                            </Panel>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <LeadEmailList
                                activeId={this.state.emailId}
                                activateItem={this.showEditForm}
                                emails={this.state.emails}
                            />
                        </div>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }

    public showEditForm = (row: LeadEmailType) => {
        if (!row || !row.id) {
            return;
        }

        this.setState({
            emailId: row.id,
            emailData: row,
            showForm: true
        });
    };

    public showAddForm = () => {
        this.setState({
            emailId: 0,
            emailData: undefined,
            showForm: true
        });
    };

    public closeForm = () => {
        this.setState({
            emailId: 0,
            emailData: undefined,
            showForm: false
        });
    };

    public create = (values: LeadEmailType, actions: FormikHelpers<LeadEmailType>) => {
        const { createLeadEmail } = this.injected;
        return createLeadEmail(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public update = (values: LeadEmailType, actions: FormikHelpers<LeadEmailType>) => {
        const { updateLeadEmail } = this.injected;
        return updateLeadEmail(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public delete = (id: number) => {
        const { deleteLeadEmail } = this.injected;
        return deleteLeadEmail(this.props.lead_id, id).then(() => {
            this.closeForm();
            return this.fetchData();
        });
    };

    public fetchData() {
        const { fetchLeadEmail } = this.injected;

        return fetchLeadEmail(this.props.lead_id).then((response: any) => {
            this.setState({
                emails: response.data.mail_messages
            });
        });
    }
}
