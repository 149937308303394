import * as React from 'react';
import { Panel } from 'react-bootstrap';
import { LeadStatus } from '../../../types/Lead';
import { FieldGroup, FieldGroupProps } from '../../misc/FieldGroup';
import { FieldGroupPhone } from '../../misc/FieldGroupPhone';
import { COLUMNS_THREE, COLUMNS_TWO } from '../../misc/FieldGroupColSizes';
import { LeadFieldLabels, LeadFields } from './LeadEdit';

const labels = {
    ...LeadFieldLabels,
    first_name: 'First Name',
    last_name: 'Last name',
    email: 'Email',
    phone_number: 'Phone Number',
    status: 'Opportunity status',
    is_agree_sms_notification: 'SMS Message Preference'
};

interface LeadCustomerDataFieldGroupProps extends FieldGroupProps<LeadFields> {
    isCompanyLight: boolean;
    isSuperAdmin: boolean;
    isAdmin: boolean;
    enableSmsMessages: boolean;
    preview?: boolean;
}

export default function CustomerDataFieldGroup(props: LeadCustomerDataFieldGroupProps) {
    const fieldProps = {
        ...props,
        labels
    };
    const disabledIsAgree = fieldProps.disabled || (!fieldProps.isSuperAdmin && !fieldProps.isAdmin);
    const isUpdateForm = !!props.values.id;

    return (
        <>
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Customer</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <div className="indent-outside">
                        <FieldGroup id="first_name" {...fieldProps} colSize={COLUMNS_THREE} />
                        <FieldGroup id="last_name" {...fieldProps} colSize={COLUMNS_THREE} />
                        <FieldGroup id="email" {...fieldProps} colSize={COLUMNS_THREE} />
                        <FieldGroupPhone id="phone_number" {...fieldProps} colSize={COLUMNS_TWO} />
                        <FieldGroup id="status" {...fieldProps} colSize={COLUMNS_TWO} componentClass="select">
                            <option value={LeadStatus.APPOINTMENT_SCHEDULED}>Appointment Scheduled</option>
                            <option value={LeadStatus.OPEN_OPPORTUNITY}>Open Opportunity</option>
                            <option value={LeadStatus.APPOINTMENT_COMPLETED}>Completed Appointment</option>
                            <option value={LeadStatus.APPOINTMENT_CANCELLED}>Cancelled Appointment</option>
                            <option value={LeadStatus.PURCHASED}>Purchased</option>
                            <option value={LeadStatus.NO_OPPORTUNITY}>No Opportunity</option>
                        </FieldGroup>
                        {props.enableSmsMessages ? (
                            <FieldGroup
                                id="is_agree_sms_notification"
                                {...fieldProps}
                                colSize={COLUMNS_TWO}
                                componentClass="select"
                                disabled={disabledIsAgree}
                            >
                                <option value="">...</option>
                                <option value="No">No user consent</option>
                                <option value="Yes">Yes user consent</option>
                                {isUpdateForm ? <option value="Pending">Pending</option> : null}
                            </FieldGroup>
                        ) : null}
                    </div>
                </Panel.Body>
            </Panel>
        </>
    );
}
