import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { FieldGroup } from '../../../misc/FieldGroup';
import { FieldGroupWysiwyg } from '../../../misc/FieldGroupWysiwyg';
import { LeadEmail } from '../../../../types/LeadEmail';
import { Col } from 'react-bootstrap';

interface LeadEmailFormProps {
    controls: React.ReactNode;
}

const labels = {
    subject: 'Subject',
    content: 'Message',
    cc: 'Cc',
    bcc: 'Bcc'
};

export default (props: LeadEmailFormProps) => {
    return (bag: FormikProps<LeadEmail>) => {
        const fieldProps = {
            ...bag,
            labels
        };

        return (
            <div>
                <Form id="reminderForm">
                    {fieldProps.errors && typeof fieldProps.errors === 'string' ? (
                        <span className="error">{fieldProps.errors}</span>
                    ) : null}
                    <div className="indent-outside">
                        <FieldGroup id="subject" {...fieldProps} />
                        <Col className="col-xs-12">
                            <FieldGroupWysiwyg id="content" {...fieldProps} />
                        </Col>
                        <FieldGroup id="cc" {...fieldProps} />
                        <FieldGroup id="bcc" {...fieldProps} />
                    </div>
                    {props.controls}
                </Form>
            </div>
        );
    };
};
