import * as React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

interface WysiwygEditorProps {
    id?: string;
    defaultValue: string;
    disabled?: boolean;
    onChange?: (v: string) => void;
}

export default class WysiwygEditor extends React.Component<WysiwygEditorProps> {
    public shouldComponentUpdate(nextProps: WysiwygEditorProps) {
        return nextProps.disabled !== this.props.disabled || nextProps.id !== this.props.id;
    }

    public handleChange = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };

    public render() {
        const props = {
            autoFocus: false,
            lang: 'en',
            width: '100%',
            disable: this.props.disabled,
            setOptions: {
                buttonList: [['fontSize', 'bold', 'italic', 'underline', 'fontColor', 'hiliteColor', 'list', 'align']]
            },
            setContents: this.props.defaultValue || ''
        };
        return <SunEditor onChange={this.handleChange} {...props} />;
    }
}
