import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { TaskForm } from './TaskForm';
import Store from '../../../store';

interface TaskNewProps {
    handleClose: (item:any) => any;
    store?: Store;
    assistantSid: string;
}

@inject('store')
@observer
export class TaskNew extends React.Component<TaskNewProps> {
    public get injected() {
        return this.props.store!;
    }

    public render() {
        return (
            <TaskForm handleSubmit={this.handleSubmit} />
        );
    }

    public handleSubmit = (values: any, actions: FormikHelpers<any>) => {
        const { createAutoPilotTask } = this.injected;
        const { handleClose } = this.props;
        
        return createAutoPilotTask(this.props.assistantSid, values.uniqueName, values.friendlyName).then(data => {
            actions.resetForm();
            handleClose(values);
            actions.setSubmitting(false);
        });
    }
}