import * as React from 'react';
import Store from '../../store';
import { InjectedProps } from '../../types';
import * as constants from '../../constants';
import { GoogleChartOptions } from 'react-google-charts/dist/types';

export const baseChartOptions: GoogleChartOptions = {
    div: false,
    titleTextStyle: {
        fontSize: 24,
        bold: true
    },
    backgroundColor: 'none',
    legend: { position: 'bottom' }
};

export const friendlyModuleNames = {
    MODULE_BPN: 'Best Price Now',
    MODULE_CALLUS: 'Call Us',
    MODULE_CHAT: 'Chat',
    MODULE_CONCESSIONMANAGER: 'Spotlight',
    MODULE_INCOMECALCULATOR: 'Income Calculator',
    MODULE_SCHEDULEGENIE: 'Schedule Genie',
    MODULE_INSITE: 'Insite',
    MODULE_TEXTUS: 'Text Us Now',
    MODULE_CHATBOT: 'Virtual Assistant',
    CM: 'Spotlight',
    IC: 'Income Calculator',
    SG: 'Schedule Genie',
    INSITE: 'Insite',
    CHAT: 'Chat',
    BPN: 'Best Price Now',
    TEXT_US: 'Text Us Now',
    TOTAL: 'All Applications',
    CB: 'ChatBot',
    ULM_CONCESSION_MANAGER: 'ULM Spotlight',
    ULM_SCHEDULE_GENIE: 'ULM Schedule Genie'
};

export const friendlyAppNames = {
    BPN: 'Best Price Now',
    CONCESSION_MANAGER: 'Spotlight',
    INCOME_CALCULATOR: 'Income Calculator',
    SCHEDULE_GENIE: 'Schedule Genie',
    CHAT: 'Chat',
    TOTAL_UNIQUE: 'Total Unique'
};

// Original Clarity colors -- for reference
// const clarityBlue = '#04adf0';
// const clarityGreen = '#03828b';
// const clxRed = '#b60611';

const clarityPink = '#E80978';
const clarityOrange = '#F68C21';
const clarityGreen = '#009AA5';
const clarityLightBlue = '#47C4F2';
const clarityBlue = '#009DDC';
const clarityPurple = '#9E4198';
const clarityGray = '#989998';

export const barColors = [
    clarityPink,
    clarityOrange,
    clarityGreen,
    clarityLightBlue,
    clarityBlue,
    clarityPurple,
    clarityGray
];

export const pieSliceColors = [
    { color: clarityPink },
    { color: clarityGreen },
    { color: clarityPurple },
    { color: clarityBlue }
];

export const utmSliceColors = [
    { color: clarityPurple },
    { color: clarityGreen },
    { color: clarityBlue },
    { color: clarityLightBlue, textStyle: { color: 'black' } },
    { color: clarityGray }
];

export interface ChartConfig {
    div?: string; // if there is a naming conflict and you need a unique divname different than the datasource name
    datasource: string;
    doNotPrint?: boolean;
    datamap: (data: any) => any;
    chartType?: string;
    height?: number;
    width?: number;
    columns?: string[] | Array<{ type: string; label?: string }>;
    options?: GoogleChartOptions;
    formatters?: Array<{ column: number; type: string; options?: { pattern: string } }>;
}

export const leadCounts: ChartConfig = {
    datasource: 'lead-counts',
    doNotPrint: true,
    datamap: (data: any) => {
        let total = 0;
        const result = data.map((row: any) => {
            total = total + row.leads;
            return row;
        });
        result.push({ lead_type: 'TOTAL', leads: total });
        return result;
    }
};

export interface WidgetMetricsProps {
    store?: Store;
}

abstract class WidgetMetricCommon extends React.Component<WidgetMetricsProps> {
    public abstract REPORT_LIST: ChartConfig[];
    public abstract state: any;

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public reportDataInitialState = () => {
        const data = {};
        this.REPORT_LIST.forEach(report => {
            data[report.datasource] = 0;
        });
        this.setState({ loading: false });
        return data;
    };

    public processReportData = (data: any) => {
        this.REPORT_LIST.forEach((report: ChartConfig) => {
            const datasourceName = report.datasource;
            data[datasourceName] = report.datamap(data[report.datasource]);
        });
        this.setState({ loading: false });
        return data;
    };

    public fetchData() {
        const { fetchBatchWidgetMetrics, getFilterSetting, changeFilterSetting } = this.injected.store;
        const reportDataSources = this.REPORT_LIST.map(ds => {
            return ds.datasource;
        });

        let filter = getFilterSetting('');
        if (!filter) {
            filter = constants.filterDefault();
            changeFilterSetting('', filter);
        }

        fetchBatchWidgetMetrics(reportDataSources, filter)
            .then((response: any) => {
                this.setState({
                    report_data: this.processReportData(response.data)
                });
            })
            .catch();
    }

    protected changeFilter = () => {
        this.setState({ loading: true, report_data: this.reportDataInitialState() });
        this.fetchData();
    };
}

export default WidgetMetricCommon;
