import { isArray, map, trim } from 'lodash';
import { LeadType, LeadTypeNames, UniverseLocator } from '../../../../types';
import { PredicatePicklists } from '../../../RuleEditor/RuleEditor';

export const EMAIL_DELIMITER = /[/:/;,]+/;

export const CrmEmailsToArray = (crm_emails?: string[] | string) => {
    if (!crm_emails) {
        return [];
    }
    return isArray(crm_emails) ? crm_emails : map(crm_emails.split(EMAIL_DELIMITER), trim);
};

export const crmTimezones: Array<{ key: string; label: string }> = [
    { key: 'America/New_York', label: '[-05] America/New_York (Eastern)' },
    { key: 'America/Chicago', label: '[-06] America/Chicago (Central)' },
    { key: 'America/Boise', label: '[-07] America/Boise (Mountain)' },
    { key: 'America/Los_Angeles', label: '[-08] America/Los_Angeles (Pacific)' },
    { key: 'America/Anchorage', label: '[-09] America/Anchorage' },
    { key: 'Pacific/Honolulu', label: '[-10] Pacific/Honolulu' },
    { key: 'UTC', label: '[+00] UTC' }
];

// This is the only place we need to add new subjects for all CRMs.
export const CrmRuleConditionSubjects: Array<UniverseLocator> = [
    {
        key: 'utm_source',
        label: 'Last Touch UTM Source',
        type: 'string'
    },
    {
        key: 'utm_medium',
        label: 'Last Touch UTM Medium',
        type: 'string'
    },
    {
        key: 'first_touch_utm_source',
        label: 'First Touch UTM Source',
        type: 'string'
    },
    {
        key: 'first_touch_utm_medium',
        label: 'First Touch UTM Medium',
        type: 'string'
    },
    {
        key: 'multi_touch_utm_source',
        label: 'Multi-Touch UTM Source',
        type: 'string_array'
    },
    {
        key: 'multi_touch_utm_medium',
        label: 'Multi-Touch UTM Medium',
        type: 'string_array'
    },
    {
        key: 'lead_type',
        label: 'Lead Type',
        type: 'string'
    }
];

export const CrmRulePredicatePicklists: PredicatePicklists = {
    lead_type: [
        // TODO: turn this into a function
        { val: LeadType.BPN, label: LeadTypeNames.BPN },
        { val: LeadType.CH, label: LeadTypeNames.CH },
        { val: LeadType.CM, label: LeadTypeNames.CM },
        { val: LeadType.CU, label: LeadTypeNames.CU },
        { val: LeadType.IC, label: LeadTypeNames.IC },
        { val: LeadType.IN, label: LeadTypeNames.IN },
        { val: LeadType.SG, label: LeadTypeNames.SG },
        { val: LeadType.TU, label: LeadTypeNames.TU }
    ]
};
