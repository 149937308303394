import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { emptyLeadSms } from '../../../../store/initial';
import { LeadSmsMessage } from '../../../../types/LeadSmsMessage';
import LeadSmsForm, { LeadSmsFields } from './LeadSmsForm';
import Store from '../../../../store';
import ControlButtonSubmit from '../../../misc/ControlButtonSubmit';

interface LeadSmsNewProps {
    formCode: string;
    createHandler: (values: LeadSmsFields, actions: FormikHelpers<LeadSmsMessage>) => Promise<boolean>;
    store?: Store;
}

@inject('store')
@observer
class LeadSmsNew extends React.Component<LeadSmsNewProps> {
    public get injected() {
        return this.props.store!;
    }

    public initialValues = () => {
        const { getDraftSetting } = this.injected;
        return {
            ...emptyLeadSms,
            ...getDraftSetting(this.props.formCode)
        };
    };

    public saveDraftForm = (values: LeadSmsMessage) => {
        const { saveDraftForm } = this.injected;

        saveDraftForm(this.props.formCode, values);
    };

    public render() {
        const colProps = {
            xs: 12
        };
        return (
            <Row className="block-indent__padding">
                <Col {...colProps}>
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues()}
                        validate={this.saveDraftForm}
                        onSubmit={this.handleSubmit}
                    >{LeadSmsForm({
                        controls: (bag: FormikProps<LeadSmsFields>) => (
                            <ControlButtonSubmit
                                icon={faPaperPlane}
                                text="Send Message"
                                disabled={!bag.values.message}
                            />
                        )
                    })}</Formik>
                </Col>
            </Row>
        );
    }

    public handleSubmit = (values: LeadSmsMessage, actions: FormikHelpers<LeadSmsMessage>) => {
        const { clearDraftForm } = this.injected;

        return this.props.createHandler(values, actions).then((result: boolean) => {
            if (result) {
                actions.setValues({
                    ...values,
                    ...emptyLeadSms
                });
                clearDraftForm(this.props.formCode);
            }
        });
    };
}

export default LeadSmsNew;
