import * as React from 'react';
import { FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import { Panel, Row, Button, ButtonToolbar, Glyphicon } from 'react-bootstrap';
import LeadNoteList from './LeadNoteList';
import LeadNoteNew from './LeadNoteNew';
import LeadNoteEdit from './LeadNoteEdit';
import Store from '../../../../store';
import { LeadNote as LeadNoteType } from '../../../../types';

interface LeadNoteProps {
    lead_id: number;
    company_id: number;
    store?: Store;
}

interface LeadNoteState {
    noteId: number;
    noteData?: LeadNoteType;
    showForm: boolean;
    notes: any[];
}

@inject('store')
@observer
export default class LeadNote extends React.Component<LeadNoteProps, LeadNoteState> {
    public state = {
        noteId: 0,
        noteData: undefined,
        showForm: false,
        notes: []
    };

    public get injected() {
        return this.props.store!;
    }

    public componentDidMount() {
        return this.fetchData();
    }

    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Notes</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body collapsible>
                    <Row className="holder-wrap-widget">
                        <div className="col-xs-12 col-md-6">
                            <Panel bsStyle="primary" defaultExpanded>
                                <Panel.Heading>
                                    <Panel.Title toggle>
                                        <h4>Note Details</h4>
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    {this.state.showForm ? (
                                        this.state.noteId && this.state.noteData ? (
                                            <LeadNoteEdit
                                                id={this.state.noteId}
                                                data={this.state.noteData!}
                                                updateHandler={this.updateNote}
                                                deleteHandler={this.deleteNote}
                                                closeForm={this.closeForm}
                                            />
                                        ) : (
                                            <LeadNoteNew closeForm={this.closeForm} createHandler={this.createNote} />
                                        )
                                    ) : (
                                        <div>
                                            <ButtonToolbar>
                                                <Button bsStyle="primary" onClick={this.showAddForm}>
                                                    New Note <Glyphicon glyph="plus" />
                                                </Button>
                                            </ButtonToolbar>
                                        </div>
                                    )}
                                </Panel.Body>
                            </Panel>
                        </div>
                        <div className="col-xs-12 col-md-6">
                            <LeadNoteList
                                id={this.state.noteId}
                                activateItem={this.showEditForm}
                                notes={this.state.notes}
                            />
                        </div>
                    </Row>
                </Panel.Body>
            </Panel>
        );
    }

    public showEditForm = (row: LeadNoteType) => {
        if (!row || !row.id) {
            return;
        }

        this.setState({
            noteId: row.id,
            noteData: row,
            showForm: true
        });
    };

    public showAddForm = () => {
        this.setState({
            noteId: 0,
            noteData: undefined,
            showForm: true
        });
    };

    public closeForm = () => {
        this.setState({
            noteId: 0,
            noteData: undefined,
            showForm: false
        });
    };

    public createNote = (values: LeadNoteType, actions: FormikHelpers<LeadNoteType>) => {
        const { createLeadNote } = this.injected;
        return createLeadNote(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public updateNote = (values: LeadNoteType, actions: FormikHelpers<LeadNoteType>) => {
        const { updateLeadNote } = this.injected;
        return updateLeadNote(this.props.lead_id, this.props.company_id, values)
            .then(() => {
                this.closeForm();
                return this.fetchData();
            })
            .catch((error: any) => actions.setErrors(error.response.data));
    };

    public deleteNote = (id: number) => {
        const { deleteLeadNote } = this.injected;
        return deleteLeadNote(this.props.lead_id, id).then(() => {
            this.closeForm();
            return this.fetchData();
        });
    };

    public fetchData() {
        const { fetchLeadNote } = this.injected;

        return fetchLeadNote(this.props.lead_id).then((response: any) => {
            this.setState({
                notes: response.data.leads_note
            });
        });
    }
}
