import { Formik, FormikHelpers } from 'formik';
import { filter, first } from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row } from 'react-bootstrap';
import { emptyUser } from '../../store/initial';
import { InjectedProps, User } from '../../types';
import ControlButtonSave from '../misc/ControlButtonSave';
import UserForm, { IUserForm } from './UserForm';

export interface UserNewProps {
    handleClose: (user: User) => void;
}

@inject('store')
@observer
class UserNew extends React.Component<UserNewProps> {
    public get injected() {
        return (this.props as any) as InjectedProps;
    }

    public initialValues = () => {
        const role = first(this.availableRoles);
        return {
            ...emptyUser,
            role_id: role ? role.id : 0
        };
    };

    protected get availableRoles() {
        const { roles, activeUser, activeUserFetched } = this.injected.store;

        return activeUserFetched
            ? filter(
                roles.data,
                role =>
                    role.access_level <= activeUser!.role.access_level && activeUser!.role.slug !== 'company_light'
            )
            : [];
    }

    public render() {
        const colProps = {
            xs: 12
        };
        const { getActiveCompanies, activeUser } = this.injected.store;
        const { accessibleAgencies } = this.injected.store.agencyStore;
        return (
            <Row>
                <Col {...colProps}>
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues()}
                        onSubmit={this.handleSubmit}
                    >{UserForm({
                        activeUser,
                        isNew: true,
                        editing: true,
                        companies: getActiveCompanies(),
                        agencies: accessibleAgencies,
                        roles: this.availableRoles,
                        controls: () => <ControlButtonSave />
                    })}</Formik>
                </Col>
            </Row>
        );
    }

    public handleSubmit = (values: any, actions: FormikHelpers<IUserForm>) => {
        const { users, fetchUsers } = this.injected.store;
        const { handleClose } = this.props;
        return users
            .create(values)
            .then(() => {
                actions.resetForm();
                handleClose(values);
                return fetchUsers();
            })
            .catch(error => actions.setErrors(error.response.data));
    };
}

export default UserNew;
