import { Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { emptyLeadReminder } from '../../../../store/initial';
import { LeadReminder } from '../../../../types/LeadReminder';
import LeadReminderForm, { LeadReminderFields } from './LeadReminderForm';

interface LeadReminderNewProps {
    closeForm: () => void;
    createHandler: (values: LeadReminderFields, actions: FormikHelpers<LeadReminder>) => Promise<void>;
}

@inject('store')
@observer
class LeadReminderNew extends React.Component<LeadReminderNewProps> {
    public initialValues = () => {
        return {
            ...emptyLeadReminder
        };
    };

    public render() {
        const colProps = {
            xs: 12
        };
        return (
            <Row className="block-indent__padding">
                <Col {...colProps}>
                    <Formik
                        enableReinitialize
                        initialValues={this.initialValues()}
                        onSubmit={this.handleSubmit}
                    >{LeadReminderForm({
                        controls: this.controls()
                    })}</Formik>
                </Col>
            </Row>
        );
    }

    public controls = () => {
        return (
            <>
                <Button className="right-indent" bsStyle="primary" type="submit">
                    Save
                </Button>
                <Button bsStyle="default" type="reset" onClick={this.props.closeForm}>
                    Cancel
                </Button>
            </>
        );
    };

    public handleSubmit = (values: LeadReminderFields, actions: FormikHelpers<LeadReminder>) => {
        return this.props.createHandler(values, actions);
    };
}

export default LeadReminderNew;
