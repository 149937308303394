import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    ButtonAccent,
    ButtonPrimary,
    Form,
    handlePromise,
    InputCheckbox,
    InputColor,
    InputGroup,
    InputSelect,
    InputText,
    useAsyncEffect,
} from '@lambdacurry/component-library';
import {
    ActionList,
    AppFooter,
    AppHeader,
    AppPage,
    AppSection,
    AppSectionHeader,
    InputSwitch,
    Title,
    useSnackbar,
} from '../../../components-v2/shared';
import useStore from '../../../store/useStore';
import { AgencyDetailsFormValues, PricingModel } from '../Agency.types';
import { FormikHelpers, FormikProps } from 'formik';
import { emptyAgency, emptyAgencyCustomization } from '../../../store/initial';
import { getHexColor, hexToRGB, lightenDarkenColor, pickTextColorBasedOnBgColorSimple } from '../../../util/colors';
import { usStates } from '../../../constants/us-states';
import { AccessibleAgency, Agency, AgencyCustomization } from '../../../types';
import { S3FileUploader } from '../../../components-v2/shared';
import { AgencyValidationSchema } from './AgencyDetails.validation';
import { getFileUrl } from '../../../util/file';

import './agency-details.scss';
import { unsavedChangesContainerSelectorDefault } from '../../../constants';

enum AgencyDetailsModes {
    UPDATE = 'update',
    CREATE = 'create',
}

export const AgencyDetails = observer(() => {
    const { agencyStore, store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { agencies, isAdminRole, fetchActiveUser } = store;
    const {
        activeAgencyId,
        activeAgency,
        agencyCustomization,
        fetchAgencyById,
        fetchAgencyCustomizationById,
        updateAgencyCustomizationById,
    } = agencyStore;

    const mode = activeAgencyId ? AgencyDetailsModes.UPDATE : AgencyDetailsModes.CREATE;

    const [loading, setLoading] = useState<boolean>(true);
    const [active, setActive] = useState<boolean>(false);
    const [uploadingFile, setUploadingFile] = useState<boolean>(false);
    const [pricingModels, setPricingModels] = useState<PricingModel[]>([]);

    const pricingModelOptions = (pricingModels || []).map(({ id, description }) => ({
        value: id,
        label: description || '** NO DESCRIPTION PROVIDED **',
    }));

    const getAgencyLogoPreview = (agency?: AccessibleAgency, customization?: AgencyCustomization) =>
        agency?.logo || customization?.agency_logo
            ? [
                {
                    preview: getFileUrl(agency?.logo || customization?.agency_logo || ''),
                },
            ]
            : [];

    const getAgencyAvatarPreview = (agency?: AccessibleAgency) =>
        agency?.avatar
            ? [
                {
                    preview: getFileUrl(agency?.avatar, 'agencyAvatar'),
                },
            ]
            : [];

    const fetchPricingModels = async () => {
        const [response, error] = await handlePromise(store.Api.client.get('agency-billing/company/pricing'));

        if (!response?.data || error) {
            console.error(error?.response);
            addSnackbar('Failed to fetch pricing models.', { variant: 'error' });
            return;
        }

        setPricingModels(response.data);
    };

    const fetchData = async () => {
        if (!activeAgencyId && isAdminRole) {
            await fetchPricingModels();
        }

        if (activeAgencyId) {
            // Note: the order of these matters, because for some reason the activeAgency data was getting replaced with
            // the Agency Customization data. Fetching the data agency last here allows the correct data to be in place
            // for the form on reload. - Jake 05/03/2021
            await fetchAgencyCustomizationById(activeAgencyId);
            await fetchAgencyById(activeAgencyId);
        }

        setLoading(false);
    };
    useAsyncEffect(fetchData, undefined, [activeAgencyId]);

    // Change the active state when switching between modes
    useEffect(() => {
        setActive(mode === AgencyDetailsModes.UPDATE ? !!activeAgency?.active : true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, activeAgency]);

    const handleToggleClick = async () => {
        if (mode !== AgencyDetailsModes.UPDATE) {
            return;
        }

        try {
            await agencies.update({ ...activeAgency, active: !active });
            setActive(!active);
            addSnackbar(`Partner "${activeAgency.name}" now has been ${active ? 'deactivated' : 'activated'}.`, {
                variant: 'success',
            });
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${active ? 'deactivate' : 'activate'} partner "${activeAgency.name}."`, {
                variant: 'error',
            });
        }
    };
    const handleSubmit = async (values: AgencyDetailsFormValues, actions: FormikHelpers<AgencyDetailsFormValues>) => {
        const {
            agency: agencyValues,
            customization: {
                primary_button_text_color,
                primary_color,
                secondary_button_text_color,
                secondary_color,
                id,
            },
        } = values;
        const [agencySaveResponse, agencySaveError] = await handlePromise(
            agencies[mode]({ ...agencyValues, active } as Agency)
        );
        let settingsId = id;
        if (mode === AgencyDetailsModes.CREATE) {
            const response = await agencies.getItem(agencySaveResponse);
            await fetchActiveUser();
            if (response) {
                settingsId = response?.id;
            }
        }

        if (agencySaveError) {
            if (typeof agencySaveError.response.data === 'string') {
                return addSnackbar(agencySaveError.response.data, { variant: 'error' });
            }

            return actions.setErrors({ agency: { ...agencySaveError.response.data } });
        }

        const [updatedAgencyCustomization, agencyCustomizationSaveError] = await handlePromise(
            updateAgencyCustomizationById({
                primary_button_text_color,
                primary_color,
                secondary_button_text_color,
                secondary_color,
                id: settingsId,
            })
        );

        if (agencyCustomizationSaveError) {
            if (typeof agencyCustomizationSaveError.response.data === 'string') {
                return addSnackbar(agencySaveError.response.data, { variant: 'error' });
            }

            return actions.setErrors({ customization: { ...agencyCustomizationSaveError.response.data } });
        }

        const updatedAgency = agencies.getItem(updatedAgencyCustomization.agency_id);

        actions.resetForm({
            values: {
                agency: {
                    ...updatedAgency,
                    active,
                    logo_file: getAgencyLogoPreview(updatedAgency, updatedAgencyCustomization),
                    avatar_file: getAgencyAvatarPreview(updatedAgency),
                },
                customization: updatedAgencyCustomization,
            },
        });

        await fetchAgencyById(updatedAgencyCustomization.agency_id);
        await fetchAgencyCustomizationById(updatedAgencyCustomization.agency_id);
    };

    const handleColorInputChange = (
        color: 'primary' | 'secondary',
        value: string,
        formikProps: FormikProps<AgencyDetailsFormValues>
    ) => {
        const hexColor = getHexColor(value);
        const textColor = pickTextColorBasedOnBgColorSimple(hexColor);

        formikProps.setFieldValue(`customization.${color}_color`, value || '');
        formikProps.setFieldValue(`customization.${color}_button_text_color`, textColor || '');
    };

    const handleClearFile = (fieldNames: string[], formikProps: FormikProps<AgencyDetailsFormValues>) => {
        fieldNames.forEach((fieldName) => formikProps.setFieldValue(fieldName, ''));
        setUploadingFile(false);
    };

    const handleSaveFile = (
        fileUrl: string,
        fieldNames: string[],
        formikProps: FormikProps<AgencyDetailsFormValues>
    ) => {
        const fileName = fileUrl.split('/').pop();
        fieldNames.forEach((fieldName) => formikProps.setFieldValue(fieldName, fileName));
        setUploadingFile(false);
    };

    const initialValues: AgencyDetailsFormValues =
        mode === AgencyDetailsModes.CREATE
            ? {
                agency: { ...emptyAgency },
                customization: { ...emptyAgencyCustomization },
            }
            : {
                agency: {
                    ...emptyAgency,
                    ...activeAgency,
                    logo_file: getAgencyLogoPreview(activeAgency, agencyCustomization),
                    avatar_file: getAgencyAvatarPreview(activeAgency),
                },
                customization: {
                    ...emptyAgencyCustomization,
                    ...agencyCustomization,
                },
            };

    return (
        <AppPage loading={loading}>
            <AppHeader title="Partner Details">
                {mode === AgencyDetailsModes.UPDATE && (
                    <ActionList position="end">
                        <InputSwitch
                            labelOn="Active"
                            labelOff="Inactive"
                            labelPlacement="start"
                            onClick={handleToggleClick}
                            checked={active}
                        />
                    </ActionList>
                )}
            </AppHeader>

            <Form
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={AgencyValidationSchema[mode]}
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault,
                }}
            >
                {(formikProps: FormikProps<AgencyDetailsFormValues>) => {
                    return (
                        <>
                            <AppSection className="field-group">
                                <div className="field-group-content">
                                    <div>
                                        <AppSectionHeader title="Edit Details">
                                            {isAdminRole && (
                                                <InputCheckbox
                                                    name="agency.internal"
                                                    label="Internal"
                                                    formikProps={formikProps}
                                                    disabled={mode === AgencyDetailsModes.UPDATE}
                                                />
                                            )}
                                        </AppSectionHeader>

                                        {mode === AgencyDetailsModes.CREATE && isAdminRole && (
                                            <InputSelect
                                                name="agency.company_price_id"
                                                label="Pricing Model"
                                                placeholder="Select one"
                                                optionValueKey="value"
                                                options={pricingModelOptions}
                                                formikProps={formikProps}
                                                autoComplete="off"
                                            />
                                        )}

                                        <InputText name="agency.name" label="Partner Name" formikProps={formikProps} />
                                        <InputText name="agency.address1" label="Address 1" formikProps={formikProps} />
                                        <InputText name="agency.address2" label="Address 2" formikProps={formikProps} />

                                        <InputText name="agency.city" label="City" formikProps={formikProps} />
                                        <InputGroup>
                                            <InputSelect
                                                label="State"
                                                name="agency.state"
                                                optionLabelKey="name"
                                                optionValueKey="code"
                                                options={usStates.filter(({ name }) => !!name)}
                                                formikProps={formikProps}
                                            />
                                            <InputText
                                                name="agency.zipcode"
                                                label="ZIP Code"
                                                formikProps={formikProps}
                                            />
                                        </InputGroup>
                                        <InputText
                                            name="agency.website_url"
                                            label="Website URL"
                                            formikProps={formikProps}
                                        />
                                        <InputText
                                            name="agency.widget_privacy_url"
                                            label="Consumer Privacy Notice URL for the App"
                                            formikProps={formikProps}
                                        />
                                    </div>
                                    <div>
                                        <AppSectionHeader title="Customize" />
                                        <InputColor
                                            label="Primary Color"
                                            name="customization.primary_color"
                                            formikProps={formikProps}
                                            onChange={(event) =>
                                                handleColorInputChange('primary', event.target.value, formikProps)
                                            }
                                            onPickerChange={(event) =>
                                                handleColorInputChange('primary', event.target.value, formikProps)
                                            }
                                        />
                                        <InputColor
                                            label="Secondary Color"
                                            name="customization.secondary_color"
                                            formikProps={formikProps}
                                            // TODO: Consider merging/combining onChange/onPickerChange in component library
                                            onChange={(event) =>
                                                handleColorInputChange('secondary', event.target.value, formikProps)
                                            }
                                            onPickerChange={(event) =>
                                                handleColorInputChange('secondary', event.target.value, formikProps)
                                            }
                                        />

                                        <Title as="h3">Color Preview</Title>
                                        <div
                                            className="agency-theme-preview"
                                            style={{
                                                // @ts-ignore
                                                [`--primary_color`]: hexToRGB(
                                                    formikProps.values.customization.primary_color || ''
                                                ),
                                                [`--primary_color_dark`]: hexToRGB(
                                                    lightenDarkenColor(
                                                        formikProps.values.customization.primary_color || ''
                                                    )
                                                ),
                                                [`--primary_button_text_color`]: hexToRGB(
                                                    formikProps.values.customization.primary_button_text_color || ''
                                                ),
                                                [`--secondary_color`]: hexToRGB(
                                                    formikProps.values.customization.secondary_color || ''
                                                ),
                                                [`--secondary_color_dark`]: hexToRGB(
                                                    lightenDarkenColor(
                                                        formikProps.values.customization.secondary_color || ''
                                                    )
                                                ),
                                                [`--secondary_button_text_color`]: hexToRGB(
                                                    formikProps.values.customization.secondary_button_text_color || ''
                                                ),
                                            }}
                                        >
                                            <ButtonPrimary>Primary Color</ButtonPrimary>
                                            <ButtonAccent>Secondary Color</ButtonAccent>
                                        </div>
                                    </div>
                                </div>
                            </AppSection>

                            <AppSection className="field-group">
                                <div className="field-group-content">
                                    <div>
                                        <AppSectionHeader title="Logo" />
                                        <S3FileUploader
                                            name="agency.logo_file"
                                            application="agency"
                                            formikProps={formikProps}
                                            fileUploaderProps={{
                                                accept: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
                                                onDrop: () => setUploadingFile(true),
                                                onClearAll: () =>
                                                    handleClearFile(
                                                        ['agency.logo', 'customization.agency_logo'],
                                                        formikProps
                                                    ),
                                                onRemoveFile: () =>
                                                    handleClearFile(
                                                        ['agency.logo', 'customization.agency_logo'],
                                                        formikProps
                                                    ),
                                            }}
                                            imageCropperProps={{
                                                onSave: (fileUrl: string) =>
                                                    handleSaveFile(
                                                        fileUrl,
                                                        ['agency.logo', 'agency.agency_logo'],
                                                        formikProps
                                                    ),
                                                onCancel: () => setUploadingFile(false),
                                            }}
                                        />
                                        <p className="color-gray-dark">
                                            For optimal display across the app and emails, we recommend using a square
                                            or horizontal logo.
                                        </p>
                                    </div>
                                    <div>
                                        <AppSectionHeader title="Avatar" />
                                        <S3FileUploader
                                            name="agency.avatar_file"
                                            application="agency-avatar"
                                            formikProps={formikProps}
                                            fileUploaderProps={{
                                                accept: ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'],
                                                onDrop: () => setUploadingFile(true),
                                                onClearAll: () => handleClearFile(['agency.avatar'], formikProps),
                                                onRemoveFile: () => handleClearFile(['agency.avatar'], formikProps),
                                            }}
                                            imageCropperProps={{
                                                onSave: (fileUrl: string) =>
                                                    handleSaveFile(fileUrl, ['agency.avatar'], formikProps),
                                                onCancel: () => setUploadingFile(false),
                                                initialCrop: {
                                                    unit: 'px',
                                                    aspect: 1,
                                                    width: 112,
                                                    height: 112,
                                                },
                                                minWidth: 56,
                                                minHeight: 56,
                                            }}
                                        />
                                    </div>
                                </div>
                            </AppSection>

                            <AppFooter sticky={true}>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button
                                            onClick={() => formikProps.resetForm()}
                                            disabled={uploadingFile || formikProps.isSubmitting}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={
                                            uploadingFile ||
                                            !formikProps.dirty ||
                                            !formikProps.isValid ||
                                            formikProps.isSubmitting
                                        }
                                    >
                                        {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                        </>
                    );
                }}
            </Form>
        </AppPage>
    );
});
