import { Icon, InputCheckbox, Modal, ModalActions, ModalHeader } from '@lambdacurry/component-library';
import { FormikHelpers, FormikProps, FieldArray } from 'formik';
import React, { Reducer, useEffect, useReducer, useState } from 'react'; // Reducer, useReducer
import { toJS } from 'mobx';
import {
    AppPage,
    AppHeader,
    AppSection,
    Form,
    InputSelect,
    AppFooter,
    Button,
    ButtonPrimary,
    ButtonLink,
    ButtonOutline,
    InputText,
    InputSwitch,
    useSnackbar,
    NotificationSettingsFieldGroup,
    ActionList,
    AppSectionHeader
} from '../../components-v2/shared';
import {
    getAppointmentDurationLabels,
    getAppointmentDurationOptions,
    maxAppointmentPeriods,
    periodStartWorkLengths,
    scheduleGenieReducer,
    ScheduleGenieReducerAction,
    ScheduleGenieReducerState
} from './ScheduleGenie.helpers';
import useStore from '../../store/useStore';
import { observer } from 'mobx-react';
import { Link } from 'mobx-router';
import DefaultRoutes from '../../routes/DefaultRoutes';
import styles from './schedule-genie.module.scss';
import { addressFromCompanyFormatter } from '../../util/formatters';
import { companyEmptyNotification, emptyCalendar } from '../../store/initial';
import { Calendar } from '../../types/Calendar';
import { SelectedAppointmentType } from '../../types/SelectedAppointmentTypes';
import { ScheduleGenieFormValidationSchema } from './ScheduleGenie.validation';
import { NotificationSettings } from '../../types/NotificationSettings';
import classNames from 'classnames';
import { uniqWith } from 'lodash';
import { createNumericalInputOptions } from '../../util/form';
import { unsavedChangesContainerSelectorDefault } from '../../constants';
import { handlePromise, useAsyncEffect } from '../../util/async';
import { AppsName } from '../../types';
import { ExternalCalendarBundle, LinkRemoteCalendarForm } from '../ExternalCalendar/ExternalCalendarHelpers';
import { CompanyRoutes } from '../../routes/CompanyRoutes';
import { FEATURES } from '../../types/Features';
import { LeadNurtureAppSource } from '../EmailEditor/EmailEditorList.helpers';
import { JSONWithQuestionBundle, QuestionEditor } from '../QuestionEditor/QuestionEditor';
import { QuestionBundle } from '../../types/QuestionSettings';

export interface ScheduleGenieForm extends NotificationSettings {
    id: number;
    company_id: number;
    schedule_id: number;
    drip_schedule_id: number;
    call_to_action: string;
    create_success_message: string;
    confirmation_text: string | null;
    question_bundle: QuestionBundle;
    slots_per_day: number;
    appointment_length: number;
    slot_length: number;
    slot_amount_in_same_time: number;
    slot_amount_in_same_time_sun: number | null;
    slot_amount_in_same_time_mon: number | null;
    slot_amount_in_same_time_tue: number | null;
    slot_amount_in_same_time_wed: number | null;
    slot_amount_in_same_time_thu: number | null;
    slot_amount_in_same_time_fri: number | null;
    slot_amount_in_same_time_sat: number | null;
    slot_remove_after_taken: boolean | number;
    period_start_work: number;
    max_appointment_period: string;
    slot_taken_status: string;
    lead_email_attachement: string;
    address: string;
    direct_link_text: string;
    direct_link_url: string;
    active: boolean;
    email_subject: string;
    override_lead_notification: boolean;
    use_dynamic_scheduling: boolean | null;
    use_external_crm_scheduling: boolean | null;
    external_crm_scheduling_available: boolean;
    block_external_appointments: string | null;
    selected_appointment_types?: Array<SelectedAppointmentType>;
    appointment_types_enabled: boolean | null;
}

export const ScheduleGenieAppPage = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const {
        fetchCalendars,
        fetchSchedules,
        fetchDripSchedules,
        fetchFeatureFlags,
        fetchSelectedAppointmentTypesForCompany,
        schedules,
        features,
        dripSchedules,
        calendars,
        router,
        activeCompany,
        activeUser,
        selectedAppointmentTypes,
        Api
    } = store;
    const hasDynamicSchedulingToggle = activeUser?.previewFlags?.includes('dynamic-scheduling-toggle');
    const { companyId } = router.params;

    const calendar = calendars.values.length ? calendars.values[0] : undefined;
    const calendarAddress = calendar?.address;

    const leadNurturingEnabled = features[FEATURES.lead_nurturing];

    const [externalCalendarBundle, setExternalCalendarBundle] = useState<ExternalCalendarBundle>({
        availableAuthTokens: [],
        availableCalendars: []
    });

    const [unlinkModalOpen, setUnlinkModalOpen] = useState<boolean>(false);

    const unlinkButtonHandler = async () => {
        await deleteLinkedCalendar();
    };

    const deleteLinkedCalendar = async () => {
        if (!calendar || !calendar.id) {
            return;
        }
        // We have everything we need to link up the selected external calendar with this Schedule
        const [, unlinkExternalCalendarError] = await handlePromise(
            Api.client.delete(`external/calendars/calendar/${calendar?.id}`)
        );
        if (unlinkExternalCalendarError) {
            console.error(unlinkExternalCalendarError);
            addSnackbar(`Unlinking ExternalCalendar from Schedule Genie failed.`, { variant: 'error' });
            closeModal();
            return;
        }
        closeModal();
        addSnackbar(`ExternalCalendar has been unlinked from Schedule Genie.`, { variant: 'success' });
        await fetchExternalCalendars();
    };

    const fetchExternalCalendars = async () => {
        const [getExternalCalendarBundleResponse, getExternalCalendarBundleError] = await handlePromise<{
            data: any[];
        }>(Api.client.get(`external/calendars/bundle/${companyId}/calendar`));

        if (getExternalCalendarBundleError || !getExternalCalendarBundleResponse) {
            // TODO: handle error
            return;
        }
        setExternalCalendarBundle(getExternalCalendarBundleResponse.data as any);
    };

    const handleSubmitRemoteCalendarForm = async ({ available_calendar }: LinkRemoteCalendarForm) => {
        if (!calendar || !calendar.id) {
            return;
        }
        if (available_calendar?.inUse) {
            addSnackbar(`Calendar is already in use and cannot be linked again here.`, { variant: 'error' });
            closeModal();
            return;
        }
        if (available_calendar && available_calendar.externalId && available_calendar.authId) {
            // We have everything we need to link up the selected external calendar with this Schedule
            const [linkExternalCalendarResponse, linkExternalCalendarError] = await handlePromise<{
                data: ExternalCalendarBundle;
            }>(Api.client.post(`external/calendars/calendar/${calendar.id}`, { available_calendar }));
            if (linkExternalCalendarError || !linkExternalCalendarResponse) {
                console.error(linkExternalCalendarError || linkExternalCalendarResponse);
                addSnackbar(`Linking ExternalCalendar to Schedule Genie failed.`, { variant: 'error' });
                closeModal();
                return;
            }
        }
        addSnackbar(`External calendar has been linked to Schedule Genie.`, { variant: 'success' });
        await fetchExternalCalendars();
        closeModal();
    };

    useAsyncEffect(fetchExternalCalendars);

    const openModal = (name: string, data?: object) => {
        dispatch({ name: 'openModal', payload: name });

        if (data) {
            dispatch({ name: 'setModalData', payload: data });
        }
    };

    const closeModal = (clearData: boolean = true) => {
        dispatch({ name: 'closeModal' });

        if (clearData) {
            dispatch({ name: 'setModalData', payload: {} });
        }
    };

    const openRemoteCalendarModal = async () => {
        let title = 'Link External Calendar';
        let formInitialValues: LinkRemoteCalendarForm = {};
        openModal('editRemoteCalendar', {
            title,
            formInitialValues
        });
    };

    const [state, dispatch] = useReducer<Reducer<ScheduleGenieReducerState, ScheduleGenieReducerAction>>(
        scheduleGenieReducer,
        {
            modal: {
                active: 'none',
                data: {}
            },
            usingOverrideAddress: !!calendarAddress,
            loading: true,
            showCustomerAppointmentsPerTimeSlotFields: false,
            showAvailableAppointmentTypes: false
        }
    );

    const { modal } = state;

    useEffect(() => {
        dispatch({ name: 'setUsingOverrideAddress', payload: !!calendarAddress });
    }, [calendarAddress]);

    const daySlotAmountArray = [
        calendar?.slot_amount_in_same_time_sun,
        calendar?.slot_amount_in_same_time_mon,
        calendar?.slot_amount_in_same_time_tue,
        calendar?.slot_amount_in_same_time_wed,
        calendar?.slot_amount_in_same_time_thu,
        calendar?.slot_amount_in_same_time_fri,
        calendar?.slot_amount_in_same_time_sat
    ];

    useEffect(() => {
        const showCustomerAppointmentsPerTimeSlotFields = daySlotAmountArray.find(daySlotAmount => !!daySlotAmount)
            ? true
            : false;

        dispatch({
            name: 'setShowCustomerAppointmentsPerTimeSlotFields',
            payload: showCustomerAppointmentsPerTimeSlotFields
        });
    }, daySlotAmountArray);

    useEffect(() => {
        // Get the initial values to determine if we should show appointment types
        calendar?.appointment_types_enabled &&
            dispatch({
                name: 'setShowAvailableAppointmentTypes',
                payload: calendar.appointment_types_enabled
            });
    }, [calendar]);

    const fetchRequiredData = async () => {
        fetchFeatureFlags();
        await fetchSchedules();
        await fetchDripSchedules();
        await fetchCalendars();
        await fetchSelectedAppointmentTypesForCompany(parseInt(companyId));
        dispatch({ name: 'setLoading', payload: false });
    };
    useAsyncEffect(fetchRequiredData, undefined, []);

    // The reason we have to deproxy the selected appointment types is because we can't get them with the calendar when a company is first created because
    // the calendar doesn't exist yet, so we have to get it directly from the selected appointment types controller. However when it's added to the mobx
    // store it isn't added as an array of objects but as a proxied array of proxies, for reasons that presumably made sense to the mobx guys. However,
    // there's also a weird issue where the first time you load the Schedule Genie App page after creating a calendar there are duplicated sets of
    // appointment types. I suspect that this has something to do with our routing, but frankly I don't know for sure. This issue goes away on a page refresh
    // but it's confusing and undesirable behavior, so to prevent it I return the array of mapped types after uniqWith'ing them by id.
    const deproxySelectedAppointmentTypes = (proxiedtypes: any): Array<SelectedAppointmentType> => {
        return uniqWith(
            proxiedtypes
                .map((types: any) => {
                    return Object.keys(types).map(key => {
                        return toJS(types[key]);
                    });
                })
                .flat(),
            (a, b) => a.id === b.id
        );
    };

    const initialValues = (): Calendar => {
        let clonedAppointmentTypes;
        if (!calendar) {
            clonedAppointmentTypes = deproxySelectedAppointmentTypes(selectedAppointmentTypes.values);
            return {
                ...emptyCalendar,
                company_id: parseInt(companyId, 10),
                schedule_id: schedules.values.length ? schedules.values[0].id : 0,
                drip_schedule_id: dripSchedules.values.length ? dripSchedules.values[0].id : 0,
                selected_appointment_types: clonedAppointmentTypes,
                ...companyEmptyNotification(activeCompany)
            };
        }

        clonedAppointmentTypes = calendar.selected_appointment_types || [];

        return {
            ...calendar,
            slot_remove_after_taken: !!calendar.slot_remove_after_taken,
            confirmation_text: calendar.confirmation_text ? calendar.confirmation_text : '',
            lead_email_attachement: calendar.lead_email_attachement || 'without attachment',
            crm_config_id: calendar.crm_config_id || 0,
            selected_appointment_types: clonedAppointmentTypes
        };
    };

    const handleToggleClick = async () => {
        const method = calendar ? 'update' : 'create';
        const calendarData = calendar || initialValues();
        const active = method === 'create' ? true : !calendarData.active;

        try {
            await calendars[method]({ ...calendarData, active });
            addSnackbar(`Schedule Genie has been ${!active ? 'deactivated' : 'activated'}.`, {
                variant: 'success'
            });
            const [response, error] = await handlePromise(
                Api.client.patch(`public_roles/updateActiveModules`, {
                    toggleType: !active ? 'remove' : 'add',
                    company_id: companyId,
                    moduleName: AppsName.SG
                })
            );

            if (!response?.data || error) {
                return console.error('error: ', error);
            }
        } catch (error) {
            console.error(error.response.data);
            addSnackbar(`Failed to ${!active ? 'deactivate' : 'activate'} Schedule Genie.`, {
                variant: 'error'
            });
        }
    };

    const handleSubmit = async (values: ScheduleGenieForm, actions: FormikHelpers<ScheduleGenieForm>) => {
        const method = calendar ? 'update' : 'create';

        try {
            await calendars[method](values);
            addSnackbar(`Schedule Genie has been updated.`, { variant: 'success' });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    const slotAmountInSameTimeWeekdayOptions = [
        { value: 0, label: 'Default' },
        ...[...Array(9).keys()].map(item => ({
            value: item + 1,
            label: (item + 1).toString()
        }))
    ];

    const calendarsAvailableForLinking = externalCalendarBundle.availableCalendars
        .filter(c => {
            return !c.inUse;
        })
        .map(ac => {
            return {
                ...ac,
                display_name: `${ac.authName}: ${ac.name}`
            };
        });

    const hasCalendarsAvailableForLinking = calendarsAvailableForLinking.length > 0;

    return (
        <AppPage className={styles['schedule-genie']} loading={state.loading}>
            <AppHeader title="Schedule Genie" icon={{ name: 'schedule', color: 'purple' }}>
                <ActionList position="end">
                    <InputSwitch
                        labelOn="Active"
                        labelOff="Inactive"
                        labelPlacement="start"
                        checked={calendar?.active}
                        onClick={handleToggleClick}
                    />
                </ActionList>
            </AppHeader>

            <Form
                enableReinitialize
                confirmUnsavedChanges
                unsavedChangesConfig={{
                    containerQuerySelectorAll: unsavedChangesContainerSelectorDefault
                }}
                initialValues={initialValues()}
                onSubmit={handleSubmit}
                validationSchema={ScheduleGenieFormValidationSchema}
            >
                {(formikProps: FormikProps<ScheduleGenieForm>) => {
                    return (
                        <>
                            <AppSection className="field-group-content">
                                <div className="field-group-col">
                                    {formikProps.values.external_crm_scheduling_available && (
                                        <>
                                            <AppSectionHeader title="Use External Availability Schedule">
                                                {
                                                    <ActionList position="end">
                                                        <InputSwitch
                                                            name="use_external_crm_scheduling"
                                                            labelPlacement="start"
                                                            formikProps={formikProps}
                                                        />
                                                    </ActionList>
                                                }
                                            </AppSectionHeader>
                                            <div className={styles['schedule-genie-availability-row']}>
                                                Because you have a CRM configured that supports real-time availability
                                                schedules, you have the option to use that instead of the settings
                                                below. When active, the settings below will only be used if the external
                                                CRM is unavailable or returns an invalid response.
                                            </div>
                                            <hr />
                                        </>
                                    )}
                                    <div>
                                        <AppSectionHeader title="Availability" />
                                        <div className={styles['schedule-genie-availability-row']}>
                                            <InputSelect
                                                className={styles['schedule-genie-availability-row-select']}
                                                label="Select Availability Schedule"
                                                name="schedule_id"
                                                options={schedules.values}
                                                optionValueKey="id"
                                                optionLabelKey="name"
                                                formikProps={formikProps}
                                                autocompleteConfig={{
                                                    disableClearable: true
                                                }}
                                            />

                                            <ButtonOutline
                                                className={styles['schedule-genie-availability-row-link']}
                                                as={buttonProps => (
                                                    <Link
                                                        {...buttonProps}
                                                        view={DefaultRoutes.ScheduleEditorListPage}
                                                        params={router.params}
                                                        store={store}
                                                    />
                                                )}
                                            >
                                                Edit Schedules
                                            </ButtonOutline>
                                        </div>
                                        <AppSectionHeader title="Appointment Duration" />
                                        <InputSelect
                                            label="Select Duration"
                                            name="appointment_length"
                                            optionValueKey="value"
                                            options={getAppointmentDurationOptions({
                                                start: 15,
                                                end: 180,
                                                step: 15
                                            })}
                                            formikProps={formikProps}
                                            autocompleteConfig={{
                                                disableClearable: true
                                            }}
                                        />
                                        <AppSectionHeader title="Scheduling Settings">
                                            {hasDynamicSchedulingToggle && (
                                                <ActionList position="end">
                                                    <InputSwitch
                                                        name="use_dynamic_scheduling"
                                                        labelOn="Dynamic Schedules"
                                                        labelOff="Static Schedules"
                                                        labelPlacement="start"
                                                        formikProps={formikProps}
                                                    />
                                                </ActionList>
                                            )}
                                        </AppSectionHeader>
                                        <InputText
                                            name="slot_length"
                                            label="Time between appointments"
                                            suffix={<>Minutes</>}
                                            type="number"
                                            formikProps={formikProps}
                                            inputProps={{
                                                min: 0
                                            }}
                                        />
                                        <InputSelect
                                            label="Appointments per day"
                                            name="slots_per_day"
                                            optionValueKey="value"
                                            options={[
                                                ...createNumericalInputOptions(9),
                                                { value: 0, label: 'No limit' }
                                            ]}
                                            formikProps={formikProps}
                                            autocompleteConfig={{ disableClearable: true }}
                                        />
                                        <InputSelect
                                            label="Available appointments per time slot"
                                            name="slot_amount_in_same_time"
                                            optionValueKey="value"
                                            options={[
                                                ...createNumericalInputOptions(9),
                                                { value: 0, label: 'No limit' }
                                            ]}
                                            formikProps={formikProps}
                                            autocompleteConfig={{ disableClearable: true }}
                                        />
                                        <InputCheckbox
                                            className={styles['schedule-genie-checkbox']}
                                            name="slot_remove_after_taken"
                                            label="Remove time slot when appointment is made"
                                            formikProps={formikProps}
                                        />
                                        <InputSelect
                                            label="Booked time slot language"
                                            name="slot_taken_status"
                                            optionLabelKey="value"
                                            optionValueKey="value"
                                            options={[{ value: 'reserved' }, { value: 'taken' }, { value: 'gone' }]}
                                            formikProps={formikProps}
                                        />
                                        <InputSelect
                                            label="How soon can a prospect schedule?"
                                            name="period_start_work"
                                            optionValueKey="value"
                                            options={[
                                                { value: 0, label: 'Same day' },
                                                ...periodStartWorkLengths.map((value: number) => ({
                                                    value,
                                                    label: getAppointmentDurationLabels(value)
                                                }))
                                            ]}
                                            formikProps={formikProps}
                                            autocompleteConfig={{ disableClearable: true }}
                                        />
                                        <InputSelect
                                            label="How far in advance?"
                                            name="max_appointment_period"
                                            optionValueKey="value"
                                            options={[...maxAppointmentPeriods]}
                                            formikProps={formikProps}
                                            autocompleteConfig={{ disableClearable: true }}
                                        />
                                        <AppSectionHeader
                                            title="Customer Appointments (per time slot)"
                                            subtitle="Override the global number of allowed appointments per time slot per day."
                                        >
                                            <ActionList position="end">
                                                <InputSwitch
                                                    checked={state.showCustomerAppointmentsPerTimeSlotFields}
                                                    onClick={() => {
                                                        if (state.showCustomerAppointmentsPerTimeSlotFields) {
                                                            [
                                                                'slot_amount_in_same_time_sun',
                                                                'slot_amount_in_same_time_mon',
                                                                'slot_amount_in_same_time_tue',
                                                                'slot_amount_in_same_time_wed',
                                                                'slot_amount_in_same_time_thu',
                                                                'slot_amount_in_same_time_fri',
                                                                'slot_amount_in_same_time_sat'
                                                            ].forEach(daySlotAmountFieldName =>
                                                                formikProps.setFieldValue(daySlotAmountFieldName, 0)
                                                            );
                                                        }

                                                        dispatch({
                                                            name: 'setShowCustomerAppointmentsPerTimeSlotFields',
                                                            payload: !state.showCustomerAppointmentsPerTimeSlotFields
                                                        });
                                                    }}
                                                />
                                            </ActionList>
                                        </AppSectionHeader>
                                        {state.showCustomerAppointmentsPerTimeSlotFields && (
                                            <div className={styles['schedule-genie-custom-time-slots']}>
                                                {[
                                                    'Sunday',
                                                    'Monday',
                                                    'Tuesday',
                                                    'Wednesday',
                                                    'Thursday',
                                                    'Friday',
                                                    'Saturday'
                                                ].map(day => (
                                                    <InputSelect
                                                        optionValueKey="value"
                                                        key={day}
                                                        label={day}
                                                        name={`slot_amount_in_same_time_${day
                                                            .substr(0, 3)
                                                            .toLowerCase()}`}
                                                        options={slotAmountInSameTimeWeekdayOptions}
                                                        formikProps={formikProps}
                                                    />
                                                ))}
                                            </div>
                                        )}

                                        <>
                                            <AppSectionHeader title="Available Appointment Types">
                                                <ActionList position="end">
                                                    <InputSwitch
                                                        name="appointment_types_enabled"
                                                        labelPlacement="start"
                                                        formikProps={formikProps}
                                                        onClick={() => {
                                                            dispatch({
                                                                name: 'setShowAvailableAppointmentTypes',
                                                                payload: !state.showAvailableAppointmentTypes
                                                            });
                                                        }}
                                                    />
                                                </ActionList>
                                            </AppSectionHeader>
                                            {state.showAvailableAppointmentTypes && (
                                                <FieldArray name="selected_appointment_types">
                                                    {arrayHelpers => (
                                                        <div className="two-columns">
                                                            {(formikProps.values
                                                                .selected_appointment_types as SelectedAppointmentType[]).map(
                                                                (appointmentType, i) => {
                                                                    return (
                                                                        <div>
                                                                            <InputCheckbox
                                                                                key={`${appointmentType.name}_${i}`}
                                                                                className={
                                                                                    styles['schedule-genie-checkbox']
                                                                                }
                                                                                name={`selected_appointment_types[${i}].enabled`}
                                                                                value={appointmentType.id}
                                                                                label={appointmentType.name}
                                                                                formikProps={formikProps}
                                                                                checked={appointmentType.enabled}
                                                                            />
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </div>
                                                    )}
                                                </FieldArray>
                                            )}
                                        </>
                                    </div>
                                </div>

                                <div className="field-group-col">
                                    <AppSectionHeader title="Appointment Address" />
                                    {!state.usingOverrideAddress && activeCompany.address1 && (
                                        <>
                                            {addressFromCompanyFormatter(activeCompany) + ' (Company Address)'}
                                            <br />
                                            <ButtonLink
                                                className={styles['schedule-genie-address-button']}
                                                onClick={() =>
                                                    dispatch({ name: 'setUsingOverrideAddress', payload: true })
                                                }
                                            >
                                                Use another address
                                            </ButtonLink>
                                        </>
                                    )}
                                    {state.usingOverrideAddress && (
                                        <>
                                            <InputText
                                                name="address"
                                                label="Appointment Address"
                                                formikProps={formikProps}
                                            />
                                            <ButtonLink
                                                className={styles['schedule-genie-address-button']}
                                                onClick={() => {
                                                    formikProps.setFieldValue('address', null);
                                                    dispatch({ name: 'setUsingOverrideAddress', payload: false });
                                                }}
                                            >
                                                Use company default address
                                            </ButtonLink>
                                        </>
                                    )}
                                    <AppSectionHeader title="App" />
                                    <InputText name="call_to_action" label="Call To Action" formikProps={formikProps} />
                                    <InputText
                                        name="create_success_message"
                                        label="Success Message"
                                        formikProps={formikProps}
                                    />
                                    <InputText
                                        name="confirmation_text"
                                        label="Confirmation Message"
                                        helperText="Message displayed above the appointment time"
                                        formikProps={formikProps}
                                    />
                                    <QuestionEditor
                                        sectionTitle="Qualification Questions"
                                        formikProps={formikProps as FormikProps<JSONWithQuestionBundle>}
                                    />
                                </div>
                            </AppSection>
                            {leadNurturingEnabled && (
                                <AppSection>
                                    <div className="field-group-content">
                                        <div className="field-group-col">
                                            <AppSectionHeader title="Lead Nurturing" />
                                            <div className={styles['schedule-genie-availability-row']}>
                                                <InputSelect
                                                    className={styles['schedule-genie-availability-row-select']}
                                                    label="Select Lead Nurturing Drip Schedule"
                                                    name="drip_schedule_id"
                                                    options={dripSchedules.values.filter(d => {
                                                        return (
                                                            LeadNurtureAppSource.schedule_genie === d.app_source_type
                                                        );
                                                    })}
                                                    optionValueKey="id"
                                                    optionLabelKey="name"
                                                    formikProps={formikProps}
                                                    autocompleteConfig={{
                                                        disableClearable: false
                                                    }}
                                                />

                                                <ButtonOutline
                                                    className={styles['schedule-genie-availability-row-link']}
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.DripScheduleEditorListPage}
                                                            params={router.params}
                                                            store={store}
                                                        />
                                                    )}
                                                >
                                                    Edit Drip Schedules
                                                </ButtonOutline>
                                            </div>
                                        </div>
                                    </div>
                                </AppSection>
                            )}
                            <AppSection>
                                <div className="field-group-content">
                                    <div className="field-group-col">
                                        <div className={styles['field-group-content-section']}>
                                            <AppSectionHeader title="Confirmation Text Message" />
                                            <InputText
                                                name="sms_message_create"
                                                multiline
                                                rows={4}
                                                formikProps={formikProps}
                                            />
                                            <p className={classNames('field-group-helper-text')}>
                                                To add company name, use <span>[companyName]</span>
                                                <br /> To add appointment time, use <span>[appointmentDateTime]</span>
                                            </p>
                                        </div>
                                        <div className={styles['field-group-content-section']}>
                                            <AppSectionHeader title="External Calendar Integration" />
                                            <ButtonOutline
                                                hidden={
                                                    !externalCalendarBundle ||
                                                    externalCalendarBundle.availableCalendars.length === 0
                                                }
                                                className="field-group-button-full-width"
                                                data-lc-trigger-unsaved-changes={true}
                                                onClick={event => openRemoteCalendarModal()}
                                                icon={<Icon name="schedule" />}
                                            >
                                                Link External Calendar
                                            </ButtonOutline>
                                            <ButtonOutline
                                                hidden={
                                                    !externalCalendarBundle ||
                                                    !externalCalendarBundle.currentExternalCalendarLink?.id
                                                }
                                                className="field-group-button-full-width"
                                                data-lc-trigger-unsaved-changes={true}
                                                onClick={event => setUnlinkModalOpen(true)}
                                                icon={<Icon name="schedule" />}
                                            >
                                                Unlink External Calendar:{' '}
                                                {externalCalendarBundle.currentExternalCalendarLink?.name}
                                            </ButtonOutline>
                                            <ButtonOutline
                                                hidden={
                                                    !externalCalendarBundle ||
                                                    externalCalendarBundle.availableAuthTokens.length > 0
                                                }
                                                className="field-group-button-full-width"
                                                data-lc-trigger-unsaved-changes={true}
                                                onClick={event =>
                                                    router.goTo(CompanyRoutes.RemoteAuthList, { companyId }, store)
                                                }
                                                icon={<Icon name="schedule" />}
                                            >
                                                Add Remote Auth To Link External Calendar
                                            </ButtonOutline>
                                        </div>
                                    </div>
                                    <div className="field-group-col">
                                        <div className={styles['field-group-content-section']}>
                                            <AppSectionHeader title="Confirmation Email" />
                                            <ButtonOutline
                                                className="field-group-button-full-width"
                                                data-lc-trigger-unsaved-changes={true}
                                                icon={<Icon name="confirmationEmail" />}
                                                as={buttonProps => (
                                                    <Link
                                                        {...buttonProps}
                                                        view={DefaultRoutes.EmailTemplatesList}
                                                        params={{ companyId }}
                                                        store={store}
                                                    />
                                                )}
                                            >
                                                Edit Email
                                            </ButtonOutline>
                                        </div>
                                        <div className={styles['field-group-content-section']}>
                                            <AppSectionHeader title="Link Attachment" />
                                            <InputSelect
                                                label="File attached to lead notification email"
                                                name="lead_email_attachement"
                                                optionValueKey="value"
                                                options={[
                                                    { value: 'without attachment', label: 'No Attachment' },
                                                    { value: 'Text attachment', label: 'Text Attachment' },
                                                    { value: 'PDF attachment', label: 'PDF Attachment' }
                                                ]}
                                                formikProps={formikProps}
                                            />
                                        </div>
                                        <NotificationSettingsFieldGroup
                                            formikProps={formikProps}
                                            appName="Schedule Genie"
                                            crmBlacklist={[]}
                                        />
                                    </div>
                                </div>
                            </AppSection>

                            <AppFooter className={styles['schedule-genie-footer']} sticky={true}>
                                <ActionList position="end">
                                    {formikProps.dirty && (
                                        <Button
                                            onClick={() => {
                                                dispatch({
                                                    name: 'setUsingOverrideAddress',
                                                    payload: !!calendar?.address
                                                });
                                                dispatch({
                                                    name: 'setShowAvailableAppointmentTypes',
                                                    payload: calendar?.appointment_types_enabled
                                                });
                                                formikProps.resetForm();
                                            }}
                                            className={styles['schedule-genie-footer-cancel']}
                                            disabled={formikProps.isSubmitting}
                                            data-test-id="button_cancel"
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                    <ButtonPrimary
                                        type="submit"
                                        disabled={
                                            !formikProps.dirty || formikProps.isSubmitting || !formikProps.isValid
                                        }
                                        data-test-id="button_save"
                                    >
                                        {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                                    </ButtonPrimary>
                                </ActionList>
                            </AppFooter>
                            <Modal isOpen={unlinkModalOpen} closeButton={false}>
                                <ModalHeader
                                    title={
                                        <>
                                            <Icon name="error" className="color-danger" />
                                            Are you sure you want to unlink this remote calendar from your schedule?
                                        </>
                                    }
                                />
                                <p>This action cannot be undone.</p>
                                <ModalActions>
                                    <ActionList position="end">
                                        <Button
                                            onClick={() => {
                                                setUnlinkModalOpen(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                        <ButtonPrimary
                                            onClick={() => {
                                                unlinkButtonHandler();
                                                setUnlinkModalOpen(false);
                                            }}
                                        >
                                            Confirm
                                        </ButtonPrimary>
                                    </ActionList>
                                </ModalActions>
                            </Modal>
                            <Modal
                                id="editRemoteCalendarModal"
                                isOpen={modal.active === 'editRemoteCalendar'}
                                onAfterClose={() => closeModal()}
                            >
                                <ModalHeader title={modal.data.title || ''} />
                                <Form
                                    initialValues={modal.data.formInitialValues || {}}
                                    onSubmit={handleSubmitRemoteCalendarForm}
                                >
                                    {(formikProps: FormikProps<LinkRemoteCalendarForm>) => {
                                        return (
                                            <>
                                                <InputSelect
                                                    hidden={!hasCalendarsAvailableForLinking}
                                                    name="available_calendar"
                                                    optionLabelKey="display_name"
                                                    placeholder="Select an external calendar…"
                                                    options={calendarsAvailableForLinking}
                                                    formikProps={formikProps}
                                                />
                                                <div hidden={hasCalendarsAvailableForLinking}>
                                                    <Icon name="error" className="color-danger" />
                                                    All available calendars are already linked.
                                                </div>
                                                <ModalActions>
                                                    <div className="flex-spacer" />
                                                    <Button onClick={() => closeModal()}>Cancel</Button>
                                                    <ButtonPrimary
                                                        type="submit"
                                                        hidden={!hasCalendarsAvailableForLinking}
                                                        disabled={!formikProps.values.available_calendar}
                                                    >
                                                        Save
                                                    </ButtonPrimary>
                                                </ModalActions>
                                            </>
                                        );
                                    }}
                                </Form>
                            </Modal>
                        </>
                    );
                }}
            </Form>
        </AppPage>
    );
});
