import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { Title } from '../../../components-v2/shared';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { AssistantNew } from './AssistantNew';
import Store from '../../../store';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import { IAssistant } from './AssistantNew';
import Alert from '../../misc/AlertDismissable';

interface ChatBotAdminState {
    newItem?: IAssistant;
    assistants: Array<any>;
    open: boolean;
}

interface ChatBotAdminProps {
    store?: Store;
}

@inject('store')
@observer
export class ChatBotAdminPage extends React.Component<ChatBotAdminProps, ChatBotAdminState> {
    public state: ChatBotAdminState = {
        assistants: [],
        open: false
    };

    public get injected() {
        return this.props.store!;
    }

    public componentDidMount() {
        const { fetchAutoPilotAssistants } = this.injected;
        fetchAutoPilotAssistants().then(assistants => {
            this.setState({
                assistants: assistants.data
            });
        });
    }

    render() {
        const options = {
            onRowClick: this.handleRowClick
        };
        const { newItem } = this.state;

        return (
            <>
                <Title>Virtual Assistant Administration</Title>
                <div>
                    <Alert show={!!newItem} onHide={this.handleDismissAlert}>
                        New assistant "{newItem?.friendlyName}" was created successfully.
                    </Alert>
                    <AssistantNew />
                    <div>
                        <BootstrapTable data={this.state.assistants} options={options} pagination search striped>
                            <TableHeaderColumn dataField="uniqueName" isKey dataSort>
                                Unique Name
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="friendlyName">Friendly Name</TableHeaderColumn>
                        </BootstrapTable>
                    </div>
                </div>
            </>
        );
    }

    public itemAdded = (item: any) => {
        this.setState({ newItem: item });
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };

    public toggleNewForm = () => {
        this.setState({ open: !this.state.open });
    };

    public handleRowClick = (row: any) => {
        const { router } = this.injected;
        router.goTo(DefaultRoutes.ChatBotEditAssistant, { sid: row.sid });
    };
}
