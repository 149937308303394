import { DateTime } from 'luxon';

import { AccessibleAgency, AccessibleCompany } from '../../types';
import { ClarityChartConfig } from '../../constants';
import { DashboardData } from '../Dashboard/HomeDashboard.helpers';
import { FiltersContext } from '../Filters/Filters.helpers';
import {
    BaseClarityData,
    ClarityDateRange,
    ClarityDisplayMode,
    ClarityFilterExplanation,
    ClarityLoadingStatus,
    ClarityReportsItems,
    ClarityScheduledReportsItems,
    ClarityTabName,
    ClarityUserPreferences
} from './Clarity.types';

export const isoToLocal = (iso: string | DateTime, fmt: string) => {
    try {
        return DateTime.fromISO(iso.toString(), { zone: DateTime.local().zoneName }).toFormat(fmt);
    } catch (e) {
        return 'Unknown';
    }
};

export const formatClarityDateRange = (dr: ClarityDateRange): string => {
    const priorDay = DateTime.fromISO(dr.end.toString()).minus({ days: 1 });
    return `From ${isoToLocal(dr.start, 'DD')} to ${isoToLocal(priorDay, 'DD')}`;
};

export const clarityTabNames = Object.values(ClarityTabName);

export const getClarityTabNameFromIndex = (index: number) => clarityTabNames[index];

export const getClarityTabIndexFromName = (name: ClarityTabName) => clarityTabNames.indexOf(name);

export interface ClarityReducerState {
    loadingStatus: ClarityLoadingStatus;
    displayMode: ClarityDisplayMode;
    agencyId?: number;
    companyId?: number;
    preferences?: ClarityUserPreferences;
    data?: DashboardData;
    clarityData?: BaseClarityData;
    filterExplanation?: ClarityFilterExplanation;
    selectedCharts?: ClarityChartConfig[];
    initialSelectedCharts?: ClarityChartConfig[];

    activeTab: ClarityTabName;

    reportsTab: {
        loading: boolean;
        page: number;
        limit: number;
        items?: ClarityReportsItems;
    };

    scheduledReportsTab: {
        loading: boolean;
        page: number;
        limit: number;
        items?: ClarityScheduledReportsItems;
    };

    createReportModal: {
        open: boolean;
        reportName?: string;
        reportNameError?: string;
    };

    createReportFailedModal: {
        open: boolean;
    };

    editSchedulerModal: {
        open: boolean;
    };
    createSchedulerModal: {
        open: boolean;
    };
}

export const DashboardInitialState: ClarityReducerState = {
    loadingStatus: ClarityLoadingStatus.loading,
    displayMode: 'screen',
    activeTab: ClarityTabName.default,
    scheduledReportsTab: {
        loading: true,
        page: 1,
        limit: 10
    },
    reportsTab: {
        loading: true,
        page: 1,
        limit: 10
    },
    createReportModal: {
        open: false,
        reportName: '',
        reportNameError: ''
    },
    createReportFailedModal: {
        open: false
    },
    editSchedulerModal: {
        open: false
    },
    createSchedulerModal: {
        open: false
    }
};

export const ClarityReducers = {
    setLoadingStatus: (state: ClarityReducerState, loadingStatus: ClarityLoadingStatus) => ({
        ...state,
        loadingStatus
    }),
    setFilterExplanation: (state: ClarityReducerState, filterExplanation: ClarityFilterExplanation) => {
        return { ...state, filterExplanation };
    },
    setPreferences: (state: ClarityReducerState, preferences: ClarityUserPreferences) => {
        return { ...state, preferences, displayMode: preferences.config.displayMode };
    },
    setPreferencesIdWithoutReRender: (state: ClarityReducerState, id: any) => {
        // Note: for some reason id wanted to be any here, even though is a number. We are not destructuring the
        if (state.preferences) {
            state.preferences.id = id;
        }
        return state;
    },
    setPageData: (state: ClarityReducerState, data: DashboardData) => {
        return { ...state, data };
    },
    setClarityData: (state: ClarityReducerState, clarityData: BaseClarityData) => {
        return { ...state, clarityData };
    },
    setSelectedCharts: (state: ClarityReducerState, charts: ClarityChartConfig[]) => {
        return { ...state, selectedCharts: charts };
    },
    setInitialSelectedCharts: (state: ClarityReducerState, charts: ClarityChartConfig[]) => {
        return { ...state, initialSelectedCharts: charts };
    },

    // Global actions
    setActiveTab: (state: ClarityReducerState, activeTab: ClarityTabName) => {
        return { ...state, activeTab };
    },

    // Reports tab actions
    setReportsTabLimit: (state: ClarityReducerState, limit: number) => {
        return { ...state, reportsTab: { ...state.reportsTab, limit } };
    },
    setReportsTabLoading: (state: ClarityReducerState, loading: boolean) => {
        return { ...state, reportsTab: { ...state.reportsTab, loading } };
    },
    setReportsTabPage: (state: ClarityReducerState, page: number) => {
        return { ...state, reportsTab: { ...state.reportsTab, page } };
    },
    setReportsTabItems: (state: ClarityReducerState, items?: ClarityReportsItems) => {
        return { ...state, reportsTab: { ...state.reportsTab, items } };
    },

    // Schedule Reports tab actions
    setScheduledReportsTabLimit: (state: ClarityReducerState, limit: number) => {
        return { ...state, scheduledReportsTab: { ...state.scheduledReportsTab, limit } };
    },
    setScheduledReportsTabLoading: (state: ClarityReducerState, loading: boolean) => {
        return { ...state, scheduledReportsTab: { ...state.scheduledReportsTab, loading } };
    },
    setScheduledReportsTabPage: (state: ClarityReducerState, page: number) => {
        return { ...state, scheduledReportsTab: { ...state.scheduledReportsTab, page } };
    },
    setScheduledReportsTabItems: (state: ClarityReducerState, items?: ClarityScheduledReportsItems) => {
        return { ...state, scheduledReportsTab: { ...state.scheduledReportsTab, items } };
    },

    // Create Report modal actions
    setCreateReportModalOpen: (state: ClarityReducerState, open: boolean) => {
        return { ...state, createReportModal: { ...state.createReportModal, open } };
    },
    setCreateReportModalReportName: (state: ClarityReducerState, reportName?: string) => {
        return { ...state, createReportModal: { ...state.createReportModal, reportName } };
    },
    setCreateReportModalReportNameError: (state: ClarityReducerState, reportNameError?: string) => {
        return { ...state, createReportModal: { ...state.createReportModal, reportNameError } };
    },

    // Create Report Failed modal actions
    setCreateReportFailedModalOpen: (state: ClarityReducerState, open: boolean) => {
        return { ...state, createReportFailedModal: { ...state.createReportFailedModal, open } };
    },

    // Edit Scheduler modal
    setEditSchedulerModalOpen: (state: ClarityReducerState, open: boolean) => {
        return { ...state, editSchedulerModal: { ...state.editSchedulerModal, open } };
    },
    setCreateSchedulerModalOpen: (state: ClarityReducerState, open: boolean) => {
        return { ...state, createSchedulerModal: { ...state.createSchedulerModal, open } };
    }
};

export const getDateStart = (
    context: FiltersContext | undefined,
    agency: AccessibleAgency,
    company: AccessibleCompany,
    accessibleCompanies: AccessibleCompany[]
) => {
    if (!context) {
        return;
    }

    if (agency && context === FiltersContext.AGENCY) {
        const agencyCompanies = accessibleCompanies
            .filter(({ agency_id }) => agency.id === agency_id)
            .sort((a, b) => (a.created_at < b.created_at ? -1 : a.created_at > b.created_at ? 1 : 0));

        return agencyCompanies.length ? DateTime.fromISO(agencyCompanies[0].created_at).toJSDate() : null;
    }

    if (company && context === FiltersContext.COMPANY) {
        return DateTime.fromISO(company.created_at).toJSDate();
    }

    return DateTime.local()
        .minus({ years: 3 })
        .startOf('year')
        .toJSDate();
};

export interface ClarityReducerAction {
    name: keyof typeof ClarityReducers;
    payload?: any;
}

export const ClarityReducer = (state: ClarityReducerState, action: ClarityReducerAction) => {
    if (!ClarityReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: ClarityReducerState = ClarityReducers[action.name](state, action.payload);
    return nextState;
};

export const getArrayItemFromNumber = (n: number, array: any[]) => array[(n % array.length) - 1];

export const clarityPaginationOptions: { label: string; value: number }[] = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '75', value: 75 },
    { label: '100', value: 100 }
];
