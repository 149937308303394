import * as Yup from 'yup';

const baseFloorplanValidationSchema = {
    floor_plan_name: Yup.string().required('Please enter a title.'),
    bedrooms: Yup.number().required('Please enter the number of bedrooms.'),
    bathrooms: Yup.number().required('Please enter the number of bathrooms.'),
    market_rent: Yup.number().required('Please enter the market rent.')
};

export const CreateFloorplanValidationSchema = () => Yup.object().shape(baseFloorplanValidationSchema);

export const UpdateFloorplanValidationSchema = () =>
    Yup.object().shape({
        ...baseFloorplanValidationSchema,
        max_allowed_value_0: Yup.number().required('Please enter a value.'),
        max_allowed_value_1: Yup.number().required('Please enter a value.'),
        max_allowed_value_2: Yup.number().required('Please enter a value.')
    });
