import { FormikProps } from 'formik';
import * as React from 'react';
import { Col, ControlLabel, FormControlProps, FormGroup, HelpBlock } from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import { FieldGroupColSizes } from './FieldGroupColSizes';
import InputMask from 'react-input-mask';
import { ValidationState } from './FieldGroup';
import { COLUMNS_ONE } from '../misc/FieldGroupColSizes';

interface FieldGroupPhoneProps<T> extends FormControlProps, FormikProps<T> {
    id: string;
    labels: Record<string, string | undefined>;
    placeholders?: Record<string, string | undefined>;
    maxLength?: number;
    colSize?: FieldGroupColSizes;
    helpText?: string | React.ReactElement;
    preview?: boolean;
}

export function FieldGroupPhone<T>(props: FieldGroupPhoneProps<T>) {
    const onChangeMaskValue = (event: any) => {
        props.setFieldValue(props.id, event.target.value);
    };

    const getPhoneValue = (v: string): string => {
        if (v.match(/^\+\d+$/)) {
            return v.substr(-10);
        }

        return v;
    };

    const { id } = props;

    const error = props.errors[id];

    let validationState: ValidationState = null;

    if (props.value) {
        validationState = 'success';
    }

    if (error) {
        validationState = 'error';
    }

    let colSize = COLUMNS_ONE;
    if (props.colSize) {
        colSize = props.colSize;
    }

    const label = props.labels[id];
    const placeholder = props.placeholders ? props.placeholders[id] : '';
    const mask = '(999) 999-9999';

    let value = props.values[id] === null || props.values[id] === undefined ? '' : props.values[id];
    value = getPhoneValue(value);

    return (
        <Col {...colSize} className="number-control-form">
            <FormGroup
                controlId={props.id}
                validationState={validationState}
                className={props.className}
                hidden={props.hidden}
            >
                <ControlLabel>{label}</ControlLabel>
                {props.preview ? (
                    <p>{value}</p>
                ) : (
                    <InputMask
                        name={props.id}
                        mask={mask}
                        placeholder={placeholder}
                        hidden={props.hidden}
                        value={value}
                        disabled={props.disabled}
                        onChange={onChangeMaskValue}
                        alwaysShowMask={true}
                    />
                )}
                {error && <HelpBlock>{error}</HelpBlock>}
            </FormGroup>
        </Col>
    );
}
