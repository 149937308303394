import * as moment from 'moment-timezone';
import * as React from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { dateTimeFormats } from '../../../constants';
import { Lead } from '../../../types/Lead';
import { LeadItemType } from './index';
// import { CalendarEvent } from './LeadItem';

export interface CalendarEventSimple {
    id: number;
    title: string;
    start: Date;
    end: Date;
    lead_type: string;
    lead_customer_id: number;
    resource?: any;
}

export interface CalendarEvent {
    id: number;
    title: string;
    start: Date;
    end: Date;
    lead: LeadItem;
}

abstract class LeadItem {
    public abstract name: string;
    public abstract dateFormatClass: string;
    public abstract calendarEventClass: string;

    constructor(protected readonly lead: Lead) {}

    public abstract getEntity(): LeadItemType;

    public get type() {
        return this.lead.lead_type;
    }

    public get customer() {
        return this.lead.customer;
    }

    public get company() {
        return this.lead.company;
    }

    public get data() {
        return this.lead;
    }

    public get id() {
        return this.lead.id;
    }

    public createCalendarEvent(): CalendarEvent {
        const entity = this.getEntity();
        return {
            id: this.data.id,
            title: `${this.customer.first_name} ${this.customer.last_name}`,
            start: new Date(moment(entity.created_at).format(dateTimeFormats.date)),
            end: new Date(moment(entity.created_at).format(dateTimeFormats.date)),
            lead: this
        };
    }

    public renderDetails = () => {
        return <div />;
    };

    public renderModalDetails = (event: CalendarEvent) => {
        return <div />;
    };

    public renderEditForm = () => {
        return <div />;
    };
}

export default LeadItem;
