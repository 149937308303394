import {
    ButtonPrimary,
    Button,
    Icon,
    InputSwitch,
    Form,
    useAsyncEffect,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonOutlinePrimary
} from '@lambdacurry/component-library';
import { FormikHelpers, FormikProps } from 'formik';
import React, { useState } from 'react';
import { ActionList, AppFooter, AppHeader, Grid, GridColumn, useSnackbar } from '../../components-v2/shared';
import {
    activationModalMessages,
    applicationSettingsFormSchema,
    ApplicationSettingsFormValues,
    initialFormValues
} from './ApplicationSettings.helpers';
import './application-settings.scss';
import { observer } from 'mobx-react';
import useStore from '../../store/useStore';
import { PublicRole } from '../../types';
import { isNil, omit, omitBy } from 'lodash';
import { handlePromise } from '../../util/async';
import { StylingSection } from './Sections/StylingSection/StylingSection';
import { PositionSection } from './Sections/PositionSection/PositionSection';
import { PreviewSection } from './Sections/PreviewSection/PreviewSection';
import { OrderSection } from './Sections/OrderSection/OrderSection';
import { apiServerURL } from '../../constants';
import { AdvancedSettings } from './AdvancedSettings/AdvancedSettings';

export const ApplicationSettings = observer(() => {
    const { store } = useStore();
    const { fetchCssPaths } = store;
    const { addSnackbar } = useSnackbar();
    const [showLegacyModal, setShowLegacyModal] = useState<boolean>(false);
    const [showContrastModal, setShowContrastModal] = useState<boolean>(false);
    const [showAdvancedPanel, setShowAdvancedPanel] = useState<boolean>(false);

    const fetchData = async () => {
        try {
            fetchCssPaths();
        } catch (e) {
            console.error(e);
        }
    };

    useAsyncEffect(fetchData, undefined, []);

    const getInitialValues = (): ApplicationSettingsFormValues => {
        const { publicRoles } = store;
        const firstItemId = publicRoles.length > 0 ? publicRoles.getFirstItemId() : 0;
        const publicRole: PublicRole | {} = firstItemId === 0 ? {} : publicRoles.getItem(firstItemId); 
               
        // `omitBy(publicRole, isNil)` removes nullish properties so we can receive the default values defined by `initialFormValues`
        return { ...initialFormValues, ...omitBy(publicRole, isNil) };
    };

    const handleSubmit = async (
        values: ApplicationSettingsFormValues,
        formikHelpers: FormikHelpers<ApplicationSettingsFormValues>
    ) => {
        formikHelpers.setSubmitting(true);

        const omittedValues = ['api_key', 'css_updated_at', 'updated_at', 'updated_by', 'contrast'];
        const [, errors] = await handlePromise(store.publicRoles.update(omit(values, omittedValues)));

        if (errors) {
            addSnackbar(`Failed to save configuration.`, { variant: 'error' });
            formikHelpers.setSubmitting(false);
            return;
        }

        if (values.beta_widget_enabled) {
            // Don't show snackbar if they are disabling the beta widget, navigation redirect will occur
            addSnackbar(`Configuration saved successfully.`, { variant: 'success' });
        }

        // Note: setting the contrast value back to 1 will reset the validation on it
        values.contrast = 1;
        formikHelpers.resetForm({ values });
        formikHelpers.setSubmitting(false);
    };

    const toggleApplicationStatus = async (formikProps: FormikProps<ApplicationSettingsFormValues>): Promise<void> => {
        const { setFieldValue, submitForm, values, validateForm } = formikProps;
        const isDisabled = values.widget_disabled;

        setFieldValue('widget_disabled', !values.widget_disabled);

        // Application is being activated, enable demo mode by default
        if (isDisabled) {
            setFieldValue('widget_hidden', true);
        }

        // Note: setting field value to 0 allows the form to save regardless of the contrast values
        setFieldValue('contrast', 0);
        await validateForm();
        submitForm();
        setShowLegacyModal(false);
    };

    const handleContrastOverride = async (formikProps: FormikProps<ApplicationSettingsFormValues>): Promise<void> => {
        const { setFieldValue, submitForm, validateForm } = formikProps;

        // Note: setting field value to 0 allows the form to save regardless of the contrast values
        setFieldValue('contrast', 0);
        await validateForm();
        submitForm();
        setShowContrastModal(false);
    };

    return (
        <Form
            confirmUnsavedChanges
            initialValues={getInitialValues()}
            validationSchema={applicationSettingsFormSchema}
            onSubmit={handleSubmit}
            validateOnMount
        >
            {(formikProps: FormikProps<ApplicationSettingsFormValues>) => {
                const { dirty, isSubmitting, resetForm, values } = formikProps;
                const isButtonDisabled = !dirty || isSubmitting;

                return (
                    <>
                        <AppHeader variant="simple" title="App Customization">
                            <ActionList position="end">
                                <InputSwitch
                                    labelOn="Active"
                                    labelOff="Inactive"
                                    labelPlacement="start"
                                    checked={!values.widget_disabled}
                                    onChange={() => setShowLegacyModal(true)}
                                />
                                <ButtonOutlinePrimary
                                    icon={<Icon name="settings" />}
                                    iconPlacement="start"
                                    aria-label="Advanced Settings"
                                    className="advanced-settings"
                                    onClick={() => setShowAdvancedPanel(true)}
                                >
                                    Advanced
                                </ButtonOutlinePrimary>
                            </ActionList>
                        </AppHeader>

                        <Grid className="application-settings-content">
                            <GridColumn lg={6}>
                                <StylingSection
                                    formikProps={formikProps}
                                    formIsDisabled={formikProps.isSubmitting || store.isCompanyLightRole}
                                />
                                <PositionSection
                                    formikProps={formikProps}
                                    formIsDisabled={formikProps.isSubmitting || store.isCompanyLightRole}
                                />
                            </GridColumn>
                            <GridColumn lg={6}>
                                <PreviewSection
                                    formikProps={formikProps}
                                    hostname={
                                        process.env.REACT_APP_ENV === 'local' ? process.env.REACT_APP_WIDGET_ENV_LC : apiServerURL
                                    }
                                    formIsDisabled={formikProps.isSubmitting || store.isCompanyRole}
                                />
                                <OrderSection formikProps={formikProps} />
                            </GridColumn>
                        </Grid>

                        <AppFooter variant="full-width">
                            <ActionList position="end">
                                <Button disabled={isButtonDisabled} onClick={() => resetForm()}>
                                    Cancel
                                </Button>
                                {!formikProps.errors.contrast ? (
                                    <ButtonPrimary type="submit" disabled={isButtonDisabled}>
                                        Save
                                    </ButtonPrimary>
                                ) : (
                                    <ButtonPrimary
                                        onClick={() => setShowContrastModal(true)}
                                        disabled={isButtonDisabled}
                                    >
                                        Save
                                    </ButtonPrimary>
                                )}
                            </ActionList>
                        </AppFooter>

                        {/* Revert to Legacy Modal */}
                        <Modal isOpen={showLegacyModal} closeButton={false}>
                            <ModalHeader title={`${values.widget_disabled ? 'Activate' : 'Deactivate'} Application`} />
                            <p>{activationModalMessages[values.widget_disabled ? 'activate' : 'deactivate']}</p>
                            <ModalActions>
                                <ActionList position="end">
                                    <Button onClick={() => setShowLegacyModal(false)}>No</Button>
                                    <ButtonPrimary onClick={() => toggleApplicationStatus(formikProps)}>
                                        Yes
                                    </ButtonPrimary>
                                </ActionList>
                            </ModalActions>
                        </Modal>

                        {/* Contrast Error Modal */}
                        <Modal isOpen={showContrastModal} closeButton={false}>
                            <ModalHeader
                                title={
                                    <>
                                        <Icon name="error" className="icon color-danger" />
                                        Accessibility Warning
                                    </>
                                }
                            />
                            <p>
                                The brand, font, and icon colors selected, with the current color theme, do not meet web
                                accessibility standards and may be difficult for some people to see.
                            </p>
                            <p>We recommend changing the colors to be more legible.</p>
                            <ModalActions>
                                <ActionList position="end">
                                    <Button onClick={() => setShowContrastModal(false)}>Change Colors</Button>
                                    <ButtonPrimary onClick={() => handleContrastOverride(formikProps)}>
                                        Proceed Anyway
                                    </ButtonPrimary>
                                </ActionList>
                            </ModalActions>
                        </Modal>

                        <AdvancedSettings
                            isOpen={showAdvancedPanel}
                            setOpen={setShowAdvancedPanel}
                            formikProps={formikProps}
                        />
                    </>
                );
            }}
        </Form>
    );
});
