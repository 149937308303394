import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import { ButtonToolbar, Button, Panel, ListGroup, ListGroupItem } from 'react-bootstrap';
import { dateTimeFormats } from '../../../../constants/';
import { LeadReminder } from '../../../../types';

interface LeadReminderListProps {
    id?: number;
    activateItem?: (reminder: LeadReminder) => void;
    activeReminders: any[];
    passiveReminders: any[];
}

@inject('store')
@observer
export default class LeadReminderList extends React.Component<LeadReminderListProps> {
    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>My Reminders</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body className="hold-box-list" collapsible>
                    <ListGroup componentClass="ul" className="list-group__holder">
                        {this.props.activeReminders.map((row: LeadReminder, i: number) => {
                            const id = row.id;
                            const disabled = row.id === this.props.id;

                            return (
                                <ListGroupItem key={i} disabled={disabled}>
                                    <div onClick={this.handleClick(id, row)}>
                                        <div>{row.title}</div>
                                        <span className="date-text">
                                            {moment(row.remind_at).unix() <= moment().unix() ? (
                                                <ButtonToolbar>
                                                    <Button className="red-buttom-overdue" bsStyle="danger">
                                                        OVERDUE
                                                    </Button>
                                                </ButtonToolbar>
                                            ) : null}
                                            {moment(row.remind_at).format(dateTimeFormats.reminderDate)}
                                        </span>
                                    </div>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                    {this.props.passiveReminders.length ? (
                        <>
                            <div className="title-list-block">
                                <h5>Completed Reminders</h5>
                            </div>
                            <ListGroup componentClass="ul" className="list-group__holder">
                                {this.props.passiveReminders.map((row: LeadReminder, i: number) => {
                                    const id = row.id;
                                    const disabled = row.id === this.props.id;

                                    return (
                                        <ListGroupItem key={i} disabled={disabled}>
                                            <div onClick={this.handleClick(id, row)}>
                                                <div className="text-decor">{row.title}</div>
                                                <span className="date-text">
                                                    {moment(row.remind_at).unix() <= moment().unix() ? (
                                                        <ButtonToolbar>
                                                            <Button className="red-buttom-overdue" bsStyle="danger">
                                                                OVERDUE
                                                            </Button>
                                                        </ButtonToolbar>
                                                    ) : null}
                                                    {moment(row.remind_at).format(dateTimeFormats.reminderDate)}
                                                </span>
                                            </div>
                                        </ListGroupItem>
                                    );
                                })}
                            </ListGroup>
                        </>
                    ) : null}
                </Panel.Body>
            </Panel>
        );
    }

    public handleClick = (id: any, row: LeadReminder) => () => {
        if (this.props.activateItem) {
            this.props.activateItem(row);
        }
    };
}
