import React from 'react';
import { observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { ActionList, AppPage, AppHeader, useSnackbar } from '../../components-v2/shared';
import { EditQuestionForm } from './components/EditQuestionForm';
import { Question } from '../../types/Question';
import useStore from '../../store/useStore';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { ToggleQuestionSwitch } from './components/ToggleQuestionSwitch';

export const QuestionEdit = observer(() => {
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { questions, router, fetchChatbotQuestions } = store;
    const { questionId, companyId } = router.params;
    const question = questions.getItem(parseInt(questionId, 10));

    const fetchRequiredData = async () => {
        if (!companyId) {
            return;
        }

        await fetchChatbotQuestions(parseInt(questionId, 10));
    };

    useAsyncEffect(fetchRequiredData);

    const initialValues = () => (!question ? {} : question);

    const handleSubmit = async (values: Question, actions: FormikHelpers<Question>) => {
        try {
            await questions.update(values);
            addSnackbar(`Question "${values.name}" has been updated.`, { variant: 'success' });
        } catch (error) {
            actions.setErrors(error.response.data);
        } finally {
            actions.setSubmitting(false);
        }
    };

    return (
        <AppPage loading={!question}>
            {question && (
                <>
                    <AppHeader
                        title="Virtual Assistant"
                        icon={{
                            name: 'chat',
                            color: 'green',
                            img: 'https://storage.googleapis.com/static.clxp.us/tcc/icons/claire-icon.png',
                            height: 48
                        }}
                        onBack={() => router.goTo(DefaultRoutes.ChatBotTopicList, { companyId }, store)}
                    >
                        <ActionList position="end">
                            <ToggleQuestionSwitch question={question} questions={questions} />
                        </ActionList>
                    </AppHeader>
                    <EditQuestionForm
                        initialValues={initialValues() as Question}
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                    />
                </>
            )}
        </AppPage>
    );
});
