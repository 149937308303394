import { InputCheckbox, InputSelect, InputText, useAsyncEffect } from '@lambdacurry/component-library';
import { FormikProps } from 'formik';
import { observer } from 'mobx-react';
import React from 'react';
import { AppSectionHeader } from '../../components-v2/shared';
import useStore from '../../store/useStore';
import { CompanyDetailsFormValues } from './CompanyDetailsForm.helpers';

export const CompanyDetailsNotificationSettings: React.FC<{
    formikProps: FormikProps<CompanyDetailsFormValues>;
}> = observer(({ formikProps }) => {
    const { store } = useStore();
    const { fetchCrmConfigOptions, crmConfigOptionList } = store;

    const fetchData = async () => {
        fetchCrmConfigOptions();
    };

    useAsyncEffect(fetchData);

    return (
        <>
            <AppSectionHeader title="Notification Settings" />
            <InputCheckbox
                name="weekly_notification"
                label="Receive Weekly Lead Summary"
                formikProps={formikProps}
                className="notification-checkbox"
            />
            <InputCheckbox
                name="hide_alerts"
                label="Disable dashboard notifications"
                formikProps={formikProps}
                className="notification-checkbox"
            />
            {formikProps.values['weekly_notification'] && (
                <InputText
                    label="Emails for Weekly Lead Summary"
                    name="weekly_lead_notification_email"
                    formikProps={formikProps}
                />
            )}
            <InputText
                label="Emails for Lead Notifications"
                name="lead_notification_primary_emails"
                formikProps={formikProps}
            />
            <InputText
                label="CC Emails for Lead Notifications"
                name="lead_notification_cc_emails"
                formikProps={formikProps}
            />
            <InputText label="Reply-to Email" name="reply_to_email" formikProps={formikProps} />
            <InputSelect
                label="CRM Config"
                name="crm_config_id"
                optionLabelKey="name"
                optionValueKey="id"
                options={[{ id: null, name: 'None' }, ...(crmConfigOptionList || [])]}
                autocompleteConfig={{ filterOptions: x => x }}
                formikProps={formikProps}
            />
        </>
    );
});
