import React, { Reducer, useReducer } from 'react';
import { observer } from 'mobx-react';
import {
    Button,
    ButtonPrimary,
    InputText,
    AppPage,
    Title,
    AppSection,
    AppSectionHeader,
    useSnackbar
} from '../../../components-v2/shared';
import { StripeCardInput, StripeFormWrapper } from '@lambdacurry/component-library';
import * as Yup from 'yup';
import './agency-billing.scss';
import useStore from '../../../store/useStore';
import { handlePromise, useAsyncEffect } from '../../../util/async';
import { FormikHelpers } from 'formik';
import {
    BillingCharge,
    BillingHistory,
    AgencyCustomerInfoResponse,
    BillSummary,
    BillingCompanies,
    BillingSummary,
    PaymentMethodSummary
} from '../../Billing';
import { fieldSchemas } from '../../../util/validations/formSchemas';

export type AgencyBillingModals = 'none';

enum PaymentMethodStatus {
    Loading = 'loading',
    AddingPaymentMethod = 'adding-payment-method',
    ShowingCustomerData = 'showing-customer-data'
}
export interface AgencyBillingReducerState {
    loading: boolean;
    billingHistory: Array<BillingCharge>;
    billSummary: BillSummary;
    customerData: { source: any; id: 'string'; email: 'string'; name: 'string' } | null;
    paymentMethodStatus: PaymentMethodStatus;
}

export interface AgencyBillingReducerAction {
    name: keyof typeof AgencyBillingReducers;
    payload?: any;
}

const AgencyBillingReducers = {
    setBillingHistory: (
        state: AgencyBillingReducerState,
        billingHistory: AgencyBillingReducerState['billingHistory']
    ) => ({
        ...state,
        billingHistory
    }),
    setBillSummary: (state: AgencyBillingReducerState, billSummary: AgencyBillingReducerState['billSummary']) => ({
        ...state,
        billSummary
    }),
    setCustomerData: (state: AgencyBillingReducerState, customerData: AgencyBillingReducerState['customerData']) => ({
        ...state,
        customerData,
        paymentMethodStatus: PaymentMethodStatus.ShowingCustomerData
    }),
    setLoading: (state: AgencyBillingReducerState, loading: AgencyBillingReducerState['loading']) => ({
        ...state,
        loading
    }),
    setPaymentMethodStatus: (state: AgencyBillingReducerState, paymentMethodStatus: any) => ({
        ...state,
        paymentMethodStatus
    })
};

export const AgencyBillingReducer = (state: AgencyBillingReducerState, action: AgencyBillingReducerAction) => {
    if (!AgencyBillingReducers[action.name]) {
        throw new Error(`reducer ${action.name} not defined`);
    }

    const nextState: AgencyBillingReducerState = AgencyBillingReducers[action.name](state, action.payload);
    return nextState;
};

export const AgencyBilling: React.FC<{}> = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { Api, router } = store;
    const { agencyId } = router.params;

    const [state, dispatch] = useReducer<Reducer<AgencyBillingReducerState, AgencyBillingReducerAction>>(
        AgencyBillingReducer,
        {
            billingHistory: [],
            billSummary: {
                id: 0,
                monthlyEstimate: null,
                billableCompaniesCount: null,
                billableAppsCount: null,
                companies: []
            },
            loading: false,
            customerData: null,
            paymentMethodStatus: PaymentMethodStatus.Loading
        }
    );
    const { loading, billSummary, billingHistory } = state;

    const fetchBillSummary = async () => {
        const [response, error] = await handlePromise<{ data: any }>(
            Api.client.get(`/agency-billing/${agencyId}/price-estimate`)
        );
        if (!response?.data || error) {
            addSnackbar(`Failed to get bill summary.`, {
                variant: 'error'
            });
            return;
        }
        dispatch({ name: 'setBillSummary', payload: response.data.estimate });
    };

    const fetchBillingHistory = async () => {
        const [response, error] = await handlePromise(Api.client.get(`/agency-billing/${agencyId}/charges`));
        if (!response?.data || error) {
            addSnackbar(`Failed to get billing history.`, {
                variant: 'error'
            });
            return;
        }
        dispatch({ name: 'setBillingHistory', payload: response.data.charges });
    };

    const fetchCustomerData = async () => {
        dispatch({ name: 'setPaymentMethodStatus', payload: PaymentMethodStatus.Loading });

        const [response, error] = await handlePromise<{ data: AgencyCustomerInfoResponse }>(
            Api.client.get(`/agency-billing/${agencyId}/customer-info`)
        );
        if (!response?.data || error) {
            addSnackbar(`Failed to get customer info.`, {
                variant: 'error'
            });
            return;
        }
        dispatch({ name: 'setCustomerData', payload: response.data.customer ? response.data.customer : null });
    };

    const fetchData = async () => {
        await fetchBillingHistory();
        await fetchBillSummary();
        await fetchCustomerData();
    };
    useAsyncEffect(fetchData);

    const handleSavePaymentMethod = async (
        { values: { email }, token: { id: source_token } }: { values: { email: string }; token: any },
        { setSubmitting, setStatus }: FormikHelpers<{}>
    ) => {
        const [response, error] = await handlePromise<{ data: AgencyCustomerInfoResponse }>(
            Api.client.patch(`agency-billing/${agencyId}/customer-info`, {
                email,
                source_token
            })
        );

        if (!response?.data || error) {
            if (error.response.data) {
                // Catch card-specific errors from Stripe
                // See: https://stripe.com/docs/testing#cards-responses
                if (typeof error.response.data === 'string') {
                    return addSnackbar(`Failed to save payment method. ${error.response.data}`, { variant: 'error' });
                }

                return setStatus({ serverErrors: error.response.data });
            }

            return addSnackbar(
                `Failed to save payment method. Please double-check the form and try submitting again.`,
                {
                    variant: 'error'
                }
            );
        }

        dispatch({ name: 'setCustomerData', payload: response.data.customer ? response.data.customer : null });
        setSubmitting(false);
    };

    return (
        <div className="agency-billing">
            <AppPage loading={loading}>
                <AppSection>
                    <AppSectionHeader title="Bill Summary" />
                    <BillingSummary billSummary={billSummary} />
                </AppSection>

                <AppSection>
                    <AppSectionHeader title="Active Companies" />
                    <BillingCompanies companies={billSummary.companies} />
                </AppSection>

                <AppSection>
                    <AppSectionHeader
                        title="Payment Method"
                        subtitle="Manage your saved payment methods for purchases and renewals. "
                    >
                        {!state.customerData?.source &&
                            state.paymentMethodStatus !== PaymentMethodStatus.AddingPaymentMethod && (
                                <ButtonPrimary
                                    onClick={() => {
                                        dispatch({
                                            name: 'setPaymentMethodStatus',
                                            payload: PaymentMethodStatus.AddingPaymentMethod
                                        });
                                    }}
                                >
                                    Add Payment Method
                                </ButtonPrimary>
                            )}
                    </AppSectionHeader>
                    {state.paymentMethodStatus === PaymentMethodStatus.AddingPaymentMethod && (
                        <StripeFormWrapper
                            validationSchema={Yup.object().shape({
                                email: fieldSchemas.email.standard
                            })}
                            STRIPE_KEY={process.env.REACT_APP_STRIPE_KEY}
                            initialValues={{ 'stripe-input': false, email: '' }}
                            onSubmit={handleSavePaymentMethod}
                            onError={() =>
                                addSnackbar(`Failed to save payment method.`, {
                                    variant: 'error'
                                })
                            }
                        >
                            {formikProps => (
                                <>
                                    <StripeCardInput
                                        name="stripe-input"
                                        acceptedBrands={['amex', 'mastercard', 'visa']}
                                    />
                                    <InputText
                                        label="Billing Email"
                                        name="email"
                                        type="email"
                                        formikProps={formikProps}
                                    />
                                    <div className="agency-billing-actions">
                                        <Button
                                            disabled={formikProps.isSubmitting}
                                            onClick={() =>
                                                state.customerData?.source
                                                    ? dispatch({
                                                          name: 'setPaymentMethodStatus',
                                                          payload: PaymentMethodStatus.ShowingCustomerData
                                                      })
                                                    : dispatch({
                                                          name: 'setPaymentMethodStatus',
                                                          payload: PaymentMethodStatus.Loading
                                                      })
                                            }
                                        >
                                            Cancel
                                        </Button>
                                        <ButtonPrimary type="submit" disabled={formikProps.isSubmitting}>
                                            {formikProps.isSubmitting ? 'Saving' : 'Save Card'}
                                        </ButtonPrimary>
                                    </div>
                                </>
                            )}
                        </StripeFormWrapper>
                    )}
                    {state.paymentMethodStatus === PaymentMethodStatus.ShowingCustomerData &&
                        state.customerData?.source && (
                            <PaymentMethodSummary card={state.customerData.source} dispatch={dispatch} />
                        )}

                    {state.paymentMethodStatus !== PaymentMethodStatus.AddingPaymentMethod &&
                        state.paymentMethodStatus !== PaymentMethodStatus.ShowingCustomerData && (
                            <div className="agency-billing-add-payment-method-empty" />
                        )}
                </AppSection>

                {state.billingHistory.length > 0 && (
                    <AppSection>
                        <Title>Billing History</Title>
                        <BillingHistory charges={billingHistory} />
                    </AppSection>
                )}
            </AppPage>
        </div>
    );
});
