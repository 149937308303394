import  React from 'react';
import { FormikProps, FormikHelpers } from 'formik';
import { Form, InputText, ButtonPrimary} from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';
import { Header } from '../../../components-v2/shared';

export interface IAssistant {
    uniqueName: string;
    friendlyName: string;
}

export const AssistantNew: React.FC = () => {

    const { store } = useStore();
    const { createAutoPilotAssistant } = store;

    const handleSubmit = (values: any, helpers: FormikHelpers<IAssistant>) => {
        return createAutoPilotAssistant(values.uniqueName, values.friendlyName).then(data => {
            helpers.resetForm();
            helpers.setSubmitting(false);
        });
    }

    return (
        <Form className="new-assistant-form" initialValues={{uniqueName: '', friendlyName: ''}} onSubmit={handleSubmit}>
            {(formikProps: FormikProps<IAssistant>) => (
                <>
                    <Header>New Assistant</Header>
                    <div className="new-assistant-form-row">
                        <InputText label="Unique Name" name="uniqueName" formikProps={formikProps} />
                        <InputText label="Friendly Name" name="friendlyName" formikProps={formikProps} />
                    </div>
                    <div className="new-assistant-form-actions">
                      <ButtonPrimary type="submit">{formikProps.isSubmitting ? 'Saving' : 'Save Assistant'}</ButtonPrimary>
                    </div>
                </>
            )}
        </Form>
    );
}
