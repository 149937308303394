import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { AppHeader, AppPage } from '../../components-v2/shared';
import ChartRenderer from 'react-google-charts';
import useStore from '../../store/useStore';
import styles from './drip-schedule-reports.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { ClarityStatChart } from '../Clarity/ClarityStatChart';

export const DripScheduleReportPage = observer(() => {
    const { store } = useStore();
    const { router, Api } = store;
    const { companyId, scheduleId } = router.params;
    const [reportData, setReportData] = useState<any>();

    const fetchSchedule = async () => {
        const [response, err] = await handlePromise<{ data: any }>(
            Api.client.get(`company/${companyId}/drip-schedules/${scheduleId}/reports`)
        );

        if (err || !response) {
            // TODO: handle error
            return;
        }
        setReportData({ name: response.data.name, data: response.data.data });
    };

    const fetchData = async () => {
        await fetchSchedule();
    };

    useAsyncEffect(fetchData);

    const summarizeReportData = (data: any[]): { label: string; val: number }[] => {
        if (!data) {
            return [];
        }

        const result = data.reduce((acc: any[], curr: any[]) => {
            curr.forEach((b, i) => {
                acc[i] = (acc[i] || 0) + b;
            });
            return acc;
        }, []);
        return [
            { label: 'Emails Sent', val: result[1] },
            { label: 'Bounced', val: result[2] },
            { label: 'Opened', val: result[3] },
            { label: 'Clicked', val: result[4] },
            { label: 'Unsubscribed', val: result[5] }
        ];
    };

    const stackData = (data?: any[]) => {
        if (!data) {
            return [];
        }
        return data.map(row => {
            return [row[0], row[1] - row[2], row[2], row[3], row[4], row[5]];
        });
    };

    return (
        <AppPage loading={!reportData?.name} className={styles['drip-schedule-reports']}>
            <AppHeader
                title={`Lead Nurturing Drip Campaign Reports: ${reportData?.name}`}
                onBack={() => router.goTo(DefaultRoutes.DripScheduleEditorListPage, { companyId }, store)}
            ></AppHeader>
            <ClarityStatChart stats={summarizeReportData(reportData?.data)} />
            <div style={{ width: '100%' }}>
                <ChartRenderer
                    chartType="ComboChart"
                    width="100%"
                    height="600px"
                    columns={[
                        { type: 'string', label: 'Day' },
                        { type: 'number', label: 'Emails Delivered' },
                        { type: 'number', label: 'Bounced' },
                        { type: 'number', label: 'Opened' },
                        { type: 'number', label: 'Clicked' },
                        { type: 'number', label: 'Unsubscribed' }
                    ]}
                    rows={stackData(reportData?.data)}
                    options={{
                        div: false,
                        backgroundColor: 'none',
                        legend: { position: 'bottom' },
                        animation: {
                            duration: 2000,
                            easing: 'out',
                            startup: true
                        },
                        isStacked: true,
                        seriesType: 'bars',
                        series: {
                            0: { type: 'bar', targetAxisIndex: 0, baseline: 0 },
                            1: { type: 'bar', targetAxisIndex: 0, baseline: 0 },
                            2: { type: 'line', targetAxisIndex: 0, baseline: 0 },
                            3: { type: 'line', targetAxisIndex: 0, baseline: 0 },
                            4: { type: 'line', targetAxisIndex: 0, baseline: 0 }
                        }
                    }}
                />
            </div>
        </AppPage>
    );
});
