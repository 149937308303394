import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { Alert } from 'react-bootstrap';
import { InjectedProps } from '../../types';

export interface FormAlertsData {
    type: string;
    message: string;
}

@inject('store')
@observer
export class FormAlerts extends React.Component {
    public get injected() {
        return this.props as InjectedProps;
    }

    public render() {
        const { formAlerts, removeFormAlert } = this.injected.store;

        if (!formAlerts.length) {
            return null;
        }

        return (
            <div className="m-3">
                <div className="alert-notification-group">
                    {formAlerts.map((alert, index) => (
                        <div key={index} className={`msg-${alert.type}`}>
                            <a className="close" onClick={removeFormAlert}>
                                &times;
                            </a>
                            <Alert bsStyle={alert.type}>{alert.message}</Alert>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
