import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { InjectedProps } from '../../../types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Title, Header } from '../../../components-v2/shared';
import { TaskNew } from './TaskNew';
import { ITask } from './TaskForm';
import DefaultRoutes from '../../../routes/DefaultRoutes';
import Alert from '../../misc/AlertDismissable';
import { ButtonGroup, Button, ButtonPrimary, ButtonWarning } from '@lambdacurry/component-library';

interface AssistantEditState {
    newItem?: ITask;
    deletedAssistant?: any;
    modelBuilt?: any;
    tasks: Array<any>;
    open: boolean;
}

@observer
@inject('store')
export class AssistantEdit extends React.Component<{}, AssistantEditState> {
    public state: AssistantEditState = {
        tasks: [],
        open: false,
    };

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        const { fetchAutoPilotTasks } = this.injected.store;
        fetchAutoPilotTasks(this.injected.store.router.params.sid).then(data => {
            this.setState({
                tasks: data.data
            });
        });
    }

    render() {
        const options = {
            onRowClick: this.handleRowClick
        };
        const { newItem, deletedAssistant, modelBuilt } = this.state;

        return (
            <>
                <Title>Assistant Details</Title>
                <div>
                    <Alert show={!!newItem} onHide={this.handleDismissAlert}>
                        New task "{newItem?.friendlyName}" was created successfully.
                    </Alert>
                    <Alert show={!!deletedAssistant} onHide={this.handleDismissAlert}>
                        Assistant "{deletedAssistant?.friendlyName}" was deleted successfully.
                    </Alert>
                    <Alert show={!!modelBuilt} onHide={this.handleDismissAlert}>
                        Model build started.
                    </Alert>
                    <Header>New Task:</Header>
                    <TaskNew assistantSid={this.injected.store.router.params.sid} handleClose={this.itemAdded} />
                    <BootstrapTable data={this.state.tasks} options={options} pagination search striped>
                        <TableHeaderColumn dataField="uniqueName" isKey dataSort>
                            Task Unique Name
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="friendlyName">Task Friendly Name</TableHeaderColumn>
                    </BootstrapTable>
                    <ButtonGroup>
                        <Button onClick={this.handleBack}>
                            Back
                        </Button>
                        <ButtonWarning onClick={this.deleteAssistant}>
                            Delete Assistant
                        </ButtonWarning>
                        <ButtonPrimary onClick={this.createModelBuild}>
                            Build Assistant Model
                        </ButtonPrimary>
                        <Button onClick={this.testAssistant}>
                            Test Assistant
                        </Button>
                    </ButtonGroup>
                </div>
            </>
        );
    }

    public handleRowClick = (row: any) => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotEditTask, {assistantSid: this.injected.store.router.params.sid, taskSid: row.sid});
    }

    public itemAdded = (item: any) => {
        this.setState({ newItem: item });
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined, deletedAssistant: undefined, modelBuilt: undefined });
    };

    public handleBack = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotAdmin);
    }

    public deleteAssistant = async () => {
        const { deleteAutoPilotAssistant } = this.injected.store;
        deleteAutoPilotAssistant(this.injected.store.router.params.sid).then(data => {
            this.setState({
                deletedAssistant: data
            });
        });
    }

    public createModelBuild = async () => {
        const { createAutoPilotModelBuild } = this.injected.store;
        createAutoPilotModelBuild(this.injected.store.router.params.sid).then(data => {
            this.setState({
                modelBuilt: data
            });
        });
    }

    public testAssistant = () => {
        const { router } = this.injected.store;
        router.goTo(DefaultRoutes.ChatBotTestAssistant, {assistantSid: this.injected.store.router.params.sid});
    }
}