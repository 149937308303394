import assert from 'assert';
import moment from 'moment';
import { dateTimeFormats } from './dateFormat';

const requiredVariables = ['REACT_APP_API_SERVER_URL', 'REACT_APP_API_SERVER_WS_URL', 'REACT_APP_BASENAME'];

if (process.env.NODE_ENV !== 'test') {
    requiredVariables.forEach(variable => {
        assert(process.env.hasOwnProperty(variable), `${variable} must be defined in the root .env file`);
    });
}

export const apiServerURL = process.env.REACT_APP_API_SERVER_URL;
export const firebaseAppId = process.env.REACT_APP_FIREBASE_APP_ID;
export const firebaseMeasurementId = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
export const firebaseApiKey = process.env.REACT_APP_FIREBASE_API_KEY;
export const firebaseAuthDomain = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const firebaseProjectId = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const firebaseStorageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;
export const firebaseMessagingSenderId = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID;

export const apiServerWSUrl = process.env.REACT_APP_API_SERVER_WS_URL;
const defaultApiWSTimeCheckAlerts = 60000;
export const apiWSTimeCheckAlerts = process.env.REACT_APP_API_WS_CHECK_TIME_PERIOD
    ? parseInt(process.env.REACT_APP_API_WS_CHECK_TIME_PERIOD, 10)
    : defaultApiWSTimeCheckAlerts;
export const mailFromAddress = process.env.REACT_APP_MAIL_FROM_ADDRESS;
export const dirBasename = process.env.REACT_APP_BASENAME;
export const versionNumber = process.env.REACT_APP_VERSION_NUMBER || '';
export const versionRelease =
    moment(process.env.REACT_APP_VERSION_DATE, dateTimeFormats.dateApi).format(dateTimeFormats.dateDefault) || '';
export const pushDebug = process.env.REACT_APP_PUSH_DEBUG ? !!Number(process.env.REACT_APP_PUSH_DEBUG) : false;
