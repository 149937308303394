import * as React from 'react';
import { Title } from '../../../components-v2/shared';
import { inject, observer } from 'mobx-react';
import { FormikHelpers } from 'formik';
import { SampleForm } from './SampleForm';
import Store from '../../../store';

interface SampleNewProps {
    handleClose: (item:any) => any;
    store?: Store;
    sid: string;
    assistantSid: string;
}

@inject('store')
@observer
export class SampleNew extends React.Component<SampleNewProps> {
    public get injected() {
        return this.props.store!;
    }

    public render() {
        return (
            <>
                <Title>Create Sample</Title>
                <SampleForm handleSubmit={this.handleSubmit}/>
            </>
        );
    }

    public handleSubmit = (values: any, actions: FormikHelpers<any>) => {
        const { createAutoPilotSample } = this.injected;
        const { handleClose } = this.props;
        
        return createAutoPilotSample(this.props.assistantSid, this.props.sid, values.taggedText).then(data => {
            actions.resetForm();
            handleClose(values);
            actions.setSubmitting(false);
        });
    }
}