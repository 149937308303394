import React, { useReducer } from 'react';
import { FormikHelpers } from 'formik';
import { useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../store/useStore';
import { AppPage, AppHeader, useSnackbar } from '../../components-v2/shared';
import { IncomeCalculatorForm } from './IncomeCalculatorForm';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { emptyIncomeCalculator } from '../../store/initial';
import { incomeCalculatorInitialState, incomeCalculatorReducer, setLoading } from './IncomeCalculator.helpers';

import styles from './IncomeCalculator.module.scss';
import { IncomeCalculator } from '../../types/IncomeCalculator';
import { IncomeCalculatorEdit } from './IncomeCalculatorEdit';

export const IncomeCalculatorNew = () => {
    const [state, dispatch] = useReducer(incomeCalculatorReducer, incomeCalculatorInitialState);
    const { addSnackbar } = useSnackbar();
    const { store } = useStore();
    const { router, fetchIncomeCalculators, incomeCalculators } = store;
    const { companyId } = router.params;

    const fetchData = async () => {
        await fetchIncomeCalculators();
        setLoading(dispatch, false);
    };
    useAsyncEffect(fetchData);

    if (!companyId) {
        router.goTo(DefaultRoutes.Home, {}, store);
        return null;
    }

    if (incomeCalculators.length > 0) {
        return <IncomeCalculatorEdit incomeCalculatorId={incomeCalculators.getFirstItemId()} />;
    }

    const handleSubmit = async (values: IncomeCalculator, actions: FormikHelpers<IncomeCalculator>) => {
        try {
            setLoading(dispatch, true);
            const response = await incomeCalculators.create(values);
            if(response) setLoading(dispatch, false);
            addSnackbar(`Income calculator has been created.`, {
                variant: 'success'
            });
        } catch (error) {
            actions.setErrors(error.response.data);
        }
    };

    const initialValues = {
        ...emptyIncomeCalculator,
        company_id: companyId,
        active: true
    };
    
    return (
        <AppPage loading={state.loading} className={styles.incomeCalculator}>
            <AppHeader title="Income Calculator" icon={{ name: 'income', color: 'purple' }} />
            <IncomeCalculatorForm initialValues={initialValues} onSubmit={handleSubmit} />
        </AppPage>
    );
};
