import React from 'react';
import { FormikProps } from 'formik';
import { NotificationSettings } from '../../../types/NotificationSettings';
import { InputText, InputSelect, InputPhone, InputSwitch, AppSectionHeader } from '..';
import { observer } from 'mobx-react';
import useStore from '../../../store/useStore';
import { useAsyncEffect } from '@lambdacurry/component-library';
import { phoneFormatter } from '../../../util/formatters';
import { filterCRMList } from '../../../types/Crm';

interface NotificationSettingsFieldGroupProps {
    formikProps: FormikProps<Partial<NotificationSettings>>;
    crmBlacklist: Array<string>;
    title?: string;
    subtitle?: string;
    appName?: string;
}

export const NotificationSettingsFieldGroup = observer(
    ({
        formikProps,
        crmBlacklist,
        title = 'Override Lead Notification Settings',
        subtitle,
        appName
    }: NotificationSettingsFieldGroupProps) => {
        const { store } = useStore();
        const { activeCompany, crmConfigOptionList, fetchCrmConfigOptions } = store;
        const defaultPhone = phoneFormatter(activeCompany.phone);
        const phoneInputConfig = !!defaultPhone ? { shrinkLabel: true } : {};

        useAsyncEffect(fetchCrmConfigOptions);

        if (!crmConfigOptionList) {
            return <>Loading...</>;
        }

        const defaultSubtitle = `Change company email notifications for a new lead${appName ? ` from ${appName}` : ''
            }.`;

        return (
            <>
                <AppSectionHeader title={title} subtitle={subtitle || defaultSubtitle}>
                    <InputSwitch name="override_lead_notification" formikProps={formikProps} />
                </AppSectionHeader>

                {formikProps.values['override_lead_notification'] && (
                    <>
                        <InputText
                            label="Include Emails for Lead Notifications"
                            name="lead_notification_primary_emails"
                            formikProps={formikProps}
                        />
                        <InputText label="Reply-to Email" name="reply_to_email" formikProps={formikProps} />
                        <InputPhone
                            label="Phone Number"
                            name="phone"
                            inputConfig={phoneInputConfig}
                            placeholder={defaultPhone ? `Default: ${phoneFormatter(activeCompany.phone)}` : ''}
                            formikProps={formikProps}
                        />
                        <InputSelect
                            label="CRM Configuration"
                            name="crm_config_id"
                            optionLabelKey="name"
                            optionValueKey="id"
                            options={[
                                { id: null, name: 'No CRM' },
                                ...filterCRMList(crmConfigOptionList, crmBlacklist)
                            ]}
                            formikProps={formikProps}
                            autocompleteConfig={{ disableClearable: true }}
                        />
                    </>
                )}
            </>
        );
    }
);
