import React, { FC, Fragment } from 'react';
import { FormikHelpers, FormikProps } from 'formik';
import { observer } from 'mobx-react';
import {
    AppSection,
    AppSectionHeader,
    InputText,
    InputSelect,
    Form,
    ButtonPrimary,
    Wysiwyg,
    useSnackbar
} from '../../../components-v2/shared';
import { useAsyncEffect } from '@lambdacurry/component-library';
import useStore from '../../../store/useStore';
import { Question } from '../../../types/Question';
import { EditChatBotQuestionValidationSchema } from '../Chatbot.validation';

export interface EditQuestionFormProps {
    initialValues: Question;
    onSubmit: (values: Question, actions: FormikHelpers<Question>) => Promise<void>;
    enableReinitialize?: boolean;
}

export enum Behaviors {
    Link = 'navigate_link',
    OpenScheduleGenie = 'open_sg',
    OpenOffer = 'open_cm',
    ResponseText = 'response_text',
    OpenLiveChat = 'open_chat'
}

export const EditQuestionForm: FC<EditQuestionFormProps> = observer(({ onSubmit, ...props }) => {
    const { store } = useStore();
    const { offers, fetchOffers } = store;
    const { addSnackbar } = useSnackbar();
    const fetchRequiredData = async () => {
        await fetchOffers();
    };

    const offersForTopics = offers.values
        .filter(offer => offer.active)
        .map(offer => {
            return {
                id: offer.id.toString(),
                offer_text: offer.offer_text
            };
        });

    useAsyncEffect(fetchRequiredData);

    const behaviorTypes = [
        { label: 'Link', value: Behaviors.Link },
        { label: 'Open Schedule Genie', value: Behaviors.OpenScheduleGenie },
        { label: 'Open Offer', value: Behaviors.OpenOffer },
        { label: 'Response Text', value: Behaviors.ResponseText },
        { label: 'Open Live Chat', value: Behaviors.OpenLiveChat }
    ];

    const handleSubmit = (values: Question, formikProps: FormikHelpers<Question>) => {
        if (values.behavior === 'open_cm') {
            const offer = offers.getItem(Number(values.action));

            if (offer && offer.active) {
                onSubmit(values, formikProps);
            } else {
                addSnackbar('Can not update topic without an active offer ', {
                    variant: 'error'
                });
                formikProps.setSubmitting(false);
            }
        } else {
            onSubmit(values, formikProps);
        }
    };

    const wysiwigModules = {
        toolbar: {
            container: [['bold', 'italic', 'underline', 'link'], [{ list: 'ordered' }, { list: 'bullet' }], ['clean']]
        }
    };

    const renderActionInput = (formikProps: FormikProps<Question>) => {
        switch (formikProps.values.behavior) {
            case 'navigate_link':
                return <InputText name="action" label="Link" formikProps={formikProps} />;
            case 'response_text':
                return (
                    <Wysiwyg
                        name="action"
                        modules={wysiwigModules}
                        formikProps={formikProps}
                        style={{ marginBottom: '24px' }}
                    />
                );
            case 'open_cm':
                return (
                    <InputSelect
                        name="action"
                        label="Offer"
                        formikProps={formikProps}
                        options={offersForTopics}
                        optionValueKey={'id'}
                        optionLabelKey={'offer_text'}
                    />
                );
            case 'open_chat':
                return <InputText name="action" label="Boldchat Chat ID" formikProps={formikProps} />;
            case 'open_sg':
            default:
                return <Fragment />;
        }
    };

    return (
        <Form
            {...props}
            onSubmit={handleSubmit}
            validationSchema={EditChatBotQuestionValidationSchema}
            confirmUnsavedChanges
            unsavedChangesConfig={{}}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {(formikProps: FormikProps<Question>) => {
                return (
                    <AppSection className="field-group-content">
                        <div className="field-group-col">
                            <AppSectionHeader title="Virtual Assistant Topic" />
                            <InputText
                                id="name"
                                name="name"
                                label="Name"
                                placeholder="The name of your question"
                                formikProps={formikProps}
                                inputProps={{ maxLength: 25 }}
                            />
                            <InputText
                                id="display_name"
                                name="display_name"
                                label="Display Name"
                                placeholder="This will be shown to your users"
                                formikProps={formikProps}
                                inputProps={{ maxLength: 25 }}
                            />
                            <InputText
                                id="description"
                                name="description"
                                label="Description"
                                placeholder="Describe the intent of your question for future reference"
                                formikProps={formikProps}
                            />
                            <InputSelect
                                id="behavior"
                                name="behavior"
                                label="Behavior"
                                options={behaviorTypes}
                                formikProps={formikProps}
                                optionValueKey={'value'}
                            />
                            {renderActionInput(formikProps)}
                            <ButtonPrimary
                                type="submit"
                                disabled={!formikProps.dirty || formikProps.isSubmitting}
                                data-test-id="button_save"
                            >
                                {formikProps.isSubmitting ? 'Saving...' : 'Save'}
                            </ButtonPrimary>
                        </div>
                    </AppSection>
                );
            }}
        </Form>
    );
});
