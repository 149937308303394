export enum AppIcons {
    SG = 'schedule',
    CM = 'concessionManager',
    IC = 'income',
    BPN = 'deal',
    CH = 'chat',
    TU = 'sms',
    CU = 'phoneRing',
    IN = 'addUser',
    UCM = 'concessionManager',
    USG = 'schedule'
}

export enum AppDisplayNames {
    SG = 'Schedule Genie',
    CM = 'Spotlight',
    IC = 'Income Calculator',
    BPN = 'Best Price Now',
    CH = 'Chat',
    TU = 'Text Us Now',
    CU = 'Call Us Now',
    IN = 'Insite',
    UCM = 'ULM Spotlight',
    USG = 'ULM Schedule Genie'
}
