import * as React from 'react';
import { Alert, Sizes } from 'react-bootstrap';

interface AlertDismissableProps {
    show: boolean;
    variant?: string;
    size?: Sizes;
    onHide?: () => void;
}

interface AlertDismissableState {
    show: boolean;
}

class AlertDismissable extends React.Component<AlertDismissableProps, AlertDismissableState> {
    public state = {
        show: this.props.show
    };

    // TODO: look at converting this to componentDidUpdate before React v17
    public UNSAFE_componentWillReceiveProps(props: AlertDismissableProps) {
        this.setState({ show: props.show });
    }

    protected handleDismiss = () => {
        const { onHide } = this.props;
        if (onHide) {
            onHide();
        }
        this.setState({ show: false });
    };

    protected handleShow = () => {
        this.setState({ show: true });
    };

    public render() {
        return this.state.show ? (
            <Alert
                bsStyle={this.props.variant || 'danger'}
                bsSize={this.props.size || 'small'}
                className="alert"
                onDismiss={this.handleDismiss}
            >
                {this.props.children}
            </Alert>
        ) : null;
    }
}

export default AlertDismissable;
