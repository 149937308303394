import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap';
import { dateTimeFormats } from '../../../../constants';
import { LeadNote } from '../../../../types';

interface LeadNoteListProps {
    id?: number;
    activateItem?: (reminder: LeadNote) => void;
    notes: any[];
}

@inject('store')
@observer
export default class LeadNoteList extends React.Component<LeadNoteListProps> {
    public render() {
        return (
            <Panel bsStyle="primary" defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <h4>Note Activity</h4>
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body className="hold-box-list" collapsible>
                    <ListGroup componentClass="ul" className="list-group__holder">
                        {this.props.notes.map((row: LeadNote, i: number) => {
                            const disabled = row.id === this.props.id;

                            return (
                                <ListGroupItem key={i} disabled={disabled}>
                                    <div onClick={this.handleClick(row)}>
                                        <div className="group-list_holder">
                                            <div>{row.title}</div>
                                            <span className="date-text">
                                                {row.created_at
                                                    ? `${moment(row.created_at).format(dateTimeFormats.leadDateTime)}`
                                                    : ''}
                                            </span>
                                        </div>
                                        <span className="date-text">{row.note_small}</span>
                                    </div>
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Panel.Body>
            </Panel>
        );
    }

    public handleClick = (row: LeadNote) => () => {
        if (this.props.activateItem) {
            this.props.activateItem(row);
        }
    };
}
