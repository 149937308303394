import { NotificationSettings } from './NotificationSettings';

export enum CompanyEmailFormat {
    YARDI = 'YARDI',
    RealPage = 'REAL_PAGE',
    MeetElise = 'MEET_ELISE',
    ADF = 'ADF',
    KNOCK = 'KNOCK',
    HTML = 'HTML',
    ENQUIRE = 'ENQUIRE',
    ENTRATA = 'ENTRATA'
}

export interface CompanyAddress {
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    zipcode: string;
}

export interface NotificationBarLink {
    caption: string;
    url: string;
}

export interface Company extends NotificationSettings, CompanyAddress {
    id: number;
    created_at: string;
    updated_at: string | null;
    updated_by: string;
    deleted_at: string | null;
    name: string;
    displayName?: string;
    state: string;
    website_url: string;
    is_test: boolean;
    dma: number | null;
    assigned_users?: any[];
    logo: string | null;
    enquire_portal_id: number | null;
    weekly_notification: boolean;
    rev_link_google: string | null;
    rev_link_yelp: string;
    rev_link_google_small: string;
    rev_link_yelp_small: string;
    rev_link_google_date: string | null;
    rev_link_yelp_date: string | null;
    business_category_id: number | null;
    active: boolean;
    ownership_group?: string;
    ownership_group_id: null | number;
    property_management_company_id: null | number;
    region_id: null | number;
    request_review_google: string;
    request_review_yelp: string;
    property_management?: string;
    weekly_lead_notification_email: string | null;
    include_company_name_in_email_header: boolean;
    agency_id?: number;
    hide_alerts: boolean;
    hide_alerts_users_ids?: number[];
    notification_bar_message: string | null;
    notification_bar_color: string;
    notification_bar_background_color: string;
    notification_bar_links: NotificationBarLink[];
    notification_bar_active: boolean;
    time_zone: string;
    full_name?: string;
    trial_end_date: string;
    forwardNumbers: any[];
    companyFeatures: any[];
    apiKeys: [];
    lead_notification_cc_emails: string | null;
    estimate_multiplier: null;
    custom_disclaimer: null;
    active_coupon_id: null;
    crmConfig: {
        id: number;
        company_id: number;
        crm_type: string;
        name: string;
        configuration: { crm_emails: string[] };
        created_at: string;
        updated_at: null | string;
        ruleset: { rules: any[] };
    } | null;
    realyncAccountId?: string | null;
}

export type AccessibleCompany = Company;
