import { ClarityDisplayMode } from '../components/Clarity/Clarity.types';
import { ChartType, ClarityApplicationModule } from '../types';

export enum ClarityDataSource {
    surveySummary = 'survey_summary',
    leadCountSummary = 'lead-count-summary',
    utmSourceCounts = 'utm-source-counts',
    leadsPerModuleType = 'leads-per-module-type',
    leadsPerCity = 'leads-per-city',
    utmCategoryCounts = 'utm-category-counts',
    conversionRateByDayOfWeek = 'conversion-rate-by-day-of-week',
    leadsPerModuleTypeTrend = 'leads-per-module-type-trend'
}

export interface ClarityConfig {
    charts: Array<ClarityChartConfig>;
    displayMode: ClarityDisplayMode;
}

export interface ClarityTableConfig {
    columns: Array<any>;
    pageSize?: number;
    renderPlugins?: Array<{ name: string; plug: string }>;
}

export enum friendlyUtmSourceNames {
    'imps.conversionlogix.com' = 'Conversion Logix Campaign',
    'imps.theconversioncloud.com' = 'Conversion Logix Campaign',
    'googlelocallisting' = 'Google Local Listing',
    'google organic' = 'Google Organic',
    'GoogleLocalListing' = 'Google Local Listing',
    'googlemybusiness' = 'Google My Business',
    'zillow' = 'Zillow',
    'facebook organic' = 'Facebook Organic',
    'yahoo organic' = 'Yahoo Organic',
    'bing organic' = 'Bing Organic',
    'google' = 'Google',
    'facebook' = 'Facebook',
    'fb' = 'Facebook',
    'GBP' = 'Google Business Profile',
    'gmb' = 'Google My Business',
    'greystar.com' = 'Greystar.com',
    'GMB' = 'Google My Business'
}

export interface ClarityChartConfig {
    staticData?: any;
    useLegacyEngine?: boolean;
    legacyOptions?: any;
    tableConfig?: ClarityTableConfig;
    id: string;
    guid: string;
    chartType: ChartType;
    chartTypeCustom?: ClarityChartTypeCustom;
    title: string;
    size: ClarityChartSize;
    dataSource: ClarityDataSource;
    sliceLimit?: number;
    datasetLimit?: number;
    showSummary?: boolean;
    module?: ClarityApplicationModule;
    modules?: Array<ClarityApplicationModule>;
    legendPosition?: 'left' | 'right' | 'top' | 'bottom';
    options?: any;
    helpText?: string;
}

export type ClarityChartLayout = ClarityChartConfig[];

export interface ClarityChartData {
    title: string;
    datasets: Array<any>;
    stats?: { label: string, val: number }[];
    labels?: Array<string>;
    totalValue?: any;
}

export enum ClarityChartTypeCustom {
    countSummary = 'count-summary'
}

export enum ClarityChartSize {
    full = 'full',
    half = 'half'
}

export const ClaritySliceOptions = [
    { id: 5, name: '5' },
    { id: 10, name: '10' },
    { id: 15, name: '15' },
    { id: 20, name: '20' }
];

export const ClarityChartSizes = [
    { id: ClarityChartSize.half, name: 'Half' },
    { id: ClarityChartSize.full, name: 'Full' }
];

export const ClarityApplicationModuleToDisplayName = {
    MODULE_BPN: 'Best Price Now',
    MODULE_CONCESSIONMANAGER: 'Spotlight',
    MODULE_INCOMECALCULATOR: 'Income Calculator',
    MODULE_SCHEDULEGENIE: 'Schedule Genie',
    MODULE_CALLUS: 'Call Us',
    MODULE_CHAT: 'Chat',
    MODULE_TEXTUS: 'Text Us Now',
    MODULE_INSITE: 'Insite'
};

export const ObjToPicklist = (obj: any) => {
    const response: Array<{ id: string; name: string }> = [];
    Object.keys(obj).forEach((key: string) => {
        response.push({ id: key, name: obj[key] });
    });
    return response;
};
