export enum ChartType {
    custom = 'custom',
    doughnut = 'doughnut',
    stats = 'stats',
    combo = 'combo',
    bubble = 'bubble',
    table = 'table',
    line = 'line',
    bar = 'bar',
    pie = 'pie',
    number = 'number'
}
