import { observer } from 'mobx-react';
import {
    ActionList,
    AppContent,
    AppHeader,
    AppList,
    AppListItem,
    AppListItemContent,
    AppPage,
    InputSwitch
} from '../../components-v2/shared';
import React, { useState } from 'react';
import useStore from '../../store/useStore';
import { useAsyncEffect } from '../../util/async';

const Features = observer(() => {
    const [loading, setLoading] = useState(true);
    const { store } = useStore();
    const { router, fetchFeatures, features, saveCompanyFeatures, isSuperAdminRole, activeUser } = store;
    const { companyId } = router.params;

    const fetchRequiredData = async () => {
        window.scrollTo(0, 0);
        await fetchFeatures(parseInt(companyId, 10));
        setLoading(false);
    };
    useAsyncEffect(fetchRequiredData);

    const generallyAvailableFeatures = [
        { name: 'Best Price Now', slug: 'best_price_now' },
        { name: 'Live Chat', slug: 'chat' },
        { name: 'SMS Messaging', slug: 'sms_messaging' },
        { name: 'Income Calculator', slug: 'income_calculator' },
        { name: 'Call Us Now', slug: 'call_us_now' },
        { name: 'Spotlight', slug: 'concession_manager' },
        { name: 'Schedule Genie', slug: 'schedule_genie' },
        { name: 'Text Us Now', slug: 'text_us_now' },
        { name: 'Insite', slug: 'insite' }
    ];

    // only manageable by super admins
    const additionalAvailableFeatures = isSuperAdminRole
        ? [
              { name: 'ULM Spotlight', slug: 'ulm_concession_manager' },
              { name: 'ULM Schedule Genie', slug: 'ulm_schedule_genie' },
              { name: 'Realync Video Integration', slug: 'realync' },
              { name: 'Lead Nurturing', slug: 'lead_nurturing' }
          ]
        : [];

    let featureFlagFeatures: any = [];
    activeUser?.previewFlags.map(flag => {
        switch (flag) {
            case 'chatbot':
                featureFlagFeatures.push({ name: 'Virtual Assistant', slug: 'chatbot' });
                break;
        }
    });

    const availableFeatures = generallyAvailableFeatures.concat(additionalAvailableFeatures, featureFlagFeatures);

    const handleToggle = (slug: string) => {
        const activeFeatureSlugs: string[] = availableFeatures
            .filter((feature: { name: string; slug: string }) => {
                if (feature.slug === slug) {
                    return !features[feature.slug];
                } else {
                    return features[feature.slug];
                }
            })
            .map((feature: { name: string; slug: string }) => {
                return feature.slug;
            });
        saveCompanyFeatures(activeFeatureSlugs);
    };

    return (
        <AppPage loading={loading}>
            <AppHeader title="Features"></AppHeader>
            <AppContent>
                <AppList>
                    {availableFeatures.map((feature: { name: string; slug: string }, index) => {
                        return (
                            <>
                                <AppListItem key={index}>
                                    <AppListItemContent title={feature.name} />
                                    <ActionList position="end">
                                        <InputSwitch
                                            onClick={() => {
                                                handleToggle(feature.slug);
                                            }}
                                            checked={!!features[feature.slug]}
                                        />
                                    </ActionList>
                                </AppListItem>
                            </>
                        );
                    })}
                </AppList>
            </AppContent>
        </AppPage>
    );
});

export default Features;
