import { RuleSet } from './RuleSet';

export interface Crm {
    id: number;
    company_id: number;
    configuration: any;
    crm_type: string;
    name: string;
    ruleset?: RuleSet;
    created_at?: string;
    updated_at?: null | string;
    default?: boolean;
}

export interface CrmConfigOption {
    id: number;
    name: string;
    type: string;
}

export const filterCRMList = (crmConfigOptionList: Array<CrmConfigOption>, blacklist: Array<string> = []) => {
    return crmConfigOptionList?.filter((opt: CrmConfigOption) => {
        return !blacklist.includes(opt.type);
    });
};
