import * as Yup from 'yup';
import { urlRegExp } from '../../util/url';

export const EditBestPriceNowFormValidationSchema = () => {
    return Yup.object().shape({
        bpn_menu_title: Yup.string().required('Please enter a title.'),
        close_cta_url: Yup.string().matches(urlRegExp, {
            message: 'Please enter a valid URL (must contain "http://" or "https://").',
            excludeEmptyString: true
        })
    });
};
