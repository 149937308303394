import React, { FC } from 'react';
import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { Button } from '@lambdacurry/component-library';
import { ClarityChartConfig } from '../../constants';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import { ClarityDataGridPlugins } from './DataGridPlugins/ClarityDataGridPlugins';
import { ClarityDisplayMode } from './Clarity.types';

export interface ClarityDataGridChartProps {
    config: ClarityChartConfig;
    displayMode: ClarityDisplayMode;
    data?: Array<any>;
    listeners?: DraggableSyntheticListeners;
    handle?: boolean;
    dragging?: boolean;
    dragOverlay?: boolean;
}

export const ClarityDataGridChart: FC<ClarityDataGridChartProps> = ({ config, data, displayMode }) => {
    const downloadBlob = (blob: any, fileName = `${config.id}-data.csv`) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);

        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const exportCSV = () => {
        const SEPARATOR = ',';
        if (!config.tableConfig || !data) {
            return;
        }
        const columns = config.tableConfig.columns;
        const header = columns
            .map((col: { header: string; csvHeader?: string }) => {
                return col.csvHeader ? col.csvHeader : col.header;
            })
            .join(SEPARATOR);

        const rows = data.map(data => columns.map(c => `"${data[c.name]}"`).join(SEPARATOR));

        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

        downloadBlob(blob);
    };

    const columns = (config.tableConfig?.columns || [])
        .filter((col: any, i: number) => {
            if (col.csvOnly) {
                return false;
            }
            return displayMode === 'screen' || !col.hideOnPrint;
        })
        .map((col: any, i: number) => {
            if (config.tableConfig?.renderPlugins) {
                const plugName = config.tableConfig.renderPlugins.find((plugin: { name: string; plug: string }) => {
                    return plugin.name === col.name;
                })?.plug;
                if (plugName && ClarityDataGridPlugins[plugName]) {
                    col.render = ClarityDataGridPlugins[plugName];
                }
            }
            return col;
        });
    const rowCount = data?.length || 0;
    const footerHeight = rowCount > 10 ? 40 : 0;
    const pagesize = 10;

    return displayMode === 'print' ? (
        <>
            <ReactDataGrid
                dataSource={data || []}
                columns={columns}
                loading={false}
                style={{ minHeight: 39 + 40 * (data?.length || 1), fontSize: 12 }}
                lockedRows={[]}
            />
        </>
    ) : (
        <>
            <ReactDataGrid
                dataSource={data || []}
                columns={columns}
                loading={false}
                pagination={rowCount > pagesize ? 'local' : undefined}
                defaultLimit={pagesize}
                style={{ minHeight: 39 + footerHeight + 40 * Math.min(rowCount || 1, pagesize), fontSize: 12 }}
                lockedRows={[]}
            />
            <Button style={{ marginTop: 20, width: 120 }} onClick={exportCSV}>
                Export CSV
            </Button>
        </>
    );
};
