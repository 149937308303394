import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { PageHeader, Well, Panel } from 'react-bootstrap';
import { InjectedProps } from '../../../types';
import { Typeahead } from 'react-bootstrap-typeahead';
import { RenderedTypeaheadMenu } from '../Layout/MainMenu/MenuItems/RenderedTypeaheadMenu';
import LeadCreateChat from '../../Lead/AutoLeads/Chat/LeadCreateChat';
import { FormAlerts } from '../../FormAlerts/FormAlerts';
import { isBoolean } from 'lodash';

export interface LiveChatManualLeadSubmissionState {
    companyId: number;
    chatId: number;
    data?: any[];
    newItem?: any;
    feature?: { [name: string]: boolean };
    preview: boolean;
    companyName: string;
}

@inject('store')
@observer
class LiveChatManualLeadSubmission extends React.Component<{}, LiveChatManualLeadSubmissionState> {
    public async componentDidMount() {
        const { api } = this.injected.store;
        const data = await api.fetch('chats/companies');
        this.setState({ data: data.data });
    }

    public componentWillUnmount() {
        const { removeFormAlert } = this.injected.store;
        removeFormAlert();
    }

    public get injected() {
        return this.props as InjectedProps;
    }

    public state: LiveChatManualLeadSubmissionState = {
        companyId: 0,
        chatId: 0,
        feature: {},
        preview: false,
        companyName: ''
    };

    public render() {
        const handleChangeCompany = async (selectedValue: any) => {
            if (!selectedValue.length) {
                return;
            }

            this.setState({
                chatId: selectedValue[0].chat_id,
                companyId: selectedValue[0].id,
                companyName: selectedValue[0].name
            });

            this.fetchFeatures(selectedValue[0].id);
        };
        return (
            <>
                <FormAlerts />
                <PageHeader>Live Chat: Manual Lead Submission</PageHeader>

                <Well className="lead-submission">
                    <Panel bsStyle="primary" defaultExpanded>
                        <Panel.Heading>
                            <Panel.Title toggle>
                                <h4>Live Chat</h4>
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                            <div className={'holder-manual-Lead-submission__box'}>
                                {this.state.data && this.state.data.length ? (
                                    <div className="select-company-wrapper select-companys">
                                        <div className="col-lg-4 col-md-6 col-xs-12 block-companies-holder">
                                            <label className="control-label">Company Chat</label>
                                            <div className="name-company">
                                                {this.state.preview ? this.state.companyName : null}
                                                <div style={{ display: this.state.preview ? 'none' : 'block' }}>
                                                    <Typeahead
                                                        id="accessibleCompaniesTypeaheadLiveChat"
                                                        labelKey="name"
                                                        options={this.state.data}
                                                        placeholder="— Choose Company Chat —"
                                                        onChange={handleChangeCompany}
                                                        renderMenu={RenderedTypeaheadMenu}
                                                        onInputChange={() => {
                                                            this.setState({
                                                                chatId: 0,
                                                                companyId: 0
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="block-company-chat">
                                            <LeadCreateChat
                                                chatId={this.state.chatId || 0}
                                                companyId={this.state.companyId}
                                                feature={this.state.feature}
                                                preview={this.state.preview}
                                                companyName={this.state.companyName}
                                                previewHandler={() => {
                                                    this.setState({ preview: !this.state.preview });
                                                }}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </Panel.Body>
                    </Panel>
                </Well>
            </>
        );
    }

    public fetchFeatures = async (id_company: number) => {
        const { getCompanyFeatures } = this.injected.store;

        const featureBundle = await getCompanyFeatures(id_company);

        if (isBoolean(featureBundle) || !featureBundle.data) {
            return;
        }

        const features: { [name: string]: boolean } = featureBundle.data;

        this.setState({
            feature: features
        });
    };

    public handleDismissAlert = () => {
        this.setState({ newItem: undefined });
    };
}

export default LiveChatManualLeadSubmission;
