import React, { FC, useState } from 'react';
import {
    AppList,
    AppListHeader,
    AppListItem,
    AppListItemContent,
    ButtonOutline,
    Label
} from '../../../components-v2/shared';
import { moneyFormatter } from '../../../util/formatters';
import { BillSummaryCompany } from '../../Billing';

import './billing-companies.scss';

export interface BillSummaryProps {
    companies: BillSummaryCompany[];
    initialDisplayLimit?: number;
}

export const BillingCompanies: FC<BillSummaryProps> = ({ companies, initialDisplayLimit = 4 }) => {
    const [companiesViewAll, setCompaniesViewAll] = useState<boolean>(false);

    return (
        <AppList>
            <AppListHeader className="billing-companies-list-header">
                <div>Company</div>
                <div className="billing-companies-list-header-monthly-cost">Monthly Estimate</div>
            </AppListHeader>

            {companies.length > 0 &&
                companies.map((company, index) => {
                    if (!companiesViewAll && index > initialDisplayLimit - 1) {
                        return null;
                    }

                    return (
                        <AppListItem className="billing-companies-list-item" key={company.id}>
                            <div className="billing-companies-list-item-company">
                                {company.logo && (
                                    <div className="billing-companies-list-item-logo">
                                        <img
                                            className="billing-companies-list-item-logo-image"
                                            src={company.logo}
                                            alt={company.logo}
                                        />
                                    </div>
                                )}

                                <AppListItemContent
                                    title={
                                        <>
                                            <span className="billing-companies-list-item-company-name">
                                                {company.name}
                                            </span>{' '}
                                            {company.freeCompany && <Label type="success">Free Company</Label>}
                                            {company.proofingMode && <Label>Proofing Mode</Label>}
                                            {company.inTrial && <Label>Trial Ends {company.trialEndDate}</Label>}
                                        </>
                                    }
                                    description={<>Paid Apps: {company.appsCount}</>}
                                />
                            </div>
                            <div className="billing-companies-list-item-monthly-cost">
                                {moneyFormatter(company.monthlyEstimate / 100, 2)}
                            </div>
                        </AppListItem>
                    );
                })}

            {companies.length > initialDisplayLimit && (
                <div className="billing-companies-list-view-all">
                    <ButtonOutline onClick={() => setCompaniesViewAll(!companiesViewAll)}>
                        View {!companiesViewAll ? 'All' : 'Fewer'}
                    </ButtonOutline>
                    <div className="billing-companies-list-view-all-count">
                        Showing {!companiesViewAll ? initialDisplayLimit : companies.length} of {companies.length}{' '}
                        companies
                    </div>
                </div>
            )}
        </AppList>
    );
};
