import * as React from 'react';
import { FormikProps, FormikHelpers } from 'formik';
import { Form, InputText, ButtonPrimary, ButtonGroup} from '@lambdacurry/component-library';

export interface IQuery {
    queryText: string;
}

interface TestQueryFormProps {
    handleSubmit: (values: any, actions: FormikHelpers<any>) => Promise<void>;
}

export const TestQueryForm: React.FC<TestQueryFormProps> = ({handleSubmit}) => {
    return (
        <Form className="form-holder-block" initialValues={{queryText: ''}} onSubmit={handleSubmit}>
            {(formikProps: FormikProps<IQuery>) => (
                <>
                    <div>
                        <InputText label="Query" name="queryText" formikProps={formikProps} />
                    </div>
                    <ButtonGroup>
                        <ButtonPrimary type="submit">{formikProps.isSubmitting ? 'Submitting' : 'Submit'}</ButtonPrimary>
                    </ButtonGroup>
                </>
            )}
        </Form>
    );
};
