import Store from '../store';
import DefaultRoutes from './DefaultRoutes';
import { isObject } from 'lodash';

export interface CheckRouteAccessOptions {
    userPermissions?: {
        resource: string;
        action: string;
    };
    previewFlag?: {
        flag: string;
        redirectRouteName: string;
        shouldHaveFlag?: boolean;
    };
}

export type RouteCheckFunction = (
    router: RouterStore,
    params: any,
    store: Store,
    queryParams?: any
) => Promise<boolean>;

export const routeCheckAll = (routeChecks: RouteCheckFunction[]): RouteCheckFunction => async (
    router,
    params,
    store
) => {
    if (!store.activeUserFetched) {
        return store.fetchActiveUser().then(async () => {
            const results = await Promise.all(routeChecks.map(routeCheck => routeCheck(router, params, store)));
            return results.every(result => !!result);
        });
    }
    const results = await Promise.all(routeChecks.map(routeCheck => routeCheck(router, params, store)));
    return results.every(result => !!result);
};

export const isActivePathByDisplayName = (route: Route, currentView: Route) => {
    if (!route || !currentView) {
        return false;
    }
    return route.displayName === currentView.displayName ? true : false;
};

export const checkUserPermissions = (resource: string, action: string) => async (
    router: RouterStore,
    params: any,
    store: Store
) => {
    if (!store) {
        return false;
    }
    let user;
    if (store.activeUserFetched) {
        user = store.activeUser;
    } else {
        await store.fetchActiveUser();
        user = store.activeUser;
    }
    if (!user) {
        return false;
    }
    const permissions = user.role.permissions;
    const right = `${resource}.${action}`;

    if (!permissions[right]) {
        store.router.goTo(DefaultRoutes.Permission, params, store);
    }

    return !!permissions[right];
};

export const checkPasswordReset = () => async (router: RouterStore, params: any, store: Store): Promise<boolean> => {
    if (!store) {
        return false;
    }
    let user;
    if (store.activeUserFetched) {
        user = store.activeUser;
    } else {
        await store.fetchActiveUser();
        user = store.activeUser;
    }
    if (user?.reset_password) {
        store.router.goTo(DefaultRoutes.NotifyPasswordReset, params, store);
        return false;
    }
    return true;
};

export const checkUserHome = () => async (
    router: RouterStore,
    params: any,
    store: Store,
    queryParams?: any
): Promise<boolean> => {
    if (!store) {
        return false;
    }

    if (!store.activeUserFetched) {
        await store.fetchActiveUser();
    }

    if (!store.activeUser) {
        return false;
    }

    // Note: Destructure after the await, so that we ensure we have the latest values.
    const { isAdminRole, isAgencyRole, isCompanyRole, accessibleCompanies, isChatRole, agencyStore } = store;
    const { accessibleAgencies } = agencyStore;

    // Note: Need to use the `params.agencyId` because the `agencyStore.activeAgencyId` is returning `undefined` here.
    const activeAgencyId = params.agencyId;
    const activeCompanyId = params.companyId;

    if (isChatRole) {
        store.router.goTo(DefaultRoutes.LiveChatManualLeadSubmission, params, store, queryParams);
        return false;
    }

    // Note: These rootPath early returns allow redirects to the correct path based on user role below, without these
    // we get into an infinite loop on checkUserHome() - Jake 03/30/2021
    if (router.rootPath === '/partners') {
        return !!(activeAgencyId || isAgencyRole || isAdminRole);
    }

    if (router.rootPath === '/company' && router.path.includes('dashboard')) {
        return true;
    }

    if (router.rootPath === '/partners' && router.path.includes('calendar_leads')) {
        return true;
    }

    if (router.rootPath === '/company' && router.path.includes('calendar_leads')) {
        return true;
    }

    if (
        (activeAgencyId && accessibleAgencies?.find(({ id }) => id === activeAgencyId)) ||
        (isAgencyRole && accessibleAgencies?.length === 1)
    ) {
        const route = DefaultRoutes.AgencyDashboard;
        params.agencyId = activeAgencyId || accessibleAgencies[0].id;
        await store.setActiveCompanyId(0);
        store.router.goTo(route, params, store, queryParams);
        return false;
    }

    if ((isCompanyRole && accessibleCompanies?.length === 1) || activeCompanyId) {
        const route = DefaultRoutes.CompanyDashboard;
        params.companyId = activeCompanyId || accessibleCompanies[0].id;
        store.router.goTo(route, params, store, queryParams);
        return false;
    }

    return true;
};

export const checkFeatures = (feature: any) => async (
    router: RouterStore,
    params: any,
    store: Store
): Promise<boolean> => {
    if (!store) {
        return false;
    }

    if (!store.activeUserFetched) {
        await store.fetchActiveUser();
    }

    if (!store.activeUser) {
        return false;
    }

    const companyId = params.companyId ? parseInt(params.companyId, 10) : undefined;
    const featuresResponse = await store.getCompanyFeatures(companyId);

    if (!featuresResponse || !featuresResponse.data || !isObject(featuresResponse.data)) {
        return false;
    }

    if (!featuresResponse.data[feature]) {
        store.router.goTo(DefaultRoutes.Home, params, store);
        return false;
    }

    return true;
};

export const checkPreviewFlag = (
    previewFlag: string,
    redirectRouteName: string,
    shouldHavePreviewFlag: boolean = true
) => async (router: RouterStore, params: any, store: Store, queryParams: any): Promise<boolean> => {
    if (!store) {
        return false;
    }

    if (!store.activeUserFetched) {
        await store.fetchActiveUser();
    }

    if (!store.activeUser) {
        return false;
    }

    const hasPreviewFlag = store.activeUser?.previewFlags?.includes(previewFlag);

    if (
        DefaultRoutes[redirectRouteName] &&
        ((shouldHavePreviewFlag && !hasPreviewFlag) || (!shouldHavePreviewFlag && hasPreviewFlag))
    ) {
        store.router.goTo(DefaultRoutes[redirectRouteName], params, store, queryParams);
        return false;
    }

    return true;
};

export const checkRouteAccess = ({ userPermissions, previewFlag }: CheckRouteAccessOptions) => async (
    router: RouterStore,
    params: any,
    store: Store,
    queryParams: any
) => {
    const { resource = '', action = '' } = userPermissions || {};
    const { flag = '', redirectRouteName = '', shouldHaveFlag } = previewFlag || {};

    const results = await Promise.all([
        userPermissions ? checkUserPermissions(resource, action)(router, params, store) : true,
        previewFlag
            ? checkPreviewFlag(flag, redirectRouteName, shouldHaveFlag)(router, params, store, queryParams)
            : true
    ]);

    return results.every(result => result === true);
};

export const getRouteWithContext: (baseRouteName: string, router: RouterStore) => Route = (baseRouteName, router) => {
    if (router.params?.companyId) {
        return DefaultRoutes[`Company${baseRouteName}`];
    }

    if (router.params?.agencyId) {
        return DefaultRoutes[`Agency${baseRouteName}`];
    }

    return DefaultRoutes[baseRouteName];
};
