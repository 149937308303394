import { inject, observer } from 'mobx-react';
import * as React from 'react';
import createLeadItem from '.';
import { InjectedProps } from '../../../types';
import LeadItem from './LeadItem';
import { LeadStatus, Lead } from '../../../types/Lead';
import { LeadNote } from '../../../types/LeadNote';

export enum LeadJourneyEventType {
    lead_customer_log = 'lead_customer_log',
    smsMessage = 'sms_message',
    sessionTouch = 'session_touch',
    matchback = 'matchback'
}
export interface JourneyEvent {
    data: {
        id: number;
        created_at: string;
        device_location: string;
        device_type: string;
        interactions: number;
        lead_id: number;
        os_name: string;
        referrer: string;
        utm_campaign: string;
        utm_content: string;
        utm_medium: string;
        utm_source: string;
        utm_term: string;
        apps_opened: string;
        lead_via_apps: string;
        notification_type?: string;
        message?: string;
        message_error?: string;
        status?: string;
        sent_at?: string;
        description?: string;
        log_type?: string;
    };
    description?: string;
    timestamp: string;
    type: string;
}
export interface LeadFields extends Omit<Lead, 'company'> {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    status: LeadStatus;
    utm_data: string;
    referrer: string;
    journey_events?: JourneyEvent[];
    company?: any;
    note?: LeadNote;
    is_agree_sms_notification: string;
}

export const LeadFieldLabels = {
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    phone_number: 'Phone Number',
    status: 'Lead Status',
    utm_data: 'UTM Data',
    referrer: 'Url Referrer'
};

interface LeadEditState {
    lead?: LeadItem;
}

@inject('store')
@observer
export class LeadEdit extends React.Component<{}, LeadEditState> {
    public state: LeadEditState = {};

    public get injected() {
        return this.props as InjectedProps;
    }

    public componentDidMount() {
        const { runInQueue } = this.injected.store;
        return runInQueue(this.fetchData());
    }

    public fetchData() {
        const { fetchLead } = this.injected.store;
        return fetchLead(this.id)
            .then(response => response.data)
            .then(data => {
                this.setState({
                    lead: createLeadItem(data)
                });
            });
    }

    public get id() {
        const { router } = this.injected.store;
        return router.params.id ? parseInt(router.params.id, 10) : 0;
    }

    public render() {
        const { lead } = this.state;

        if (!lead) {
            return null;
        }

        return lead.renderEditForm();
    }
}

export default LeadEdit;
