import { Formik, FormikHelpers } from 'formik';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { InjectedProps } from '../../../../types';
import { LeadStatus, LeadType } from '../../../../types/Lead';
import ControlButtonSave from '../../../misc/ControlButtonSave';
import LeadChatCreateForm from './LeadChatCreateForm';
import { LeadChatFields } from './LeadChatForm';

interface LeadCreateChatProps {
    chatId: number;
    companyId?: number;
    onSave?: (values: LeadChatFields) => void;
    feature?: { [name: string]: boolean };
    preview?: boolean;
    companyName?: string;
    previewHandler?: () => void;
}

interface LeadCreateChatState {
    isSaving: boolean;
}

@inject('store')
@observer
export class LeadCreateChat extends React.Component<LeadCreateChatProps & Partial<InjectedProps>, LeadCreateChatState> {
    public get injected() {
        return this.props as InjectedProps;
    }

    public state = {
        isSaving: false
    };

    public initialValues = (): any => {
        return {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            status: LeadStatus.OPEN_OPPORTUNITY,
            comments: ''
        };
    };

    public render() {
        return (
            <Formik initialValues={this.initialValues()} enableReinitialize onSubmit={this.handleSubmit}>
                {this.renderForm()}
            </Formik>
        );
    }

    protected renderForm = () => {
        const { isAdminRole, isSuperAdminRole, isCompanyLightRole, features } = this.injected.store;
        return LeadChatCreateForm({
            isSuperAdmin: isSuperAdminRole,
            isAdmin: isAdminRole,
            isCompanyLight: isCompanyLightRole,
            editing: true,
            features: this.props.feature || features,
            preview: this.props.preview,
            controls: () => (
                <ControlButtonSave
                    isSaving={this.state.isSaving}
                    showBack={this.props.preview}
                    onClickBackHandler={this.props.previewHandler}
                    text="Submit"
                />
            )
        });
    };

    protected handleSubmit = (values: LeadChatFields, actions: FormikHelpers<LeadChatFields>) => {
        this.setState({
            isSaving: true
        });

        const { leads, activeCompanyId, addFormAlert, isChatRole } = this.injected.store;
        if (!(activeCompanyId || this.props.companyId) || !this.props.chatId) {
            addFormAlert({
                type: 'error',
                message: 'Choose company or current company do not have active chat'
            });
            this.setState({ isSaving: false });
            return;
        }

        let isAgree = values.is_agree_sms_notification || '';
        if (isChatRole) {
            isAgree = 'Yes';
        }
        if (typeof this.props.preview === 'boolean') {
            this.setState({ isSaving: false });
            if (!this.props.preview && this.props.previewHandler) {
                this.props.previewHandler();
                return;
            }
        }

        return leads
            .create({
                first_name: values.first_name,
                last_name: values.last_name,
                email: values.email,
                phone_number: values.phone_number,
                comments: values.comments,
                lead_type: LeadType.CH,
                company_id: activeCompanyId || this.props.companyId,
                status: values.status,
                chat_id: this.props.chatId,
                is_agree_sms_notification: isAgree
            } as any)
            .then(() => {
                this.setState({ isSaving: false });
                actions.resetForm();
                addFormAlert({
                    type: 'success',
                    message: 'Lead was created.'
                });
                if (this.props.onSave) {
                    this.props.onSave(values);
                }
                if (this.props.previewHandler) {
                    this.props.previewHandler();
                }
            })
            .catch(error => {
                actions.setErrors(error.response.data);
                this.setState({ isSaving: false });

                addFormAlert({
                    type: 'error',
                    message: 'Please check form. Some fields are incorrect.'
                });
            });
    };
}

export default LeadCreateChat;
