import React, { FC } from 'react';
import { observer } from 'mobx-react';
import DefaultRoutes from '../../../../../routes/DefaultRoutes';
import useStore from '../../../../../store/useStore';
import { FEATURES } from '../../../../../types/Features';
import { SideNavMenu } from '../../SideNavMenu';
import { SideNavMenuItem } from '../../SideNavMenuItem';
import { getSupportUrl } from '../../SideNav.helpers';
import { FEATURE_FLAGS } from '../../../../../types/FeatureFlag';
import { getRouteWithContext } from '../../../../../routes/routeUtils';

export interface MenuCompanyProps {}

export const MenuCompany: FC<MenuCompanyProps> = observer(props => {
    const { store } = useStore();
    const { features, isAdminRole, isAgencyRole, amountUnreadSmsMessages, hasAccess, router, activeUser } = store;

    // Note: Set `previewFlags` to an empty array by default to avoid "can't read property 'includes' of `undefined`" error.
    const { previewFlags = [] } = activeUser || {};

    const hideBestPriceNow = !features[FEATURES.best_price_now];
    const hideCallUsNow = !features[FEATURES.call_us_now];
    const hideLiveChat = !features[FEATURES.chat];
    const hideIncomeCalculator = !features[FEATURES.income_calculator];
    const hideConcessionManager = !features[FEATURES.concession_manager];
    const hideTextUsNow = !features[FEATURES.text_us_now] || !features[FEATURES.sms_messages];
    const hideScheduleGenie = !features[FEATURES.schedule_genie];
    const hideInsite = !features[FEATURES.insite];
    const hideMessages = !features[FEATURES.amount_twilio_numbers] && !features[FEATURES.sms_messages];
    const hasRealyncIntegration = previewFlags.includes('realync-integration') && features[FEATURES.realync];
    const hideChatBot = !previewFlags.includes(FEATURE_FLAGS.ENABLE_CHATBOT) || !features[FEATURES.chatbot];
    const hideLeadNurturing = !features[FEATURES.lead_nurturing];

    const leadRoutes = [{ route: DefaultRoutes.CompanyLeadView }];
    const leadsItems = [...leadRoutes, { route: DefaultRoutes.CompanyInsiteMetricDataList, hidden: hideInsite }];
    // TODO: Update routes to use our `checkRouteAccess` function, rather than doing this...
    const appsItems = [
        {
            route: DefaultRoutes.BestPriceNow,
            hidden: hideBestPriceNow
        },
        {
            route: DefaultRoutes.CallUsNow,
            hidden: hideCallUsNow
        },
        {
            route: DefaultRoutes.LiveChat,
            hidden: hideLiveChat
        },
        {
            route: DefaultRoutes.ConcessionManagerList,
            hidden: hideConcessionManager
        },
        {
            route: DefaultRoutes.IncomeCalculator,
            hidden: hideIncomeCalculator
        },
        {
            route: DefaultRoutes.ScheduleGenieAppPage,
            hidden: hideScheduleGenie
        },
        {
            route: DefaultRoutes.TextUsNow,
            hidden: hideTextUsNow
        },
        {
            route: DefaultRoutes.Insite,
            hidden: hideInsite
        },
        { route: DefaultRoutes.WebNotifications },
        {
            route: DefaultRoutes.ChatBot,
            hidden: hideChatBot
        }
    ];

    const clarityV2 = { route: getRouteWithContext('ClarityDashboard', router) };

    const companySettingsItems = [
        { route: DefaultRoutes.CompanyEdit },
        { route: DefaultRoutes.EmailTemplatesList },
        { route: DefaultRoutes.DripScheduleEditorListPage, hidden: hideLeadNurturing },
        { route: DefaultRoutes.ScheduleEditorListPage },
        { route: DefaultRoutes.CompanyFeatureFlags, hidden: !isAdminRole && !isAgencyRole },
        { route: DefaultRoutes.CompanyFeatures, hidden: !isAdminRole && !isAgencyRole },
        { route: DefaultRoutes.RemoteAuthList },
        { route: DefaultRoutes.ApiKeyList },
        { route: DefaultRoutes.CrmList },
        { route: DefaultRoutes.MatchbackImport },
        { route: DefaultRoutes.QuestionDefinitionPresetPage },
        { route: DefaultRoutes.CompanyIntegrations, hidden: (!isAdminRole && !isAgencyRole) || !hasRealyncIntegration }
    ];

    return (
        <SideNavMenu>
            <SideNavMenuItem name="dashboard" icon="home" route={DefaultRoutes.CompanyDashboard} />

            {hideInsite ? (
                <SideNavMenuItem name="leads" label="Leads" icon="user" route={DefaultRoutes.CompanyLeadView} />
            ) : (
                <SideNavMenuItem
                    name="leads"
                    label="Leads"
                    icon="user"
                    submenu={{ items: leadsItems }}
                    route={leadsItems[0].route}
                />
            )}

            {!hideMessages && (
                <SideNavMenuItem
                    name="messages"
                    icon="chat"
                    notificationCount={amountUnreadSmsMessages}
                    route={DefaultRoutes.CompanyMessages}
                />
            )}

            <SideNavMenuItem
                name="apps"
                label="Apps"
                icon="apps"
                submenu={{ items: appsItems }}
                route={appsItems.find(item => !item.hidden)?.route}
            />

            <SideNavMenuItem name="customize" icon="paintBrush" route={DefaultRoutes.ApplicationSettings} />

            {hasAccess('admin.dashboard') && (
                <SideNavMenuItem name="clarity_v2" label="CLarity" icon="analytics" route={clarityV2.route} />
            )}

            <SideNavMenuItem
                name="company"
                label="Company"
                icon="company"
                submenu={{ items: companySettingsItems }}
                route={companySettingsItems[0].route}
            />

            <li className="side-nav-divider" />

            <SideNavMenuItem
                name="support"
                label="Support"
                icon="support"
                onClick={() => window.open(getSupportUrl(store), '_blank')}
            />
        </SideNavMenu>
    );
});
