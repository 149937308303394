import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { ListGroup } from 'react-bootstrap';

interface LeadSmsListProps {
    sms: any[];
}

@inject('store')
@observer
export default class LeadSmsList extends React.Component<LeadSmsListProps> {
    public render() {
        if (!this.props.sms || !this.props.sms.length) {
            return null;
        }
        return <ListGroup className="list-group__holder"></ListGroup>;
    }
}
