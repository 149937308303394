import * as React from 'react';
import LeadEditChat from './LeadEditChat';
import LeadItem, { CalendarEvent } from '../LeadItem';
import LeadListChatDetails from './LeadListChatDetails';
import LeadModalDetailsChat from './LeadModalDetailsChat';
import { LeadChat } from '../../../../types/Lead';

class LeadChatItem extends LeadItem {
    public name = 'CH';
    public dateFormatClass = 'ch-chat-col';
    public calendarEventClass = 'ch-calendar-event';

    public getEntity = (): LeadChat => {
        return this.lead.chat!;
    };

    public renderDetails = () => {
        return <LeadListChatDetails lead={this} />;
    };

    public renderModalDetails = (event: CalendarEvent) => {
        return <LeadModalDetailsChat event={event} />;
    };

    public renderEditForm = () => {
        return <LeadEditChat lead={this} />;
    };
}

export default LeadChatItem;
