import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { configure } from 'mobx';
import { Provider } from 'mobx-react';
import { startRouter } from 'mobx-router';
import * as ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import './css/bootstrap_dashboard.css';
import './css/clxicon/clxicon.css';
import './css/normalize.css';
import './css/tcc_dashboard.scss';
import DefaultRoutes from './routes/DefaultRoutes';
import * as serviceWorker from './serviceWorker';
import Store from './store';
import { App } from './components/App';
import { MUIThemeProvider } from './components-v2/MUIThemeProvider';
import { Snackbar } from './components-v2/shared';
import ReactModal from 'react-modal';
import { DrawerProvider } from '@lambdacurry/component-library';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseApiKey, firebaseAppId, firebaseAuthDomain, firebaseMeasurementId, firebaseMessagingSenderId, firebaseProjectId, firebaseStorageBucket } from './constants';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

if (firebaseAppId && firebaseMeasurementId) {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: firebaseApiKey,
        authDomain: firebaseAuthDomain,
        projectId: firebaseProjectId,
        storageBucket: firebaseStorageBucket,
        messagingSenderId: firebaseMessagingSenderId,
        appId: firebaseAppId,
        measurementId: firebaseMeasurementId
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
}

const store = new Store();
configure({ enforceActions: 'always' });
library.add(faCheck, faEdit, faTimes);

startRouter(DefaultRoutes, store);

ReactModal.setAppElement('#root');

ReactDOM.render(
    <Provider store={store}>
        <DrawerProvider>
            <MUIThemeProvider>
                <Snackbar.Provider>
                    <App />
                </Snackbar.Provider>
            </MUIThemeProvider>
        </DrawerProvider>
    </Provider>,
    document.getElementById('root') as HTMLElement
);

serviceWorker.register();
