import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { Col } from 'react-bootstrap';
import { FieldGroup } from '../../../misc/FieldGroup';
import { FieldGroupWysiwyg } from '../../../misc/FieldGroupWysiwyg';

interface LeadNoteFormProps {
    controls: React.ReactNode;
}

export interface LeadNoteFields {
    title: string;
    note: string;
}

const labels = {
    title: 'Title',
    note: 'Note'
};

export default (props: LeadNoteFormProps) => {
    return (bag: FormikProps<LeadNoteFields>) => {
        const fieldProps = {
            ...bag,
            labels
        };

        return (
            <div>
                <Form id="noteForm">
                    {fieldProps.errors && typeof fieldProps.errors === 'string' ? (
                        <span className="error">{fieldProps.errors}</span>
                    ) : null}

                    <div className="indent-outside">
                        <FieldGroup id="title" {...fieldProps} />
                        <Col className="col-xs-12">
                            <FieldGroupWysiwyg id="note" {...fieldProps} />
                        </Col>
                    </div>
                    {props.controls}
                </Form>
            </div>
        );
    };
};
