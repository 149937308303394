import { Form, FormikProps } from 'formik';
import * as React from 'react';
import { FieldGroup } from '../../../misc/FieldGroup';
import { FieldGroupDatePicker } from '../../../misc/FieldGroupDatePicker';
import { FieldGroupTextarea } from '../../../misc/FieldGroupTextarea';

interface LeadReminderFormProps {
    controls: React.ReactNode;
}

export interface LeadReminderFields {
    title: string;
    description: string;
    remind_at: Date;
}

const labels = {
    title: 'Reminder Title',
    description: 'Notes',
    remind_at: 'Date'
};

export default (props: LeadReminderFormProps) => {
    return (bag: FormikProps<LeadReminderFields>) => {
        const fieldProps = {
            ...bag,
            labels
        };
        const maxLengthProspect = 500;

        return (
            <div className="form__holder-datapicker">
                <Form id="reminderForm">
                    <div className="indent-outside">
                        <FieldGroup id="title" {...fieldProps} />
                        <FieldGroupDatePicker type="time" id="remind_at" {...fieldProps} />
                        <FieldGroupTextarea
                            id="description"
                            {...fieldProps}
                            componentClass="textarea"
                            maxLength={maxLengthProspect}
                        />
                    </div>
                    {props.controls}
                </Form>
            </div>
        );
    };
};
