import React, { Reducer, useReducer, useState } from 'react';
import { observer } from 'mobx-react';
import {
    IconButton,
    AppHeader,
    AppPage,
    AppContent,
    AppList,
    AppListItem,
    AppListItemIcon,
    AppListItemContent,
    Button,
    Title,
    ButtonPrimary,
    Modal,
    ModalHeader,
    ModalActions,
    ButtonDanger,
    Form,
    InputText,
    ButtonUnstyled,
    ActionList,
    InputSelect,
    InputSwitch,
    useSnackbar
} from '../../components-v2/shared';

import useStore from '../../store/useStore';
import styles from './drip-schedule-editor.module.scss';
import { handlePromise, useAsyncEffect } from '../../util/async';
import {
    DripScheduleEditorListReducerAction,
    DripScheduleEditorListReducerState,
    dripScheduleEditorListReducer
} from './DripScheduleEditor.helpers';
import { FormikHelpers, FormikProps } from 'formik';
import { Link } from 'mobx-router';
import DefaultRoutes from '../../routes/DefaultRoutes';
import { CreateDripScheduleFormValidationSchema } from './DripScheduleEditor.validation';
import { DripSchedule } from './DripScheduleEditor.types';
import { LeadNurtureAppSource } from '../EmailEditor/EmailEditorList.helpers';
import { FEATURES } from '../../types/Features';

interface CreateDripScheduleForm {
    name: string;
    active: false;
    app_source_type: LeadNurtureAppSource;
}

export const DripScheduleEditorListPage = observer(() => {
    const { store } = useStore();
    const { addSnackbar } = useSnackbar();
    const { router, Api } = store;
    const { companyId } = router.params;
    const [appTypeFilter, setAppTypeFilter] = useState<LeadNurtureAppSource>();

    const [state, dispatch] = useReducer<
        Reducer<DripScheduleEditorListReducerState, DripScheduleEditorListReducerAction>
    >(dripScheduleEditorListReducer, {
        modal: {
            active: 'none',
            data: {}
        }
    });
    const { schedules, modal } = state;
    const { features } = store;

    const enabledApps = [LeadNurtureAppSource.customer]; // Always available by default
    if (features[FEATURES.schedule_genie]) {
        enabledApps.push(LeadNurtureAppSource.schedule_genie);
    }
    if (features[FEATURES.concession_manager]) {
        enabledApps.push(LeadNurtureAppSource.spotlight);
    }

    const lnDataSourceList = [
        { id: LeadNurtureAppSource.spotlight, name: 'Spotlight' },
        { id: LeadNurtureAppSource.schedule_genie, name: 'Schedule Genie' }
    ].filter(ds => {
        return enabledApps.includes(ds.id);
    });

    const leadNurtureTemplateFriendlyName = (app_source_type?: LeadNurtureAppSource): string => {
        if (!app_source_type) {
            return '';
        }
        switch (app_source_type) {
            case LeadNurtureAppSource.customer:
                return `General`;
            case LeadNurtureAppSource.schedule_genie:
                return `Schedule Genie`;
            case LeadNurtureAppSource.spotlight:
                return `Spotlight`;
            default:
                return '';
        }
    };

    const fetchDripSchedules = async () => {
        const [getDripSchedulesResponse, getDripSchedulesError] = await handlePromise<{
            data: { count: number; data: DripSchedule[] };
        }>(Api.client.get(`company/${companyId}/drip-schedules`));

        if (getDripSchedulesError || !getDripSchedulesResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'setSchedules', payload: getDripSchedulesResponse.data.data });
    };

    useAsyncEffect(fetchDripSchedules);

    const handleOpenModal = (modalName: string, modalData?: object) => {
        dispatch({ name: 'openModal', payload: modalName });

        if (modalData) {
            dispatch({ name: 'setModalData', payload: modalData });
        }
    };

    const handleCreateDripSchedule: (
        values: CreateDripScheduleForm,
        formikHelpers: FormikHelpers<CreateDripScheduleForm>
    ) => void = async ({ name, app_source_type }, formikHelpers) => {
        const newDripSchedule = {
            name,
            app_source_type,
            active: false,
            company_id: Number.parseInt(companyId, 10)
        };

        const [createDripScheduleResponse, createDripScheduleError] = await handlePromise<{ data: DripSchedule }>(
            Api.client.post(`company/${companyId}/drip-schedules`, newDripSchedule)
        );

        if (createDripScheduleError || !createDripScheduleResponse) {
            // TODO: handle error
            return;
        }

        formikHelpers.resetForm();
        router.goTo(
            DefaultRoutes.EditDripSchedulePage,
            { companyId, scheduleId: createDripScheduleResponse.data.id },
            store
        );
    };

    const handleDeleteDripSchedule = async (selectedDripSchedule?: DripSchedule) => {
        if (!selectedDripSchedule) {
            return;
        }

        const [deleteDripScheduleResponse, deleteDripScheduleError] = await handlePromise<{
            data: DripSchedule;
        }>(Api.client.delete(`company/${companyId}/drip-schedules/${selectedDripSchedule.id}`));

        if (deleteDripScheduleError || !deleteDripScheduleResponse) {
            // TODO: handle error
            return;
        }

        dispatch({ name: 'closeModal', payload: 'deleteDripSchedule' });

        await fetchDripSchedules();
    };

    const handleActiveToggle = async (selectedDripScheduleId?: number) => {
        if (!selectedDripScheduleId) {
            return;
        }

        const [toggleDripScheduleResponse, toggleDripScheduleError] = await handlePromise<{
            data: DripSchedule;
        }>(Api.client.patch(`company/${companyId}/drip-schedules/${selectedDripScheduleId}/toggle`));

        if (toggleDripScheduleError || !toggleDripScheduleResponse) {
            addSnackbar(toggleDripScheduleError.response.data || 'Failed to toggle Drip Schedule', {
                variant: 'error'
            });
            return;
        }

        //dispatch({ name: 'closeModal', payload: 'toggleDripSchedule' });

        await fetchDripSchedules();
    };

    return (
        <>
            <AppPage>
                <AppHeader title="Drip Schedules">
                    <div className="flex-spacer" />
                    <ButtonPrimary onClick={() => handleOpenModal('createDripSchedule')}>
                        Create Drip Schedule
                    </ButtonPrimary>
                </AppHeader>
                <AppContent loading={!schedules} className={styles['schedule-editor-list-page-content']}>
                    {schedules && schedules?.length > 0 && (
                        <AppList>
                            <InputSelect
                                name={`template_filter_selector`}
                                label="Filter by application type"
                                optionValueKey="id"
                                optionLabelKey="name"
                                onChange={(evt, val) => setAppTypeFilter(val)}
                                value={appTypeFilter}
                                contentEditable={false}
                                allowCreateOption={false}
                                options={lnDataSourceList}
                                autocompleteConfig={{
                                    disableClearable: false
                                }}
                            />
                            {schedules
                                ?.filter(sch => {
                                    return !appTypeFilter || appTypeFilter === sch.app_source_type;
                                })
                                .map(schedule => {
                                    const { id, name, app_source_type, active } = schedule;

                                    return (
                                        <AppListItem key={id}>
                                            <AppListItemIcon color="green" name="schedule" />

                                            <AppListItemContent
                                                title={
                                                    <ButtonUnstyled
                                                        as={buttonProps => (
                                                            <Link
                                                                {...buttonProps}
                                                                view={DefaultRoutes.EditDripSchedulePage}
                                                                params={{ companyId, scheduleId: id }}
                                                                store={store}
                                                            />
                                                        )}
                                                    >
                                                        {name}
                                                    </ButtonUnstyled>
                                                }
                                                description={leadNurtureTemplateFriendlyName(app_source_type)}
                                            />

                                            <ActionList position="end">
                                                <InputSwitch
                                                    onClick={() => handleActiveToggle(id)}
                                                    checked={!!active}
                                                />
                                                <IconButton
                                                    icon="search"
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.DripScheduleReportPage}
                                                            params={{ companyId, scheduleId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                />
                                                <IconButton
                                                    icon="pencil"
                                                    as={buttonProps => (
                                                        <Link
                                                            {...buttonProps}
                                                            view={DefaultRoutes.EditDripSchedulePage}
                                                            params={{ companyId, scheduleId: id }}
                                                            store={store}
                                                        />
                                                    )}
                                                />
                                                <IconButton
                                                    icon="trash"
                                                    onClick={() =>
                                                        handleOpenModal('deleteDripSchedule', {
                                                            selectedSchedule: schedule
                                                        })
                                                    }
                                                />
                                            </ActionList>
                                        </AppListItem>
                                    );
                                })}
                        </AppList>
                    )}

                    {schedules?.length === 0 && (
                        <div className={styles['schedule-editor-list-page-empty']}>
                            <Title className={styles['schedule-editor-list-page-empty-title']}>
                                Create your first Lead Nurturing drip schedule!
                            </Title>
                        </div>
                    )}
                </AppContent>
            </AppPage>
            <Modal
                isOpen={modal.active === 'createDripSchedule'}
                onAfterClose={() => dispatch({ name: 'closeModal' })}
                closeButton={false}
            >
                <ModalHeader title="New Drip Schedule" />
                <Form
                    initialValues={{
                        name: '',
                        active: false,
                        app_source_type: ''
                    }}
                    onSubmit={handleCreateDripSchedule}
                    validationSchema={CreateDripScheduleFormValidationSchema}
                >
                    {(formikProps: FormikProps<CreateDripScheduleForm>) => {
                        return (
                            <>
                                <InputText
                                    autoFocus={true}
                                    name="name"
                                    label="Schedule Name"
                                    formikProps={formikProps}
                                />
                                <InputSelect
                                    name="app_source_type"
                                    label="Application"
                                    labelPlacement="inset"
                                    optionValueKey="id"
                                    optionLabelKey="name"
                                    options={lnDataSourceList}
                                    formikProps={formikProps}
                                />
                                <ModalActions>
                                    <div className="flex-spacer" />
                                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                                    <ButtonPrimary type="submit">Create Schedule</ButtonPrimary>
                                </ModalActions>
                            </>
                        );
                    }}
                </Form>
            </Modal>
            <Modal
                isOpen={modal.active === 'deleteDripSchedule'}
                onAfterClose={() => dispatch({ name: 'setModalData', payload: {} })}
                closeButton={false}
            >
                <ModalHeader title={`Delete Drip Schedule`} />

                <p className="text">
                    Are you sure you want to delete{' '}
                    <strong className={styles['bold']}>{modal.data.selectedSchedule?.name}</strong>?
                </p>
                <ModalActions>
                    <div className="flex-spacer" />
                    <Button onClick={() => dispatch({ name: 'closeModal' })}>Cancel</Button>
                    <ButtonDanger onClick={() => handleDeleteDripSchedule(modal.data.selectedSchedule)}>
                        Delete Schedule
                    </ButtonDanger>
                </ModalActions>
            </Modal>
        </>
    );
});
